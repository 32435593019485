import gql from 'graphql-tag'
import { LanguageCodes, TASK_TYPES, StemType } from '@/constants'
import { Session } from '../navigationModels'
import { LC, CommonTaskAttributes, TasktypeData, Tasktype } from '../questionModels'

export const Tasktype6Query = gql`
  fragment Tasktype6 on Tasktype6 {
    __typename
    id
    data {
      slug {
        iv
      }
      reference {
        iv
      }
      morph {
        __language
      }
      stem {
        __language
      }
      morphedStem {
        __language
      }
      affix {
        iv
      }
      category {
        iv
      }
      audioDone {
        iv
      }
      unforgiving {
        iv
      }
      # specific attibutes start here..

      box1image {
        __language {
          url
          slug
        }
      }
      box1word {
        iv
      }
      box2image {
        __language {
          url
          slug
        }
      }
      box2word {
        iv
      }
      words {
        __language {
          text
          audio {
            url
            slug
          }
          word
        }
      }
    }
  }
`

interface Type6Attributes {
  box1image: LC<{ url: string; slug: string }[]>
  box1word: LC<string>
  box2image: LC<{ url: string; slug: string }[]>
  box2word: LC<string>
  unforgiving: boolean
  words: LC<
    {
      text: string
      audio: { url: string; slug: string }[]
      word: string
    }[]
  >
}
export interface Tasktype6Data extends TasktypeData {
  data: CommonTaskAttributes & Type6Attributes
}
export class Tasktype6 extends Tasktype {
  box1image = '' // URL
  box1word: StemType = StemType.None
  box2image = '' // URL
  box2word: StemType = StemType.None
  unforgiving = false
  words: {
    text: string
    audio: string // URL
    type: StemType
  }[] = []

  constructor(spec: Tasktype6Data, language: LanguageCodes, parent?: Session) {
    super(spec, language, parent)
    this.type = TASK_TYPES.Tasktype6
    this.parent = parent

    const b1i = spec.data.box1image[language]
    if (b1i) this.box1image = b1i[0]?.url + b1i[0]?.slug || ''
    this.box1word = spec.data.box1word.iv as StemType
    const b2i = spec.data.box2image[language]
    if (b2i) this.box2image = b2i[0]?.url + b2i[0]?.slug || ''
    this.box2word = spec.data.box2word.iv as StemType
    this.unforgiving = !!spec.data.unforgiving

    spec.data.words[language]?.forEach((w) => {
      this.words.push({
        text: w.text,
        audio: w.audio[0]?.url + w.audio[0]?.slug || '',
        type: (w.word as StemType) || StemType.None
      })
    })
  }

  get assetList(): string[] {
    const list = [this.box1image, this.box2image]
    this.words.forEach((w) => list.push(w.audio))
    return list
  }
}
