/*
 Designed and developed by Richard Nesnass

 This file is part of SL+.

 SL+ is free software: you can redistribute it and/or modify
 it under the terms of the GNU Affero General Public License as published by
 the Free Software Foundation, either version 3 of the License, or
 (at your option) any later version.

 GPL-3.0-only or GPL-3.0-or-later

 SL+ is distributed in the hope that it will be useful,
 but WITHOUT ANY WARRANTY; without even the implied warranty of
 MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 GNU Affero General Public License for more details.

 You should have received a copy of the GNU Affero General Public License
 along with SL+.  If not, see <http://www.gnu.org/licenses/>.
 */

/* eslint-disable @typescript-eslint/no-explicit-any */
import { h, resolveComponent } from 'vue'
import { createRouter, createWebHistory, createWebHashHistory, RouteRecordRaw } from 'vue-router'
import { baseUrl } from '@/constants'
import Landing from '../views/landing/LandingPage.vue'
import AfterLogin from '../views/landing/AfterLogin.vue'
import Delay from '../views/landing/Delay.vue'
import Privacy from '@/views/landing/PrivacyStatement.vue'

import Monitor from '../views/admin/Monitor.vue'
import MonitorUser from '../views/admin/MonitorUser.vue'
import MonitorGroups from '../views/admin/MonitorGroups.vue'
import MonitorGames from '../views/admin/MonitorGames.vue'
import MonitorLogs from '../views/admin/MonitorLogs.vue'
import MonitorMastery from '../views/admin/MonitorProgress.vue'
import MonitorSettings from '../views/admin/MonitorSettings.vue'

import Dashboard from '@/views/dashboard/Dashboard.vue'

import Task from '@/views/game/Task.vue'
import Lobby from '@/views/game/Lobby.vue'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'Landing',
    component: Landing
  },
  { path: '/index.html', redirect: '/' },
  {
    path: '/postlogin',
    name: 'AfterLogin',
    component: AfterLogin
  },
  {
    path: '/delay',
    name: 'Delay',
    component: Delay
  },
  {
    path: '/privacy',
    name: 'Privacy',
    component: Privacy
  },
  {
    // monitor / admin pages
    path: '/monitor',
    redirect: '/monitor/profile',
    name: 'Monitor',
    component: Monitor,
    children: [
      {
        path: 'settings',
        name: 'MonitorSettings',
        component: MonitorSettings
      },
      {
        // UserProfile will be rendered inside User's <router-view>
        // when /user/profile is matched
        path: 'profile',
        name: 'MonitorProfile',
        component: MonitorUser
      },
      {
        // UserProfile will be rendered inside User's <router-view>
        // when /user/groups is matched
        path: 'groups',
        name: 'MonitorGroups',
        component: MonitorGroups
      },
      {
        // UserPosts will be rendered inside User's <router-view>
        // when /user/games is matched
        path: 'games',
        name: 'MonitorGames',
        component: MonitorGames
      },
      {
        // UserPosts will be rendered inside User's <router-view>
        // when /user/projects is matched
        path: 'logs',
        name: 'MonitorLogs',
        component: MonitorLogs
      },
      {
        // UserPosts will be rendered inside User's <router-view>
        // when /user/projects is matched
        path: 'mastery',
        name: 'MonitorMastery',
        component: MonitorMastery
      }
    ]
  },
  {
    path: '/dashboard',
    name: 'Dashboard',
    component: Dashboard
  },
  {
    path: '/game',
    redirect: '/game/lobby',
    name: 'Game',
    component: {
      // Inline declaration of a component that renders our <router-view>
      render() {
        return h(resolveComponent('router-view'))
      }
    },
    children: [
      {
        // UserProfile will be rendered inside User's <router-view>
        // when /user/groups is matched
        path: 'lobby',
        name: 'Lobby',
        component: Lobby
      },
      {
        path: 'task/:schema?/:id?/:language?',
        props: true,
        name: 'Task',
        component: Task
      }
    ]
  },
  {
    path: '/sample/:schema/:id/:language',
    name: 'Sample',
    redirect: (to) => {
      return {
        name: 'Task',
        path: `/game/task`,
        params: {
          schema: to.params.schema,
          id: to.params.id,
          language: to.params.language
        }
      }
    }
  }
]

// Apparently Cordova does not work in browser 'history' mode
const useHashHistory = !!window.cordova //baseUrl.includes('engagelab') || baseUrl.includes('dev.kapteinmorf')
const baseURL = baseUrl.includes('engagelab') ? import.meta.env.BASE_URL : '/'
const routerType = useHashHistory // As engagelab server uses proxying for multiple apps, this is the easiest way..
  ? createWebHashHistory()
  : createWebHistory(baseURL)
console.log(`Hash history mode: ${useHashHistory ? 'on' : 'off'}`)

const router = createRouter({
  history: routerType,
  routes
})

router.beforeEach((to, from) => {
  const isSample = to.fullPath.includes('/sample')
  // If we reloaded after authentication, we might need to continue logging in
  if (
    !(from.name || to.redirectedFrom?.name) &&
    to.name !== 'Login' &&
    to.name !== 'Privacy' &&
    to.name !== 'AfterLogin' &&
    to.name !== 'Landing' &&
    !isSample
  ) {
    return { name: 'AfterLogin' }
  }
})

export default router
