<template>
  <!-- class="nudge-audio-recorder flex items-end" -->
  <div>
    <button
      class="w-14 h-14 justify-center items-center flex rounded-full p-4"
      :class="recordingClass"
      :style="recordingStyles"
      @click.stop="toggleRecord()"
    >
      <img
        v-if="!listeningState"
        class="absolute invert w-10 h-10 p-1"
        :src="WalkieTalkie"
        alt="Record stop button"
      />
      <img
        v-else
        class="absolute invert w-12 h-12 p-px -ml-px"
        :src="Intercom"
        alt="Record stop button"
      />
    </button>
  </div>
</template>

<script setup lang="ts">
import { ref, watch, Ref, onMounted, reactive, computed } from 'vue'

import useMultiPlayerState from '@/composition/useMultiplayerState'

// Assets
import Intercom from '@/assets/icons/svg/intercom.svg'
import WalkieTalkie from '@/assets/icons/fontawesome/walkie-talkie-solid.svg'
import { WebAudio } from '@/models/audio'

const isRecording = ref(false)
const recorder: Ref<MediaRecorder | null> = ref(null)
let chunks: Blob[] = []

const multiplayer = useMultiPlayerState()

const emit = defineEmits<{
  (e: 'sendIntercomParcel', audio: Blob): void
}>()

onMounted(() => {
  chunks = []
})

watch(multiplayer.getters.currentAudio, (audio) => {
  receiveIntercomParcel(audio)
})

const listeningState = ref(false)

const recordingClass = computed(() => {
  if (isRecording.value) return 'bg-green-500 animate-pulse'
  else return listeningState.value ? 'bgfadealt' : 'bg-red-600'
})

const recordingStyles = computed(() => {
  const padding = 20 //+ state.recordingPeak * 18 // 20 as the base value
  return isRecording.value ? `padding: ${padding}px;` : ''
})

const stopRecording = () => {
  if (recorder.value) {
    recorder.value.stop()
    isRecording.value = false
  } else console.log('MediaRecorder is undefined')
}

const startAudioStream = async (): Promise<boolean> => {
  if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
    console.log('getUserMedia supported.')
    chunks = []
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true })
      const context = new AudioContext()
      const analyser = context.createAnalyser()
      const source = context.createMediaStreamSource(stream)
      source.connect(analyser)

      recorder.value = new MediaRecorder(stream)
      recorder.value.ondataavailable = function (e: BlobEvent) {
        const array = new Uint8Array(analyser.fftSize)
        analyser.getByteTimeDomainData(array)
        /* state.recordingPeak =
          array.reduce((max, current) => Math.max(max, Math.abs(current - 127)), 0) / 128 */
        chunks.push(e.data)
      }
      recorder.value.onstop = function () {
        // once the recorder.stop() is called, this will be executed to transfer the data and reset the chunk state
        const audioBlob = new Blob(chunks, { type: 'audio/ogg; codecs=opus' })
        emit('sendIntercomParcel', audioBlob)
        chunks = []
      }
      return true
    } catch (err) {
      console.log('The following getUserMedia error occurred: ' + err)
      return false
    }
  } else {
    alert('getUserMedia not supported on your browser!')
    return false
  }
}

const toggleRecord = async () => {
  let recording = isRecording.value
  if (recording) stopRecording()
  else if (!listeningState.value) {
    recording = await startAudioStream()
    if (recording && recorder.value) {
      if (recorder.value.state !== 'inactive') recorder.value.stop()
      recorder.value.start() // 100ms interval for each Blob on data available
      isRecording.value = true
    } else alert('audio not supported on your device')
  }
}

const receiveIntercomParcel = async (audio: Blob | undefined) => {
  // do not play if the audio is reset to ''
  if (audio) {
    listeningState.value = true
    const audioAB = await audio.arrayBuffer()
    const a = new WebAudio(audioAB, '', () => (listeningState.value = false))
    a.onended = () => (listeningState.value = false)
    a.onerror = () => (listeningState.value = false)
    a.playWhenReady()
  }
}
</script>

<style scoped></style>
