/*
 Designed and developed by Richard Nesnass

 This file is part of SL+.

 SL+ is free software: you can redistribute it and/or modify
 it under the terms of the GNU Affero General Public License as published by
 the Free Software Foundation, either version 3 of the License, or
 (at your option) any later version.

 GPL-3.0-only or GPL-3.0-or-later

 SL+ is distributed in the hope that it will be useful,
 but WITHOUT ANY WARRANTY; without even the implied warranty of
 MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 GNU Affero General Public License for more details.

 You should have received a copy of the GNU Affero General Public License
 along with SL+.  If not, see <http://www.gnu.org/licenses/>.
 */
import { shuffleItems } from '@/utilities'
import { CmsGQLData, DISPLAY_MODE, SESSION_TYPE } from './main'
import { QuestionUnion } from './tasktypes'
import { MORFOLOGICAL_INTROS, TASK_TYPES, QUESTION_MODE, EPISODE_TYPE } from '@/constants'

interface Dictionary<T> {
  [Key: string]: T
}

type EnumDictionary<T extends string | symbol | number, U> = {
  [K in T]: U
}

export type { Dictionary, EnumDictionary }

interface LocationData {
  value: number
  label: string
  x: number
  y: number
  image: [{ url: string }] | undefined
}
export class Location {
  value: number
  label: string
  x: number
  y: number
  image: string

  constructor(data: LocationData) {
    this.value = data ? data.value : 0
    this.label = data ? data.label : ''
    this.x = data ? data.x : 0
    this.y = data ? data.y : 0
    this.image = data && data.image ? data.image[0].url : ''
  }

  get leftPC() {
    return this.x + '%'
  }

  get topPC() {
    return this.y + '%'
  }
}

export interface SessionData {
  id: string
  flatData: {
    name: string
    description: string
    password: string
    location: LocationData
    morfologicalIntro: MORFOLOGICAL_INTROS
    consolidation: boolean
    shuffleWarmups: boolean
    shuffleTasks: boolean
    type?: SESSION_TYPE
  }
}
export class Session {
  id = ''
  index = 0
  name = ''
  description = ''
  password = ''
  consolidation = false
  morfologicalIntro: MORFOLOGICAL_INTROS = MORFOLOGICAL_INTROS.None
  location?: Location
  type = SESSION_TYPE.singlePlayer

  warmups!: {
    [key in TASK_TYPES]: QuestionUnion[]
  }
  sortedWarmups: QuestionUnion[] = []
  shuffleWarmups = true
  warmupTaskCount = 0

  tasks!: {
    [key in TASK_TYPES]: QuestionUnion[]
  }
  sortedTasks: QuestionUnion[] = []
  shuffleTasks = true
  testTaskCount = 0

  // Local
  activated = false
  completed = false
  skipped = false

  // Frontend only
  disabled = false
  parent?: Episode
  collection?: Collection
  thumbnail = ''

  constructor(spec?: SessionData, parent?: Episode, index?: number, collection?: Collection) {
    this.createNewTaskLists()
    this.id = spec?.id || ''
    this.parent = parent
    this.collection = collection
    this.index = index || -1
    this.type = spec?.flatData.type || SESSION_TYPE.singlePlayer
    if (spec) this.updateSession(spec)
  }

  createNewTaskLists() {
    this.warmups = {
      Tasktype1: [],
      Tasktype2: [],
      Tasktype2mp: [],
      Tasktype22st: [],
      Tasktype23st: [],
      Tasktype24st: [],
      Tasktype3: [],
      Tasktype3mp: [],
      Tasktype4: [],
      Tasktype5: [],
      Tasktype6: [],
      Tasktype7: [],
      Tasktype8: [],
      Tasktype9: [],
      Tasktype9mp: [],
      Tasktype10: [],
      Tasktype10mp: [],
      Tasktype11: [],
      Tasktype11st: [],
      Tasktype12: [],
      Tasktype13: []
    }
    this.tasks = {
      Tasktype1: [],
      Tasktype2: [],
      Tasktype2mp: [],
      Tasktype22st: [],
      Tasktype23st: [],
      Tasktype24st: [],
      Tasktype3: [],
      Tasktype3mp: [],
      Tasktype4: [],
      Tasktype5: [],
      Tasktype6: [],
      Tasktype7: [],
      Tasktype8: [],
      Tasktype9: [],
      Tasktype9mp: [],
      Tasktype10: [],
      Tasktype10mp: [],
      Tasktype11: [],
      Tasktype11st: [],
      Tasktype12: [],
      Tasktype13: []
    }
  }

  updateSession(data: SessionData) {
    this.password = data.flatData.password || ''
    this.name = data.flatData.name || ''
    this.morfologicalIntro = data.flatData.morfologicalIntro || MORFOLOGICAL_INTROS.None
    this.consolidation = data.flatData.consolidation || false
    this.location = new Location(data.flatData.location)
    this.shuffleWarmups = data.flatData.shuffleWarmups || false
    this.shuffleTasks = data.flatData.shuffleTasks || false
  }

  public addWarmupQuestion(model: QuestionUnion, tasktype: TASK_TYPES) {
    model.mode = QUESTION_MODE.warmup
    const alreadyAddedIndex = this.warmups[tasktype].findIndex((task) => task.id === model.id)
    if (alreadyAddedIndex > 0) this.warmups[tasktype][alreadyAddedIndex] = model
    else {
      // only increase / push if the task doesn't exist yet
      this.warmups[tasktype].push(model)
      this.testTaskCount++
    }
  }

  public addTestQuestion(model: QuestionUnion, tasktype: TASK_TYPES) {
    model.mode = QUESTION_MODE.task
    const alreadyAddedIndex = this.tasks[tasktype].findIndex((test) => test.id === model.id)
    if (alreadyAddedIndex !== -1) this.tasks[tasktype][alreadyAddedIndex] = model
    else {
      // only increase / push if the task doesn't exist yet
      this.tasks[tasktype].push(model)
      this.testTaskCount++
    }
  }

  public get totalTaskCount() {
    return this.testTaskCount + this.warmupTaskCount
  }

  public clearTasks() {
    this.testTaskCount = 0
    this.warmupTaskCount = 0
    this.createNewTaskLists()
  }

  public activateSession(password = ''): boolean {
    if (password === this.password) {
      this.activated = true
      return true
    }
    return false
  }

  public get hasPassword(): boolean {
    return !!this.password
  }

  public get allTasks(): QuestionUnion[] {
    return Array.from(Object.values(this.tasks).flat())
  }
  public get allWarmups(): QuestionUnion[] {
    return Array.from(Object.values(this.warmups).flat())
  }

  /* public get tasks(): SPQuestionUnion | MPQuestionUnion | STQuestionUnion {
    switch (this.type) {
      case SESSION_TYPE.singlePlayer:
        return
    }
  } */
}

export interface CollectionData {
  sessions: SessionData[]
  shuffle: boolean
}
export interface Collection {
  index: number
  sessions: Session[]
  shuffle: boolean
}

export interface EpisodeData {
  id: string
  flatData: {
    name: string
    description: string
    location: LocationData
    collections?: CollectionData[]
    sets?: SessionData[]
    type: EPISODE_TYPE
    shuffle: boolean
  }
}

// This should match the second Navigation level in Squidex
export class Episode {
  id: string
  name: string
  location: Location
  collections: Collection[] = []
  type = EPISODE_TYPE.regular
  shuffle = false

  title = ''
  subtitle = ''
  description: string
  consolidation?: boolean

  // Frontend only
  disabled = false
  parent?: Activity
  completed?: boolean

  constructor(spec: EpisodeData, parent: Activity) {
    this.id = spec ? spec.id : ''
    this.name = spec ? spec.flatData.name : ''
    this.description = spec ? spec.flatData.description : ''
    this.shuffle = spec ? spec.flatData.shuffle : false
    this.type = spec ? spec.flatData.type : EPISODE_TYPE.regular

    this.parent = parent || undefined

    this.location = new Location(spec.flatData.location)
    if (spec && spec.flatData.collections) {
      spec.flatData.collections.forEach((s, index) => {
        const collection: Collection = {
          index,
          sessions: [],
          shuffle: s.shuffle
        }
        this.collections.push(collection)
        collection.sessions = s.sessions.map((s, j) => new Session(s, this, j, collection))
      })
    } else if (spec && spec.flatData.sets) {
      const collection: Collection = {
        index: 0,
        sessions: [],
        shuffle: false
      }
      this.collections.push(collection)
      spec.flatData.sets.forEach((s, j) => {
        collection.sessions.push(new Session(s, this, j, collection))
      })
    }
  }
}

export interface ReducedActivityData extends CmsGQLData {
  id: string
  flatData: {
    name: string
    description: string
    beginDate: Date
    finishDate: Date
    openingHour: string
    closingHour: string
  }
}

// This should match the first Navigation level in Squidex
export interface ActivityData extends CmsGQLData {
  id: string
  flatData: {
    name: string
    description: string
    displayMode: DISPLAY_MODE
    shuffle: boolean // shuffle main episodes
    episodes?: EpisodeData[] // V3
    pretest?: EpisodeData[] // V3
    posttest?: EpisodeData[] // V3
    sets?: EpisodeData[] // CMS backwards compatible
    beginDate: Date
    finishDate: Date
    openingHour: string
    closingHour: string
    exitPassword: string
  }
}

export class Activity {
  id = ''
  name = ''
  description = ''
  episodes: Episode[] = []
  beginDate: Date
  finishDate: Date
  openingHour: number
  closingHour: number
  exitPassword = ''

  parent: undefined

  constructor(spec?: ActivityData) {
    if (spec) {
      this.id = spec.id
      this.name = spec.flatData.name
      this.description = spec.flatData.description
      this.beginDate = new Date(spec.flatData.beginDate)
      this.finishDate = new Date(spec.flatData.finishDate)
      this.openingHour = parseInt(spec.flatData.openingHour)
      this.closingHour = parseInt(spec.flatData.closingHour)
      this.exitPassword = spec.flatData.exitPassword || ''
      this.episodes = []
      if (!spec.flatData.sets) {
        // V3 CMS - add all episodes (incl. pre and posttest) to episodes field

        let shuffledMainEpisodes = spec.flatData.episodes
        if (spec.flatData.shuffle && shuffledMainEpisodes)
          shuffledMainEpisodes = shuffleItems(shuffledMainEpisodes)
        const allEpisodes = [
          ...(spec.flatData.pretest || []),
          ...(shuffledMainEpisodes || []),
          ...(spec.flatData.posttest || [])
        ].flat() // this should ensure that pretests come before main episodes and main episodes before posttests
        allEpisodes.forEach((s) => this.episodes.push(new Episode(s, this)))
      }
      // CMS backwards compatible
      else spec.flatData.sets.forEach((s) => this.episodes.push(new Episode(s, this)))
    } else {
      // in case of a demo game
      this.beginDate = new Date()
      this.finishDate = new Date()
      this.openingHour = 0
      this.closingHour = 24
      this.episodes = []
    }
  }
}
