<!-- Copyright 2023 Richard Nesnass, Tom Bjarne Seidel

 This file is part of KMMP.

KMMP is free software: you can redistribute it and/or modify
 it under the terms of the GNU Affero General Public License as published by
 the Free Software Foundation, either version 3 of the License, or
 (at your option) any later version.

 GPL-3.0-only or GPL-3.0-or-later

KMMP is distributed in the hope that it will be useful,
 but WITHOUT ANY WARRANTY; without even the implied warranty of
 MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 GNU Affero General Public License for more details.

 You should have received a copy of the GNU Affero General Public License
 along with KMMP.  If not, see http://www.gnu.org/licenses/. -->
<template>
  <div class="flex flex-row h-full">
    <div class="px-2 border-r-2 border-grey w-1/4">
      <div class="flex flex-col text-left mr-1">
        <SelectionBox
          id="select-locationfilter"
          v-model="selectedGroup"
          :label="'Filter by location'"
          :options="groupList"
          @change="filterGamesByGroup"
        ></SelectionBox>
        <div class="flex flex-col flex-grow mt-3">
          <p class="font-bold">Games</p>
          <ul class="mt-3 half-height overflow-scroll text-white -ml-5">
            <li
              v-for="(g, i) in games"
              :id="`li-participant-${g._id}`"
              :key="'ap-' + i"
              class="cursor-pointer p-1 my-px border-t-2 border-slate-700 flex"
              :class="{
                'bg-black': g._id !== selectedGame?._id,
                'bg-slate-900': g._id === selectedGame?._id
              }"
              @click="selectGame(g)"
            >
              <span
                class="ml-4"
                :class="{
                  'text-red-500': g.details.dyadSplit,
                  'text-white': !g.details.dyadSplit
                }"
                >{{ g.profile.name }}</span
              >
              <span
                v-if="selectedGame && g._id === selectedGame._id"
                class="bg-green-500 rounded-full flex w-6 h-6 ml-2"
                >&nbsp;✔︎&nbsp;</span
              >
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div class="flex flex-col flex-grow text-left ml-2 overflow-y-auto w-3/4">
      <h1 class="font-bold">Game Details</h1>
      <div v-if="selectedGame && cmsRoot">
        <MonitorParticipantMastery :game="selectedGame" :cmsroot="cmsRoot" :results="results">
        </MonitorParticipantMastery>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, computed, Ref, watch, onMounted } from 'vue'
import { Group, Game, SPECIAL_REQUEST_TYPE, SpecialRequestData } from '@/models/main'

import useAppStore from '@/store/useAppStore'
import useUserStore from '@/store/useUserStore'
import useCMSStore from '@/store/useCMSStore'
import useGameStore from '@/store/useGameStore'

import MonitorParticipantMastery from '@/components/admin/MonitorParticipantProgress.vue'
import SelectionBox from '@/components/base/SelectionBox.vue'

interface GroupListItem {
  itemName: string
  item: Group
}

const { getters: appGetters } = useAppStore()
const { getters: userGetters, actions: userActions } = useUserStore()
const { getters: cmsGetters, actions: cmsActions } = useCMSStore()
const { getters: gameGetters, actions: gameActions } = useGameStore()

const groups = userGetters.selectedUserGroups
const selectedGroup: Ref<GroupListItem> = ref({
  item: new Group(),
  itemName: ''
})

const results: Ref<SpecialRequestData['data']> = ref({})
const selectedGame: Ref<Game | undefined> = ref()
const games = gameGetters.games

onMounted(async () => {
  if (groups.value[0]) {
    selectedGroup.value.itemName = groups.value[0].name
    selectedGroup.value.item = groups.value[0]

    // Fetch games inital value for the default location
    await gameActions.getGames(selectedGroup.value.item._id)
  }
  if (games.value.length > 0) selectGame(games.value[0]) // default select game
})

watch(gameGetters.selectedGame, (newValue) => {
  selectedGame.value = newValue
})

// ----------------  CMS --------------------------------

const getCMSData = () => {
  return cmsActions.getSets(appGetters.languageCode.value)
}

// --------------- Game functions -----------------

const groupList = computed((): GroupListItem[] => {
  return groups.value.map((g: Group) => ({
    item: g,
    itemName: g.name
  }))
})

const cmsRoot = computed(() => {
  const newRoot = cmsGetters.root.value
  return newRoot
})

const selectGame = (p: Game) => {
  cmsActions.setActivityID(p.details.currentActivityId) // set game activity id
  gameActions.selectGame(p)
  getCMSData().then(() => {
    gameActions
      .getSpecialRequest(p._id, SPECIAL_REQUEST_TYPE.successresults)
      .then((pd: SpecialRequestData) => {
        results.value = pd.data
        selectedGame.value = new Game(pd.game)
      })
  })
}

const filterGamesByGroup = (value: GroupListItem) => {
  selectedGroup.value = value
  userActions.selectGroup(selectedGroup.value.item)
  gameActions.getGames(selectedGroup.value.item._id).then(() => {
    if (games.value.length > 0) selectGame(games.value[0])
  })
}
</script>

<style scoped></style>
