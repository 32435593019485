import gql from 'graphql-tag'
import { ExternalSquidexData, LanguageCodes, TASK_TYPES } from '@/constants'
import { Session } from '../navigationModels'
import { LC, CommonTaskAttributes, TasktypeData, Tasktype } from '../questionModels'
import { WebAudio } from '../audio'

export const Tasktype22STQuery = gql`
  fragment Tasktype22st on Tasktype22st {
    __typename
    id
    data {
      name {
        __language
      }
      rounds {
        __language {
          instructionAudio {
            url
            slug
          }
          words {
            word
            wordAudio {
              url
              slug
            }
            image {
              url
            }
            altImage {
              url
            }
            order
          }
        }
      }
    }
  }
`
// TODO: does this need suffix/prefix and more of the 'common' task details?
interface Type22STAttributes {
  name: LC<string>
  rounds: LC<
    {
      instructionAudio: ExternalSquidexData[]
      words: {
        word: string
        wordAudio: ExternalSquidexData[]
        image: ExternalSquidexData[]
        altImage: ExternalSquidexData[]
        order: number
      }[]
    }[]
  >
}

// TODO: properly rename the interfaces

export interface Type22stRound {
  instructionAudio: ExternalSquidexData[]
  words: Type22stWord[]
}

export interface Type22stWord {
  hasValue: boolean
  word: string
  wordAudio: ExternalSquidexData[]
  audio: WebAudio | undefined
  audioURL: string // URL
  imageURL: string
  altImage: ExternalSquidexData[]
  order: number
  position: WordPosition
}

export interface WordPosition {
  x: number
  y: number
}

// NOTE: this does not extend TasktypeData because the old fields are simply not needed
export interface Tasktype22STData extends TasktypeData {
  data: CommonTaskAttributes & Type22STAttributes
}

export class Tasktype22st extends Tasktype {
  name = ''
  rounds: Type22stRound[] = []

  constructor(spec: Tasktype22STData, language: LanguageCodes, parent?: Session) {
    super(spec, language, parent)

    this.type = TASK_TYPES.Tasktype22st
    this.name = spec.data.name[language] || 'no name'

    const rounds = spec.data.rounds[language]
    if (rounds) {
      for (const round of rounds) {
        const words = [] as Type22stWord[]
        round.words?.forEach((w, index) =>
          words.push({
            hasValue: true,
            word: w.word,
            wordAudio: w.wordAudio,
            audio: undefined,
            audioURL: w.wordAudio[0]?.url + w.wordAudio[0]?.slug || '',
            imageURL: w.image[0]?.url + w.image[0]?.slug || '',
            altImage: w.altImage,
            order: w.order || index,
            position: { x: 0, y: 0 }
          })
        )

        this.rounds.push({
          instructionAudio: round.instructionAudio,
          words: words
        } as Type22stRound)
      }
    }
  }

  get assetList(): string[] {
    const list: string[] = []
    this.rounds.forEach((r) => {
      r.words.forEach((w) => {
        if (w.audioURL) list.push(w.audioURL)
        if (w.imageURL) list.push(w.imageURL)
      })
    })
    return list
  }
}
