import { mqttReconnectAttempts, ParcelType } from '@/constants'
import mqtt, {
  type IClientOptions,
  type IClientPublishOptions,
  type MqttClient,
  type IPublishPacket,
  type IConnackPacket,
  type IClientSubscribeOptions,
  Packet,
  ISubscriptionGrant
} from 'mqtt'

import { Parcel } from '@/models/main'

/* --------------  MQTT Server --------------- */

const MQTT_OPTIONS = {
  host: import.meta.env.VITE_MQTT_HOST,
  hostname: import.meta.env.VITE_MQTT_HOST,
  protocol: 'ws',
  port: import.meta.env.VITE_MQTT_PORT,
  path: '/kmmp_parcel',
  keepalive: 60,
  clientId: '',
  protocolId: 'MQTT',
  protocolVersion: 5,
  clean: true,
  reconnectPeriod: 5000,
  connectTimeout: 30 * 1000,
  username: '',
  password: '',
  rejectUnauthorized: false
} as IClientOptions

const SUBSCRIPTION_OPTIONS: IClientSubscribeOptions = {
  qos: 2
}

interface ServiceInterface {
  connected: () => boolean
  initializeClientConnection: ({
    userId,
    mqttUsername,
    mqttPassword,
    connectionCallback,
    newMessageCallback,
    catchCallback
  }: {
    userId: string
    mqttUsername: string
    mqttPassword: string
    connectionCallback: (data?: IConnackPacket, error?: Error) => void
    newMessageCallback: (topic: string, payload: Buffer, packet: IPublishPacket) => void
    catchCallback: (message: string) => void
  }) => Promise<void>
  closeClientConnection: () => Promise<void>
  subscribe: (topic: string) => Promise<string[]>
  unSubscribe: (topic: string) => Promise<void>
  publish: (topic: string, data: string | Buffer) => Promise<void>
  clientId: string
}

function useMqttService(): ServiceInterface {
  const clientId = `KMMP_CLIENT${Math.random().toString(16).substring(2, 8)}`
  let globalClient: MqttClient
  let mqttOptions: IClientOptions = {}
  let reconnectAttempts = 0

  const applyProxy = (options: IClientOptions): IClientOptions => {
    const host = import.meta.env.VITE_SERVER_HOST
    if (!host.includes('localhost') || import.meta.env.VITE_MQTT_PROTOCOL === 'wss') {
      options.protocol = 'wss'
      options.path = '/kmmp_parcel'
      options.port = import.meta.env.VITE_MQTT_PORT
    }
    return options
  }

  const setOptions = (username: string, password: string, userId: string) => {
    mqttOptions = { ...MQTT_OPTIONS, username: username, password: password }
    mqttOptions = applyProxy(mqttOptions)
    mqttOptions.clientId = createNewClientId(userId)
    if (mqttOptions.will) {
      const lastWillPayload = generateLastWillParcel(userId)
      mqttOptions.will.payload = lastWillPayload
      mqttOptions.will.topic = 'disconnection'
      mqttOptions.will.qos = 2
      // mqttOptions.will.retain = true
    }
  }

  const generateLastWillParcel = (userId: string): Buffer => {
    return Buffer.from(
      new Parcel({
        parcelType: ParcelType.UserDisconnect,
        subscription: {
          user: {
            id: userId,
            username: 'unknown - last will disconnect'
          }
        }
      }).serialised()
    )
  }

  const initializeClientConnection = ({
    userId,
    mqttUsername,
    mqttPassword,
    connectionCallback,
    newMessageCallback,
    catchCallback
  }: {
    userId: string
    mqttUsername: string
    mqttPassword: string
    connectionCallback: (data?: IConnackPacket, error?: Error) => void
    newMessageCallback: (topic: string, payload: Buffer, packet: IPublishPacket) => void
    catchCallback: (message: string) => void
  }): Promise<void> => {
    return new Promise((resolve, reject) => {
      setOptions(mqttUsername, mqttPassword, userId)
      if (!globalClient) {
        const clt = mqtt.connect(mqttOptions)
        if (clt) {
          globalClient = clt
          applyClientRules(connectionCallback, newMessageCallback, catchCallback)
          resolve()
        } else reject()
      } else {
        globalClient.reconnect(mqttOptions)
        applyClientRules(connectionCallback, newMessageCallback, catchCallback)
        resolve() // resolve if client is already connected
      }
    })
  }

  const createNewClientId = (userId: string) => {
    return encodeURIComponent(userId) + `_${Math.random().toString(16).substring(2, 8)}`
  }

  const applyClientRules = (
    connectionCallback: (data?: IConnackPacket, error?: Error) => void,
    newMessageCallback: (topic: string, payload: Buffer, packet: IPublishPacket) => void,
    catchCallback: (message: string) => void
  ) => {
    if (globalClient) {
      globalClient.on('connect', (packet: IConnackPacket): void => {
        console.log(`Connected to Message Broker at ${mqttOptions.host}`)
        connectionCallback(packet)
      })

      globalClient.on('disconnect', (): void => {
        console.log(`Disconnected from Message Broker at ${mqttOptions.host}`)
      })

      globalClient.on('message', (topic: string, payload: Buffer, packet: IPublishPacket): void => {
        newMessageCallback(topic, payload, packet)
      })

      globalClient.on('reconnect', async () => {
        console.log('Re-connected to Message Broker')
        if (reconnectAttempts === mqttReconnectAttempts) {
          reconnectAttempts = 0
          console.log('ENDING CONNECTION - RE-CONNECTION EXHAUSTED')
          await closeClientConnection()
        } else connectionCallback()
        reconnectAttempts += 1
      })

      globalClient.on('error', (error: Error) => {
        console.log(error)
        connectionCallback(undefined, error)
        catchCallback(error.message)
      })

      globalClient.on('close', () => {
        const msg = 'closed connection!'
        console.log(msg)
      })

      globalClient.on('offline', () => {
        const msg = 'lost connection!'
        console.log(msg)
      })
    } else console.error('No client available to listen to')
  }

  const connected = (): boolean => {
    return globalClient ? globalClient.connected : false
  }

  const closeClientConnection = (): Promise<void> => {
    return new Promise((resolve, reject) => {
      if (globalClient)
        globalClient
          .endAsync(false, mqttOptions)
          .then(() => {
            console.log('Disconnected from Message Broker')
            resolve()
          })
          .catch((error) => {
            if (error) reject(error)
          })
      else reject(new Error('No MQTT client available to close connection'))
    })
  }

  const subscribe = (topic: string): Promise<string[]> => {
    return new Promise((resolve, reject) => {
      let count = 0
      if (globalClient)
        globalClient
          .subscribeAsync(topic, SUBSCRIPTION_OPTIONS)
          .then((granted: ISubscriptionGrant[]) => {
            if (granted.length === 0) {
              if (count === 1) reject(new Error('No subscriptions granted by Broker'))
              unSubscribe(topic).then(async () => {
                // we try to unsubscribe and re-subscribe once before throwing an error
                await globalClient.subscribeAsync(topic, SUBSCRIPTION_OPTIONS)
                count = 1
              })
            } else resolve(granted.map((g) => g.topic).filter((g) => g))
          })
      else reject(new Error('No MQTT client available to subscribe to'))
    })
  }

  const unSubscribe = (topic: string): Promise<void> => {
    return new Promise((resolve, reject) => {
      if (globalClient) {
        globalClient.unsubscribe(topic, SUBSCRIPTION_OPTIONS)
        resolve()
      } else reject(new Error('No MQTT client available to unsubscribe from'))
    })
  }

  const publish = (topic: string, data: string | Buffer): Promise<void> => {
    const options: IClientPublishOptions = {
      qos: 2,
      properties: {
        contentType: 'application/json'
      },
      retain: false
    }
    return new Promise((resolve, reject) => {
      if (globalClient)
        globalClient.publishAsync(topic, data, options).then((packet: Packet | undefined) => {
          if (!packet) reject(new Error('Unable to publish'))
          console.log(`Data published on topic: ${topic}`)
          resolve()
        })
      else reject(new Error('No MQTT client available to publish to'))
    })
  }

  return {
    initializeClientConnection,
    closeClientConnection,
    subscribe,
    unSubscribe,
    publish,
    connected,
    clientId
  }
}

export default useMqttService
