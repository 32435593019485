<!-- Copyright 2023 Richard Nesnass, Tom Bjarne Seidel

 This file is part of KMMP.

KMMP is free software: you can redistribute it and/or modify
 it under the terms of the GNU Affero General Public License as published by
 the Free Software Foundation, either version 3 of the License, or
 (at your option) any later version.

 GPL-3.0-only or GPL-3.0-or-later

KMMP is distributed in the hope that it will be useful,
 but WITHOUT ANY WARRANTY; without even the implied warranty of
 MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 GNU Affero General Public License for more details.

 You should have received a copy of the GNU Affero General Public License
 along with KMMP.  If not, see http://www.gnu.org/licenses/. -->
<template>
  <div class="flex flex-col cursor-default relative bg-black min-h-screen w-full">
    <div class="flex flex-col p-4">
      <ul class="flex border-b px-11">
        <router-link to="/monitor/profile">
          <li
            v-if="hasMonitorRole"
            id="li-userstab"
            class="-mb-px mr-1 inline-block py-2 px-4 text-white font-semibold text-2xl"
            :class="{ tabSelected: selectedTab === 'users' }"
            @click="selectTab('users', 'profile')"
          >
            Users
          </li>
        </router-link>
        <router-link to="/monitor/groups">
          <li
            v-if="hasMonitorRole"
            id="li-groupstab"
            class="-mb-px mr-1 inline-block py-2 px-4 text-white font-semibold text-2xl"
            :class="{ tabSelected: selectedTab === 'groups' }"
            @click="selectTab('groups', 'groups')"
          >
            Classes
          </li>
        </router-link>
        <router-link :to="{ name: 'MonitorGames' }">
          <li
            v-if="hasMonitorRole"
            id="li-gamestab"
            class="-mb-px mr-1 inline-block py-2 px-4 text-white font-semibold text-2xl"
            :class="{ tabSelected: selectedTab === 'games' }"
            @click="selectTab('games', 'games')"
          >
            Games
          </li>
        </router-link>
        <router-link to="/monitor/mastery">
          <li
            v-if="hasMonitorRole"
            id="li-masterytab"
            class="-mb-px mr-1 inline-block py-2 px-4 text-white font-semibold text-2xl"
            :class="{ tabSelected: selectedTab === 'mastery' }"
            @click="selectTab('mastery', 'mastery')"
          >
            Progress
          </li>
        </router-link>
        <router-link to="/monitor/logs">
          <li
            v-if="hasLogsRole"
            id="li-masterytab"
            class="-mb-px mr-1 inline-block py-2 px-4 text-white font-semibold text-2xl"
            :class="{ tabSelected: selectedTab === 'logs' }"
            @click="selectTab('logs', 'logs')"
          >
            Logs
          </li>
        </router-link>
        <router-link to="/monitor/settings">
          <li
            v-if="hasMonitorRole"
            id="li-settingstab"
            class="-mb-px mr-1 inline-block py-2 px-4 text-white font-semibold text-2xl"
            :class="{ tabSelected: selectedTab === 'settings' }"
            @click="selectTab('settings', 'settings')"
          >
            Settings
          </li>
        </router-link>
      </ul>
    </div>

    <div class="flex flex-col px-4 flex-grow">
      <router-view></router-view>
    </div>
    <div class="absolute top-0 right-0 m-4">
      <a
        id="button-return"
        class="inline-block py-2 px-4 text-green-600 font-semibold cursor-pointer"
        @click="restart()"
        >Return</a
      >
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, computed } from 'vue'
import { useRouter } from 'vue-router'
import { USER_ROLE } from '@/constants'
import useUserStore from '@/store/useUserStore'
import useCmsStore from '@/store/useCMSStore'
import { LicenseManager } from 'ag-grid-enterprise'

LicenseManager.setLicenseKey(import.meta.env.VITE_AG_GRID_LICENSE_KEY as string)

export default defineComponent({
  name: 'MonitorComponent',
  setup() {
    const router = useRouter()
    const { actions: userActions, getters: userGetters } = useUserStore()
    const { actions: cmsActions } = useCmsStore()

    const selectedTab = ref('users')
    const selectedSubTab = ref('profile')

    const selectTab = (main: string, secondary: string) => {
      selectedTab.value = main
      selectedSubTab.value = secondary
    }

    const restart = () => {
      cmsActions.resetStorage()
      router.push(`/postlogin`)
    }

    /*Computed functions */
    const hasMonitorRole = computed(() =>
      userActions.hasMinimumRole(userGetters.myUser.value, USER_ROLE.monitor)
    )
    const hasLogsRole = computed(() =>
      userActions.hasMinimumRole(userGetters.myUser.value, USER_ROLE.logs)
    )

    return {
      // Computed
      hasMonitorRole,
      hasLogsRole,
      selectedTab,
      selectedSubTab,

      // Methods
      restart,
      selectTab
    }
  }
})
</script>

<style scoped lang="postcss">
.tabSelected {
  border-bottom: 1px solid black;
  @apply border-l border-t border-r rounded-t text-blue-200;
}
</style>
