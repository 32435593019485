import { WebAudio } from '@/models/audio'
import useDeviceService from '@/composition/useDevice'

interface SoundControl {
  soundList: (string | WebAudio | Media)[]
  soundTimeout?: NodeJS.Timeout
  currentSound?: WebAudio | Media
  forceStop: boolean
  endSoundCallback?: () => unknown
}

const { actions: deviceActions } = useDeviceService()

const soundControl: SoundControl = {
  soundList: [],
  soundTimeout: undefined,
  currentSound: undefined,
  forceStop: false,
  endSoundCallback: () => ({}),
}

// Create a new audio from a source URL and load it, checking first if it is in cache
export const createSound = async (url: string | ArrayBuffer, onEnded?: () => unknown): Promise<WebAudio> => {
  // Check for sound in media cache first
  let source
  if (typeof url === 'string') source = await deviceActions.getCachedMedia(url, false)
  else source = url
  let sound: WebAudio
  if (typeof source === 'string') {
    sound = new WebAudio(undefined, source, onEnded)
  } else {
    sound = new WebAudio(source, '', onEnded)
  }
  console.log(`WebAudio created: ${url}`)
  return sound
}

export const getLocalSoundURI = async (url: string): Promise<string> => {
  // Check for sound in media cache first
  const source = await deviceActions.getCachedMediaURI(url)
  return source
}

/* const playSounds = async (soundFiles?: (string | MP3Audio | Media)[], delay?: number, callback?: () => unknown): Promise<void> => {
  if (soundFiles) {
    soundControl.soundList = [...soundFiles]
    soundControl.forceStop = false // Reset this when we play a new list next time
  }
  if (callback) soundControl.endSoundCallback = callback

  const s = soundControl.soundList.shift()
  if (s) {
    if (typeof s === 'string') {
      try {
        soundControl.currentSound = await createSound(s, playSounds)
      } catch (error) {
        console.log(`Error creating sound ${s}: ${error}`)
        playSounds()
      }
    } else {
      soundControl.currentSound = s
    }
    soundControl.soundTimeout = setTimeout(async () => {
      try {
        if (soundControl.currentSound) soundControl.currentSound.play()
      } catch (error) {
        console.log(`Error playing sound ${s}: ${error}`)
        playSounds()
      }
    }, delay || 500)
  } else if (soundControl.soundList.length) playSounds()
  else if (soundControl.endSoundCallback && !soundControl.forceStop) soundControl.endSoundCallback()
} */

export const stopSounds = (): void => {
  soundControl.forceStop = true // Prevent callback being called when Media is stopped (rather than 'ended')
  if (soundControl.soundTimeout) clearTimeout(soundControl.soundTimeout)
  if (window.cordova && soundControl.currentSound && soundControl.currentSound instanceof Media) {
    soundControl.currentSound.stop()
  } else if (soundControl.currentSound && soundControl.currentSound instanceof HTMLAudioElement) {
    soundControl.currentSound.pause()
    soundControl.currentSound.currentTime = 0
  }
}
