import gql from 'graphql-tag'
import { LanguageCodes, TASK_TYPES } from '@/constants'
import { Session } from '../navigationModels'
import { LC, CommonTaskAttributes, TasktypeData, Tasktype } from '../questionModels'

export const Tasktype1Query = gql`
  fragment Tasktype1 on Tasktype1 {
    __typename
    id
    data {
      reference {
        iv
      }
      slug {
        iv
      }
      morph {
        __language
      }
      stem {
        __language
      }
      morphedStem {
        __language
      }
      affix {
        iv
      }
      category {
        iv
      }
      audioDone {
        iv
      }
      # specific attibutes start here..
      example {
        __language {
          stemImage {
            url
            slug
          }
          stemAudio {
            url
            slug
          }
          stemAudioText
          captionBeforeStem
          customStem
          captionAfterStem
          morphedStemImage {
            url
            slug
          }
          morphedStemAudio {
            url
            slug
          }
          morphedStemAudioText
          captionBeforeMorphedStem
          customMorphedStem
          captionAfterMorphedStem
        }
      }
      challenge {
        __language {
          instructionBeforeWord
          word
          wordAudio {
            url
            slug
          }
          instructionAfterWord
          instructionAudio {
            url
            slug
          }
          instructionAudioText
          image1 {
            url
            slug
          }
          image2 {
            url
            slug
          }
          correctImage
        }
      }
    }
  }
`

interface Type1Attributes {
  example: LC<{
    stemImage: { url: string; slug: string }[]
    stemAudio: { url: string; slug: string }[]
    stemAudioText: string
    captionBeforeStem: string
    customStem: string
    captionAfterStem: string
    morphedStemImage: { url: string; slug: string }[]
    morphedStemAudio: { url: string; slug: string }[]
    morphedStemAudioText: string
    captionBeforeMorphedStem: string
    customMorphedStem: string
    captionAfterMorphedStem: string
  }>
  challenge: LC<{
    instructionBeforeWord: string
    word: string
    wordAudio: { url: string; slug: string }[]
    instructionAfterWord: string
    instructionAudio: { url: string; slug: string }[]
    instructionAudioText: string
    image1: { url: string; slug: string }[]
    image2: { url: string; slug: string }[]
    correctImage: string
  }>
}
export interface Tasktype1Data extends TasktypeData {
  data: CommonTaskAttributes & Type1Attributes
}
export class Tasktype1 extends Tasktype {
  example = {
    stemImage: '', // URL
    stemAudio: '', // URL
    stemAudioText: '',
    captionBeforeStem: '',
    customStem: '',
    captionAfterStem: '',

    morphedStemImage: '', // URL
    morphedStemAudio: '', // URL
    morphedStemAudioText: '',
    captionBeforeMorphedStem: '',
    customMorphedStem: '',
    captionAfterMorphedStem: ''
  }
  challenge = {
    instructionBeforeWord: '',
    word: '',
    wordAudio: '', // URL
    instructionAfterWord: '',
    instructionAudio: '', // URL
    instructionAudioText: '',
    image1: '', // URL
    image2: '', // URL
    correctImage: ''
  }

  constructor(spec: Tasktype1Data, language: LanguageCodes, parent?: Session) {
    super(spec, language, parent)
    this.type = TASK_TYPES.Tasktype1
    const ed = spec.data.example[language]
    if (ed) {
      if (!ed.stemImage[0]?.slug || !ed.morphedStemImage[0]?.slug) {
        console.log(`Missing slug in ${this.__typename} ${this.reference}`)
      }
      this.example = {
        stemImage: (ed.stemImage && ed.stemImage[0]?.url + ed.stemImage[0]?.slug) || '',
        stemAudio: (ed.stemAudio && ed.stemAudio[0]?.url + ed.stemAudio[0]?.slug) || '',
        stemAudioText: ed.stemAudioText,
        captionBeforeStem: ed.captionBeforeStem,
        customStem: ed.customStem,
        captionAfterStem: ed.captionAfterStem,

        morphedStemImage:
          (ed.morphedStemImage && ed.morphedStemImage[0]?.url + ed.morphedStemImage[0]?.slug) || '',
        morphedStemAudio:
          (ed.morphedStemAudio && ed.morphedStemAudio[0]?.url + ed.morphedStemAudio[0]?.slug) || '',
        morphedStemAudioText: ed.morphedStemAudioText,
        captionBeforeMorphedStem: ed.captionBeforeMorphedStem,
        customMorphedStem: ed.customMorphedStem,
        captionAfterMorphedStem: ed.captionAfterMorphedStem
      }
    }
    const cd = spec.data.challenge[language]
    if (cd) {
      if (!cd.image1[0]?.slug || !cd.image2[0]?.slug) {
        console.log(`Missing slug in ${this.__typename} ${this.reference}`)
      }
      this.challenge = {
        instructionBeforeWord: cd.instructionBeforeWord,
        word: cd.word,
        wordAudio: (cd.wordAudio && cd.wordAudio[0]?.url + cd.wordAudio[0]?.slug) || '',
        instructionAfterWord: cd.instructionAfterWord,
        instructionAudio:
          (cd.instructionAudio && cd.instructionAudio[0]?.url + cd.instructionAudio[0]?.slug) || '',
        instructionAudioText: cd.instructionAudioText,
        image1: (cd.image1 && cd.image1[0]?.url + cd.image1[0]?.slug) || '',
        image2: (cd.image2 && cd.image2[0]?.url + cd.image2[0]?.slug) || '',
        correctImage: cd.correctImage
      }
    } else console.log('Type1: GraphQL data null')
    /* if (useLocalAssets) {
    this.rewriteUrlsToLocalAssets(spec)
  } */
  }

  get assetList(): string[] {
    return [
      this.example.stemImage,
      this.example.stemAudio,
      this.example.morphedStemImage,
      this.example.morphedStemAudio,
      this.challenge.wordAudio,
      this.challenge.instructionAudio,
      this.challenge.image1,
      this.challenge.image2
    ]
  }

  /* rewriteUrlsToLocalAssets(input: Object) {
  if (this.example) {
    if (this.example.stemImage) {
      this.example.stemImage.url = localAssetUrl + input['example']['stemImage']['path'] + '/' + input['example']['stemImage']['filename']
    }
    if (this.example.stemAudio) {
      this.example.stemAudio.url = localAssetUrl + input['example']['stemAudio']['path'] + '/' + input['example']['stemAudio']['filename']
    }
    if (this.example.morphedStemImage) {
      this.example.morphedStemImage.url =
        localAssetUrl + input['example']['morphedStemImage']['path'] + '/' + input['example']['morphedStemImage']['filename']
    }
    if (this.example.morphedStemAudio) {
      this.example.morphedStemAudio.url =
        localAssetUrl + input['example']['morphedStemAudio']['path'] + '/' + input['example']['morphedStemAudio']['filename']
    }
  }
  if (this.challenge) {
    if (this.challenge.image1) {
      this.challenge.image1.url = localAssetUrl + input['challenge']['image1']['path'] + '/' + input['challenge']['image1']['filename']
    }
    if (this.challenge.image2) {
      this.challenge.image2.url = localAssetUrl + input['challenge']['image2']['path'] + '/' + input['challenge']['image2']['filename']
    }
    if (this.challenge.instructionAudio) {
      this.challenge.instructionAudio.url =
        localAssetUrl + input['challenge']['instructionAudio']['path'] + '/' + input['challenge']['instructionAudio']['filename']
    }
    if (this.challenge.wordAudio) {
      this.challenge.wordAudio.url =
        localAssetUrl + input['challenge']['wordAudio']['path'] + '/' + input['challenge']['wordAudio']['filename']
    }
  }
} */
}
