/*
 Designed and developed by Richard Nesnass

 This file is part of SL+.

 SL+ is free software: you can redistribute it and/or modify
 it under the terms of the GNU Affero General Public License as published by
 the Free Software Foundation, either version 3 of the License, or
 (at your option) any later version.

 GPL-3.0-only or GPL-3.0-or-later

 SL+ is distributed in the hope that it will be useful,
 but WITHOUT ANY WARRANTY; without even the implied warranty of
 MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 GNU Affero General Public License for more details.

 You should have received a copy of the GNU Affero General Public License
 along with SL+.  If not, see <http://www.gnu.org/licenses/>.
 */
import { ref, computed, ComputedRef, Ref } from 'vue'
import router from '../router'
import { apiRequest } from '@/api/apiRequest'
import useDeviceService from '@/composition/useDevice'
import {
  PersistedAppState,
  LocalUser,
  APIRequestPayload,
  XHR_REQUEST_TYPE,
  DialogConfig,
  CordovaData,
  User
} from '@/models/main'

import { appVersion, LanguageCodes } from '../constants'

// ------------  Types --------------
interface UserPasswordLoginResponse {
  code: string
  user: User
}
interface AppStatus {
  validToken: boolean
  loading: boolean
  isMobileApp: boolean
}
interface AppState {
  validToken: boolean
  errorMessage: string
  loading: boolean
  lastLogin: Date
  currentLocalUser: LocalUser | undefined
  appIsOld: boolean
  languageCode: LanguageCodes
  disableDelays: boolean
  feedbackText: string
  dialogConfig: DialogConfig
}
// ------------  State (internal) --------------
const _appState: Ref<AppState> = ref({
  validToken: false,
  errorMessage: '',
  loading: false,
  lastLogin: new Date(),
  currentLocalUser: undefined,
  appIsOld: false,
  languageCode: LanguageCodes.no,
  disableDelays: import.meta.env.VITE_DISABLE_DELAYS === 'true',
  feedbackText: '',
  dialogConfig: {
    title: '',
    text: '',
    visible: false,
    cancel: () => ({}),
    cancelText: 'Cancel',
    confirm: () => ({}),
    confirmText: 'Confirm'
  }
})

// This will be saved to device storage
const _persistedAppState: Ref<PersistedAppState> = ref({
  localUsers: {}
})
let feedbackTimeout: ReturnType<typeof setTimeout>

// ------------  Getters (Read only) --------------
interface Getters {
  status: ComputedRef<AppStatus>
  languageCode: ComputedRef<LanguageCodes>
  disableDelays: ComputedRef<boolean>
  currentLocalUser: ComputedRef<LocalUser | undefined>
  persistedLocalUsers: ComputedRef<Record<string, LocalUser>>
  dialogConfig: ComputedRef<DialogConfig>
  feedback: ComputedRef<string>
}
interface Actions {
  setDisableDelays: (s: boolean) => void
  setLanguageCode: (lang: LanguageCodes) => void
  setError: (message: string) => void
  setLoading: (loading: boolean) => void
  setCurrentLocalUser: (user: LocalUser) => void
  logout: (rememberMe: boolean) => Promise<void>
  tokenLogin: () => Promise<boolean>
  userPasswordLogin: (username: string, password: string) => Promise<boolean>
  smsLogin: (mobil: string) => Promise<void>
  smsCode: (query: Record<string, string>) => Promise<void>
  loadSettings: () => Promise<void>
  saveSettings: () => Promise<void>
  detectOldApp: () => Promise<void>
  setDialog: (visible: boolean, config?: DialogConfig) => void
  logFeedback: (feedback: string) => void
}
interface ServiceInterface {
  actions: Actions
  getters: Getters
}
function useAppStore(): ServiceInterface {
  const { actions: deviceActions, getters: deviceGetters } = useDeviceService()
  const getters = {
    get status(): ComputedRef<AppStatus> {
      return computed(() => ({
        loading: _appState.value.loading,
        validToken: _appState.value.validToken,
        isMobileApp: deviceGetters.deviceReady.value || navigator.userAgent.indexOf('Mobile') > -1
      }))
    },
    get languageCode(): ComputedRef<LanguageCodes> {
      return computed(() => _appState.value.languageCode)
    },
    // TESTING ONLY. Remove app delays and show cheat functions
    get disableDelays(): ComputedRef<boolean> {
      return computed(() => _appState.value.disableDelays)
    },
    get currentLocalUser(): ComputedRef<LocalUser | undefined> {
      return computed(() => {
        return _appState.value.currentLocalUser ? _appState.value.currentLocalUser : undefined
      })
    },
    get persistedLocalUsers(): ComputedRef<Record<string, LocalUser>> {
      return computed(() => _persistedAppState.value.localUsers)
    },
    get dialogConfig(): ComputedRef<DialogConfig> {
      return computed(() => _appState.value.dialogConfig)
    },
    get feedback(): ComputedRef<string> {
      return computed(() => _appState.value.feedbackText)
    }
  }
  // ------------  Actions --------------

  const actions = {
    setDialog(visible: boolean, config?: DialogConfig): void {
      if (config) _appState.value.dialogConfig = config
      _appState.value.dialogConfig.visible = visible
    },
    logFeedback(feedback: string): void {
      clearTimeout(feedbackTimeout)
      _appState.value.feedbackText = feedback
      feedbackTimeout = setTimeout(() => (_appState.value.feedbackText = ''), 3000)
    },

    // FOR TESTING ONLY. Remove app delays
    setDisableDelays(s: boolean) {
      _appState.value.disableDelays = s
    },
    setLanguageCode: function (languageCode: LanguageCodes): void {
      _appState.value.languageCode = languageCode
    },
    setError: function (message: string): void {
      _appState.value.errorMessage = message
    },
    setLoading: function (loading: boolean): void {
      _appState.value.loading = loading
    },
    setCurrentLocalUser: function (user: LocalUser): void {
      _appState.value.currentLocalUser = user
      localStorage.setItem('jwt', user.jwt)
    },
    // The intention of logout is to enforce a new login with the server
    logout: async function (rememberMe: boolean): Promise<void> {
      await apiRequest({
        route: '/auth/logout',
        method: XHR_REQUEST_TYPE.GET,
        credentials: true
      })
      if (_appState.value.currentLocalUser) {
        if (rememberMe) {
          _persistedAppState.value.localUsers[_appState.value.currentLocalUser._id] =
            _appState.value.currentLocalUser
        } else {
          delete _persistedAppState.value.localUsers[_appState.value.currentLocalUser._id]
        }
        _appState.value.currentLocalUser = undefined
      }
      localStorage.removeItem('jwt')
      await this.saveSettings()
    },
    // Call server for the current version of the app
    detectOldApp: async function (): Promise<void> {
      const payload: APIRequestPayload = {
        method: XHR_REQUEST_TYPE.GET,
        credentials: false,
        route: '/api/appversion',
        contentType: 'text/html'
      }
      let version = ''
      try {
        version = await apiRequest<string>(payload)
      } catch (error: unknown) {
        console.log(`Error getting server version: ${error}`)
      }
      if (appVersion !== version) {
        _appState.value.appIsOld = true
      }
    },
    loadSettings: function (): Promise<void> {
      return new Promise((resolve) => {
        const cd: CordovaData = new CordovaData({
          fileName: 'settings.json',
          readFile: true,
          asText: true,
          asJSON: true
        })
        // If the file does not exist, our cordovaService will create it
        return deviceActions.loadFromStorage<PersistedAppState>(cd).then((data) => {
          if (data) {
            _persistedAppState.value.localUsers = {}
            const d = data
            Object.keys(d.localUsers).forEach(
              (key) => (_persistedAppState.value.localUsers[key] = d.localUsers[key])
            )
          }
          resolve()
        })
      })
    },
    saveSettings: function (): Promise<void> {
      const cd: CordovaData = new CordovaData({
        fileName: 'settings.json', // Saved to app's root folder
        data: _persistedAppState.value,
        asText: true,
        asJSON: true
      })
      return deviceActions.saveToStorage(cd)
    },
    // Try to exchange token for a session if the token already exists
    tokenLogin: function (): Promise<boolean> {
      return apiRequest({
        route: '/auth/token',
        method: XHR_REQUEST_TYPE.GET,
        credentials: true
      })
        .then(() => {
          // We now have an active session so proceed as normal..
          router.push('/postlogin')
          return Promise.resolve(true)
        })
        .catch(() => {
          // Exchange was not accepted, clear the token and redirect to login page
          console.log('No valid token. Redirecting to login page..')
          _appState.value.currentLocalUser = undefined
          return Promise.resolve(false)
        })
    },
    // Request One Time Code by mobile number and password
    smsLogin: function (mobil: string): Promise<void> {
      return apiRequest({
        route: '/auth/sms/login',
        method: XHR_REQUEST_TYPE.POST,
        credentials: true,
        body: { mobil }
      })
    },
    // Log in with One Time Code
    smsCode: function (query: Record<string, string>): Promise<void> {
      query['appVersion'] = appVersion
      return apiRequest({
        route: '/auth/sms/code',
        method: XHR_REQUEST_TYPE.GET,
        credentials: true,
        query
      })
    },
    // Log in with username and password
    userPasswordLogin: function (username: string, password: string): Promise<boolean> {
      const client = getters.status.value.isMobileApp ? 'mobileApp' : 'webApp'
      return apiRequest<UserPasswordLoginResponse>({
        route: '/auth/password/login',
        method: XHR_REQUEST_TYPE.POST,
        credentials: true,
        body: { username, password, client, appVersion }
      }).then((data: UserPasswordLoginResponse) => {
        if (data.code) {
          localStorage.setItem('jwt', data.code)
          return actions.tokenLogin()
        } else {
          return Promise.resolve(true)
        }
      })
    }
  }
  return {
    getters,
    actions
  }
}

type AppStoreType = ReturnType<typeof useAppStore>
//export const AppKey: InjectionKey<UseApp> = Symbol('UseApp')
export type { AppStoreType, AppState }
export default useAppStore
