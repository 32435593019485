/* eslint-disable @typescript-eslint/no-unused-vars */
<!-- Copyright 2023 Richard Nesnass, Tom Bjarne Seidel

 This file is part of KMMP.

KMMP is free software: you can redistribute it and/or modify
 it under the terms of the GNU Affero General Public License as published by
 the Free Software Foundation, either version 3 of the License, or
 (at your option) any later version.

 GPL-3.0-only or GPL-3.0-or-later

KMMP is distributed in the hope that it will be useful,
 but WITHOUT ANY WARRANTY; without even the implied warranty of
 MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 GNU Affero General Public License for more details.

 You should have received a copy of the GNU Affero General Public License
 along with KMMP.  If not, see http://www.gnu.org/licenses/. -->
<template>
  <div>
    <div
      class="relative flex flex-col justify-center items-center fadeInOut"
      :style="{ opacity: opacity }"
    >
      <div class="flex flex-wrap justify-between items-center relative text-white h-full">
        <div
          v-for="(image, i) in images"
          :key="i"
          class="flex borderedImage imageMedium overflow-hidden items-end"
          :class="imageClasses(image)"
        >
          <img
            v-cache
            class="flex self-center w-full h-full object-contain bg-white"
            :src="image.imageUrl"
            @click="clickAnswer(image)"
          />
        </div>
      </div>
    </div>

    <span class="borderedWordBox m-auto w-32 left-0 right-0" @click="playWordAudio()">
      {{ taskRound.instructionBeforeWord }}
      <span class="wordHighlight">{{
        taskRound.word === StemType.Stem ? props.task.stem : props.task.morphedStem
      }}</span>
      {{ taskRound.instructionAfterWord }}</span
    >
  </div>
</template>

<script setup lang="ts">
import { ref, PropType, toRefs, computed, Ref } from 'vue'
import { Choice, Tracking } from '@/models/main'
// import { useI18n } from 'vue-i18n'
// import useCMSStore, { CMSStoreType } from '@/store/useCMSStore'
import useState from '@/composition/useState'
import { shuffleItems } from '@/utilities'
import { SpeechSounds, TaskMode, StemType } from '@/constants'
import { Tasktype2 } from '@/models/tasktypes/Tasktype2'
import { WebAudio } from '@/models/audio'
import { createSound } from '@/api/audioService'
import moment from 'moment'

interface Image {
  id: string
  index: number
  name: string
  imageUrl: string
  correct1: boolean // correct for the first word
  correct2: boolean // correct for the second word
  enabled: boolean
  borderGreen: boolean
  borderRed: boolean
}

const emit = defineEmits(['completed'])
const props = defineProps({
  task: { required: true, type: Object as PropType<Tasktype2> },
  myIndex: { required: false, type: Number, default: 0 }
})
/* const messages = {
    no: {},
    sv: {},
    en: {},
  } */
//const { t } = useI18n({ messages })
//const cmsStore: CMSStoreType = useCMSStore()
const { getters: stateGetters, setters: stateSetters, actions: stateActions } = useState()
const { task } = toRefs(props)

const tracking = new Tracking(stateGetters.tracking.value)
stateSetters.trackingData = tracking
let choiceTimer = new Date()

// 'correct' is the count of correctly answered items. 'of' is the total allocated correct items
const roundData = { round1: { correct: 0, of: 0 }, round2: { correct: 0, of: 0 } }
const images: Ref<Image[]> = ref([])
const opacity = ref(0)
let playingAudio = false
let textAudio: WebAudio
let instructionAudio: WebAudio
const unforgivingTestMode = false
const round = ref(1)
let attempts = 0
let finished = false

const taskRound = computed(() => (round.value === 1 ? task.value.round1 : task.value.round2))

const setupTask = async () => {
  finished = false
  playingAudio = false
  images.value = []
  if (typeof task.value === 'undefined' || task.value === null) {
    alert('A Type 2 task does not exist - check your Session layout in the CMS')
    return
  }

  let tempImages = []
  for (let i = 0; i < task.value.images.length; i++) {
    const entry = task.value.images[i]
    const image: Image = {
      id: `image-id-${i}`,
      index: i,
      name: entry.name,
      imageUrl: entry.file || '',
      correct1: entry.correct1,
      correct2: entry.correct2,
      enabled: true,
      borderGreen: false,
      borderRed: false
    }

    if (image.correct1 && round.value === 1) {
      roundData.round1.of++
    }
    if (image.correct2 && round.value === 2) {
      roundData.round2.of++
    }
    tempImages.push(image)
  }
  tempImages = shuffleItems(tempImages)
  images.value = tempImages

  stateActions.progress.progressShow(round.value === 1 ? roundData.round1.of : roundData.round2.of)

  if (taskRound.value.wordAudio) {
    textAudio = await createSound(taskRound.value.wordAudio)
    textAudio.onended = () => {
      playingAudio = false
    }
  }

  setTimeout(() => {
    opacity.value = 1
    introduceChallenge()
  }, 1000)
}

const introduceChallenge = async () => {
  attempts = 0
  stateSetters.speakerIsPlaying = true
  if (taskRound.value.instructionAudio) {
    instructionAudio = await createSound(taskRound.value.instructionAudio)
    instructionAudio.onended = () => {
      stateActions.setSpeakerSound([taskRound.value.instructionAudio])
      stateSetters.speakerIsPlaying = false
    }
  }
  setTimeout(() => {
    choiceTimer = new Date()
    if (taskRound.value.instructionAudio) {
      instructionAudio.playWhenReady()
    }
  }, 500)
}

const playWordAudio = () => {
  if (!playingAudio && textAudio) {
    playingAudio = true
    textAudio.playWhenReady()
    tracking.use_audio_content_items++
    stateSetters.trackingData = tracking
  }
}

const clickAnswer = (item: Image) => {
  const startDate = moment(choiceTimer)
  const endDate = moment()
  const choice = new Choice()
  choice.duration = endDate.diff(startDate, 'milliseconds')
  choice.response = item.name
  choice.target = images.value
    .filter((i) => i.correct1)
    .map((j) => j.imageUrl)
    .join(';')
  choice.content = images.value.map((i) => i.name).join(';')
  choice.round = round.value
  choice.valid = true

  const resultsRound = round.value === 1 ? roundData.round1 : roundData.round2
  if (item.enabled && !finished) {
    attempts++
    choice.committed = true
    if (item.correct1 && round.value === 1) {
      choice.committed = true
      resultsRound.correct++
      choice.correct = true
      stateActions.progress.completeAStar()
      item.borderGreen = true
    } else if (item.correct2 && round.value === 2) {
      resultsRound.correct++
      choice.correct = true
      stateActions.progress.completeAStar()
      item.borderGreen = true
    } else {
      item.borderRed = true
      //dataService.tryAgain()
    }
    item.enabled = false

    // Set the allowed number of attempts here - at the moment it is matched with the number of correct answers
    if (
      (unforgivingTestMode && attempts === resultsRound.of) ||
      (!unforgivingTestMode && resultsRound.of === resultsRound.correct)
    ) {
      finished = true
      nextRound()
    }
  }
  choice.attempt = attempts
  choiceTimer = new Date()
  tracking.choices.push(choice)
  stateSetters.trackingData = tracking
}

const nextRound = () => {
  setTimeout(() => {
    opacity.value = 0
    setTimeout(() => {
      if (round.value === 1) {
        round.value = 2
        setupTask()
      } else {
        if (stateGetters.state.value.taskMode === TaskMode.Warmups) {
          stateActions.speakLocalised(
            SpeechSounds.instructions.warmups.T2,
            () => {
              opacity.value = 0
              completeTask()
            },
            1000,
            false
          )
        } else {
          opacity.value = 0
          completeTask()
        }
      }
    }, 500)
  }, 500)
}

const completeTask = () => {
  setTimeout(() => {
    emit('completed', true, tracking)
  }, 1000)
}
setupTask()

const imageClasses = (image: Image) => {
  return { borderGreen: image.borderGreen, borderRed: image.borderRed }
}
</script>

<style scoped></style>
