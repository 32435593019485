<!-- Copyright 2020, 2021 Richard Nesnass

 This file is part of Kaptein Morf.

 VIVA is free software: you can redistribute it and/or modify
 it under the terms of the GNU Affero General Public License as published by
 the Free Software Foundation, either version 3 of the License, or
 (at your option) any later version.

 GPL-3.0-only or GPL-3.0-or-later

 VIVA is distributed in the hope that it will be useful,
 but WITHOUT ANY WARRANTY; without even the implied warranty of
 MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 GNU Affero General Public License for more details.

 You should have received a copy of the GNU Affero General Public License
 along with VIVA.  If not, see http://www.gnu.org/licenses/. -->
<template>
  <input
    ref="inputCarat"
    v-model="value"
    class="type12-input"
    autocapitalize="none"
    type="text"
    style="text-transform: lowercase"
    title="Word Input"
    :style="{ color: inputColour }"
    @keyup.exact="filterTextCharacters(modelValue)"
    @keyup.enter.exact="enterKey"
    @keyup.shift.enter="enterKey"
    @click="focusOnInput()"
  />
</template>

<script setup lang="ts">
import { ref, Ref, toRefs, watch, computed, onMounted } from 'vue'

const props = defineProps({
  modelValue: {
    type: String,
    default: '',
  },
  caretLocation: {
    type: String,
    default: 'start',
  },
  theMorfem: {
    type: String,
    default: '',
  },
  inputColour: {
    type: String,
    default: '#FFFFFF',
  },
})
const emit = defineEmits(['update:modelValue', 'answer'])
const { modelValue, theMorfem } = toRefs(props)

const value = computed({
  get() {
    return props.modelValue
  },
  set(value) {
    emit('update:modelValue', value)
  },
})

const inputCarat: Ref<HTMLInputElement | undefined> = ref()

function enterKey() {
  emit('answer', modelValue.value)
}
function focusOnInput() {
  inputCarat.value?.focus()
}
function filterTextCharacters(text: string | undefined) {
  const t = (text && text.toString().replace(/\d|\s/g, '')) || ''
  const filtered = t.replace(
    /([\u002E]?|[#0-9]\u20E3)|\d|[\xA9\xAE\u203C\u2047-\u2049\u2122\u2139\u3030\u303D\u3297\u3299][\uFE00-\uFEFF]?|[\u2190-\u21FF][\uFE00-\uFEFF]?|[\u2300-\u23FF][\uFE00-\uFEFF]?|[\u2460-\u24FF][\uFE00-\uFEFF]?|[\u25A0-\u25FF][\uFE00-\uFEFF]?|[\u2600-\u27BF][\uFE00-\uFEFF]?|[\u2900-\u297F][\uFE00-\uFEFF]?|[\u2B00-\u2BF0][\uFE00-\uFEFF]?|(?:\uD83C[\uDC00-\uDFFF]|\uD83D[\uDC00-\uDEFF])[\uFE00-\uFEFF]?/g,
    '',
  )
  if (modelValue.value && modelValue.value) modelValue.value = filtered
}

function setCaret() {
  if (inputCarat.value) {
    inputCarat.value.focus()
    if (props.caretLocation === 'start') {
      inputCarat.value.setSelectionRange(0, 0)
    } else if (props.caretLocation === 'end') {
      inputCarat.value.setSelectionRange(theMorfem.value.length, theMorfem.value.length)
    }
  }
}

watch(theMorfem, () => setCaret())

onMounted(() => setCaret())
</script>

<style scoped lang="postcss">
  .type12-input {
    font-size: 6vh;
    background-color: rgba(0, 0, 0, 0);
    border: none;
    font-weight: 400;
    outline: none;
    text-align: center;
    width: 80%;
  }
</style>
