import gql from 'graphql-tag'
import { LanguageCodes, TASK_TYPES, StemType } from '@/constants'
import { Session } from '../navigationModels'
import { LC, CommonTaskAttributes, TasktypeData, Tasktype } from '../questionModels'

export const Tasktype2MPQuery = gql`
  fragment Tasktype2 on Tasktype2 {
    __typename
    id
    data {
      slug {
        iv
      }
      reference {
        iv
      }
      morph {
        __language
      }
      stem {
        __language
      }
      morphedStem {
        __language
      }
      affix {
        iv
      }
      category {
        iv
      }
      audioDone {
        iv
      }
      # specific attibutes start here..
      round1 {
        __language {
          instructionBeforeWord
          word
          wordAudio {
            url
            slug
          }
          instructionAfterWord
          instructionAudio {
            url
            slug
          }
          instructionAudioText
        }
      }
      round2 {
        __language {
          instructionBeforeWord
          word
          wordAudio {
            url
            slug
          }
          instructionAfterWord
          instructionAudio {
            url
            slug
          }
          instructionAudioText
        }
      }
      images {
        __language {
          file {
            url
            slug
            fileName
          }
          correct1
          correct2
        }
      }
    }
  }
`
interface Type2Attributes {
  round1: LC<{
    instructionBeforeWord: string
    word: string
    wordAudio: { url: string; slug: string }[]
    instructionAfterWord: string
    instructionAudio: { url: string; slug: string }[]
    instructionAudioText: string
  }>
  round2: LC<{
    instructionBeforeWord: string
    word: string
    wordAudio: { url: string; slug: string }[]
    instructionAfterWord: string
    instructionAudio: { url: string; slug: string }[]
    instructionAudioText: string
  }>
  images: LC<
    {
      file: { url: string; slug: string; fileName: string }[]
      correct1: boolean
      correct2: boolean
    }[]
  >
}
export interface Tasktype2MPData extends TasktypeData {
  data: CommonTaskAttributes & Type2Attributes
}
export class Tasktype2mp extends Tasktype {
  round1 = {
    instructionBeforeWord: '',
    word: StemType.None,
    wordAudio: '', // URL
    instructionAfterWord: '',
    instructionAudio: '', // URL
    instructionAudioText: ''
  }
  round2 = {
    instructionBeforeWord: '',
    word: StemType.None,
    wordAudio: '', // URL
    instructionAfterWord: '',
    instructionAudio: '', // URL
    instructionAudioText: ''
  }
  images: {
    file: string
    filename: string
    correct1: boolean
    correct2: boolean
  }[] = [] // URLs

  constructor(spec: Tasktype2MPData, language: LanguageCodes, parent?: Session) {
    super(spec, language, parent)
    this.type = TASK_TYPES.Tasktype2mp
    const rd = spec.data.round1[language]
    if (rd) {
      this.round1 = {
        instructionBeforeWord: rd.instructionBeforeWord,
        word: rd.word as StemType,
        wordAudio: rd.wordAudio[0]?.url + rd.wordAudio[0]?.slug || '',
        instructionAfterWord: rd.instructionAfterWord,
        instructionAudio: rd.instructionAudio[0]?.url + rd.instructionAudio[0]?.slug || '',
        instructionAudioText: rd.instructionAudioText
      }
    } else console.log('Type1: GraphQL data null')
    const r2d = spec.data.round2[language]
    if (r2d) {
      this.round2 = {
        instructionBeforeWord: r2d.instructionBeforeWord,
        word: r2d.word as StemType,
        wordAudio: r2d.wordAudio[0]?.url + r2d.wordAudio[0]?.slug || '',
        instructionAfterWord: r2d.instructionAfterWord,
        instructionAudio: r2d.instructionAudio[0]?.url + r2d.instructionAudio[0]?.slug || '',
        instructionAudioText: r2d.instructionAudioText
      }
    } else console.log('Type1: GraphQL data null')
    spec.data.images[language]?.forEach((image) =>
      this.images.push({
        filename: image.file[0].fileName || '(unknown)',
        file: image.file[0]?.url || '',
        correct1: image.correct1,
        correct2: image.correct2
      })
    )
    /* if (useLocalAssets) {
      this.rewriteUrlsToLocalAssets(spec)
    } */
  }

  get assetList(): string[] {
    const list = [
      this.round1.instructionAudio,
      this.round1.wordAudio,
      this.round2.instructionAudio,
      this.round2.wordAudio
    ]
    this.images.forEach((l) => list.push(l.file))
    return list
  }
}
