<template>
  <div class="cursor-default relative bg-white">
    <!-- Table Content -->
    <div class="w-full flex justify-left items-center relative">
      <input
        id="wardtable-filter-text-box"
        v-model="trackingTableFilterText"
        type="text"
        placeholder="Filter..."
        class="border-2"
      />
    </div>
    <AgGridVue
      style="width: 100%; height: 75vh"
      class="ag-theme-alpine"
      :grid-options="gridOptions"
      :column-defs="columnDefs"
      :detail-cell-renderer-params="detailCellRendererParams"
      :row-selection="'single'"
      :row-data="rowData"
      @first-data-rendered="dataReady"
    >
    </AgGridVue>
  </div>
</template>
<script setup lang="ts">
import { ref, onMounted } from 'vue'
// import router from '../../router'
// import { useUserStore } from '../../store/useUserStore'
import 'ag-grid-community/styles/ag-grid.css'
import 'ag-grid-community/styles/ag-theme-alpine.css'
import useGameStore from '../../store/useGameStore'
import useAppStore from '@/store/useAppStore'
import { AgGridVue } from 'ag-grid-vue3'
import type { GetDetailRowDataParams } from 'ag-grid-enterprise'

// Class
import { Tracking, TrackingData } from '@/models/main'
import { GridOptions } from 'ag-grid-community'

// const { getters: userGetters } = useUserStore()
const { actions: gameActions, getters: gameGetters } = useGameStore()
//const selectedTracking: Ref<Tracking | undefined> = ref(undefined)
const gridOptions: GridOptions<TrackingData> = {
  suppressScrollOnNewData: true,
  stopEditingWhenCellsLoseFocus: true,
  masterDetail: true,
  statusBar: {
    statusPanels: [
      {
        statusPanel: 'agFilteredRowCountComponent',
        align: 'center'
      }
    ]
  },
  sideBar: true,
  enableCharts: true,
  enableRangeSelection: true
}
// Store Tables
const trackingTableFilterText = ref('')
const rowData = gameGetters.allTrackings
//const rowCount = computed(() => trackingTable.value.length)

// Translate the headerNames
let columnDefs = Tracking.columnDefs.map((col) => {
  switch (col.field) {
    case 'duration':
      col.valueGetter = (params) => {
        const data = params.data as Tracking
        return data.duration + 's'
      }
      break
    case 'createdAt':
      col.valueGetter = (params) => {
        const data = params.data as Tracking
        return data.createdAt.toLocaleString()
      }
      break

    default:
      break
  }
  return col
})

const detailCellRendererParams = {
  // provide the Grid Options to use on the Detail Grid
  detailGridOptions: {
    columnDefs: Tracking.detailColumnDefs
  },
  // get the rows for each Detail Grid
  getDetailRowData: (params: GetDetailRowDataParams) => {
    const data = params.data as Tracking
    params.successCallback(data.choices)
  }
}

onMounted(() => {
  useAppStore().actions.setLoading(true)
  // Get table data
  gameActions.getAllTrackingDetails().catch((error) => console.log(error))
})

//Table Filter
// Filter all columns based on the text
// If one of the columns contain the text, the table will show them
/* const onTableSearchTextChanged = () => {
  gridOptions.api?.setQuickFilter(trackingTableFilterText.value)
  // rowCount.value = gridOptions.api?.getDisplayedRowCount() || 0
} */
const dataReady = () => {
  useAppStore().actions.setLoading(false)
}
// Handle Table Events
/* const cellClicked = (event) => {
  if (event.data === undefined) return
  const tracking = trackingTable.value.find((t) => t.id == event.data.id)
  selectedTracking.value = tracking
} */
</script>
