/* eslint-disable @typescript-eslint/no-unused-vars */
<!-- Copyright 2023 Richard Nesnass, Tom Bjarne Seidel

 This file is part of KMMP.

KMMP is free software: you can redistribute it and/or modify
 it under the terms of the GNU Affero General Public License as published by
 the Free Software Foundation, either version 3 of the License, or
 (at your option) any later version.

 GPL-3.0-only or GPL-3.0-or-later

KMMP is distributed in the hope that it will be useful,
 but WITHOUT ANY WARRANTY; without even the implied warranty of
 MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 GNU Affero General Public License for more details.

 You should have received a copy of the GNU Affero General Public License
 along with KMMP.  If not, see http://www.gnu.org/licenses/. -->
<template>
  <div
    class="relative flex flex-col justify-center items-center fadeInOut m-20"
    :style="{ opacity: opacity }"
  >
    <div class="flex flex-row justify-around">
      <div class="flex flex-col">
        <div
          v-for="(row, i) in images"
          :key="`image-row-${i}`"
          class="flex flex-row justify-around"
        >
          <img
            v-for="image in row"
            :key="image.id"
            v-cache
            :src="image.imageUrl"
            class="flex borderedImage imageStandard"
            :class="borderColourClass(image)"
            style="display: block; margin: 10px"
            @click="clickAnswer(image)"
          />
        </div>
      </div>
    </div>

    <div class="taskNoUserSelect flex flex-row justify-around" @click="playWordAudio()">
      <span class="borderedWordBox wordHighlight">{{ task.word }}</span>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, PropType, toRefs, Ref } from 'vue'
import { Choice, Tracking } from '@/models/main'
import useState from '@/composition/useState'
import { shuffleItems } from '@/utilities'
import { SpeechSounds, TaskMode } from '@/constants'
import { Tasktype11 } from '@/models/tasktypes/Tasktype11'
import { WebAudio } from '@/models/audio'
import { createSound } from '@/api/audioService'
import moment from 'moment'

interface Image {
  id: string
  imageUrl: string
  imageFilename: string
  name: string
  borderGreen: boolean
  borderRed: boolean
  correct: boolean
  enabled: boolean
}

const emit = defineEmits(['completed'])
const props = defineProps({
  task: { required: true, type: Object as PropType<Tasktype11> },
  myIndex: { required: false, type: Number, default: 0 }
})
const { getters: stateGetters, setters: stateSetters, actions: stateActions } = useState()
const { task } = toRefs(props)
const tracking = new Tracking(stateGetters.tracking.value)
stateSetters.trackingData = tracking
let choiceTimer = new Date()
const roundData = { correct: 0, of: 0 }
const opacity = ref(0)
let attempts = 0

let finished = false
let playingAudio = false
let textAudio: WebAudio | undefined = undefined
let instructionAudio: WebAudio

// 'correct' is the count of correctly answered items. 'of' is the total allocated correct items in each set
const images: Ref<Image[][]> = ref([[], []])

async function setupTask() {
  // Choose random locations
  //let firstItem = Math.random() > 0.5 ? '' : 'itemB';
  //let secondItem = firstItem === 'itemA' ? 'itemB' : 'itemA';
  finished = false
  playingAudio = false
  if (typeof task.value === 'undefined' || task.value === null) {
    alert('A Type 2 task does not exist - check your Session layout in the CMS')
    return
  }

  let tempImages: Image[] = []
  task.value.images.forEach((im, i) => {
    const image: Image = {
      id: `image-id-${i}`,
      imageUrl: im.file,
      imageFilename: im.filename,
      name: im.name,
      correct: im.correct,
      enabled: true,
      borderGreen: false,
      borderRed: false
    }
    if (image.correct) roundData.of++
    if (image.imageUrl) tempImages.push(image)
  })
  tempImages = shuffleItems(tempImages)
  images.value[0] = tempImages.splice(0, tempImages.length / 2)
  images.value[1] = tempImages

  stateActions.progress.progressShow(roundData.of)

  if (task.value.audio) {
    textAudio = await createSound(task.value.audio)
    textAudio.onended = () => {
      playingAudio = false
    }
  }

  if (task.value.instructionAudio) {
    instructionAudio = await createSound(task.value.instructionAudio)
    instructionAudio.onended = () => {
      playingAudio = false
      stateActions.setSpeakerSound([task.value.instructionAudio])
      stateSetters.speakerIsPlaying = false
    }
  }

  introduceChallenge()
}

function introduceChallenge() {
  attempts = 0
  setTimeout(() => {
    opacity.value = 1
    if (task.value.instructionAudio) {
      playingAudio = true
      stateSetters.speakerIsPlaying = true
      instructionAudio.playWhenReady()
    }
  }, 1000)
}

function playWordAudio() {
  if (!playingAudio && textAudio) {
    playingAudio = true
    textAudio.playWhenReady()
    tracking.use_audio_content_items++
  }
}

function clickAnswer(item: Image) {
  const startDate = moment(choiceTimer)
  const endDate = moment()
  const imageRow1 = images.value[0].map((j) => j.name)
  const imageRow2 = images.value[1].map((j) => j.name)
  const correctImageRow1 = images.value[0].filter((i) => i.correct).map((j) => j.name)
  const correctImageRow2 = images.value[1].filter((i) => i.correct).map((j) => j.name)
  const choice = new Choice()
  choice.createdAt = new Date()
  choice.duration = endDate.diff(startDate, 'milliseconds')
  choice.response = item.name
  choice.target = correctImageRow1.concat(correctImageRow2).join(';')
  choice.content = imageRow1.concat(imageRow2).join(';')

  tracking.choices.push(choice)
  choiceTimer = new Date()

  if (item.enabled && !finished) {
    choice.valid = true
    attempts++
    if (item.correct) {
      choice.correct = true
      roundData.correct++
      stateActions.progress.completeAStar()
      item.borderGreen = true
    } else {
      item.borderRed = true
      choice.correct = false
      if (task.value.unforgiving) {
        stateActions.progress.completeAStar()
      }
      //dataService.tryAgain()
    }
    item.enabled = false

    // Set the allowed number of attempts here - at the moment it is matched with the number of correct answers
    if (
      (task.value.unforgiving && attempts === roundData.of) ||
      (!task.value.unforgiving && roundData.of === roundData.correct)
    ) {
      finished = true
      nextRound()
    }
  }
  stateSetters.trackingData = tracking
}

function nextRound() {
  setTimeout(() => {
    opacity.value = 0
    if (stateGetters.state.value.taskMode === TaskMode.Warmups) {
      stateActions.speakLocalised(
        SpeechSounds.instructions.warmups.T11,
        () => {
          opacity.value = 0
          completeTask()
        },
        1000,
        false
      )
    } else {
      setTimeout(() => {
        completeTask()
      }, 1000)
    }
  }, 500)
}

function completeTask() {
  emit('completed', true, tracking)
}

function borderColourClass(image: Image) {
  if (task.value.unforgiving && !image.enabled) return 'borderYellow'
  else {
    if (image.borderGreen) return 'borderGreen'
    else if (image.borderRed) return 'borderRed'
    else return ''
  }
}

setupTask()

// ----------------- TASK 5 -----------------------
</script>

<style scoped lang="postcss">
.scanner-word {
  margin-top: -50px;
  position: relative;
  z-index: 100;
}
</style>
