<!-- Copyright 2023 Richard Nesnass, Tom Bjarne Seidel

 This file is part of SL+.

 SL+ is free software: you can redistribute it and/or modify
 it under the terms of the GNU Affero General Public License as published by
 the Free Software Foundation, either version 3 of the License, or
 (at your option) any later version.

 GPL-3.0-only or GPL-3.0-or-later

 SL+ is distributed in the hope that it will be useful,
 but WITHOUT ANY WARRANTY; without even the implied warranty of
 MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 GNU Affero General Public License for more details.

 You should have received a copy of the GNU Affero General Public License
 along with SL+.  If not, see http://www.gnu.org/licenses/. -->
<template>
  <div class="z-101 flex justify-end mt-7">
    <div v-if="props.isMp" class="flex mb-2">
      <!-- Old style below for case v-if="props.isMp",
      where two avatars having a crown on/off shows the leader status: 
    <div>
      <div
        class="rounded-full p-1 w-20 h-20 flex justify-center items-center"
        :class="avatarStyles(true)"
        :style="userStyle(user.profile.username)"
      >
        <Avatar :avatar-ref="user.avatar.ref" />
      </div>
      {{ props.role }}
      <div v-if="props.role === UserTaskRole.Leader" class="absolute top-3 -ml-2 p-1 w-10 h-10 bg-yellow-400 rounded-full">
        <img class="invert -rotate-12" :src="CrownIcon" alt="crown" />
      </div>
    </div>
    <div>
      <div
        class="ml-1 rounded-full p-1 w-20 h-20 flex justify-center items-center"
        :class="avatarStyles(false)"
        :style="userStyle(props.participantInformation.name)"
      >
        <Avatar :avatar-ref="props.participantInformation.avatar.ref" />
      </div>
      <div v-if="props.role === UserTaskRole.Advisor" class="absolute top-3 -ml-2 p-1 w-10 h-10 bg-yellow-400 rounded-full">
        <img class="invert -rotate-12" :src="CrownIcon" alt="crown" />
      </div>
    </div>
    -->
      <div>
        <div
          v-if="props.role === UserTaskRole.Leader"
          class="ml-2 bg-yellow-400 p-7 rounded-2xl h-16 flex justify-center items-center"
        >
          <p class="text-xl font-bold">{{ t('youreLeader') }}</p>
        </div>
        <div
          v-if="props.role === UserTaskRole.Advisor"
          class="ml-2 bg-blue-400 p-7 rounded-2xl h-16 flex justify-center items-center"
        >
          <p>{{ t('youreAdvisor') }}</p>
        </div>
      </div>
    </div>
    <div v-else-if="props.isSt">
      <div class="ml-2 bg-green-400 p-7 rounded-2xl">
        <p v-if="props.role === UserTaskRole.Student" class="text-xs">{{ t('studentteacher1') }}</p>
        <p v-if="props.role === UserTaskRole.Teacher" class="text-xs">{{ t('studentteacher2') }}</p>
      </div>
    </div>
    <div v-else>
      <div class="ml-2 bg-green-400 p-7 rounded-2xl h-16 flex justify-center items-center">
        <p>{{ t('singleplayer') }}</p>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { PropType } from 'vue'
import { useI18n } from 'vue-i18n'

import useMultiPlayerState from '@/composition/useMultiplayerState'
import useColorStore from '@/composition/colors'

import useUserStore from '@/store/useUserStore'

import { ParticipantInformation, UserTaskRole } from '@/constants'

import CrownIcon from '@/assets/icons/fontawesome/crown-solid.svg'
import Avatar from '@/components/base/Avatar.vue'

const { getters: userGetters } = useUserStore()
const multiplayer = useMultiPlayerState()
const color = useColorStore()

const { t } = useI18n({})

const props = defineProps({
  isMp: {
    type: Boolean,
    required: true
  },
  isSt: {
    type: Boolean,
    required: true
  },
  participantInformation: {
    type: Object as PropType<ParticipantInformation>,
    required: true
  },
  role: { type: String, required: true }
})

const user = userGetters.myUser

function userStyle(name: string) {
  return `background-color: ${color.actions.selectColor(name)};`
}

function avatarStyles(ownUser: boolean) {
  return (ownUser && multiplayer.getters.actionAllowed.value) ||
    (!ownUser && !multiplayer.getters.actionAllowed.value)
    ? ''
    : 'opacity-50'
}
</script>

<style scoped></style>
