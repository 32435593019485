<!-- Copyright 2023 Richard Nesnass, Tom Bjarne Seidel

 This file is part of SL+.

 SL+ is free software: you can redistribute it and/or modify
 it under the terms of the GNU Affero General Public License as published by
 the Free Software Foundation, either version 3 of the License, or
 (at your option) any later version.

 GPL-3.0-only or GPL-3.0-or-later

 SL+ is distributed in the hope that it will be useful,
 but WITHOUT ANY WARRANTY; without even the implied warranty of
 MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 GNU Affero General Public License for more details.

 You should have received a copy of the GNU Affero General Public License
 along with SL+.  If not, see http://www.gnu.org/licenses/. -->
<template>
  <div class="relative w-full h-full flex flex-col justify-center items-center">
    <ProgressBar class="z-10 absolute top-20" :ship="true" />
    <div v-if="!hidePasswordBox" class="flex justify-center items-center border rounded-2xl p-5">
      <img class="h-14 mr-8" :src="PasswordButton_Active" />

      <div class="flex flex-col">
        <p class="text-xl text-white mb-3">Passord for {{ selectedSession?.name }}?</p>
        <input
          ref="passwordInputElement"
          v-model="state.passwordText"
          name="passwordBox"
          class="py-3 px-2 border-gray-300 border"
          type="password"
          autocomplete="off"
          @keyup="checkPassword()"
        />
      </div>
    </div>

    <div class="w-1/2 top-1/3">
      <div
        v-if="!dyadIsPaired"
        class="absolute left-0 px-7 bottom-5 w-full flex items-center justify-between"
      >
        <Button
          bordercolour="border-transparent"
          backgroundcolour="bg-slate-700"
          textcolour="text-white"
          :roundedcorners="true"
          @click="navigateToDashboard()"
          @touchstart.prevent="navigateToDashboard()"
        >
          <img class="p-2 w-12 invert mr-2" :src="BackIcon" alt="log out" />
          <p>{{ t('back') }}</p>
        </Button>

        <div>
          <p class="text-white">
            {{ `${t('goingback1')} ${state.timeRemaining / 1000} ${t('seconds')}` }}
          </p>
        </div>
      </div>

      <div class="z-50 py-7 flex flex-col justify-center items-center dialog-bg rounded-3xl">
        <p v-if="dyadIsPaired" class="text-white text-3xl font-bold p-11">
          {{ t('participantready') }}
        </p>
        <p v-else-if="!selectedSessionIsSingleplayer" class="text-white text-3xl font-bold p-11">
          {{ t('waitingonparticipant') }}
        </p>
        <p v-else class="text-white text-3xl font-bold p-11">
          {{ t('loading') }}
        </p>

        <div class="flex py-3">
          <div class="p-3 mr-9 flex flex-col justify-center items-center">
            <div
              class="rounded-full p-3 w-32 h-32 flex justify-center items-center"
              :style="userStyle(userGetters.myUser.value.profile.username)"
            >
              <AvatarVue :avatar-ref="userGetters.myUser.value.avatar.ref" />
            </div>
            <p
              class="text-lg mt-3"
              :style="`color: ${color.actions.selectColor(userGetters.myUser.value.profile.username)};`"
            >
              {{ userGetters.myUser.value.profile.username }}
            </p>
          </div>

          <div
            v-if="participantIsAvailable && !selectedSessionIsSingleplayer"
            class="p-3 flex flex-col justify-center items-center"
          >
            <div
              class="rounded-full p-3 bg-red-700 w-32 h-32 flex justify-center items-center"
              :style="userStyle(participantInformation.name || 'no name available')"
            >
              <AvatarVue :avatar-ref="participantInformation.avatar.ref" />
            </div>
            <p
              class="text-lg mt-3"
              :style="`color: ${color.actions.selectColor(participantInformation.name || 'no name available')};`"
            >
              {{ participantInformation.name || 'no name available' }}
            </p>
          </div>

          <div
            v-else-if="!selectedSessionIsSingleplayer"
            class="p-3 flex flex-col justify-start items-center"
          >
            <div
              class="rounded-full p-11 dialog-bg border-2 w-32 h-32 flex justify-evenly items-center mb-4 text-white"
            >
              <p v-if="state.first" class="animate-bounce text-4xl">.</p>
              <p v-if="state.second" class="animate-bounce text-4xl">.</p>
              <p v-if="state.third" class="animate-bounce text-4xl">.</p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div v-if="stateService.getters.state.value.lobbyMode === LobbyMode.SessionCompleted">
      <div class="flex text-white justify-center items-center">
        <img :src="Completed" class="w-11 h-11" />
        <p>{{ t('sessioncompleted') }}</p>
      </div>
    </div>

    <div class="absolute bg-red z-101 right-0 bottom-0">
      <img v-if="sidekickAnimating" class="sidekickanimation" :src="Sidekick" />
    </div>

    <div v-if="appGetters.disableDelays" class="absolute top-0 right-0 text-xs flex flex-row z-50">
      <button
        class="m-1 text-white dialog-bg rounded-sm p-2"
        @touchstart.prevent="startPreviewSession()"
        @click="startPreviewSession()"
      >
        Skip Pass
      </button>
    </div>

    <div
      v-if="state.delayActive"
      class="absolute grid grid-cols-1 place-content-center w-full h-full top-0 left-0 pointer-events-none"
    >
      <div class="text-center text-white">{{ t('downloading') }}</div>
    </div>
    <div
      v-if="state.noSessions"
      class="absolute grid grid-cols-1 place-content-center w-full h-full top-0 left-0 pointer-events-none"
    >
      <div class="text-center text-white">{{ t('noSessions') }}</div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { onMounted, computed, watch, reactive, ref, nextTick } from 'vue'
import { useI18n } from 'vue-i18n'

import {
  LobbyMode,
  ViewState,
  SpeechSounds,
  StateVariables,
  ParcelType,
  ParticipantReadyState,
  ParticipantInformation
} from '@/constants'

import { Parcel, SESSION_TYPE } from '@/models/main'

import useMultiPlayerState from '@/composition/useMultiplayerState'
import useStateService from '@/composition/useState'
import useParcelStore from '@/composition/parcel'
import useColorStore from '@/composition/colors'

import useCMSStore from '@/store/useCMSStore'
import useAppStore from '@/store/useAppStore'
import useGameStore from '@/store/useGameStore'
import useUserStore from '@/store/useUserStore'

import AvatarVue from '@/components/base/Avatar.vue'
import ProgressBar from './ProgressBar.vue'
import Button from '@/components/base/Button.vue'

// Buildtime assets
import Sidekick from '@/assets/images/characters/sidekick-i.png'
import Completed from '@/assets/images/checkedbox.png'
import PasswordButton_Active from '@/assets/images/ship/PasswordButton_Active@2x.png'
import BackIcon from '@/assets/icons/fontawesome/arrow-left-solid.svg'

const messages = {
  no: {
    downloading: 'Vennligst vent, laster ned data...',
    noSessions: 'Alle session ferdig',
    goingback1: 'Automatic return to dashboard in',
    seconds: 'seconds',
    backToDashboard: 'Last inn lobby på nytt'
  },
  sv: {
    downloading: 'Vänta, laddar ner data...',
    noSessions: 'Alle session färdiga',
    goingback1: 'Automatic return to dashboard in',
    seconds: 'seconds',
    backToDashboard: 'Last inn lobby på nytt'
  },
  en: {
    downloading: 'Please wait, downloding data...',
    noSessions: 'All sessions are completed',
    goingback1: 'Automatic return to dashboard in',
    seconds: 'seconds',
    backToDashboard: 'Return and try again'
  }
}

const { t } = useI18n({ messages })

const stateService = useStateService()

const { getters: cmsGetters } = useCMSStore()
const { getters: appGetters } = useAppStore()
const { getters: userGetters, actions: userActions } = useUserStore()
const { getters: gameGetters, actions: gameActions } = useGameStore()

const cmsStore = useCMSStore()
const multiplayer = useMultiPlayerState()
const parcelStore = useParcelStore()
const color = useColorStore()

const passwordInputElement = ref<HTMLElement | null>(null)

const state = reactive({
  passwordText: '',
  passwordCorrect: false,
  timeRemaining: 30000,

  // used for dots animation when waiting for participant
  first: false,
  second: false,
  third: false,

  noSessions: false,
  delayActive: false,
  sessionActivated: false
})

// this is used to show the sidekick (if needed)
const sidekickAnimating = stateService.getters.sidekickAnimating
const participantInformation = multiplayer.getters.participantInformation

const participantIsReady = multiplayer.getters.participantReady
const selectedGame = gameGetters.selectedGame
const selectedSession = cmsGetters.selectedSession
let autoReturn: NodeJS.Timeout

const startTimer = () => {
  autoReturn = setTimeout(() => {
    if (state.timeRemaining === 0 && !dyadIsPaired.value) {
      navigateToDashboard() // if no participant logged on within the connection period (15 seconds to match duration of the invitation being shown)
    } else {
      state.timeRemaining -= 1000
      startTimer()
    }
  }, 1000)
}

onMounted(async () => {
  const gameId = selectedGame.value?._id
  if (gameId) {
    // This will also be reached during page reload - exit gracefully to login page
    await userActions.getMyUser()
    startTimer()

    // really make sure the user information is available / avoid default values in 'myUser' value
    const lobbyMode = stateService.getters.state.value.lobbyMode
    if (
      lobbyMode === LobbyMode.SessionLocked &&
      (!selectedSession.value?.hasPassword || hidePasswordBox.value)
    ) {
      if (!selectedSessionIsSingleplayer.value)
        setReady() // set automatically ready if no password is required (either because there is no password, or because the hidePasswordBox value is true)
      else startSelectedSession() // start session -> previously 'passwordEntered()'
    } else if (!hidePasswordBox.value) {
      await nextTick()
      if (passwordInputElement.value) passwordInputElement.value.focus()
    }

    if (lobbyMode === LobbyMode.SessionUnlocked) {
      // If we are in the process of finishing a Session (progress.value update), speak 'progress.value update' feedback
      stateService.actions.speakLocalised(SpeechSounds.computer.C41, undefined, 1000)
    }

    if (lobbyMode === LobbyMode.SessionCompleted) {
      // If the session has completed, we are back to the Ship view in interactive mode after progress.value update
      parcelStore.actions.unSubscribeTopic(gameId)
      stateService.actions.speakLocalised(SpeechSounds.computer.C60, undefined, 1000)
      setTimeout(() => {
        navigateToDashboard()
      }, 5000)
    }

    startDotsAnimation()
  }
})

watch(participantIsReady, async (newValue: ParticipantReadyState) => {
  if (hidePasswordBox.value) {
    setReady()
    if (newValue === ParticipantReadyState.Paired) {
      // sorting the shuffled items...
      const game = gameGetters.selectedGame.value?.details.shuffleDetails
      if (game) cmsStore.actions.sortActivity(game)
    }
    if (newValue === ParticipantReadyState.ProceedToGame) startSelectedSession() // set session if end state 'ProceedToGame' was reached
  }
})

const selectedSessionIsSingleplayer = computed(() => {
  return (
    cmsGetters.selectedSession.value?.type === SESSION_TYPE.singlePlayer //||
    //cmsGetters.selectedEpisode.value?.type !== EPISODE_TYPE.regular
  )
})

const hidePasswordBox = computed(() => {
  // this ensures that the box is only shown to the first user getting to the lobby (if a password is required)
  return (
    !selectedSession.value?.hasPassword ||
    state.passwordCorrect ||
    (participantInformation.value.name !== '' &&
      participantIsReady.value !== ParticipantReadyState.NotReady)
  )
})

const sessionIsCompleted = computed(() => {
  const s = selectedSession.value
  const userId = s?.type === SESSION_TYPE.singlePlayer ? userGetters.myUser.value._id : ''
  if (s && s.parent) return gameActions.itemIsCompleted(s.id, s.parent.id, userId)
  return false
})

const dyadIsPaired = computed(() => {
  return [ParticipantReadyState.Paired, ParticipantReadyState.ProceedToGame].some(
    (state) => state === participantIsReady.value
  )
})

const participantIsAvailable = computed(() => {
  return participantIsReady.value === ParticipantReadyState.Initiated || dyadIsPaired.value
})

function navigateToDashboard() {
  clearTimeout(autoReturn)
  setReady(false) // set ready state to false to prevent inconsistency

  // reset all states
  multiplayer.actions.reset()
  multiplayer.actions.resetSync()

  const newState: StateVariables = { viewState: ViewState.Dashboard }
  stateService.actions.updateState(newState)
}

function userStyle(name: string) {
  return `background-color: ${color.actions.selectColor(name)};`
}

function startDotsAnimation() {
  // dots animation
  setTimeout(() => {
    state.first = true
  }, 0)
  setTimeout(() => {
    state.second = true
  }, 50)
  setTimeout(() => {
    state.third = true
  }, 100)
}

function startSelectedSession() {
  clearTimeout(autoReturn)
  const game = selectedGame.value
  if (game) {
    setTimeout(async () => {
      await stateService.actions.startSession(game) // doesn't need to be awaited
    }, 2000) // wait two seconds before proceeding
  } else {
    const newState: StateVariables = { viewState: ViewState.Dashboard }
    stateService.actions.updateState(newState)
  }
}

function setReady(connect = true) {
  const gameId = selectedGame.value?._id
  if (gameId) {
    const subscriptionUser = userGetters.myUser.value
    const readyBody: ParticipantInformation = {
      name: subscriptionUser.profile.username,
      avatar: userGetters.myUser.value.avatar
    }
    const parcel = new Parcel({
      parcelType: connect ? ParcelType.UserConnect : ParcelType.UserDisconnect,
      subscription: {
        game_id: gameId,
        user: {
          id: subscriptionUser._id,
          username: subscriptionUser.profile.username
        }
      },
      body: readyBody
    })
    parcelStore.actions.sendParcel(gameId, parcel).then(() => {
      console.log('pairing parcel sent')
    })
  }
}

// ---------------   Button controls

function checkPassword(override = false) {
  if (selectedSession.value?.password === state.passwordText || override) {
    state.passwordCorrect = true
    if (!selectedSessionIsSingleplayer.value)
      setReady() // set automatically ready if no password is required
    else startSelectedSession() // start session -> previously 'passwordEntered()'
  }
}

function startPreviewSession() {
  if (passwordInputElement.value) passwordInputElement.value.blur()
  state.delayActive = true
  // activate session if not activated already
  if (!state.sessionActivated && selectedSession.value) {
    stateService.actions
      .activateSession(selectedSession.value, state.passwordText)
      .then(() => {
        state.sessionActivated = true
        state.delayActive = false
        if (selectedSession.value && !sessionIsCompleted.value) startSelectedSession()
      })
      .catch((error) => {
        console.error(error)
        state.noSessions = true
        state.delayActive = false
      })
  }
  state.passwordText = ''
}
</script>

<style scoped></style>
