/* eslint-disable @typescript-eslint/no-unused-vars */
<!-- Copyright 2023 Richard Nesnass, Tom Bjarne Seidel

 This file is part of KMMP.

KMMP is free software: you can redistribute it and/or modify
 it under the terms of the GNU Affero General Public License as published by
 the Free Software Foundation, either version 3 of the License, or
 (at your option) any later version.

 GPL-3.0-only or GPL-3.0-or-later

KMMP is distributed in the hope that it will be useful,
 but WITHOUT ANY WARRANTY; without even the implied warranty of
 MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 GNU Affero General Public License for more details.

 You should have received a copy of the GNU Affero General Public License
 along with KMMP.  If not, see http://www.gnu.org/licenses/. -->
<template>
  <div
    class="relative flex flex-col justify-center items-center fadeInOut"
    :style="{ opacity: opacity }"
  >
    <div class="w-full flex justify-between items-center">
      <div v-if="showing === 'example'" class="flex flex-row justify-between w-full">
        <div class="fadeInOut" :style="{ opacity: exampleLeft.opacity }">
          <img
            v-cache
            :src="exampleLeft.imageUrl"
            class="borderedImage borderColour1 imageVeryLarge"
            :class="{ borderGreen: exampleLeft.borderGreen, borderRed: exampleLeft.borderRed }"
          />
        </div>

        <div class="fadeInOut" :style="{ opacity: exampleRight.opacity }">
          <img
            v-cache
            :src="exampleRight.imageUrl"
            class="borderedImage imageVeryLarge"
            :class="{ borderGreen: exampleRight.borderGreen, borderRed: exampleRight.borderRed }"
          />
        </div>
      </div>

      <div v-if="showing === 'challenge'" class="flex flex-row justify-between w-full">
        <div
          class="fadeInOut flex flex-col place-content-center"
          :style="{ opacity: challengeLeft.opacity }"
        >
          <img
            v-cache
            :src="challengeLeft.imageUrl"
            class="borderedImage imageVeryLarge"
            :class="{ borderGreen: challengeLeft.borderGreen, borderRed: challengeLeft.borderRed }"
            @click="clickAnswer('left')"
          />
        </div>

        <div
          class="fadeInOut flex flex-col place-content-center"
          :style="{ opacity: challengeRight.opacity }"
        >
          <img
            v-cache
            class="borderedImage imageVeryLarge"
            :src="challengeRight.imageUrl"
            :class="{
              borderGreen: challengeRight.borderGreen,
              borderRed: challengeRight.borderRed
            }"
            @click="clickAnswer('right')"
          />
        </div>
      </div>
    </div>

    <div v-if="showing === 'example'" class="borderedWordBox">
      <span
        :class="{ highlightByScaling: exampleLeft.highlight }"
        class="fadeInOut"
        :style="{ opacity: exampleLeft.opacity }"
      >
        <span class="wordHighlight">{{ exampleLeft.word }}</span>
      </span>

      <span
        :class="{ highlightByScaling: exampleRight.highlight }"
        class="fadeInOut"
        :style="{ opacity: exampleRight.opacity }"
      >
        <span class="wordHighlight">{{ exampleRight.word }}</span>
      </span>
    </div>

    <div v-if="showing === 'challenge'" class="borderedWordBox" @click="playChallengeAudioWord()">
      <span class="fadeInOut" :style="{ opacity: challengeLeft.opacity }">
        {{ task.challenge.instructionBeforeWord }}
        <span class="wordHighlight">{{
          task.challenge.word === 'stem' ? task.stem : task.morphedStem
        }}</span>
        {{ task.challenge.instructionAfterWord }}</span
      >
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, PropType, toRefs } from 'vue'
// import { useI18n } from 'vue-i18n'
// import useCMSStore, { CMSStoreType } from '@/store/useCMSStore'
import useState from '@/composition/useState'
import { TaskMode, SpeechSounds } from '@/constants'
import { Tasktype1 } from '@/models/tasktypes/Tasktype1'
import { Choice, Tracking } from '@/models/main'
import { createSound } from '@/api/audioService'
import { WebAudio } from '@/models/audio'
import moment from 'moment'

const emit = defineEmits(['completed'])
const props = defineProps({
  task: { required: true, type: Object as PropType<Tasktype1> },
  myIndex: { required: false, type: Number, default: 0 }
})

/* const messages = {
    no: {},
    sv: {},
    en: {},
  } */
//const { t } = useI18n({ messages })
const { task } = toRefs(props)
//const cmsStore: CMSStoreType = useCMSStore()
const { getters: stateGetters, setters: stateSetters, actions: stateActions } = useState()
const roundData = { correct: 0, of: 0 }

// Display setup
const showing = ref('example')
const opacity = ref(0)
// We need a mutatable copy of results that we will submit to the store at Task completion
const tracking = new Tracking(stateGetters.tracking.value)
stateSetters.trackingData = tracking
let choiceTimer = new Date()

let challengeActive = false
let playingAudio = false
let textAudio: WebAudio
let exampleLeftAudio: WebAudio
let exampleRightAudio: WebAudio
let introAudio: WebAudio

const exampleLeft = ref({
  imageUrl: '',
  audioUrl: '',
  captionBefore: '',
  word: '',
  captionAfter: '',
  borderGreen: false,
  borderRed: false,
  highlight: false,
  opacity: 0
})
const exampleRight = ref({
  imageUrl: '',
  audioUrl: '',
  captionBefore: '',
  word: '',
  captionAfter: '',
  borderGreen: false,
  borderRed: false,
  highlight: false,
  opacity: 0
})
const challengeLeft = ref({
  imageUrl: '',
  borderGreen: false,
  borderRed: false,
  opacity: 0,
  correct: false
})
const challengeRight = ref({
  imageUrl: '',
  borderGreen: false,
  borderRed: false,
  opacity: 0,
  correct: false
})
const unforgivingTestMode = false

const setupTask = async () => {
  // Choose random locations
  //let firstItem = Math.random() > 0.5 ? '' : 'itemB';
  //let secondItem = firstItem === 'itemA' ? 'itemB' : 'itemA';
  stateActions.progress.progressShow(1)

  if (typeof task.value === 'undefined' || task.value === null) {
    alert('A Type 1 task does not exist - check your Session layout in the CMS')
    return
  }
  playingAudio = false
  exampleLeft.value.imageUrl =
    typeof task.value.example.stemImage !== 'undefined' ? task.value.example.stemImage : ''
  exampleRight.value.imageUrl =
    typeof task.value.example.morphedStemImage !== 'undefined'
      ? task.value.example.morphedStemImage
      : ''
  exampleLeft.value.audioUrl =
    typeof task.value.example.stemAudio !== 'undefined' ? task.value.example.stemAudio : ''
  exampleRight.value.audioUrl =
    typeof task.value.example.morphedStemAudio !== 'undefined'
      ? task.value.example.morphedStemAudio
      : ''
  exampleLeft.value.captionBefore = task.value.example.captionBeforeStem
  exampleRight.value.captionBefore = task.value.example.captionBeforeMorphedStem
  exampleLeft.value.word = task.value.example.customStem || task.value.stem
  exampleRight.value.word = task.value.example.customMorphedStem || task.value.morphedStem
  exampleLeft.value.captionAfter = task.value.example.captionAfterStem
  exampleRight.value.captionAfter = task.value.example.captionAfterMorphedStem

  challengeLeft.value.imageUrl =
    typeof task.value.challenge.image1 !== 'undefined' ? task.value.challenge.image1 : ''
  challengeRight.value.imageUrl =
    typeof task.value.challenge.image2 !== 'undefined' ? task.value.challenge.image2 : ''
  challengeLeft.value.correct = task.value.challenge.correctImage === '1'
  challengeRight.value.correct = !challengeLeft.value.correct

  if (
    typeof task.value.challenge.wordAudio !== 'undefined' &&
    task.value.challenge.wordAudio !== null
  ) {
    textAudio = await createSound(task.value.challenge.wordAudio, () => (playingAudio = false))
  }

  exampleLeftAudio = await createSound(exampleLeft.value.audioUrl)
  exampleLeftAudio.onerror = (error) => {
    console.log(error.toString())
  }
  exampleLeftAudio.onended = () => {
    exampleLeft.value.highlight = false
    if (!exampleRight.value.audioUrl) {
      setTimeout(() => {
        introduceChallenge()
      }, 2000)
    } else {
      setTimeout(() => {
        exampleRight.value.highlight = true
        exampleRightAudio.playWhenReady()
      }, 500)
    }
  }

  exampleRightAudio = await createSound(exampleRight.value.audioUrl)
  exampleRightAudio.onended = () => {
    setTimeout(() => {
      exampleLeft.value.opacity = 0
      exampleRight.value.opacity = 0
      exampleRight.value.highlight = false
      setTimeout(() => {
        introduceChallenge()
      }, 500)
    }, 500)
  }

  introAudio = await createSound(task.value.challenge.instructionAudio)
  introAudio.onended = () => {
    stateSetters.speakerIsPlaying = false
    stateActions.setSpeakerSound([task.value.challenge.instructionAudio])
  }

  setTimeout(() => {
    opacity.value = 1
    exampleLeft.value.opacity = 1
    exampleRight.value.opacity = 1
    introduceExample()
  }, 500)
}

const introduceExample = () => {
  stateSetters.speakerIsPlaying = true
  if (exampleLeft.value.audioUrl === '') {
    setTimeout(() => {
      introduceChallenge()
    }, 2000)
  } else {
    setTimeout(() => {
      exampleLeft.value.highlight = true
      exampleLeftAudio.playWhenReady()
    }, 3000)
  }
}

const introduceChallenge = () => {
  showing.value = 'challenge'
  setTimeout(() => {
    challengeLeft.value.opacity = 1
    challengeRight.value.opacity = 1
    challengeActive = true
    if (typeof task.value.challenge.instructionAudio !== 'undefined') {
      setTimeout(() => {
        introAudio.playWhenReady()
      }, 500)
    }
  }, 500)
}

const playChallengeAudioWord = () => {
  if (!playingAudio && textAudio !== null) {
    playingAudio = true
    textAudio.playWhenReady()
    tracking.use_audio_content_items++
  }
}

const completeTask = () => {
  challengeActive = false
  if (stateGetters.state.value.taskMode === TaskMode.Warmups) {
    stateActions.speakLocalised(
      SpeechSounds.instructions.warmups.T1,
      () => {
        opacity.value = 0
        emit('completed', true, tracking)
      },
      1000,
      false
    )
  } else {
    //setTimeout(() => {
    opacity.value = 0
    setTimeout(() => {
      // Call this when the task is complete
      emit('completed', true, tracking)
    }, 1000)
    //}, 1000)
  }
}

const clickAnswer = (answer: string) => {
  const choice = new Choice()
  choice.duration = moment().diff(moment(choiceTimer), 'milliseconds')
  choice.round = roundData.correct + 1
  choice.content = challengeLeft.value.imageUrl + ';' + challengeRight.value.imageUrl
  choice.correct = false
  choice.response = answer
  choice.target = ''
  choice.valid = true
  tracking.choices.push(choice)
  choiceTimer = new Date()

  if (challengeActive) {
    if (answer === 'left') {
      if (challengeLeft.value.correct) {
        choice.target = challengeLeft.value.imageUrl
        choice.correct = true
        roundData.correct++
        challengeLeft.value.borderGreen = true
        stateActions.progress.completeAStar()
        completeTask()
      } else {
        challengeLeft.value.borderRed = true
        choice.target = challengeLeft.value.imageUrl
        choice.correct = false
        //dataService.tryAgain()
        if (unforgivingTestMode) {
          completeTask()
        }
      }
    } else {
      if (challengeRight.value.correct) {
        choice.target = challengeRight.value.imageUrl
        choice.correct = true
        challengeRight.value.borderGreen = true
        stateActions.progress.completeAStar()
        completeTask()
      } else {
        challengeRight.value.borderRed = true
        choice.target = challengeRight.value.imageUrl
        choice.correct = false
        //dataService.tryAgain()
        if (unforgivingTestMode) {
          completeTask()
        }
      }
    }
  }
  stateSetters.trackingData = tracking
}
setupTask()
</script>

<style scoped>
.scanner-image {
  margin-right: auto;
  margin-left: auto;
  display: block;
}
.scanner-content {
  position: absolute;
  width: 100%;
}
.scanner-word {
  margin-top: -45px;
}
</style>
