<template>
  <div class="animate-spin h-24 w-24 loader rounded-full"></div>
</template>

<script setup lang="ts">
import { onMounted } from 'vue'

const emit = defineEmits<{
    (e: 'setLoadingDone'): void
  }>()

onMounted(() => {
  setTimeout(() => {
    emit('setLoadingDone')
  }, 1000)
})
</script>
<style scoped>
  .loader {
    border: 16px solid var(--bg-slate-middle);
    border-top: 16px solid var(--btn-yellow);
  }
</style>
