<template>
  <div class="settings-container">
    <h1 class="header">Global Settings</h1>

    <!-- Update Button with Confirmation Dialog -->
    <button @click="showConfirm = true" class="update-button">Update Values</button>

    <div v-if="showConfirm" class="confirmation-dialog">
      <p>Are you sure you want to update all values?</p>
      <button @click="updateAllSettings" class="confirm-button yes-button">Yes</button>
      <button @click="showConfirm = false" class="confirm-button no-button">No</button>
    </div>

    <!-- Individual Setting Fields -->

    <!-- Global Password -->
    <div class="setting-item">
      <label for="globalPassword" class="label">Global Password:</label>
      <input type="text" id="globalPassword" v-model="settings.globalPassword" class="text-input" />
    </div>

    <!-- Global Password Toggle -->
    <div class="setting-item">
      <label for="globalPasswordToggle" class="label">Global Password Toggle:</label>
      <input
        type="checkbox"
        id="globalPasswordToggle"
        v-model="settings.globalPasswordToggle"
        class="checkbox"
      />
    </div>

    <!-- Global Message -->
    <div class="setting-item">
      <label for="globalMessage" class="label">Global Message:</label>
      <textarea
        id="globalMessage"
        v-model="settings.globalMessage"
        class="textarea-input"
      ></textarea>
    </div>

    <!-- Global Message Toggle -->
    <div class="setting-item">
      <label for="globalMessageToggle" class="label">Global Message Toggle:</label>
      <input
        type="checkbox"
        id="globalMessageToggle"
        v-model="settings.globalMessageToggle"
        class="checkbox"
      />
    </div>

    <!-- FEIDE Login Password -->
    <div class="setting-item">
      <label for="feideLoginPassword" class="label">FEIDE Login Password:</label>
      <input
        type="text"
        id="feideLoginPassword"
        v-model="settings.feideLoginPassword"
        class="text-input"
      />
    </div>

    <!-- FEIDE Login Password Toggle -->
    <div class="setting-item">
      <label for="feideLoginPasswordToggle" class="label">FEIDE Login Password Toggle:</label>
      <input
        type="checkbox"
        id="feideLoginPasswordToggle"
        v-model="settings.feideLoginPasswordToggle"
        class="checkbox"
      />
    </div>

    <!-- SMS Login Password -->
    <div class="setting-item">
      <label for="smsLoginPassword" class="label">SMS Login Password:</label>
      <input
        type="text"
        id="smsLoginPassword"
        v-model="settings.smsLoginPassword"
        class="text-input"
      />
    </div>

    <!-- SMS Login Password Toggle -->
    <div class="setting-item">
      <label for="smsLoginPasswordToggle" class="label">SMS Login Password Toggle:</label>
      <input
        type="checkbox"
        id="smsLoginPasswordToggle"
        v-model="settings.smsLoginPasswordToggle"
        class="checkbox"
      />
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, reactive, ref } from 'vue'
import useSettingsStore, { Settings } from '@/store/useSettingsStore'

export default defineComponent({
  name: 'MonitorSettings',
  setup() {
    const { actions, getters } = useSettingsStore()
    const showConfirm = ref(false)

    // Get the settings from the store
    const settings = reactive<Settings>(getters.settings.value)

    // Fetch initial settings from the store
    actions.fetchSettings().then((data) => {
      Object.assign(settings, data) // Populate settings with fetched data
    })

    const updateAllSettings = () => {
      actions.updateSettings(settings)
      showConfirm.value = false
    }

    return {
      settings,
      showConfirm,
      updateAllSettings
    }
  }
})
</script>

<style scoped>
.settings-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start; /* Left align everything */
  width: 900px;
  margin: 20px;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 5px;
  background-color: #f9f9f9;
}

.header {
  font-size: 24px;
  margin-bottom: 20px;
}

.setting-item {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.label {
  color: #333; /* Dark gray label color */
  width: 200px; /* Adjust label width as needed */
  margin-right: 10px;
}

.checkbox {
  width: 20px;
  height: 20px;
}

.text-input {
  width: 300px;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.textarea-input {
  width: 500px;
  height: 100px;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  resize: both; /* Allows users to resize the textarea */
}

.update-button {
  background-color: #4caf50; /* Green */
  border: none;
  color: white;
  padding: 10px 20px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin-bottom: 20px;
  cursor: pointer;
  border-radius: 4px;
}

.confirmation-dialog {
  background-color: white;
  border: 1px solid #ccc;
  padding: 20px;
  border-radius: 5px;
  margin-bottom: 20px;
  text-align: center;
}

.confirm-button {
  padding: 10px 20px;
  font-size: 16px;
  border: none;
  color: white;
  border-radius: 4px;
  cursor: pointer;
  margin: 0 10px; /* Adds space between the buttons */
}

.yes-button {
  background-color: #4caf50; /* Green */
}

.no-button {
  background-color: #f44336; /* Red */
}

.confirm-button:hover {
  opacity: 0.9; /* Adds a slight hover effect */
}
</style>
