import Tasktype1 from './Tasktype1.vue'
import Tasktype2 from './Tasktype2.vue'
import Tasktype2mp from './Tasktype2mp.vue'
import Tasktype3 from './Tasktype3.vue'
import Tasktype3mp from './Tasktype3mp.vue'
import Tasktype4 from './Tasktype4.vue'
import Tasktype5 from './Tasktype5.vue'
import Tasktype6 from './Tasktype6.vue'
import Tasktype7 from './Tasktype7.vue'
import Tasktype8 from './Tasktype8.vue'
import Tasktype9 from './Tasktype9.vue'
import Tasktype9mp from './Tasktype9mp.vue'
import Tasktype10 from './Tasktype10.vue'
import Tasktype10mp from './Tasktype10mp.vue'
import Tasktype11 from './Tasktype11.vue'
import Tasktype11st from './Tasktype11st.vue' // ST-mode
import Tasktype22st from './Tasktype22st.vue' // ST-mode
import Tasktype23st from './Tasktype23st.vue' // ST-mode
import Tasktype24st from './Tasktype24st.vue' // ST-mode
import Tasktype12 from './Tasktype12.vue'
import Tasktype13 from './Tasktype13.vue'

export const taskComponents = {
  Tasktype1,
  Tasktype2,
  Tasktype2mp,
  Tasktype22st,
  Tasktype23st,
  Tasktype24st,
  Tasktype3,
  Tasktype3mp,
  Tasktype4,
  Tasktype5,
  Tasktype6,
  Tasktype7,
  Tasktype8,
  Tasktype9,
  Tasktype9mp,
  Tasktype10,
  Tasktype10mp,
  Tasktype11,
  Tasktype11st,
  Tasktype12,
  Tasktype13
}

export const theComponents = {
  Tasktype1: taskComponents.Tasktype1,
  Tasktype2: taskComponents.Tasktype2,
  Tasktype2mp: taskComponents.Tasktype2mp,
  Tasktype22st: taskComponents.Tasktype22st,
  Tasktype23st: taskComponents.Tasktype23st,
  Tasktype24st: taskComponents.Tasktype24st,
  Tasktype3: taskComponents.Tasktype3,
  Tasktype3mp: taskComponents.Tasktype3mp,
  Tasktype4: taskComponents.Tasktype4,
  Tasktype5: taskComponents.Tasktype5,
  Tasktype6: taskComponents.Tasktype6,
  Tasktype7: taskComponents.Tasktype7,
  Tasktype8: taskComponents.Tasktype8,
  Tasktype9: taskComponents.Tasktype9,
  Tasktype9mp: taskComponents.Tasktype9mp,
  Tasktype10: taskComponents.Tasktype10,
  Tasktype10mp: taskComponents.Tasktype10mp,
  Tasktype11: taskComponents.Tasktype11,
  Tasktype11st: taskComponents.Tasktype11st,
  Tasktype12: taskComponents.Tasktype12,
  Tasktype13: taskComponents.Tasktype13
}

export type allComponentsType =
  | typeof taskComponents.Tasktype1
  | typeof taskComponents.Tasktype2
  | typeof taskComponents.Tasktype2mp
  | typeof taskComponents.Tasktype22st
  | typeof taskComponents.Tasktype23st
  | typeof taskComponents.Tasktype24st
  | typeof taskComponents.Tasktype3mp
  | typeof taskComponents.Tasktype3
  | typeof taskComponents.Tasktype4
  | typeof taskComponents.Tasktype5
  | typeof taskComponents.Tasktype6
  | typeof taskComponents.Tasktype7
  | typeof taskComponents.Tasktype8
  | typeof taskComponents.Tasktype9
  | typeof taskComponents.Tasktype9mp
  | typeof taskComponents.Tasktype10
  | typeof taskComponents.Tasktype10mp
  | typeof taskComponents.Tasktype11
  | typeof taskComponents.Tasktype11st
  | typeof taskComponents.Tasktype12
  | typeof taskComponents.Tasktype13
  | typeof taskComponents
