/* eslint-disable @typescript-eslint/no-unused-vars */
<!-- Copyright 2023 Richard Nesnass, Tom Bjarne Seidel

 This file is part of KMMP.

KMMP is free software: you can redistribute it and/or modify
 it under the terms of the GNU Affero General Public License as published by
 the Free Software Foundation, either version 3 of the License, or
 (at your option) any later version.

 GPL-3.0-only or GPL-3.0-or-later

KMMP is distributed in the hope that it will be useful,
 but WITHOUT ANY WARRANTY; without even the implied warranty of
 MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 GNU Affero General Public License for more details.

 You should have received a copy of the GNU Affero General Public License
 along with KMMP.  If not, see http://www.gnu.org/licenses/. -->
<template>
  <div class="fadeInOut relative" :style="{ opacity: opacity }">
    <div class="type13SentenceContainer mt-8">
      <span class="type13Sentence taskNoUserSelect"
        >{{ task.textBefore }}<span class="theWordType13">&nbsp;{{ task.word }}&nbsp;</span
        >{{ task.textAfter }}</span
      >
    </div>

    <div class="flex flex-row justify-center mt-12">
      <div v-for="aRow of [1, 3, 5]" :key="`row-key-${aRow}`" class="flex flex-col justify-around">
        <div
          v-for="aCol of [0, 1]"
          :key="`col-key-${aCol}`"
          class="flex flex-row justify-center place-items-center"
        >
          <div
            v-if="task.answers[aCol + aRow] && task.answers[aCol + aRow].text"
            class="type13AnswerBox taskNoUserSelect flex flex-row justify-around place-items-center"
            @click="chooseAnswer(task.answers[aCol + aRow])"
          >
            <p class="flex">{{ task.answers[aCol + aRow].text }}</p>
            <div
              class="type13AnswerCheckbox flex"
              :class="{ 'type13AnswerCheckbox-selected': task.answers[aCol + aRow].checked }"
            >
              <img :src="task.answers[aCol + aRow].checked ? starSelected : noSelection" />
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="flex flex-row justify-around w-full mt-8">
      <img
        style="height: 120px"
        :src="submitActive ? sendEnabledButton : sendDisabledButton"
        :class="{ 'sendInButton-active': submitActive }"
        @click="finish()"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, PropType, toRefs } from 'vue'
import { Choice, Tracking } from '@/models/main'
import useState from '@/composition/useState'
import { Tasktype13 } from '@/models/tasktypes/Tasktype13'
import type { Type13Answer } from '@/models/tasktypes/Tasktype13'

import starSelected from '@/assets/images/tasks/type13/StarSelected@2x.png'
import noSelection from '@/assets/images/tasks/type13/NoSelectionImage@2x.png'
import sendEnabledButton from '@/assets/images/tasks/type13/SendButtonEnabled@2x.png'
import sendDisabledButton from '@/assets/images/tasks/type13/SendButtonDisabled@2x.png'
import { createSound } from '@/api/audioService'
import moment from 'moment'

const emit = defineEmits(['completed'])
const props = defineProps({
  task: { required: true, type: Object as PropType<Tasktype13> },
  myIndex: { required: false, type: Number, default: 0 }
})
const { getters: stateGetters, setters: stateSetters, actions: stateActions } = useState()
const { task } = toRefs(props)
const tracking = new Tracking(stateGetters.tracking.value)
stateSetters.trackingData = tracking
let choiceTimer = new Date()

const opacity = ref(0)
const submitActive = ref(false)
let soundPlaying = false
let chosenAnswer: Type13Answer | undefined = undefined

async function setupTask() {
  stateActions.progress.progressShow(0)
  opacity.value = 1
  if (task.value.audioURL) {
    const instructionAudio = await createSound(task.value.audioURL)
    instructionAudio.onended = () => {
      stateActions.setSpeakerSound([task.value.audioURL])
      stateSetters.speakerIsPlaying = false
      soundPlaying = false
    }
    stateSetters.speakerIsPlaying = true
    instructionAudio.playWhenReady()
  }
}

async function chooseAnswer(answer: Type13Answer) {
  const startDate = moment(choiceTimer)
  const endDate = moment()
  const choice = new Choice()
  choice.duration = endDate.diff(startDate, 'milliseconds')
  choice.response = answer.text
  choice.content = task.value.answers.map((a) => a.text).join(';')

  tracking.choices.push(choice)
  choiceTimer = new Date()

  if (chosenAnswer && chosenAnswer.checked) {
    chosenAnswer.checked = false
  }
  if (!soundPlaying && answer.audioURL) {
    soundPlaying = true
    const answerAudio = await createSound(answer.audioURL)
    answerAudio.onerror = (error) => {
      console.log(error.toString())
    }
    answerAudio.playWhenReady()
  }

  chosenAnswer = answer
  if (task.value.correct === answer.option) choice.correct = true
  chosenAnswer.checked = true
  submitActive.value = true
  stateSetters.trackingData = tracking
}

function finish() {
  if (submitActive.value && chosenAnswer) {
    setTimeout(() => {
      opacity.value = 0
      completeTask()
    }, 500)
  }
}

function completeTask() {
  setTimeout(() => {
    emit('completed', true, tracking)
  }, 1000)
}

setupTask()

// ----------------- TASK 5 -----------------------
</script>

<style scoped lang="postcss">
.type13AnswerBox {
  width: 300px;
  height: 120px;
  margin: 10px;

  border-radius: 20px;
  background-color: rgba(0, 0, 0, 0.8);
  padding: 10px;
  outline: none;
  border: solid var(--bg-slate-bright) 5px;
  cursor: pointer;
  font-size: 24pt;
  font-weight: 500;
  text-align: center;
  color: white;
}
.type13AnswerBox p {
  margin: 0 0;
  font-size: 0.8em;
}

.type13AnswerCheckbox img {
  width: 60px;
  height: 60px;
}

.type13Sentence {
  font-size: 1.8em;
  border-radius: 50px;
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
}
.type13SentenceContainer {
  position: relative;
  text-align: center;
  padding: 10px;
}
.theWordType13 {
  font-weight: bold;
  font-style: italic;
}
</style>
