<!-- Copyright 2022 Richard Nesnass, Tom Bjarne Seidel

 This file is part of SL+.

 SL+ is free software: you can redistribute it and/or modify
 it under the terms of the GNU Affero General Public License as published by
 the Free Software Foundation, either version 3 of the License, or
 (at your option) any later version.

 GPL-3.0-only or GPL-3.0-or-later

 SL+ is distributed in the hope that it will be useful,
 but WITHOUT ANY WARRANTY; without even the implied warranty of
 MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 GNU Affero General Public License for more details.

 You should have received a copy of the GNU Affero General Public License
 along with SL+.  If not, see http://www.gnu.org/licenses/. -->
<template>
  <div class="flex flex-row h-full">
    <div class="px-2 border-r-2 border-grey w-1/4">
      <div class="flex flex-col text-left mr-1">
        <div>
          <label class="block tracking-wide text-gray-400 text-xs font-bold mb-2"> Search in games</label>
          <input v-model="searchText" class="p-2 w-full rounded-none mb-3 border-2" type="text" />
        </div>
        <SelectionBox
          id="select-locationfilter"
          v-model="selectedGroup"
          class="mb-4"
          :label="'Filter by class'"
          :options="groupOptionList"
        ></SelectionBox>
        <SelectionBox id="select-userfilter" v-model="selectedUser" :label="'Filter by participant'" :options="userOptionList"></SelectionBox>
      </div>
      <div class="flex flex-col flex-grow mt-3">
        <p class="font-bold text-left">Games</p>
        <ul class="mt-3 half-height overflow-scroll text-white -ml-5">
          <li
            v-for="(g, i) in filteredGames"
            :key="'ap-' + i"
            class="cursor-pointer p-1 my-px border-t-2 border-slate-700 flex"
            :class="{ 'bg-black': g._id !== selectedGame?._id, 'bg-slate-900': g._id === selectedGame?._id }"
            @click="selectGame(g)"
          >
            <span
              class="ml-4"
              :class="{
                'text-red-500': g.details.dyadSplit,
                'text-white': !g.details.dyadSplit,
              }"
              >{{ g.profile.name }}</span
            >
            <span v-if="selectedGame && g._id === selectedGame._id" class="bg-green-500 rounded-full flex w-6 h-6 ml-2">&nbsp;✔︎&nbsp;</span>
          </li>
          <li v-if="filteredGames.length === 0" class="bg-red-100 text-red-500 p-1.5 mx-px rounded">No results</li>
        </ul>
      </div>
    </div>
    <div v-if="selectedGame" class="flex flex-col overflow-y-auto overflow-x-hidden flex-grow text-left ml-2 w-3/4 h-full">
      <MonitorGameItem :game="selectedGame" :edit="true" :selected="true" class="w-full max-h-40" />
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, Ref, computed, onMounted } from 'vue'
import { Game, Group, User } from '@/models/main'
import { GroupOptionListItem, UserOptionListItem } from '@/constants'

import useUserStore from '@/store/useUserStore'
import useGameStore from '@/store/useGameStore'

import SelectionBox from '@/components/base/SelectionBox.vue'
import MonitorGameItem from '@/components/admin/MonitorGameItem.vue'

const { getters: userGetters } = useUserStore()
const { getters: gameGetters, actions: gameActions } = useGameStore()

const selectedGroup: Ref<GroupOptionListItem> = ref({
  item: undefined,
  itemName: 'no group selected',
})

const selectedUser: Ref<UserOptionListItem> = ref({
  item: undefined,
  itemName: 'no user selected',
})

const searchText: Ref<string> = ref('')

onMounted(async () => {
  // Fetch participants inital value for the default location
  await gameActions.getGames()
})

const selectedGame = gameGetters.selectedGame
const filteredGames = computed(() => {
  let games = gameGetters.games.value

  const selectedGroupId = selectedGroup.value.item?._id
  if (selectedGroupId) games = games.filter((g: Game) => g.sharing.groups.includes(selectedGroupId))

  const selectedUserId = selectedUser.value.item?._id
  if (selectedUserId) games = games.filter((g: Game) => g.details.participants.find((p: string) => p === selectedUser?.value.item?._id))

  if (searchText.value.length > 0) games = games.filter((g: Game) => g.profile.name.toLowerCase().includes(searchText.value.toLowerCase()))
  return games
})

// --------------- Participant functions -----------------

const selectGame = (g: Game) => {
  gameActions.selectGame(g)
}

const groupOptionList = computed((): GroupOptionListItem[] => {
  const gol: GroupOptionListItem[] = userGetters.allGroups.value.map((g: Group) => ({
    item: g,
    itemName: g.name,
  }))
  gol.unshift({
    item: undefined,
    itemName: 'clear filter',
  })
  return gol
})

const userOptionList = computed((): UserOptionListItem[] => {
  const uol: UserOptionListItem[] = userGetters.allUsers.value.map((u: User) => ({
    item: u,
    itemName: u.profile.username,
  }))
  uol.unshift({
    item: undefined,
    itemName: 'clear filter',
  })
  return uol
})
</script>

<style scoped></style>
