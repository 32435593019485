x<!-- Copyright 2023 Richard Nesnass, Tom Bjarne Seidel

  This file is part of KM2MP.

 KM2MP is free software: you can redistribute it and/or modify
 it under the terms of the GNU Affero General Public License as published by
 the Free Software Foundation, either version 3 of the License, or
 (at your option) any later version.

 GPL-3.0-only or GPL-3.0-or-later

 KM2MP is distributed in the hope that it will be useful,
 but WITHOUT ANY WARRANTY; without even the implied warranty of
 MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 GNU Affero General Public License for more details.

 You should have received a copy of the GNU Affero General Public License
 along with KM2MP.  If not, see http://www.gnu.org/licenses/. -->
<template>
  <div
    class="relative flex flex-col justify-center items-center fadeInOut w-full h-full"
    :style="{ opacity: opacity }"
  >
    <div class="flex justify-evenly w-full h-full items-center flex-col relative">
      <Draggable
        v-if="props.actionAllowed && state.words[0]"
        class="fadeInOut text-white borderedWordBox borderedTaskWordBox z-10 absolute"
        :style="{ opacity: opacityWords, bottom: '-16px' }"
        :class="`${activeDrags ? 'pointer-events-none' : ''}`"
        :position="state.words[0].position"
        @start="() => onStart()"
        @stop="(e) => onDrop(e, state.words[0] as TT3Word)"
      >
        <div :class="{ highlightByScaling: highlightWord }">
          <span>{{ state.words[0].text }}</span>
        </div>
      </Draggable>

      <div
        v-if="props.role === UserTaskRole.Advisor && !props.actionAllowed && state.words[0]"
        class="fadeInOut text-white borderedWordBox bottom-0 z-10 absolute"
        :style="{ opacity: opacityWords }"
        :class="`${activeDrags ? 'pointer-events-none' : ''}`"
        :position="state.words[0].position"
      >
        <div>
          <span>{{ state.words[0].text }}</span>
        </div>
      </div>

      <div class="flex w-full h-full items-center justify-center p-4">
        <!-- Red Scanner -->
        <div
          id="red-scanner"
          ref="redScanner"
          v-draggable="{ axis: 'none' }"
          class="flex flex-col mr-3 place-items-center justify-center w-1/2 h-full border-2 rounded-xl red-border p-4"
          @start="onStart"
          @stop="onDrop"
          @mouseenter="onDropAreaMouseEnter"
          @mouseleave="onDropAreaMouseLeave"
        >
          <div class="flex flex-col place-items-center justify-center w-full h-full drop-target">
            <div
              v-if="
                props.advice &&
                props.advice.solution &&
                props.role !== UserTaskRole.Advisor &&
                props.advice.solution_id.includes('red')
              "
              :key="props.advice.solution.id"
              class="flex justify-between items-center dialog-bg fadedBorder relative rounded-full px-1 mt-2"
            >
              <p class="generalTextStyle inline-block z-10 text-white px-3 py-2">
                {{ props.advice.solution.text }}
              </p>
              <Avatar
                class="p-2 rounded-full w-16 h-16 -rotate-12 -mr-px"
                :style="userStyle(participantInformation.name)"
                :avatar-ref="participantInformation.avatar.ref"
              />
            </div>
            <div
              v-if="
                props.firstDecision &&
                props.firstDecision.solution &&
                props.role !== UserTaskRole.Leader &&
                props.firstDecision.solution_id.includes('red')
              "
              :key="props.firstDecision.solution.id"
              class="flex justify-between items-center dialog-bg fadedBorder relative rounded-full px-1 mt-2"
            >
              <p class="generalTextStyle inline-block z-10 text-white px-3 py-2">
                {{ props.firstDecision?.solution.text }}
              </p>
              <Avatar
                class="p-2 rounded-full w-16 h-16 -rotate-12 -mr-px"
                :style="userStyle(participantInformation.name)"
                :avatar-ref="participantInformation.avatar.ref"
              />
            </div>
            <div
              v-for="word in correctBox['red-scanner'].items"
              :key="word.id"
              class="flex justify-between items-center dialog-bg fadedBorder relative rounded-full px-1 mt-2"
              @click="clickBoxedWord(word)"
            >
              <p class="generalTextStyle inline-block z-10 text-white px-3 py-2">{{ word.text }}</p>
              <Avatar
                class="p-2 rounded-full w-16 h-16 -rotate-12 -mr-px"
                :style="userStyle(user.getters.myUser.value.profile.username)"
                :avatar-ref="user.getters.myUser.value.avatar.ref"
              />
            </div>
          </div>
          <span
            class="borderedWordBox red-border wordHighlight"
            :class="{ highlightByScaling: highlight1 }"
            @click="clickWord(1)"
            >{{ task.stem }}</span
          >
        </div>

        <!-- Blue Scanner -->
        <div
          id="blue-scanner"
          ref="blueScanner"
          v-draggable="{ axis: 'none' }"
          class="flex flex-col place-items-center justify-center w-1/2 h-full border-2 rounded-xl blue-border p-4"
          @start="onStart"
          @stop="onDrop"
          @mouseenter="onDropAreaMouseEnter"
          @mouseleave="onDropAreaMouseLeave"
        >
          <div class="flex flex-col place-items-center justify-center w-full h-full drop-target">
            <div
              v-if="
                props.advice &&
                props.advice.solution &&
                props.role !== UserTaskRole.Advisor &&
                props.advice.solution_id.includes('blue')
              "
              :key="props.advice.solution.id"
              class="flex justify-between items-center dialog-bg fadedBorder relative rounded-full px-1 mt-2"
            >
              <p class="generalTextStyle inline-block z-10 text-white px-3 py-2">
                {{ props.advice.solution.text }}
              </p>
              <Avatar
                class="p-2 rounded-full w-16 h-16 -rotate-12 -mr-px"
                :style="userStyle(participantInformation.name)"
                :avatar-ref="participantInformation.avatar.ref"
              />
            </div>
            <div
              v-if="
                props.firstDecision &&
                props.firstDecision.solution &&
                props.role !== UserTaskRole.Leader &&
                props.firstDecision.solution_id.includes('blue')
              "
              :key="props.firstDecision.solution.id"
              class="flex justify-between items-center dialog-bg fadedBorder relative rounded-full px-1 mt-2"
            >
              <p class="generalTextStyle inline-block z-10 text-white px-3 py-2">
                {{ props.firstDecision?.solution.text }}
              </p>
              <Avatar
                class="p-2 rounded-full w-16 h-16 -rotate-12 -mr-px"
                :style="userStyle(participantInformation.name)"
                :avatar-ref="participantInformation.avatar.ref"
              />
            </div>
            <div
              v-for="word in correctBox['blue-scanner'].items"
              :key="word.id"
              class="flex justify-between items-center dialog-bg fadedBorder relative rounded-full px-1 mt-2"
              @click="clickBoxedWord(word)"
            >
              <p class="generalTextStyle inline-block z-10 text-white px-3 py-2">{{ word.text }}</p>
              <Avatar
                class="p-2 rounded-full w-16 h-16 -rotate-12 -mr-px"
                :style="userStyle(user.getters.myUser.value.profile.username)"
                :avatar-ref="user.getters.myUser.value.avatar.ref"
              />
            </div>
          </div>
          <span
            class="borderedWordBox blue-border wordHighlight"
            :class="{ highlightByScaling: highlight2 }"
            @click="clickWord(2)"
            >{{ task.morphedStem }}</span
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, PropType, toRefs, Ref, onMounted, reactive } from 'vue'
import type { DraggableEvent } from '@braks/revue-draggable'
import { Draggable } from '@braks/revue-draggable'
import { useI18n } from 'vue-i18n'

import { Tasktype3mp, Type3Options } from '@/models/tasktypes/Tasktype3mp'
import { SolutionUnion, TT3Word } from '@/models/tasktypes'

import { Choice, TaskSync, Tracking } from '@/models/main'
import { shuffleItems } from '@/utilities'
import {
  DialogMessageType,
  SpeechSounds,
  TaskMode,
  UserTaskRole,
  TaskCallbackType,
  TaskSyncWord,
  TaskCallback,
  TaskCallbackParam,
  SyncType
} from '@/constants'

import useMultiPlayerState from '@/composition/useMultiplayerState'
import useDialogStore from '@/composition/dialog'
import useState from '@/composition/useState'
import Avatar from '@/components/base/Avatar.vue'
import useColorStore from '@/composition/colors'
import useUserStore from '@/store/useUserStore'
import { WebAudio } from '@/models/audio'
import { createSound } from '@/api/audioService'
import moment from 'moment'

type DraggableDIVElementEvent = DraggableEvent & { event: { target: HTMLDivElement } }

const emit = defineEmits<{
  (e: 'completed', value: boolean, tracking: Tracking): void
  (e: 'addSync', taskId: string, solution: SolutionUnion, solutionId: string): void
  (e: 'updateShuffleOrder', shuffleOrder: string[]): void
  (e: 'registerCallback', type: TaskCallbackType, callback: TaskCallback): void
  (
    e: 'showResultMessage',
    messageType: DialogMessageType,
    solution: TT3Word,
    callback: () => void
  ): void
}>()

const { t } = useI18n()

const props = defineProps({
  task: { required: true, type: Object as PropType<Tasktype3mp> },
  myIndex: { required: false, type: Number, default: 0 },
  actionAllowed: { required: true, type: Boolean },
  firstDecision: { required: false, type: Object as PropType<TaskSyncWord | null>, default: null },
  advice: { required: false, type: Object as PropType<TaskSyncWord | null>, default: null },
  finalDecision: { required: false, type: Object as PropType<TaskSyncWord | null>, default: null },
  role: { required: true, type: String }
})

const { getters: stateGetters, setters: stateSetters, actions: stateActions } = useState()
const multiplayer = useMultiPlayerState()
const dialog = useDialogStore()
const color = useColorStore()
const user = useUserStore()

const { task } = toRefs(props)
const tracking = new Tracking(stateGetters.tracking.value)
stateSetters.trackingData = tracking
let choiceTimer = new Date()

const currentPhase = multiplayer.getters.phase

const opacity = ref(0)
const activeDrags = ref(0)
const participantInformation = multiplayer.getters.participantInformation

const unforgivingTestMode = false
let attempts = 0
let choiceAttempt = 1

const roundData = { correct: 0, of: 0 }

const opacityWords = ref(0.1)
const redScanner = ref()
const blueScanner = ref()

// public draggableWords = [];
const droppedObjects1: Ref<TT3Word[]> = ref([])
const droppedObjects2: Ref<TT3Word[]> = ref([])

let draggingNow = false
let taskCompleted = false

const highlight1 = ref(false)
const highlight2 = ref(false)
const highlightWord = ref(false)

let instructionAudio1: WebAudio
let instructionAudio2: WebAudio

const boxAudio: { [key: number]: WebAudio } = {}
const wordAudio: { [key: string]: WebAudio } = {}

let introDone = false
let audioPlaying = false

const correctBox: Ref<{ [key: string]: { name: string; items: TT3Word[] } }> = ref({
  'blue-scanner': { name: 'Morphed Stem', items: [] },
  'red-scanner': { name: 'Stem', items: [] }
})

const state = reactive({
  initialWords: [] as TT3Word[],
  words: [] as TT3Word[]
})

// ---------      Dragula functions -------------------

const onStart = () => {
  if (props.actionAllowed) activeDrags.value++
  else dialog.actions.pushMessage(DialogMessageType.Warning, t('waitforturn'))
}

// A 'dropbox' element should incude the class 'drop-target'
const getDropboxElement = (e: DraggableEvent): Element | undefined => {
  let x = 0
  let y = 0
  if (e.event && e.event.type.includes('touch')) {
    const te = e.event as TouchEvent
    x = te.changedTouches[0].clientX
    y = te.changedTouches[0].clientY
  } else if (e.event) {
    const me = e.event as MouseEvent
    x = me.clientX
    y = me.clientY
  } else return
  const el = document.elementFromPoint(x, y)
  const condition =
    ['red', 'blue'].some((e) => el?.id.includes(e)) ||
    ['red', 'blue'].some((e) => el?.parentElement?.id.includes(e))
  return el && condition ? el : undefined
}

/* HOOKS */

onMounted(() => {
  emit('registerCallback', TaskCallbackType.FinalDecisionAccept, finalDecisionAcceptedCallback)
  emit('registerCallback', TaskCallbackType.FinalDecisionReject, finalDecisionRejectedCallback)
  emit('registerCallback', TaskCallbackType.Shuffle, shuffleCallback)
  emit('registerCallback', TaskCallbackType.ShuffleOnRequest, shuffleAndSync)

  setupTask()
})

const finalDecisionAcceptedCallback = (args: TaskCallbackParam) => {
  const decision = args as TaskSync
  const element = document.getElementById((decision as TaskSync).solution_id)
  const id = element?.id.includes('blue') ? 'blue-scanner' : 'red-scanner'
  if (element) submitFinalDecision(id, decision.solution as TT3Word)
}

const finalDecisionRejectedCallback = (args: TaskCallbackParam) => {
  const decision = args as TaskSync
  // TODO: for this task type there is no real rejection... consider when assembling tracking information
  if (decision.solution_id !== props.advice?.solution_id && props.advice?.solution) {
    // automatically choose the other solution as the one to proceed with if the solution_id is different (boolean value)
    const adviceElement = document.getElementById(props.advice.solution_id)
    if (adviceElement) submitFinalDecision(adviceElement.id, props.advice?.solution as TT3Word)
  } else reset() // invoked when both users have chosen the same box
}

const shuffleCallback = () => {
  const items = multiplayer.getters.shuffleOrder.value
  const shuffledItems = []
  for (const item of items) {
    const theItem = state.initialWords.find((word) => word.text === item)
    if (theItem) shuffledItems.push(theItem)
  }
  state.words = shuffledItems.filter((w) => w) // remove empty slots
}

/* TASK FUNCTIONS */

const onDrop = (e: DraggableDIVElementEvent, word?: TT3Word) => {
  activeDrags.value = 0
  const el = getDropboxElement(e)
  const choice = new Choice()
  choice.duration = moment().diff(moment(choiceTimer), 'milliseconds')
  choice.round = roundData.correct + 1
  choice.attempt = choiceAttempt
  choice.content = state.words.map((i) => i.text).join(';')
  choice.response = word?.text ?? '(none)'
  choice.phase = Object.values(SyncType).indexOf(currentPhase.value)
  choice.target = state.words[0].correct === 'Stem' ? task.value.stem : task.value.morphedStem
  tracking.choices.push(choice)
  choiceTimer = new Date()

  if (word && el && props.actionAllowed) {
    dropWord(el, word, true)
    choice.valid = true
    const condition =
      (el.id && el.id.includes('blue')) ||
      (el.parentElement?.id && el.parentElement?.id.includes('blue'))
    const id = condition ? 'blue-scanner' : 'red-scanner'
    choice.correct = word.correct === correctBox.value[id].name
    emit('addSync', props.task.id, word, id)
  } else if (word && props.actionAllowed) {
    clickBoxedWord(word)
    word.position = { x: 0, y: 0 }
  } else if (!props.actionAllowed) {
    dialog.actions.pushMessage(DialogMessageType.Warning, t('waitforturn'))
  }
  stateSetters.trackingData = tracking
}

const submitFinalDecision = (boxID: string | undefined, word: TT3Word) => {
  const choice = new Choice()
  choice.duration = moment().diff(moment(choiceTimer), 'milliseconds')
  choice.round = roundData.correct + 1
  choice.attempt = choiceAttempt
  choice.valid = true
  choice.committed = true
  choice.content = state.words.map((i) => i.text).join(';')
  choice.response = word?.text ?? '(none)'
  choice.phase = Object.values(SyncType).indexOf(currentPhase.value)
  choice.target = state.words[0].correct === 'Stem' ? task.value.stem : task.value.morphedStem
  tracking.choices.push(choice)
  choiceTimer = new Date()
  if (boxID && word.correct === correctBox.value[boxID].name) {
    choice.correct = true
    onDropComplete(word)
  } else {
    const callback = () => {
      reset()
    }
    emit('showResultMessage', DialogMessageType.ResultSplashWrong, word, callback)
  }
  stateSetters.trackingData = tracking
}

const shuffleAndSync = () => {
  if (props.role === UserTaskRole.Leader) {
    state.words = shuffleItems(state.words)
    const shuffleOrder = state.words.map((word) => {
      return word.text
    })
    console.log('Shuffling and syncing type 3 words')
    emit('updateShuffleOrder', shuffleOrder) // sync the re-shuffled words
  }
}

const reset = () => {
  shuffleAndSync() // add word to state, shuffle and send parcel
  multiplayer.actions.resetSync() // reset sync state for the current round
  resetBoxes()
  choiceAttempt++
  setTimeout(() => {
    draggingNow = false
    playWordAudio()
  }, 1000)
}

const resetBoxes = () => {
  correctBox.value['red-scanner'].items = [] // reset red box
  correctBox.value['blue-scanner'].items = [] // reset blue box
}

/* const scannerHeight = () => {
  if (redScanner.value) return `height: ${redScanner.value.getBoundingClientRect().width}px;`
} */

const dropWord = (el: Element, word: TT3Word, countAttempts = false) => {
  const boxID = el.parentElement?.id || undefined
  if (countAttempts) attempts++
  redScanner.value.classList.remove('saturate-200')
  blueScanner.value.classList.remove('saturate-200')
  if (boxID) correctBox.value[boxID].items.push(word)
  return boxID
}

const onDropAreaMouseEnter = (e: MouseEvent | TouchEvent) => {
  const el = e && (e.target as HTMLElement)
  if (activeDrags.value && el) {
    if (el.id === 'red-scanner') redScanner.value.classList.add('saturate-200')
    else blueScanner.value.classList.add('saturate-200')
  }
}

const onDropAreaMouseLeave = (e: MouseEvent | TouchEvent) => {
  const el = e && (e.target as HTMLElement)
  if (activeDrags.value && el) {
    if (el.id === 'red-scanner') redScanner.value.classList.remove('saturate-200')
    else blueScanner.value.classList.remove('saturate-200')
  }
}

// ---------------  Setup --------------------------

const playWordAudio = () => {
  if (!draggingNow && introDone && !audioPlaying) {
    audioPlaying = true
    highlight2.value = false
    highlight1.value = false
    highlightWord.value = true
    if (state.words[0]) {
      wordAudio[state.words[0].text].onended = () => {
        highlightWord.value = false
        audioPlaying = false
      }
      wordAudio[state.words[0].text].playWhenReady()
    } else {
      highlightWord.value = false
      audioPlaying = false
    }
  }
}

const clickWord = (box: number) => {
  if (!draggingNow && !audioPlaying && boxAudio[box]) {
    audioPlaying = true
    boxAudio[box].onended = () => {
      audioPlaying = false
      highlightWord.value = false
    }
    boxAudio[box].playWhenReady()
    tracking.use_audio_content_items++
  }
}

const clickBoxedWord = (word?: TT3Word) => {
  if (word && !draggingNow && !audioPlaying && wordAudio[word.text]) {
    audioPlaying = true
    wordAudio[word.text].playWhenReady()
    tracking.use_audio_content_items++
  }
}

const setupTask = async () => {
  if (typeof task.value === 'undefined' || task.value === null) {
    alert('A Type 3MP task does not exist - check your Session layout in the CMS')
    return
  }

  audioPlaying = false
  taskCompleted = false

  try {
    boxAudio[1] = await createSound(task.value.audio)
    if (boxAudio[1]) {
      boxAudio[1].onended = () => {
        audioPlaying = false
        highlightWord.value = false
      }
    }

    boxAudio[2] = await createSound(task.value.audio2)
    if (boxAudio[2]) {
      boxAudio[2].onended = () => {
        audioPlaying = false
        highlightWord.value = false
      }
    }
  } catch (e) {
    console.error('error playing audio')
  }

  droppedObjects1.value = []
  droppedObjects2.value = []

  const words = await setupWords()
  let tempWords = shuffleItems(words)
  const leaderShuffle = multiplayer.getters.shuffleOrder.value
  // In some cases the shuffled order is received from the participant before the task is set up
  if (words.every((word) => leaderShuffle.includes(word.text))) {
    shuffleCallback()
  } else {
    state.words = tempWords
  }
  stateActions.progress.progressShow(tempWords.length)

  if (props.role === UserTaskRole.Leader) {
    // sync the leaders' order to the advisor
    const shuffleOrder = state.words.map((word) => {
      return word.text
    })
    emit('updateShuffleOrder', shuffleOrder)
  }

  if (task.value.audio) instructionAudio1 = await createSound(task.value.audio)
  if (task.value.audio2) instructionAudio2 = await createSound(task.value.audio2)

  introduceChallenge()
}

const setupWords = async (): Promise<TT3Word[]> => {
  const tempWords: TT3Word[] = []
  for (let i = 0; i < task.value.options.length; i++) {
    const t: Type3Options = task.value.options[i]
    //task.value.options.forEach((task: Type3Options, index: number) => {
    if (t.text) {
      const word: TT3Word = {
        text: t.text,
        correct: t.correct,
        enabled: false,
        draggable: true,
        opacity: 0,
        position: { x: 0, y: 0 },
        id: 'draggable-word-' + i
      }

      try {
        wordAudio[word.text] = await createSound(t.audio)
      } catch (e) {
        console.log(e)
      }

      roundData.of++
      const occurances = t.repeated
      for (let r = 0; r < occurances; r++) {
        tempWords.push({ ...word })
        state.initialWords.push({ ...word })
      }
    }
  }
  return tempWords
}

const introduceChallenge = () => {
  attempts = 0
  opacity.value = 1
  opacityWords.value = 1

  const onSoundCompleted = () => {
    if (task.value.audio) {
      if (task.value.audio2) {
        instructionAudio1.onended = () => {
          highlight1.value = false
          setTimeout(() => {
            highlight2.value = true
            instructionAudio2.playWhenReady()
          }, 500)
        }
        instructionAudio2.onended = () => {
          introDone = true
          highlight2.value = false
          setTimeout(() => {
            audioPlaying = false
            stateSetters.speakerIsPlaying = false
            playWordAudio()
          }, 1000)
        }
      } else {
        instructionAudio1.onended = () => {
          introDone = true
          highlight1.value = false
          setTimeout(() => {
            audioPlaying = false
            stateSetters.speakerIsPlaying = false
            playWordAudio()
          }, 1000)
        }
      }
      setTimeout(() => {
        highlight1.value = true
        instructionAudio1.playWhenReady()
      }, 500)
    } else {
      introDone = true
      audioPlaying = false
      stateSetters.speakerIsPlaying = false
    }
  }
  // Play generic audio including two box audios
  audioPlaying = true
  stateSetters.speakerIsPlaying = true
  stateActions.speakLocalised(SpeechSounds.instructions.tasks.T3, () => onSoundCompleted(), 1000)
}

const onDropComplete = (item: TT3Word) => {
  const callback = () => {
    highlightWord.value = false
    roundData.correct++
    item.enabled = false

    stateActions.progress.completeAStar()
    multiplayer.actions.proceedRoundIndex() // round is completed -> proceed
    resetBoxes()

    // remove word from list if the solution was correct
    const index = state.words.findIndex((w) => w.id === item.id)
    if (index !== -1) state.words.splice(index, 1)

    setTimeout(() => {
      opacityWords.value = 1
      // Set the allowed number of attempts here - at the moment it is matched with the number of correct answers
      if (
        (unforgivingTestMode && attempts === roundData.of) ||
        (!unforgivingTestMode && roundData.of === roundData.correct)
      ) {
        if (!taskCompleted) {
          taskCompleted = true
          opacityWords.value = 0
          if (stateGetters.state.value.taskMode === TaskMode.Warmups) {
            stateActions.speakLocalised(
              SpeechSounds.instructions.warmups.T3,
              () => {
                fadeOut()
              },
              1000,
              false
            )
          } else fadeOut()
        }
      }
      draggingNow = false
      setTimeout(() => {
        audioPlaying = false
        playWordAudio()
      }, 1000)
    }, 1000)
  }
  emit('showResultMessage', DialogMessageType.ResultSplashCorrect, item, callback)
}

const fadeOut = () => {
  setTimeout(() => {
    opacity.value = 0
    completeTask()
  }, 1000)
}

const completeTask = () => {
  setTimeout(() => {
    emit('completed', true, tracking)
  }, 1000)
}

function userStyle(name: string) {
  return `background-color: ${color.actions.selectColor(name)};`
}
</script>

<style scoped lang="postcss">
.red-border {
  border-color: #dc1d43;
}

.blue-border {
  border-color: #066ec0;
}

.wordHighlight {
  position: relative;
  bottom: -50px;
}
</style>
@/draggable
