/*
 Designed and developed by Richard Nesnass

 This file is part of SL+.

 SL+ is free software: you can redistribute it and/or modify
 it under the terms of the GNU Affero General Public License as published by
 the Free Software Foundation, either version 3 of the License, or
 (at your option) any later version.

 GPL-3.0-only or GPL-3.0-or-later

 SL+ is distributed in the hope that it will be useful,
 but WITHOUT ANY WARRANTY; without even the implied warranty of
 MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 GNU Affero General Public License for more details.

 You should have received a copy of the GNU Affero General Public License
 along with SL+.  If not, see <http://www.gnu.org/licenses/>.
 */
import { ref, computed, Ref, ComputedRef } from 'vue'
import { useRouter } from 'vue-router'
import moment from 'moment'
import {
  ViewState,
  LoginMode,
  LobbyMode,
  TaskMode,
  DelayMode,
  StateVariables,
  LanguageCodes
} from '@/constants'
import { Tracking, Game, SESSION_TYPE } from '@/models/main'
import { QuestionUnion } from '@/models/tasktypes'
import { Session, Episode, Collection, Activity } from '@/models/navigationModels'
import useCMSStore from '@/store/useCMSStore'
import useGameStore from '@/store/useGameStore'
import useAppStore from '@/store/useAppStore'
import useUserStore from '@/store/useUserStore'
import useDevice from '@/composition/useDevice'

import loginBackground from '@/assets/images/background.png'
import { createSound } from '@/api/audioService'
import { WebAudio } from '@/models/audio'

export interface ControlState {
  state: {
    viewState: ViewState
    loginMode: LoginMode
    lobbyMode: LobbyMode
    taskMode: TaskMode
    delayMode: DelayMode
  }

  currentTaskPosition: number
  currentCollectionPosition: number // After sorting, the index of the currently selected Collection
  currentSessionPosition: number // After sorting, the index of the currently selected Session

  tracking?: Tracking
  trackingData: Tracking

  progress: {
    starData: {
      stars: number
      completed: number
    }
    barData: {
      completedPercent: number
    }
    shipBarData: {
      completedPercent: number
    }
    starPopSound?: WebAudio
  }

  // Narration
  sidekickAnimating: boolean

  backgroundImage: string
  savedBackgroundImage: string
  demoMode: boolean
  inactivityTimestamp: moment.Moment | undefined
  speakerIsPlaying: boolean
  speakerSound?: WebAudio
}

const _controlState: Ref<ControlState> = ref({
  state: {
    viewState: ViewState.Dashboard,
    loginMode: LoginMode.AppStarted,
    lobbyMode: LobbyMode.SessionLocked,
    taskMode: TaskMode.Tests,
    delayMode: DelayMode.None
  },

  tracking: undefined,
  trackingData: new Tracking(),

  progress: {
    starData: {
      stars: 0,
      completed: 0
    },
    barData: {
      completedPercent: 1
    },
    shipBarData: {
      completedPercent: 1
    },
    starPopSound: undefined
  },

  currentTaskPosition: 0,
  currentCollectionPosition: 0, // Position of the collection in the game (post-shuffle)
  currentSessionPosition: 0,
  sidekickAnimating: false,

  backgroundImage: loginBackground,
  savedBackgroundImage: '',
  demoMode: false,
  inactivityTimestamp: undefined,
  speakerIsPlaying: false,
  speakerSound: undefined
})

// ------------ External interfaces -------------

type Getters = {
  backgroundImage: ComputedRef<string>
  languageCode: ComputedRef<LanguageCodes>
  demoMode: ComputedRef<boolean>
  sidekickAnimating: ComputedRef<boolean>
  state: ComputedRef<ControlState['state']>
  tracking: ComputedRef<ControlState['tracking']>
  trackingData: ComputedRef<Tracking>
  progress: ComputedRef<ControlState['progress']>
  nextUncompletedEpisode: ComputedRef<Episode | undefined>
  nextUncompletedCollection: ComputedRef<Collection | undefined>
  nextUncompletedSession: ComputedRef<Session | undefined>
  speakerIsPlaying: ComputedRef<boolean>
  currentTaskPosition: ComputedRef<number>
  currentCollectionPosition: ComputedRef<number>
  currentSessionPosition: ComputedRef<number>
}
type Setters = {
  backgroundImage: string
  state: StateVariables
  speakerIsPlaying: boolean
  trackingData: Tracking
}
type Actions = {
  backgroundImage: (src: string) => void
  downloadAssets: (session?: Session) => Promise<void>
  downloadAssetsForEpisode: () => Promise<void>
  updateState: (stateVariables?: StateVariables) => void
  completeTask: (userId?: string) => Promise<void>
  setSelectedTask: () => void
  configureSession: (game: Game) => Promise<void>
  setTaskMode: (taskMode: TaskMode) => void
  speakLocalised: (
    subPaths: string,
    callback?: () => void,
    delay?: number,
    setSpeaker?: boolean,
    animateComputer?: boolean
  ) => void
  setEpisodeDetailsFromDashboard: () => void // set an episode when selecting the game in the dashboard
  begin: () => void // Start the state machine now that User, Game, and CMS data is available
  exitToLogin: () => void
  startSession: (game: Game) => Promise<void>
  progress: {
    completeAStar: () => void
    progressShow: (stars?: number) => void
    reset: () => void
    resetBar: () => void
    resetStars: () => void
    calculateEpisodeProgress: () => void
    calculateTaskSetProgress: (session: Session) => void
  }
  updateTracking: (r: Tracking) => void
  resetInactiveTimer: () => void
  activateSession: (session?: Session, password?: string) => Promise<void>
  getSessionWithTasks: (session: Session) => Promise<void>
  setSpeakerSound: (sources: string[]) => void
  speakerClick: () => void
}
interface ServiceInterface {
  getters: Getters
  setters: Setters
  actions: Actions
}
function useStateService(): ServiceInterface {
  const router = useRouter()

  const { getters: cmsGetters, actions: cmsActions } = useCMSStore()
  const { getters: gameGetters, actions: gameActions } = useGameStore()
  const { getters: appGetters, actions: appActions } = useAppStore()
  const { getters: userGetters } = useUserStore()
  const { getters: deviceGetters, actions: deviceActions } = useDevice()

  // ------------- Internal functions -------------

  function nextIncompleteEpisode(activity: Activity): Episode | undefined {
    const userId = userGetters.myUser.value._id
    const completedEpisodeIDs = gameActions.getCompletedChildren(activity.id, userId)
    // Exclude episodes that are in the Student's completed list
    const uncompletedEpisodes = activity.episodes.filter((episode) => {
      return completedEpisodeIDs.indexOf(episode.id) < 0
    })
    if (uncompletedEpisodes.length > 0) return uncompletedEpisodes[0]
    else return undefined
  }

  function nextIncompleteCollection(episode: Episode): Collection | undefined {
    const userId = userGetters.myUser.value._id
    const completedSessions = gameActions.getCompletedChildren(episode.id, userId)
    const incompleteCollections: Collection[] = episode.collections.filter((c) =>
      c.sessions.some((s) => !completedSessions.includes(s.id))
    )
    _controlState.value.currentCollectionPosition =
      episode.collections.length - incompleteCollections.length
    return incompleteCollections.length > 0 ? incompleteCollections[0] : undefined
  }

  // Checks for completion within the given Collection
  function nextIncompleteSession(episode: Episode, collection: Collection): Session | undefined {
    const userId = userGetters.myUser.value._id
    // Returns a list of session IDs for a particular episode:  string[]
    const completedSessionIDs: string[] = gameActions.getCompletedChildren(episode.id, userId)
    // Exclude sessions that are in the Student's completed list
    const incompleteSessions: Session[] = collection.sessions.filter(
      (s) => !completedSessionIDs.includes(s.id)
    )
    const allSessions = cmsGetters.selectedCollection.value?.sessions ?? []
    _controlState.value.currentSessionPosition = allSessions.length - incompleteSessions.length
    if (incompleteSessions.length > 0) return incompleteSessions[0]
    else {
      console.log('No more incomplete sessions found')
      return undefined
    }
  }

  // Node: these are 'getters' which should be called as an attribute, not as a function
  const getters = {
    get currentTaskPosition(): ComputedRef<number> {
      return computed(() => _controlState.value.currentTaskPosition)
    },
    get backgroundImage(): ComputedRef<string> {
      return computed(() => _controlState.value.backgroundImage)
    },
    get languageCode(): ComputedRef<LanguageCodes> {
      return appGetters.languageCode
    },
    get demoMode(): ComputedRef<boolean> {
      return computed(() => _controlState.value.demoMode)
    },
    get tracking() {
      return computed(() => _controlState.value.tracking)
    },
    get trackingData() {
      return computed(() => _controlState.value.trackingData)
    },
    get state() {
      return computed(() => _controlState.value.state)
    },
    get progress() {
      return computed(() => _controlState.value.progress)
    },
    get sidekickAnimating(): ComputedRef<boolean> {
      return computed(() => _controlState.value.sidekickAnimating)
    },
    get speakerIsPlaying(): ComputedRef<boolean> {
      return computed(() => _controlState.value.speakerIsPlaying)
    },

    get nextUncompletedEpisode(): ComputedRef<Episode | undefined> {
      return computed(() => {
        const activity = cmsGetters.selectedActivity.value.activity
        return activity ? nextIncompleteEpisode(activity) : undefined
      })
    },

    get nextUncompletedCollection(): ComputedRef<Collection | undefined> {
      return computed(() => {
        const episode = cmsGetters.selectedEpisode.value
        return episode ? nextIncompleteCollection(episode) : undefined
      })
    },
    get currentCollectionPosition(): ComputedRef<number> {
      return computed(() => {
        return _controlState.value.currentCollectionPosition
      })
    },

    get nextUncompletedSession(): ComputedRef<Session | undefined> {
      return computed(() => {
        // If there are no sessions assigned, we can't continue
        const selectedEpisode = cmsGetters.selectedEpisode.value
        const selectedCollection = cmsGetters.selectedCollection.value
        if (selectedEpisode && selectedCollection && selectedCollection.sessions.length > 0) {
          return nextIncompleteSession(selectedEpisode, selectedCollection)
        } else return undefined
      })
    },
    get currentSessionPosition(): ComputedRef<number> {
      return computed(() => {
        return _controlState.value.currentSessionPosition
      })
    }
  }

  const setters = {
    set backgroundImage(src: string) {
      _controlState.value.backgroundImage = src
    },
    set state(stateVariables: StateVariables) {
      _controlState.value.state = { ..._controlState.value.state, ...stateVariables }
    },
    set speakerIsPlaying(on: boolean) {
      _controlState.value.speakerIsPlaying = on
    },
    set trackingData(data: Tracking) {
      _controlState.value.trackingData.update(data)
    }
  }

  const actions: Actions = {
    backgroundImage: () => ({}),

    async downloadAssets(s?: Session): Promise<void> {
      const session = s ? s : cmsGetters.selectedSession.value
      const selectedGame = gameGetters.selectedGame.value
      if (session && selectedGame) {
        const sessionWarmupTasks = session.allWarmups
        const sessionTestTasks = session.allTasks
        const warmupAssetList = sessionWarmupTasks.map((s) => s.assetList).flat()
        const testAssetList = sessionTestTasks.map((s) => s.assetList).flat()
        const sessionAssetList = warmupAssetList.concat(testAssetList).filter((as) => as != '')
        for (let i = 0; i < sessionAssetList.length; i++) {
          appActions.logFeedback(`Downloading asset ${i} of ${sessionAssetList.length}`)
          await deviceActions.downloadToCache(sessionAssetList[i])
        }
        return await deviceActions.saveMediaCache()
      } else return Promise.reject()
    },

    // Download assets for the next two sessions
    async downloadAssetsForEpisode(): Promise<void> {
      const episode = cmsGetters.selectedEpisode.value
      const collection = cmsGetters.selectedCollection.value
      const session = cmsGetters.selectedSession.value
      if (episode && episode.collections && episode.collections.length && collection && session) {
        const csIndex = collection.sessions.findIndex((s) => s.id === session.id)
        const downloadSets =
          csIndex >= 0 && csIndex < collection.sessions.length - 1
            ? [session, collection.sessions[csIndex + 1]]
            : [session]
        for (const s of downloadSets) {
          appActions.logFeedback('Downloading next session for current episode..')
          const session = new Session() // A clean session is necessary to prevent duplicate tasks in the actual session
          session.id = s.id
          session.type = s.type // we need to rely on the type when querying the tasks
          await cmsActions.getQuestions(session, appGetters.languageCode.value)
          await actions.downloadAssets(session)
          return Promise.resolve()
        }
      } else {
        _controlState.value.state.viewState = ViewState.Delay
        _controlState.value.state.delayMode = DelayMode.NoSessionsFound
        actions.updateState()
        return Promise.reject()
      }
    },

    async speakLocalised(
      subPath: string,
      callback?: () => void,
      delay = 0,
      setSpeaker = true,
      animateComputer = true
    ) {
      const language = appGetters.languageCode.value
      const url = `/assets/sounds/${language}/${subPath}`
      const newSound = await createSound(url)
      newSound.onended = () => {
        _controlState.value.sidekickAnimating = false
        if (callback) callback()
      }
      newSound.onerror = () => {
        _controlState.value.sidekickAnimating = false
        if (callback) callback()
      }
      // Also set the 'speaker icon sound' to this sound by default
      if (setSpeaker) {
        _controlState.value.speakerSound = await createSound(url)
        _controlState.value.speakerSound.onended = () => {
          _controlState.value.sidekickAnimating = false
          _controlState.value.speakerIsPlaying = false
        }
      }
      setTimeout(() => {
        if (animateComputer) _controlState.value.sidekickAnimating = true
        newSound.playWhenReady()
      }, delay)
    },

    async setSpeakerSound(sources: string[]) {
      if (!sources.length) {
        _controlState.value.speakerSound = undefined
      } else {
        _controlState.value.speakerSound = await createSound(sources[0])
        _controlState.value.speakerSound.onended = async () => {
          // Possibly play a second sound..
          if (sources.length > 1) {
            const a = await createSound(sources[1])
            a.onended = () => (_controlState.value.speakerIsPlaying = false)
            a.playWhenReady()
          } else _controlState.value.speakerIsPlaying = false
        }
      }
    },
    speakerClick() {
      if (_controlState.value.speakerSound && !_controlState.value.speakerIsPlaying) {
        _controlState.value.speakerSound.playWhenReady()
        _controlState.value.speakerIsPlaying = true
        if (_controlState.value.tracking?.use_audio_instructions) {
          _controlState.value.tracking.use_audio_instructions++
        }
      }
    },
    updateTracking(r: Tracking): void {
      if (_controlState.value.tracking) _controlState.value.tracking.update(r)
      else _controlState.value.tracking = new Tracking(r)
    },
    resetInactiveTimer(): void {
      if (_controlState.value.inactivityTimestamp) {
        const diff = moment().diff(_controlState.value.inactivityTimestamp, 'seconds')
        const log = _controlState.value.tracking
        if (diff > 30 && log) {
          log.inactive_count++
          log.inactive_duration += diff
        }
      }
      _controlState.value.inactivityTimestamp = moment()
    },

    setEpisodeDetailsFromDashboard(): void {
      const episode = getters.nextUncompletedEpisode.value
      if (episode) cmsActions.selectEpisode(episode)
    },

    begin(): void {
      // Check for an active Activity. There must be an Activity selected before beginning
      const selectedActivity = cmsGetters.selectedActivity.value.activity
      if (!cmsGetters.selectedActivity.value.cmsID || !selectedActivity) {
        _controlState.value.state.viewState = ViewState.Delay
        _controlState.value.state.delayMode = DelayMode.NoActivitiesFound
        actions.updateState()
        return
      }

      const nextEpisode = nextIncompleteEpisode(selectedActivity)
      if (nextEpisode) {
        cmsActions.selectEpisode(nextEpisode)
      } else {
        _controlState.value.state.viewState = ViewState.Delay
        _controlState.value.state.delayMode = DelayMode.NoEpisodesFound
        actions.updateState()
        return
      }

      const nextCollection = nextIncompleteCollection(nextEpisode)
      if (nextCollection) {
        cmsActions.selectCollection(nextCollection)
      } else {
        _controlState.value.state.viewState = ViewState.Delay
        _controlState.value.state.delayMode = DelayMode.NoCollectionsFound
        actions.updateState()
        return
      }

      const nextSession = nextIncompleteSession(nextEpisode, nextCollection)
      if (nextSession) {
        cmsActions.selectSession(nextSession)
      } else {
        _controlState.value.state.viewState = ViewState.Delay
        _controlState.value.state.delayMode = DelayMode.NoSessionsFound
        actions.updateState()
        return
      }

      actions.progress.calculateEpisodeProgress()

      // Attempt to cache assets. We don't await this. Just let it run while we start the game.
      actions.downloadAssetsForEpisode().then(() => {
        const newState: StateVariables = {
          viewState: ViewState.Lobby,
          lobbyMode: LobbyMode.SessionLocked
        }
        this.updateState(newState)
      })
    },

    async startSession(game: Game): Promise<void> {
      _controlState.value.state.delayMode = DelayMode.None
      await actions.configureSession(game)
    },
    progress: {
      completeAStar() {
        _controlState.value.progress.starData.completed++
      },
      progressShow(stars?: number) {
        // resets completed and adds number of new "unfinished" stars for the current round
        if (stars) {
          _controlState.value.progress.starData.stars = stars
          _controlState.value.progress.starData.completed = 0
        }
      },
      reset() {
        this.resetBar()
        this.resetStars()
      },
      resetBar() {
        _controlState.value.progress.barData.completedPercent = 0
      },
      resetStars() {
        _controlState.value.progress.starData.stars = 0
        _controlState.value.progress.starData.completed = 0
      },
      // Counts all sessions in all collections
      calculateEpisodeProgress(): void {
        let completed = 0,
          total = 0
        const episode = cmsGetters.selectedEpisode.value
        if (episode) {
          const userId = userGetters.myUser.value._id
          episode.collections.forEach((c) => {
            const sessions = c.sessions || []
            const completedSessionIDs: string[] = gameActions.getCompletedChildren(
              episode.id,
              userId
            )
            sessions.forEach((s) => {
              const sCompleted = completedSessionIDs.includes(s.id)
              total++
              completed += sCompleted ? 1 : 0
            })
          })
        }
        const percentage = (completed / total) * 100
        _controlState.value.progress.shipBarData.completedPercent =
          percentage === 0 ? 1 : percentage
      },
      calculateTaskSetProgress(session: Session): void {
        const selectedTaskSet = cmsGetters.selectedTaskSet.value
        const fullSessionTaskLength =
          _controlState.value.state.taskMode === TaskMode.Warmups
            ? session.warmupTaskCount
            : session.testTaskCount
        _controlState.value.progress.barData.completedPercent =
          ((fullSessionTaskLength -
            selectedTaskSet.length +
            _controlState.value.currentTaskPosition) /
            fullSessionTaskLength) *
          100
      }
    },
    exitToLogin() {
      _controlState.value.state.viewState = ViewState.Login
      _controlState.value.state.loginMode = LoginMode.LoggedOut
      actions.updateState()
    },

    setTaskMode(taskMode: TaskMode) {
      _controlState.value.state.taskMode = taskMode
    },

    // Main state function called when State needs to be updated (_controlState.value.state)
    updateState(stateVariables?: StateVariables) {
      if (stateVariables) setters.state = stateVariables

      setTimeout(async () => {
        const selectedSession = cmsGetters.selectedSession.value
        switch (_controlState.value.state.viewState) {
          // The app has just finished loading

          case ViewState.Dashboard:
            router.push('/dashboard')
            break

          case ViewState.Login:
            _controlState.value.backgroundImage = loginBackground
            router.push('/')
            cmsActions.resetStorage()
            actions.progress.reset()
            break

          case ViewState.Loggedin:
            _controlState.value.backgroundImage = loginBackground
            break

          case ViewState.Lobby:
            _controlState.value.backgroundImage =
              cmsGetters.selectedEpisode.value?.location.image || ''
            //_controlState.value.backgroundImage = require('@/assets/images/map/locations/' +
            //  cmsGetters.selectedEpisode.value.episode?.location.image)

            // After playing an 'outro' we don't want to see the Ship view again
            if (
              _controlState.value.state.lobbyMode === LobbyMode.SessionCompleted &&
              selectedSession
            ) {
              _controlState.value.state.viewState = ViewState.Login
              _controlState.value.state.loginMode = LoginMode.LoggedOut
              actions.updateState()
            } else {
              router.push('/game/lobby')
            }
            break

          case ViewState.Delay:
            router.push('/delay')
            break

          case ViewState.Tasks:
            // Starting the first (uncompleted) task in the session
            this.setSelectedTask()
            _controlState.value.inactivityTimestamp = moment()
            router.push(`/game/task/${_controlState.value.currentTaskPosition}`)
            break
        }
      })
    },
    async activateSession(session?: Session, password = ''): Promise<void> {
      const passwordsMatch = session?.password === password
      if (session && !session.activated && passwordsMatch) {
        cmsActions.selectSession(session)
        cmsActions.setSessionActivation(true)
        _controlState.value.state.lobbyMode = LobbyMode.SessionUnlocked
        return Promise.resolve()
      } else return Promise.reject(new Error('No session found')) // this will also occur if there is no new session available
    },

    // After a password is entered correctly, get the details for the session
    async getSessionWithTasks(session: Session): Promise<void> {
      appActions.logFeedback('Downloading session tasks..')
      const s: Session | undefined = cmsGetters.selectedCollection.value?.sessions.find(
        (ses) => ses.id === session.id
      )
      if (s) {
        const detailedGame = gameGetters.selectedGame.value
        if (detailedGame) {
          await cmsActions.getQuestions(s, appGetters.languageCode.value)
          cmsActions.sortTasks(detailedGame.details.shuffleDetails)
          actions.progress.calculateEpisodeProgress()
          return Promise.resolve()
        } else return Promise.reject()
      } else return Promise.resolve()
    },

    async completeTask(userId = ''): Promise<void> {
      const selectedTaskSet = cmsGetters.selectedTaskSet.value
      const selectedSession = cmsGetters.selectedSession.value
      const selectedTask = cmsGetters.selectedTask.value

      // If necessary, complete the Session containing this Task and any parents
      const completeSession = (session: Session) => {
        const uId = session.type === SESSION_TYPE.singlePlayer ? userId : ''
        gameActions.completeProgressForItem(session.id, session.parent?.id || '', session.name, uId)
        // If this was the last Session in a series, we may also need to complete the grandparents
        if (session.parent && session.collection) {
          const allSessionsComplete =
            nextIncompleteSession(session.parent, session.collection) === undefined
          const allCollectionsComplete = nextIncompleteCollection(session.parent) === undefined
          if (allSessionsComplete && allCollectionsComplete) {
            const episode = session.parent
            if (episode.parent) {
              gameActions.completeProgressForItem(
                episode.id,
                episode.parent.id || '',
                episode.name,
                uId
              )
              const allEpisodesComplete = nextIncompleteEpisode(episode.parent) === undefined
              if (allEpisodesComplete) {
                const activity = episode.parent
                gameActions.completeProgressForItem(activity.id, '', activity.name, uId)
              }
            }
          }
        }
      }

      // Advance the task index
      if (_controlState.value.currentTaskPosition < selectedTaskSet.length) {
        _controlState.value.currentTaskPosition++
      }

      if (selectedSession && selectedTaskSet && selectedTask) {
        // Finish the Task log and update the Session log
        const tLog = _controlState.value.tracking
        if (tLog) {
          tLog.complete()
          gameActions.commitNewTracking(tLog)
          _controlState.value.tracking = undefined
        }

        // Mark the current Task as complete
        /* const isLeader = [UserTaskRole.Advisor, UserTaskRole.Student].every(
         (role) => role !== multiplayer.getters.currentRole.value
       ) */
        const uId = selectedSession.type === SESSION_TYPE.singlePlayer ? userId : ''
        gameActions.completeProgressForItem(
          selectedTask.id,
          selectedSession.id,
          selectedTask.reference,
          uId
        )
        //if (isLeader) await gameActions.updateGameProgress(false, undefined, true)

        this.progress.calculateTaskSetProgress(selectedSession)

        setTimeout(
          async () => {
            // Was that the final Task?
            if (_controlState.value.currentTaskPosition === selectedTaskSet.length) {
              const game = gameGetters.selectedGame.value
              // After finishing warmup tasks, go to the next delay screen and then to test tasks
              if (_controlState.value.state.taskMode === TaskMode.Warmups && game) {
                _controlState.value.state.delayMode = DelayMode.WarmupsFinished
                _controlState.value.state.taskMode = TaskMode.Tests
                await this.configureSession(game)
              } else {
                // Otherwise finish the Session and go back to the Map (unless this was a consolidation Session)
                completeSession(selectedSession)
                // Recalculate completed percentage
                this.progress.calculateEpisodeProgress()

                _controlState.value.state.viewState = ViewState.Lobby
                _controlState.value.state.lobbyMode = LobbyMode.SessionCompleted // this is the state after a session ends
              }
            }
            // Else there are more tasks to go; so just continue with the current 'task' viewState

            // Reset AudioContext. See here:
            // https://stackoverflow.com/questions/59866930/web-audio-api-not-playing-on-ios-version-13-3-works-on-older-versions-of-ios
            if (appGetters.status.value.isMobileApp) {
              WebAudio.createAudioContext()
              WebAudio.resumeAudioContext()
            }

            await gameActions.updateGameProgress(false, undefined, true)
            // Send unsynced Trackings to the Server (this could also be attempted at the end of each Session)
            await gameActions.sendTrackings()

            // Save the log files if running on a mobile device
            if (deviceGetters.deviceReady.value && !_controlState.value.demoMode) {
              await gameActions.saveGames()
              await gameActions.saveTrackings()
            }
            this.updateState()
          },
          _controlState.value.currentTaskPosition === cmsGetters.selectedTaskSet.value.length
            ? 3000
            : 1500
        )
      }
    },

    // Check for warmups and regular tasks, allocate them and determine appropriate states
    async configureSession(game: Game): Promise<void> {
      const selectedSession = cmsGetters.selectedSession.value
      if (selectedSession) {
        const userId =
          selectedSession?.type === SESSION_TYPE.singlePlayer ? userGetters.myUser.value._id : ''
        await actions.getSessionWithTasks(selectedSession)
        _controlState.value.currentTaskPosition = 0
        _controlState.value.backgroundImage = selectedSession.location?.image || ''

        const warmups = Array.from(Object.values(selectedSession.warmups).flat())
        const hasIncompleteWarmups =
          warmups.filter((w) => game.itemIsComplete(w.id, selectedSession.id, userId)).length > 0
        let sortedTasks: QuestionUnion[] = []
        cmsActions.sortTasks(game.details.shuffleDetails) // sort tasks when starting a new session
        if (selectedSession.morfologicalIntro > 0 || hasIncompleteWarmups) {
          _controlState.value.state.delayMode = DelayMode.WarmupsStarting
          _controlState.value.state.taskMode = TaskMode.Warmups
          sortedTasks = cmsGetters.selectedSession.value?.sortedWarmups ?? [] // sorted warmups
        } else {
          // move on to tasks if no warmups are left
          _controlState.value.state.delayMode = DelayMode.NoWarmups
          _controlState.value.state.taskMode = TaskMode.Tests
          sortedTasks = cmsGetters.selectedSession.value?.sortedTasks ?? [] // sorted tasks
        }
        if (sortedTasks.length > 0) {
          // Filter out any tasks that have already been done (in the case that this is a resumed Session)
          // If there are logs available, either they have not been synced or the session was not completed
          // NOTE: Only recovering from a restart during test tasks, not warm-ups
          const completedTasks = gameActions.getCompletedChildren(selectedSession.id, userId)
          const filteredTaskSet: QuestionUnion[] = sortedTasks.filter(
            (t) => !completedTasks.includes(t.id)
          )
          cmsActions.setTaskSet(filteredTaskSet)
          _controlState.value.state.viewState = ViewState.Tasks
          // proceed to task view if the task set was shuffled (either via invocation in this file or via App.vue -> parcel)
        } else {
          _controlState.value.state.delayMode = DelayMode.NoTasksFound
          _controlState.value.state.viewState = ViewState.Delay
        }
        this.updateState()

        this.progress.calculateTaskSetProgress(selectedSession)
      } else console.error('no session selected / available!')
    },

    setSelectedTask(): void {
      cmsActions.selectTask(
        cmsGetters.selectedTaskSet.value[_controlState.value.currentTaskPosition]
      )
    }
  }

  return {
    getters,
    setters,
    actions
  }
}
export default useStateService
