/*
 Designed and developed by Richard Nesnass

 This file is part of SL+.

 SL+ is free software: you can redistribute it and/or modify
 it under the terms of the GNU Affero General Public License as published by
 the Free Software Foundation, either version 3 of the License, or
 (at your option) any later version.

 GPL-3.0-only or GPL-3.0-or-later

 SL+ is distributed in the hope that it will be useful,
 but WITHOUT ANY WARRANTY; without even the implied warranty of
 MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 GNU Affero General Public License for more details.

 You should have received a copy of the GNU Affero General Public License
 along with SL+.  If not, see <http://www.gnu.org/licenses/>.
 */
import { reactive } from 'vue'
import {
  Tracking,
  TrackingSearch,
  APIRequestPayload,
  XHR_REQUEST_TYPE,
  XHR_CONTENT_TYPE,
  Game,
  GameData,
  User,
  SpecialRequestData,
  SPECIAL_REQUEST_TYPE
  //XHR_CONTENT_TYPE,
} from '@/models/main.js'
import { ref, Ref, computed, ComputedRef } from 'vue'
import { apiRequest } from '../api/apiRequest.js'
import { CordovaPathName } from '@/constants.js'
import { CordovaData } from '@/models/main.js'

import useDeviceService from '@/composition/useDevice.js'
import useAppService from './useAppStore'

const { actions: deviceActions, getters: deviceGetters } = useDeviceService()
const { actions: appActions } = useAppService()

// ------------  State (internal) --------------
interface State {
  games: Map<string, Game>
  selectedGame?: Game
  trackings: Map<string, Tracking>
  selectedLocation: string
  locations: string[]
  cordovaPath: string[] // This hsould refer to 'users/userID/
  allTrackings: Tracking[]
  updateInProgress: boolean
}

const state: Ref<State> = ref({
  games: new Map(), // The list of Participants for a User
  selectedGame: undefined, // The currently selected individual Game. Also needed for Avatar editing etc.
  trackings: new Map(),
  locations: [],
  selectedLocation: '',
  players: [], // ALl players found for the current user
  selectedPlayer: undefined,
  cordovaPath: [],
  allTrackings: [],
  updateInProgress: false
})

interface TrackingToSave {
  id: string
  data: unknown
}

// ------------  Server-side data ------------

function sleep(ms: number): Promise<void> {
  return new Promise((resolve) => setTimeout(resolve, ms))
}

// Syncronise Progress of Games with the server
async function updateGamesProgress(games: Game[]): Promise<void> {
  if (!state.value.updateInProgress) {
    state.value.updateInProgress = true
    try {
      for (const g of games) {
        const payload: APIRequestPayload = {
          method: XHR_REQUEST_TYPE.PUT,
          credentials: true,
          route: '/api/game/progress',
          body: g.asPOJO()
        }
        appActions.logFeedback(`Syncing all progress: ${g.profile.name}`)
        let gameData
        try {
          gameData = await apiRequest<GameData>(payload)
        } catch (error: unknown) {
          console.log(`Error syncing game: ${g._id}: ${g.profile.name}`)
        }
        // After updating at server, update locally
        const localG = state.value.games.get(g._id)
        // If the server's copy of the Game has changed, the local Game is updated here
        if (localG && gameData) localG.update(gameData)
        // If the server returns nothing, there is nothing to update
      }
    } catch (error) {
      state.value.updateInProgress = false
    }
    await sleep(2000)
    state.value.updateInProgress = false
  }
}

// Send unsent Progress items in a Game
async function updateOneProgress(g: Game): Promise<void> {
  if (!state.value.updateInProgress) {
    state.value.updateInProgress = true
    const unsyncedGames = [...g.notSyncedProgress] // We will slice out sent items if successful
    for (const p of unsyncedGames) {
      try {
        const payload: APIRequestPayload = {
          method: XHR_REQUEST_TYPE.PUT,
          credentials: true,
          route: `/api/game/${g._id}/progress`,
          body: p.asPOJO()
        }
        appActions.logFeedback(`Sending one progress for: ${g.profile.name}`)
        await apiRequest<GameData>(payload)
        const index = g.notSyncedProgress.indexOf(p)
        if (index > -1) g.notSyncedProgress.splice(index, 1)
      } catch (error) {
        console.log(`Error syncing game: ${g._id}: ${g.profile.name}`)
      }
    }
    state.value.updateInProgress = false
  }
}

// Get a Game's Progress and Trackings from server
// id: Game ID
// trackingtype: Type of tracking to filter for (if needed)
async function fetchGameDetails(id: string): Promise<GameData> {
  const payload: APIRequestPayload = {
    method: XHR_REQUEST_TYPE.GET,
    credentials: true,
    route: '/api/game/details',
    query: { id }
  }
  return apiRequest<GameData>(payload)
}

async function fetchTrackingDetails(): Promise<Tracking[]> {
  const payload: APIRequestPayload = {
    method: XHR_REQUEST_TYPE.GET,
    credentials: true,
    route: '/api/trackings',
    query: {}
  }
  return apiRequest<Tracking[]>(payload)
}
async function fetchSpecialRequest(
  gameID: string,
  requestType: SPECIAL_REQUEST_TYPE
): Promise<SpecialRequestData> {
  const payload: APIRequestPayload = {
    method: XHR_REQUEST_TYPE.GET,
    credentials: true,
    route: '/api/tracking/special',
    query: { gameID, requestType }
  }
  return apiRequest<SpecialRequestData>(payload)
}

async function syncTracking(tracking: Tracking) {
  const formData = new FormData()

  const data = JSON.stringify(tracking.asPOJO())
  formData.append('data', data)

  const payload: APIRequestPayload = {
    method: XHR_REQUEST_TYPE.POST,
    credentials: true,
    route: '/api/tracking',
    body: formData,
    contentType: XHR_CONTENT_TYPE.MULTIPART
  }

  // Wait for the request to return and see that it succeeded
  try {
    const trackingData = await apiRequest<Tracking>(payload)
    if (trackingData) {
      tracking.serverSynced = !!trackingData.serverSynced
      tracking.storageSynced = !!trackingData.storageSynced
      console.log(`Synced tracking for ${tracking.taskName}: ${tracking.id}!`)
    } else console.log(`Tracking POST failed! Tracking ID: ${tracking.id}`)
  } catch (error: unknown) {
    console.log(`Error posting tracking data: ${error}`)
  }
}

// ------------  Getters (Read only / Immutable)! --------------
interface Getters {
  games: ComputedRef<Game[]>
  selectedGame: ComputedRef<Game | undefined>
  selectedLocation: string
  locations: string[]
  allTrackings: ComputedRef<Tracking[]>
}
interface Actions {
  selectGame: (game?: Game) => void
  setGames: (games: Game[]) => void
  completeProgressForItem: (
    itemId: string,
    parentId: string,
    description?: string,
    userId?: string
  ) => number
  /*   findTrackingByData: (
    dataKey: string,
    dataValue: string | number | boolean
  ) => Tracking | undefined */
  commitNewTracking: (tracking: Tracking) => void
  findMatchingTrackings: (search: TrackingSearch) => Tracking[] // Find a unique Tracking based on given search params
  getCompletedChildren: (parentId: string, userId?: string) => string[]
  itemIsCompleted: (itemId: string, parentId: string, userId?: string) => boolean
  setCordovaPath: (userID: string) => void

  // Server
  getGames: (groupId?: string, userId?: string, progress?: boolean) => Promise<void>
  getGameDetails: (id: string) => Promise<Game>
  getSpecialRequest: (
    gameID: string,
    requestType: SPECIAL_REQUEST_TYPE
  ) => Promise<SpecialRequestData>
  updateGame: (p: Game) => Promise<void>
  updateGameProgress: (updateAll?: boolean, game?: Game, single?: boolean) => Promise<void>
  updateGameControl: (p: Game) => Promise<void>
  deleteGame: (g: Game) => Promise<void>
  sendTrackings: () => Promise<void>
  addGame: (user?: User) => Promise<Game>
  getAllTrackingDetails: () => Promise<Tracking[]>

  // Disk
  loadGames: () => Promise<void>
  saveGames: () => Promise<void>
  loadTrackings: () => Promise<void>
  saveTrackings: () => Promise<void>
}
interface ServiceInterface {
  actions: Actions
  getters: Getters
  state: Ref<State>
}
export function useGameStore(): ServiceInterface {
  const getters = {
    get allTrackings(): ComputedRef<Tracking[]> {
      return computed(() => state.value.allTrackings)
    },
    get selectedGame(): ComputedRef<Game | undefined> {
      return computed(() => state.value.selectedGame)
    },
    get selectedLocation(): string {
      return ref(state.value.selectedLocation).value
    },
    get locations(): string[] {
      return reactive(state.value.locations)
    },
    get games(): ComputedRef<Game[]> {
      const g = Array.from(state.value.games.values())
      return computed(() => g.filter((ga) => !ga.deleted))
    }
  }

  const actions = {
    // Select a given Game
    // If no Game is supplied, un-select all Games
    selectGame: function (game: Game | undefined): void {
      if (!game) {
        state.value.games.forEach((g) => (g.selected = false))
        state.value.selectedGame = undefined
      } else {
        if (state.value.selectedGame) state.value.selectedGame.selected = false
        game.selected = true
        state.value.selectedGame = game
        // Place file data (e.g. recordings) for this Game inside: games/<gameID>/
        deviceActions.setCordovaPath([...state.value.cordovaPath, CordovaPathName.games, game._id])
      }
    },
    // Replace the current list of games with another
    setGames: function (games: Game[]): void {
      state.value.games.clear()
      games.forEach((p: Game) => {
        state.value.games.set(p._id, p)
      })
    },
    setLocations(locations: string[]): void {
      state.value.locations.splice(0)
      Object.values(locations).forEach((l) => state.value.locations.push(l))
    },
    setCordovaPath: function (userID: string): void {
      state.value.cordovaPath = [CordovaPathName.users, userID]
    },
    // Completes an item and returns current number of completions for this item
    completeProgressForItem: function (
      itemId: string,
      parentId: string,
      description = '',
      userId = ''
    ): number {
      let completions = 0
      if (state.value.selectedGame) {
        completions = state.value.selectedGame.completeProgress(
          itemId,
          parentId,
          description,
          userId
        )
      }
      return completions
    },
    // Get Details for the currently selected Game
    // Sets them in the store and also returns them to the component
    getGameDetails: async function (id: string): Promise<Game> {
      const response: GameData = await fetchGameDetails(id)
      const game = new Game(response)
      state.value.games.set(game._id, game)
      // This includes Progress information
      return game
    },

    // Get all tracking details from Database
    getAllTrackingDetails: async function (): Promise<Tracking[]> {
      const response: Partial<Tracking>[] = await fetchTrackingDetails()
      if (response.length != 0)
        state.value.allTrackings = response.map((td) => new Tracking(td as Partial<Tracking>))
      return state.value.allTrackings
    },

    // Call for specical response data for use in mastery / visuals / ets
    getSpecialRequest: async function (
      gameID: string,
      requestType: SPECIAL_REQUEST_TYPE
    ): Promise<SpecialRequestData> {
      const response: SpecialRequestData = await fetchSpecialRequest(gameID, requestType)
      return { game: response.game, data: response.data }
    },
    // Add a new Tracking for this game
    commitNewTracking: function (tracking: Tracking): void {
      state.value.trackings.set(tracking.id, tracking)
    },
    // For a given Set parent ID, get the IDs of completed children
    getCompletedChildren: function (parentId: string, userId = ''): string[] {
      if (state.value.selectedGame) {
        return state.value.selectedGame.completedChildren(parentId, userId)
      } else return []
    },
    // For a given ID and its parent ID, determine if it is completed
    itemIsCompleted: function (itemId: string, parentId: string, userId = ''): boolean {
      if (state.value.selectedGame) {
        return state.value.selectedGame.itemIsComplete(itemId, parentId, userId)
      } else return false
    },

    // -------------   Server activities -----------------

    // Retrieve this user's Games from server
    // This call DOES NOT include their Progress data
    // For a particular Game's Progress data use fetchGameDetails()
    async getGames(
      groupId?: string, // Supply the ID of the group
      userId?: string,
      progress = false
    ): Promise<void> {
      const payload: APIRequestPayload = {
        method: XHR_REQUEST_TYPE.GET,
        credentials: true,
        query: {},
        route: '/api/games/list'
      }
      if (userId && payload.query) payload.query.userId = userId
      if (groupId && payload.query) payload.query.groupId = groupId
      if (progress && payload.query) payload.query.progress = 'true'
      return apiRequest<GameData[]>(payload).then((response: GameData[]) => {
        const gs: Game[] = response.map((g) => new Game(g))
        actions.setGames(gs)
      })
    },

    // Update Game at the server (Not including Mastery or Progress)
    async updateGame(p: Game): Promise<void> {
      const payload: APIRequestPayload = {
        method: XHR_REQUEST_TYPE.PUT,
        credentials: true,
        route: '/api/game',
        body: p.asPOJO()
      }
      let gameData
      try {
        gameData = await apiRequest<GameData>(payload)
      } catch (error: unknown) {
        console.log(`Error updating participant Mastery details: ${error}`)
      }
      // After updating at server, update locally
      const localP = state.value.games.get(p._id)
      if (localP && gameData) localP.update(gameData)
      return Promise.resolve()
    },

    // Save updated data to server and disk including synchronising Progress
    // Given game, selected game, or all Games if updateAll == true
    // After the server response, local game is saved by saveGames()
    async updateGameProgress(updateAll = false, game?: Game, single = false): Promise<void> {
      let ps: Game[] = []
      if (updateAll) ps = Array.from(state.value.games.values())
      else if (game) ps.push(game)
      else if (state.value.selectedGame) ps.push(state.value.selectedGame)
      if (deviceGetters.deviceOnline.value) {
        if (single) await updateOneProgress(ps[0])
        else await updateGamesProgress(ps)
        await actions.saveGames()
      }
    },

    // Update Mastery details ONLY for a Game at the server
    async updateGameControl(p: Game): Promise<void> {
      const payload: APIRequestPayload = {
        method: XHR_REQUEST_TYPE.PUT,
        credentials: true,
        route: '/api/game/control',
        body: p.asPOJO()
      }
      let gameData
      try {
        gameData = await apiRequest<GameData>(payload)
      } catch (error: unknown) {
        console.log(`Error updating participant Mastery details: ${error}`)
      }
      // After updating at server, update locally
      const localP = state.value.games.get(p._id)
      if (localP && gameData) localP.update(gameData)
      return Promise.resolve()
    },

    // Delete a Game at the server
    async deleteGame(g: Game): Promise<void> {
      const payload: APIRequestPayload = {
        method: XHR_REQUEST_TYPE.DELETE,
        credentials: true,
        route: '/api/game',
        query: {
          gameId: g._id
        }
      }
      try {
        await apiRequest<GameData>(payload)
        state.value.games.delete(g._id)
        if (state.value.selectedGame && state.value.selectedGame._id === g._id)
          state.value.selectedGame = undefined
      } catch (error: unknown) {
        console.log(`Error deleting Game: ${error}`)
      }
      return Promise.resolve()
    },

    async addGame(user?: User): Promise<Game> {
      const payload: APIRequestPayload = {
        method: XHR_REQUEST_TYPE.POST,
        credentials: true,
        route: '/api/game'
      }
      if (user) payload.body = user.asPOJO()
      const gData: GameData = await apiRequest<GameData>(payload)
      const newG = new Game(gData)
      state.value.games.set(newG._id, newG)
      return Promise.resolve(newG)
    },

    // Sent currently loaded Trackings to the server if not already sent
    // We can only send trackings owned by the current user, as we must know the Game IDs
    async sendTrackings(): Promise<void> {
      if (!deviceGetters.deviceOnline.value) return Promise.resolve()

      // Iterate over all tracking selecting those that are not marked 'serverSynced'
      const it = state.value.trackings.values()
      const total = Array.from(state.value.trackings, ([name, value]) => ({ name, value })).filter(
        (t) => !t.value.serverSynced
      ).length
      let count = 0

      for (const tracking of it) {
        if (!tracking.serverSynced) {
          appActions.logFeedback(`Syncing tracking ${count} of ${total}`)
          await syncTracking(tracking)
          count++
        } else console.log(`Tracking ${tracking.id} already synced!`)
      }

      // Using async-await, multiple tracking posts should be sent in series
      /*
      let t = it.next()
      while (!t.done) {
        const tracking: Tracking = t.value
        if (!tracking.serverSynced) {
          console.log('Sending tracking..')
          appActions.logFeedback(`Syncing tracking ${count} of ${total}`)
          await syncTracking(tracking)
          count++
        } else console.log('Tracking already synced!')
        t = it.next()
      } */
      console.log('Done syncing trackings with server')
      await actions.saveTrackings()
    },

    // -------------   Disk activities -----------------

    // Load participant JSON files based on Game IDs stored in User model
    loadGames: async function (): Promise<void> {
      console.log('Reading games from disk..')
      appActions.logFeedback('Reading games..')
      const cd: CordovaData = new CordovaData({
        fileName: 'games.json',
        path: state.value.cordovaPath,
        readFile: true,
        asText: true,
        asJSON: true
      })

      const data = await deviceActions.loadFromStorage<GameData[]>(cd)
      if (data && data.length) {
        console.log(`${data.length} games loaded`)
        appActions.logFeedback(`${data.length} games loaded`)
        data.forEach((game) => {
          const g = new Game(game)
          // Overwrite any matching server-downloaded Participants
          // Intending to sync with server properly in next stage
          state.value.games.set(g._id, g)
        })
      }
      return Promise.resolve()
    },
    // Games will be saved in a single JSON file under the user's directory e.g. 'users/userID/games.json'
    saveGames: async function (): Promise<void> {
      console.log('Saving games to disk..')
      appActions.logFeedback('Saving games..')
      // Collect Participants to be saved as regular objects
      const gs: Game[] = Array.from(state.value.games.values())
      const data = gs.map((g) => g.asPOJO())
      // Save each Player to its own subdirectory
      const cd: CordovaData = new CordovaData({
        fileName: 'games.json',
        data,
        asText: true,
        asJSON: true,
        path: state.value.cordovaPath
      })
      await deviceActions.saveToStorage(cd)
      console.log(`${data.length} games saved`)
      return Promise.resolve()
    },
    // Load tracking from each Game folder owned by this User, merge them into the store
    loadTrackings: async function (): Promise<void> {
      console.log('Reading trackings from disk..')
      appActions.logFeedback('Reading trackings..')
      const cd: CordovaData = new CordovaData({
        fileName: 'trackings.json',
        readFile: true,
        asText: true,
        asJSON: true
      })
      state.value.trackings.clear()
      const games: Game[] = Array.from(state.value.games.values())
      for (const g of games) {
        cd.path = [...state.value.cordovaPath, CordovaPathName.games, g._id]
        const data = await deviceActions.loadFromStorage<Tracking[]>(cd)
        if (data) {
          data.forEach((tracking) => {
            let t: Tracking
            if (tracking.id) {
              tracking.localSynced = true
              t = new Tracking(tracking)
              state.value.trackings.set(t.id, t)
            }
          })
        }
      }
      return Promise.resolve()
    },
    // Trackings are saved under each game directory e.g. 'users/userID/games/gameID/trackings.json'
    saveTrackings: async function (): Promise<void> {
      console.log('Saving unsynced trackings to disk..')
      appActions.logFeedback('Saving trackings..')
      // Convert Map to Object keyed by Game ID
      const trackingsByGame: Record<string, TrackingToSave[]> = {}
      state.value.trackings.forEach((t) => {
        // Only save trackings that have changed
        if (!t.localSynced || !t.serverSynced) {
          if (!trackingsByGame[t.gameID]) trackingsByGame[t.gameID] = []
          trackingsByGame[t.gameID].push({ id: t.id, data: t.asPOJO() })
        }
      })
      const entries = Object.entries(trackingsByGame)
      const count = entries.map((v) => v[1]).reduce((p, c) => p + c.length, 0)
      console.log(`Found ${count} unsynced trackings to save to disk`)
      for (const [gID, trackings] of entries) {
        const trackingDataAsArray = trackings.map((t) => t.data)
        const cd: CordovaData = new CordovaData({
          fileName: 'trackings.json',
          data: trackingDataAsArray,
          asText: true,
          asJSON: true,
          path: [...state.value.cordovaPath, CordovaPathName.games, gID]
        })
        await deviceActions.saveToStorage(cd)
        // Update the 'newlySynced' boolean to false
        trackings.forEach((t) => {
          const updatedTracking = state.value.trackings.get(t.id)
          if (updatedTracking) updatedTracking.localSynced = true
        })
      }
      return Promise.resolve()
    },

    findMatchingTrackings: function (search: TrackingSearch): Tracking[] {
      const tArray = Array.from(state.value.trackings.values())
      return tArray
        .sort((a, b) => b.created.getTime() - a.created.getTime()) // Reverse sort by date
        .filter((t) => {
          return (
            (!search.gameID || t.gameID === search.gameID) &&
            (!search.taskID || t.taskID === search.taskID)
          )
        })
    }
  }
  // This defines the interface used externally

  return {
    getters,
    actions,
    state
  }
}

export type GameStoreType = ReturnType<typeof useGameStore>
export default useGameStore
