<!-- Copyright 2023 Richard Nesnass, Tom Bjarne Seidel

 This file is part of KMMP.

KMMP is free software: you can redistribute it and/or modify
 it under the terms of the GNU Affero General Public License as published by
 the Free Software Foundation, either version 3 of the License, or
 (at your option) any later version.

 GPL-3.0-only or GPL-3.0-or-later

KMMP is distributed in the hope that it will be useful,
 but WITHOUT ANY WARRANTY; without even the implied warranty of
 MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 GNU Affero General Public License for more details.

 You should have received a copy of the GNU Affero General Public License
 along with KMMP.  If not, see http://www.gnu.org/licenses/. -->
<template>
  <div class="flex flex-col rounded bg-slate-900 border border-slate-700 mx-2 p-6">
    <p class="text-xl text-white">{{ game.profile.name }}</p>
    <div class="flex flex-row mb-2 justify-end">
      <Button
        class="text-md w-28 rounded-none"
        :disabled="!showSave"
        :textcolour="'text-black'"
        :backgroundcolour="'bg-green-400'"
        :bordercolour="'border-green-600'"
        @click="saveParticipant()"
      >
        Save
      </Button>
    </div>
    <hr class="my-3 border-slate-700" />
    <div class="flex flex-row mt-4 text-white">
      <div class="flex flex-col pr-2">
        <div class="flex flex-row items-center">
          <span class="mb-4">Completed</span>
          <span class="mb-4 pl-8">Access</span>
          <span class="mb-4 pl-80">Results</span>
        </div>
        <!-- No Access Option -->
        <div class="flex flex-row items-center">
          <div class="w-3 inline-block"></div>
          <p
            id="p-access-noaccess"
            class="rounded-sm ml-32 p-1 border-dashed border-gray-400"
            @click="settAccess('noaccess')"
          >
            No Access
          </p>
        </div>
        <!-- CMS-based levels -->
        <div v-for="(activity, index) in cmsroot" :key="activity.id" class="flex flex-col">
          <p class="mt-4">{{ index + 1 }}</p>
          <hr class="my-3 border-slate-700" />
          <div class="flex flex-row items-center my-1">
            <input
              :id="`input-completed-${activity.id}`"
              v-model="hierarchyAsDict[activity.id].completed"
              type="checkbox"
              @change="settComplete(activity.id)"
            />
            <p :id="`p-access-${activity.id}`" class="ml-32 w-96" @click="settAccess(activity.id)">
              <span
                :class="{
                  'bg-green-300 !border-solid': hierarchyAsDict[activity.id].accessible
                }"
                class="rounded-sm p-1 border-dashed border border-gray-400"
                >{{ activity.name }}</span
              >
              <span class="text-xs text-gray-300 ml-1">Activity</span>
            </p>
            <SetResultItem :completions="results[activity.id]" />
          </div>
          <div v-for="episode in activity.episodes" :key="episode.id" class="flex flex-col">
            <div class="flex flex-row items-center my-1">
              <input
                :id="`input-completed-${episode.id}`"
                v-model="hierarchyAsDict[episode.id].completed"
                type="checkbox"
                @change="settComplete(episode.id)"
              />
              <p
                :id="`p-access-${episode.id}`"
                class="pl-4 ml-32 w-96"
                @click="settAccess(episode.id)"
              >
                <span
                  class="rounded-sm p-1 border-dashed border border-gray-400"
                  :class="{
                    'bg-green-300 !border-solid': hierarchyAsDict[episode.id].accessible
                  }"
                  >{{ episode.name }}</span
                >
                <span class="text-xs text-gray-300 ml-1">Episode</span>
              </p>
              <SetResultItem :completions="results[episode.id]" />
            </div>
            <div v-for="(collection, ci) in episode.collections" :key="ci" class="flex flex-col">
              <div v-for="session in collection.sessions" :key="session.id" class="flex flex-col">
                <div class="flex flex-row items-center my-1">
                  <input
                    :id="`input-completed-${session.id}`"
                    v-model="hierarchyAsDict[session.id].completed"
                    type="checkbox"
                    @change="settComplete(session.id)"
                  />
                  <p
                    :id="`p-access-${session.id}`"
                    class="pl-8 ml-32 w-96"
                    @click="settAccess(session.id)"
                  >
                    <span
                      class="rounded-sm p-1 border-dashed border border-gray-400"
                      :class="{
                        'bg-green-300 !border-solid': hierarchyAsDict[session.id].accessible
                      }"
                      >{{ session.name }}</span
                    >
                    <span class="text-xs text-gray-300 ml-1">Session</span>
                  </p>
                  <SetResultItem :completions="results[session.id]" />
                </div>
                <div v-for="task in session.allTasks" :key="task.id" class="flex flex-col">
                  <div class="flex flex-row items-center my-1">
                    <input
                      :id="`input-completed-${task.id}`"
                      v-model="hierarchyAsDict[task.id].completed"
                      type="checkbox"
                      @change="settComplete(task.id)"
                    />
                    <p
                      :id="`p-access-${task.id}`"
                      class="pl-8 ml-32 w-96"
                      @click="settAccess(task.id)"
                    >
                      <span
                        class="rounded-sm p-1 border-dashed border border-gray-400"
                        :class="{
                          'bg-green-300 !border-solid': hierarchyAsDict[task.id].accessible
                        }"
                        >{{ task.name }}</span
                      >
                      <span class="text-xs text-gray-300 ml-1">Task</span>
                    </p>
                    <SetResultItem :completions="results[task.id]" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { PropType, ref, Ref, toRefs } from 'vue'
import { Game, Progress, SpecialRequestData } from '@/models/main'

import useGameStore from '@/store/useGameStore'

import SetResultItem from './SetResultItem.vue'
import Button from '@/components/base/Button.vue'
import { Activity, Episode, Session } from '@/models/navigationModels'

const props = defineProps({
  game: {
    type: Object as PropType<Game>,
    required: true
  },
  cmsroot: {
    type: Object as PropType<Activity[]>,
    required: true
  },
  results: {
    type: Object as PropType<SpecialRequestData['data']>,
    required: true
  }
})
const { cmsroot } = toRefs(props)
const { actions: gameActions } = useGameStore()

const showSave = ref(false)

// Create a structure to track selected Setts in a flattened sequence
interface HierarchyAsArray {
  id: string // An empty ID means no set is allowed access
  parentId: string
  completed: boolean
  accessible: boolean
  item?: Activity | Episode | Session
}
const hierarchyAsArray: HierarchyAsArray[] = []
const hierarchyAsDict: Ref<Record<string, HierarchyAsArray>> = ref({})

const createHierarchyAsArray = (id: string, parentId: string): HierarchyAsArray => {
  const completed = props.game.itemIsComplete(id, parentId)
  return {
    id,
    parentId,
    completed,
    accessible: props.game.status.allowedSets.indexOf(id) > -1
  }
}

const flatten = (array: Activity[] | Episode[] | Session[], level: number) => {
  array.forEach((sett) => {
    const h = createHierarchyAsArray(sett.id, sett.parent?.id || '')
    hierarchyAsArray.push(h)
    hierarchyAsDict.value[h.id] = h

    // Traverse to the next level down
    if (sett instanceof Activity) {
      const sets = sett.episodes
      if (sets && sets.length > 0) flatten(sets, level + 1)
    } else if (sett instanceof Episode) {
      sett.collections.forEach((c) => {
        const sets = c.sessions
        if (sets && sets.length > 0) flatten(sets, level + 1)
      })
    }
  })
}

// 'No Access' list option
const noAccessHierarchy = createHierarchyAsArray('noaccess', '')
hierarchyAsArray.push(noAccessHierarchy)
hierarchyAsDict.value[noAccessHierarchy.id] = noAccessHierarchy

flatten(cmsroot.value, 0)

// ----------------- Mastery functions -------------------

// Set the given Sett and all previous Setts to 'accessible'
// In the case of SLPlus project, a set is accessible if it comes before or equal to the latest 'allowed set'
// In terms of data structure, accessible sets are those included in   mastery.allowedSets
// SLPlus sets are accessed in order, by traversing the Sett tree hierarchy in a down-then-forward direction
const settAccess = (id: string) => {
  const modifiedGame = new Game(props.game)
  modifiedGame.status.allowedSets.length = 0
  Object.values(hierarchyAsDict.value).forEach((v) => (v.accessible = false))
  let i = -1
  if (id) {
    do {
      const item: HierarchyAsArray = hierarchyAsArray[++i]
      if (item) {
        modifiedGame.status.allowedSets.push(item.id)
        hierarchyAsDict.value[item.id].accessible = true
      }
    } while (hierarchyAsArray[i].id !== id)
  }
  showSave.value = true
}

// When a given Sett is to be marked completed, we also need to complete Questions inside the Sett
// As we use v-model on the template input, the changed value is waiting for us in hierarchyAsDict
const settComplete = (id: string) => {
  // At this point, 'had' will contain the updated completion state
  const had = hierarchyAsDict.value[id]
  console.log(`Marking task completions for Set ID: ${id} to ${had.completed}`)
  // The ID of the Sett is the parent part of the Question's ID/PARENT ID pair from the progress.records Map
  // We need to brak apart the Progress keys to check if the parent part matches
  let foundExactSet = false // true if we find a record for this Sett ID in Progress
  props.game.progress.forEach((value: Progress, key: string) => {
    const keys = key.split(':')
    const parentKey = keys.length > 1 ? keys[1] : ''
    const match = keys[0] === id || parentKey === id
    if (keys[0] === id) foundExactSet = true
    if (match) {
      console.log(
        `ItemKey: ${keys[0]} ParentKey: ${parentKey} ValueCompleted: ${value.completed} NewCompleted: ${had.completed}`
      )
      value.completed = had.completed
    }
  })
  // If we did not find the Sett itself, and want to mark completed: true we need to create a new Progress entry
  // Only mark completed: true. We do not add completion timestamp here...
  if (!foundExactSet && had.completed) {
    props.game.createProgress(had.id, had.parentId, had.item?.name).completed = true
    console.log('Created new Progress for set')
  }
  showSave.value = true
}

// ------------------------------------------------------

// Update Mastery status of the current Barn
const saveParticipant = async () => {
  if (!showSave.value) return
  // Update a selected Barn
  await gameActions.updateGameControl(props.game)
  showSave.value = false
}
</script>

<style lang="postcss" scoped></style>
