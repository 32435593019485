<!-- Copyright 2023 Richard Nesnass, Tom Bjarne Seidel

 This file is part of KMMP.

KMMP is free software: you can redistribute it and/or modify
 it under the terms of the GNU Affero General Public License as published by
 the Free Software Foundation, either version 3 of the License, or
 (at your option) any later version.

 GPL-3.0-only or GPL-3.0-or-later

KMMP is distributed in the hope that it will be useful,
 but WITHOUT ANY WARRANTY; without even the implied warranty of
 MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 GNU Affero General Public License for more details.

 You should have received a copy of the GNU Affero General Public License
 along with KMMP.  If not, see http://www.gnu.org/licenses/. -->
<template>
  <div>
    <div class="flex flex-col flex-grow text-left">
      <div class="flex flex-col">
        <h1 class="font-bold">All available classes</h1>
        <div v-for="g in groups" :key="g._id">
          <MonitorGroupItem :edit="true" :group="g" class="my-2 bg-white-alt"></MonitorGroupItem>
        </div>
        <Button backgroundcolour="bg-green-300 rounded-none w-24 place-self-end mr-4 mt-11" bordercolour="border-green-500" @click="addGroup()"
          >Add</Button
        >
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import useAppStore from '../../store/useAppStore'
import useUserStore from '../../store/useUserStore'
import MonitorGroupItem from '@/components/admin/MonitorGroupItem.vue'
import Button from '@/components/base/Button.vue'

export default defineComponent({
  name: 'MonitorProjects',
  components: {
    MonitorGroupItem,
    Button,
  },
  setup() {
    const { getters: appGetters } = useAppStore()
    const { getters: userGetters, actions: userActions } = useUserStore()

    const addGroup = () => {
      userActions.createGroup()
    }

    userActions.getAllGroups()

    return {
      // Computed
      status: appGetters.status,
      groups: userGetters.allGroups,

      // Methods
      addGroup,
    }
  },
})
</script>

<style scoped></style>
