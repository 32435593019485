/* eslint-disable @typescript-eslint/no-unused-vars */
<!-- Copyright 2023 Richard Nesnass, Tom Bjarne Seidel

 This file is part of KMMP.

KMMP is free software: you can redistribute it and/or modify
 it under the terms of the GNU Affero General Public License as published by
 the Free Software Foundation, either version 3 of the License, or
 (at your option) any later version.

 GPL-3.0-only or GPL-3.0-or-later

KMMP is distributed in the hope that it will be useful,
 but WITHOUT ANY WARRANTY; without even the implied warranty of
 MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 GNU Affero General Public License for more details.

 You should have received a copy of the GNU Affero General Public License
 along with KMMP.  If not, see http://www.gnu.org/licenses/. -->
<template>
  <div class="flex flex-col bg-white justify-center items-center w-full p-5 m-11 rounded-xl">
    <div
      v-if="props.role === UserTaskRole.Student"
      ref="boxContainer"
      class="flex mt-11 justify-evenly w-full h-1/2"
    >
      <div>hints used: {{ tracking.use_audio_content_items }}</div>
      <div
        v-for="(word, index) in wordsForCurrentRound"
        :key="index"
        class="flex flex-col border-4 rounded-xl w-1/4 justify-center items-center"
        :style="boxSize(word.word, index)"
      >
        <img v-if="state.flipStates[index]" v-cache :src="word.imageURL" :alt="word.word" />
        <p v-else class="text-white">{{ word.word }}</p>
        <div class="flex flex-row bg-white rounded mt-3">
          <div
            class="relative scale-50 w-16 h-16 taskNoUserSelect rounded-full border-4 border-blue-500 bg-blue-500 flex justify-center items-center cursor-pointer"
            @click="clickBoxedWord(word)"
          >
            <img class="w-12 p-1 invert" src="@/assets/icons/fontawesome/volume-solid.svg" />
          </div>
        </div>
      </div>
    </div>
    <div
      v-if="props.role === UserTaskRole.Student"
      class="border-4 rounded-xl flex flex-col justify-center items-center"
    >
      <!-- TODO: add translation -->
      <p class="mt-5">Teachers' progress:</p>
      <div class="flex">
        <div
          v-for="item in state.droppedCount"
          :key="item"
          class="scale-50 border-4 rounded"
          :style="boxSize(item.toString(), item)"
        ></div>
        <div
          v-for="item in state.flipStates.length - state.droppedCount"
          :key="item"
          class="scale-50 border-4 rounded"
          :style="boxSize()"
        ></div>
      </div>
    </div>
    <div v-if="props.role === UserTaskRole.Teacher" class="flex flex-row justify-evenly w-full">
      <div
        v-for="(word, index) in wordsForCurrentRound"
        :id="`#${index}`"
        :key="index"
        v-draggable="{ axis: 'none' }"
        class="flex flex-row border-4 rounded-xl drop-target"
        :style="boxSize()"
        @start="startDrag"
        @stop="endDrag"
        @mouseenter="onDropAreaMouseEnter"
        @mouseleave="onDropAreaMouseLeave"
      >
        <img
          v-if="state.droppedItems[index]"
          v-cache
          :src="state.droppedItems[index]?.altImage[0].url"
          :alt="state.droppedItems[index]?.word"
        />
      </div>
    </div>
    <div
      v-if="props.role === UserTaskRole.Teacher"
      ref="boxContainer"
      class="flex flex-row mt-11 justify-evenly w-full h-1/2"
    >
      <Draggable
        v-for="(word, index) in nonDroppedWords"
        :key="index"
        :class="`${state.activeDrags ? 'pointer-events-none' : ''}`"
        class="flex flex-row border-4 rounded-xl w-1/4 justify-center items-center"
        :style="boxSize(word.word, index)"
        :position="state.nonDroppedPositions[index]"
        @start="startDrag()"
        @stop="(e) => endDrag(e, word)"
        @click="clickBoxedWord(word)"
      >
        <img v-cache :src="word.altImage[0].url" :alt="word.word" />
      </Draggable>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, PropType, reactive, onMounted, computed } from 'vue'
import { Draggable, DraggableEvent } from '@braks/revue-draggable'

import {
  DialogMessageType,
  TaskCallbackType,
  UserTaskRole,
  TaskCallback,
  TaskCallbackParam
} from '@/constants'
import { STSolutionUnion, Image } from '@/models/tasktypes'
import { Tasktype22st, Type22stWord, WordPosition } from '@/models/tasktypes/Tasktype22st'
import { Choice, Tracking } from '@/models/main'

import useColorStore from '@/composition/colors'
import useState from '@/composition/useState'
import useDialogStore from '@/composition/dialog'
import { STSyncType } from '../../../constants'
import { TaskSyncST } from '@/constants'
import { createSound } from '@/api/audioService'
import moment from 'moment'

const emit = defineEmits<{
  (e: 'completed', value: boolean, tracking: Tracking): void
  (e: 'addSTSync', type: STSyncType, taskId: string, solution: STSolutionUnion): void
  (e: 'updateShuffleOrder', shuffleOrder: string[]): void
  (e: 'registerCallback', type: TaskCallbackType, callback: TaskCallback): void
  (
    e: 'showResultMessage',
    messageType: DialogMessageType,
    solution: Image,
    callback: () => void
  ): void
}>()

const props = defineProps({
  task: { required: true, type: Object as PropType<Tasktype22st> },
  myIndex: { required: false, type: Number, default: 0 },
  actionAllowed: { required: true, type: Boolean },
  role: { required: true, type: String }
})

type DraggableDIVElementEvent = DraggableEvent & { event: { target: HTMLDivElement } }
const boxContainer = ref<HTMLElement | null>(null)
const color = useColorStore()
const dialog = useDialogStore()

/* const messages = {
      no: {},
      sv: {},
      en: {},
    } */

const { getters: stateGetters, setters: stateSetters } = useState()

// We need a mutatable copy of results that we will submit to the store at Task completion
const tracking = new Tracking(stateGetters.tracking.value)
stateSetters.trackingData = tracking
let choiceTimer = new Date()
const roundData = { correct: 0, of: 0, phase: 1 }
let choiceAttempt = 1

const state = reactive({
  droppedCount: 0,
  currentRound: 0,
  initialSize: 0,
  activeDrags: 0,
  correct: true,
  flipStates: [] as boolean[],
  droppedItems: [] as Type22stWord[],
  nonDroppedPositions: [] as WordPosition[]
})

onMounted(() => {
  handleRound()

  emit('registerCallback', TaskCallbackType.Shuffle, shuffleCallback)
  emit('registerCallback', TaskCallbackType.StudentTeacherAction, handleSTAction)

  setTimeout(() => {
    for (let i = 0; i < wordsForCurrentRound.value.length; i++) {
      const word = wordsForCurrentRound.value[i]
      playWordAudio(word)
      setTimeout(() => {
        flip(i)
      }, 5000)
    }
  }, 5000)
})

const nonDroppedWords = computed(() => {
  return wordsForCurrentRound.value.filter(
    (w: Type22stWord) => !state.droppedItems.filter((d) => d).find((d) => d.word === w.word)
  )
})

const wordsForCurrentRound = computed(() => {
  return props.task.rounds[state.currentRound].words
})

const playWordAudio = (word: Type22stWord) => {
  if (word) {
    if (word.audio) {
      word.audio.playWhenReady()
    }
  }
  tracking.use_audio_content_items++
}

const handleRound = () => {
  resetTeacherStates()
  resetStudentStates()
  setupWords()
}

const setupWords = async () => {
  const words = wordsForCurrentRound.value
  for (let i = 0; i < words.length; i++) {
    const w = words[i]
    if (w.audioURL) {
      const audio = await createSound(w.audioURL)
      w.audio = audio
      roundData.of++
    }
  }
}

/**
 * handleSTAction()
 * this is invoked via callback once a parcel (ParcelType.TaskSyncST) is retrieved
 * and handles the action performed once an answer was given (for the student)
 * @param data
 */
const handleSTAction = (args: TaskCallbackParam) => {
  const data = args as TaskSyncST
  if (data.solution !== null)
    showResultSplash(data.solution) // if data.solution is null, not all slots have been allocated by the teacher yet
  else {
    // TODO: add proper translation
    dialog.actions.pushNotification(
      DialogMessageType.Information,
      'the teacher dropped an item to a box'
    )
    state.droppedCount++
  }
}

const showResultSplash = (solution: STSolutionUnion) => {
  solution = solution as boolean // explicitly convert to boolean type
  const messageType = solution
    ? DialogMessageType.ResultSplashCorrect
    : DialogMessageType.ResultSplashWrong
  const message = solution ? 'correct' : 'wrong' // TODO: add proper translations
  handleAnswerValues(solution) // handle answer values for receiving user (Student)
  dialog.actions.pushMultiplayerMessage(messageType, message)
}

const handleAnswerValues = (correct: boolean) => {
  const choice = new Choice()
  choice.duration = moment().diff(moment(choiceTimer), 'milliseconds')
  choice.content = wordsForCurrentRound.value.map((w) => w.word).join(';')
  choice.correct = correct
  choice.attempt = choiceAttempt
  choice.response = state.droppedItems.map((i) => i.word).join(';')
  choice.phase = roundData.phase
  choice.target = '' // TODO: How to determine this?
  tracking.choices.push(choice)
  stateSetters.trackingData = tracking
  choiceTimer = new Date()

  if (correct) {
    roundData.correct++
    emit('completed', true, tracking)
  } else {
    handleRound()
  }
}

const resetTeacherStates = () => {
  if (props.role === UserTaskRole.Teacher) {
    state.correct = false
    state.activeDrags = 0
    state.droppedItems = []
    state.nonDroppedPositions = []
  }
}

// TODO: invoke this if the teachers' solution was wrong
const resetStudentStates = () => {
  if (props.role === UserTaskRole.Student) {
    console.log('reset student states')
    state.flipStates = new Array(wordsForCurrentRound.value.length).fill(false)
    state.droppedCount = 0
  }
}

const boxSize = (id?: string, index?: number) => {
  if (boxContainer.value) {
    const value =
      boxContainer.value.getBoundingClientRect().width / (wordsForCurrentRound.value.length + 1)
    if (state.initialSize === 0) state.initialSize = value // prevent from re-calculating after actions were performed
    if (id !== undefined && index !== undefined) {
      const initialIndex = index
      index += 1
      const adjustedIndex = index % 2 === 0 ? -index : index
      const height = state.flipStates[initialIndex] ? state.initialSize * 1.3 : state.initialSize
      const backgroundColor = !state.flipStates[initialIndex]
        ? color.actions.selectColorAsHex(`${id}`)
        : '#fff'
      return `background-color: ${backgroundColor}; width: ${state.initialSize}px; height: ${height}px; rotate: ${
        2 * adjustedIndex
      }deg; margin-top: ${5 * adjustedIndex}px;`
    } else return `width: ${state.initialSize}px; height: ${state.initialSize}px;`
  }
}

const flip = (index: number) => {
  state.flipStates[index] = !state.flipStates[index]
}

const shuffleCallback = () => {
  // TODO: sync map from leader with own map (advisor)
}

const startDrag = () => {
  state.activeDrags++
}

const endDrag = (e: DraggableDIVElementEvent, element: Type22stWord) => {
  state.activeDrags = 0
  const el = getDropboxElement(e)
  if (el) {
    const index = parseInt(el.id.substring(1, 2))
    state.droppedItems[index] = element

    try {
      for (let i = 0; i < state.droppedItems.length; i++) {
        const dI = state.droppedItems[i]
        state.nonDroppedPositions[i] = { x: 0, y: 0 }
        if (dI.word !== element.word) dI.position = { x: 0, y: 0 }
      }
    } catch (e) {
      for (let i = 0; i < nonDroppedWords.value.length; i++) {
        state.nonDroppedPositions[i] = { x: 0, y: 0 }
        const dI = nonDroppedWords.value[i]
        dI.position = { x: 0, y: 0 }
      }
    } finally {
      if (state.droppedItems.filter((i) => i).length === wordsForCurrentRound.value.length) {
        state.correct = true // initially true, set to false as soon as a single word does not match the solution
        for (let i = 0; i < wordsForCurrentRound.value.length; i++) {
          if (wordsForCurrentRound.value[i].word !== state.droppedItems[i].word) {
            state.correct = false
            break // exit loop
          }
        }

        emit('addSTSync', STSyncType.ResultStatus, props.task.id, state.correct) // emit parcel to show result splash for other user
        showResultSplash(state.correct) // show result splash for current user
      } else emit('addSTSync', STSyncType.StatusUpdate, props.task.id, null) // emit parcel to show task progression update
    }
  }
}

const clickBoxedWord = (word?: Type22stWord) => {
  if (word && word.audio) {
    word.audio.playWhenReady()
    tracking.use_audio_content_items++
    state.activeDrags = 0
    word.position = { x: 0, y: 0 }
  }
}

const onDropAreaMouseEnter = (e: MouseEvent | TouchEvent) => {
  const el = e && (e.target as HTMLElement)
  if (state.activeDrags && el) {
    console.log('entered')
    // TODO: highlight the box
  }
}

const onDropAreaMouseLeave = (e: MouseEvent | TouchEvent) => {
  const el = e && (e.target as HTMLElement)
  if (state.activeDrags && el) {
    console.log('exited')
    // TODO: remove box highlight
  }
}

const getDropboxElement = (e: DraggableEvent): Element | undefined => {
  let x = 0
  let y = 0
  if (e.event && e.event.type.includes('touch')) {
    const te = e.event as TouchEvent
    x = te.changedTouches[0].clientX
    y = te.changedTouches[0].clientY
  } else if (e.event) {
    const me = e.event as MouseEvent
    x = me.clientX
    y = me.clientY
  } else return
  const elements = document.elementsFromPoint(x, y)
  const el = elements.find((e) => e.classList.contains('drop-target'))
  return el ? el : undefined
}
</script>

<style scoped></style>
@/draggable
