/*
 Designed and developed by Richard Nesnass and Hoang Bao Ngo

 This file is part of SL+.

 SL+ is free software: you can redistribute it and/or modify
 it under the terms of the GNU Affero General Public License as published by
 the Free Software Foundation, either version 3 of the License, or
 (at your option) any later version.

 GPL-3.0-only or GPL-3.0-or-later

 SL+ is distributed in the hope that it will be useful,
 but WITHOUT ANY WARRANTY; without even the implied warranty of
 MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 GNU Affero General Public License for more details.

 You should have received a copy of the GNU Affero General Public License
 along with SL+.  If not, see <http://www.gnu.org/licenses/>.
 */
// useCMSStore are the same across projects,
// however they provide the junction to each project's unique data structures in neighboring directories
import { ref, Ref, computed, ComputedRef } from 'vue'
import { TASK_TYPES, LanguageCodes } from '@/constants.js'
import { shuffleItems, sortItemsByKey } from '@/utilities'
import { cmsRequest } from '@/api/cmsService.js'
import useAppStore from '@/store/useAppStore.js'
import {
  CmsGQLQuery,
  CmsGQLData,
  CmsQuestionData,
  SESSION_TYPE,
  CmsQuestionUnionType,
  ShuffledContentDetails
} from '@/models/main.js'
import { Activity, ActivityData, Collection, Episode, Session } from '@/models/navigationModels.js'
import {
  QuestionQueriesMP,
  QuestionQueriesSP,
  QuestionQueriesST,
  Tasktypes
} from '@/models/tasktypes/index.js'
import {
  QuestionDataIntersection,
  QuestionUnion,
  QuestionQueries
} from '@/models/tasktypes/index.js'

// Query strings for each project
import activityQuery from '../graphql/activityQuery.gql'
import navigationQuery from '../graphql/navigationQueryV3.gql'
import singleQuestionQuery from '../graphql/singleQuestionQuery.gql'

// New for V3:
import taskQuerySP from '../graphql/taskQuerySP.gql'
import taskQueryMP from '../graphql/taskQueryMP.gql'
import taskQueryST from '../graphql/taskQueryST.gql'

const cmsAppName = (import.meta.env.VITE_SQUIDEX_APP_NAME || '') as string

// ------------  State (internal) --------------

interface State {
  activities: Activity[] // This is the root containing first level of Sett where we begin Layout navigation
  selectedSet: {
    set: Activity | Episode | Session | undefined // Currently selected Sett.  In DSLPlus, this will be a Day or a Week
    level: number // level of this sett below root (root = level 0)
  }
  selectedActivity: {
    cmsID: string // ID of the Activity in Squidex
    activity?: Activity
  }
  selectedEpisode: Episode | undefined
  selectedCollection: Collection | undefined
  selectedSession: Session | undefined
  selectedTaskSet: QuestionUnion[] // This can be a list of warmup tasks, or a list of test tasks
  selectedTask: QuestionUnion | undefined
  overrideCMS: boolean
}

const state: Ref<State> = ref({
  activities: [],
  selectedSet: {
    set: undefined,
    level: 0
  },
  selectedActivity: {
    cmsID: '',
    activity: undefined
  },
  selectedEpisode: undefined,
  selectedCollection: undefined,
  selectedSession: undefined,
  selectedTaskSet: [], // May be shuffed
  selectedTask: undefined,
  overrideCMS: false
})

interface Getters {
  root: ComputedRef<Activity[]>
  selectedSet: ComputedRef<State['selectedSet']>
  selectedActivity: ComputedRef<State['selectedActivity']>
  selectedEpisode: ComputedRef<State['selectedEpisode']>
  selectedCollection: ComputedRef<State['selectedCollection']>
  selectedSession: ComputedRef<State['selectedSession']>
  selectedTask: ComputedRef<State['selectedTask']>
  selectedTaskSet: ComputedRef<State['selectedTaskSet']>
  overrideCMS: boolean
}
interface Actions {
  getActivities: (language: LanguageCodes) => Promise<void>
  resetStorage: () => void
  getSets: (language: LanguageCodes) => Promise<void>
  getQuestions: (session: Session, language: LanguageCodes) => Promise<void>
  getQuestionByID: (
    schema: string,
    id: string,
    language: LanguageCodes,
    session: Session
  ) => Promise<QuestionUnion | void>

  sortActivity: (shuffleDetails: ShuffledContentDetails) => void
  sortTasks: (shuffleDetails: ShuffledContentDetails) => void

  selectSet: (set: Activity | Episode | Session | undefined, index: number, level: number) => void
  selectEpisode: (episode: Episode) => void
  selectCollection: (collection: Collection) => void
  selectSession: (session: Session) => void
  setSessionActivation: (activate: boolean, session?: Session) => void
  setActivityID: (activityID: string) => void
  setTaskSet: (tasks: QuestionUnion[]) => void
  selectTask: (task: QuestionUnion) => void

  overrideCMS(): void
}
interface ServiceInterface {
  actions: Actions
  getters: Getters
}
function useCMSStore(): ServiceInterface {
  const appStore = useAppStore()

  // ------------  Internal functions ------------

  async function fetchSets(language: LanguageCodes): Promise<CmsGQLQuery> {
    const query = navigationQuery.loc.source.body as string
    const variables = { __activityID: state.value.selectedActivity.cmsID, __language: language }
    return cmsRequest(cmsAppName, query, variables, language)
  }

  async function fetchQuestionsForSession(
    session: Session,
    language: LanguageCodes
  ): Promise<CmsGQLQuery> {
    if (session) {
      let queryFile
      let fragments = ''
      if (session.type === SESSION_TYPE.singlePlayer) {
        queryFile = taskQuerySP
        fragments = Object.values(QuestionQueriesSP).reduce(
          (acc, curr) => acc + '\n' + curr.loc?.source.body,
          ''
        )
      } else if (session.type === SESSION_TYPE.multiPlayer) {
        queryFile = taskQueryMP
        fragments = Object.values(QuestionQueriesMP).reduce(
          (acc, curr) => acc + '\n' + curr.loc?.source.body,
          ''
        )
      } else {
        queryFile = taskQueryST
        fragments = Object.values(QuestionQueriesST).reduce(
          (acc, curr) => acc + '\n' + curr.loc?.source.body,
          ''
        )
      }
      const questions = queryFile.loc.source.body as string
      const query = fragments + questions
      const variables = { __setID: session.id, __language: language }
      return cmsRequest(cmsAppName, query, variables, language)
    } else return Promise.resolve({})
  }

  async function fetchQuestionByID(
    schema: string,
    id: string,
    language: LanguageCodes
  ): Promise<CmsGQLQuery> {
    const s = String(schema).toPascalCase() as keyof typeof QuestionQueries
    const fragment = QuestionQueries[s]
    //const fragments = fragmentsQuery.loc.source.body as string
    const question = singleQuestionQuery.loc.source.body as string
    const query = fragment.loc?.source.body + question
    const variables = {
      __findSchemaContent: `find${s}Content`,
      __taskType: s,
      __questionID: id,
      __language: language
    }
    return cmsRequest(cmsAppName, query, variables, language)
  }

  // Add a single task type to a session
  function addQuestion(
    taskData: CmsGQLData,
    session: Session,
    language: LanguageCodes,
    type: string
  ): QuestionUnion | void {
    if (taskData.data) {
      // the MP types don't have a seperate schema in the CMS, therefore we have to manually convert
      const isMPSession = session.type === SESSION_TYPE.multiPlayer
      if (isMPSession) taskData.__typename += 'mp'
      const className = <TASK_TYPES>taskData.__typename
      if (className) {
        const QModel = Tasktypes[className] // this is where the task type instances are created
        const newModel = new QModel(
          taskData as unknown as QuestionDataIntersection,
          language,
          session
        )
        if (type === 'warmups') session.addWarmupQuestion(newModel, className)
        else session.addTestQuestion(newModel, className)
        return newModel
      } else {
        console.log(`Incorrect className undefined for data: ${taskData.data.__typename}`)
        return
      }
    }
  }

  // Add a collection of task types supplied by a query to a Session's 'warmup' and 'test' tasks
  function addQuestions(
    taskTypeLists: CmsQuestionUnionType,
    session: Session,
    language: LanguageCodes,
    type: string
  ): void {
    const lists = Object.values(taskTypeLists).filter((list) => list)
    lists.forEach((list: CmsGQLData[]) => {
      list.forEach((task) => addQuestion(task, session, language, type))
    })
  }

  // -------------- Getters -------------------

  const getters = {
    get root(): ComputedRef<Activity[]> {
      return computed(() => state.value.activities)
    },
    get selectedSet(): ComputedRef<State['selectedSet']> {
      return computed(() => state.value.selectedSet)
    },
    get selectedActivity(): ComputedRef<State['selectedActivity']> {
      return computed(() => state.value.selectedActivity)
    },
    get selectedEpisode(): ComputedRef<State['selectedEpisode']> {
      return computed(() => state.value.selectedEpisode)
    },
    get selectedCollection(): ComputedRef<State['selectedCollection']> {
      return computed(() => state.value.selectedCollection)
    },
    get selectedSession(): ComputedRef<State['selectedSession']> {
      return computed(() => state.value.selectedSession)
    },
    get selectedTask(): ComputedRef<State['selectedTask']> {
      return computed(() => state.value.selectedTask)
    },
    get selectedTaskSet(): ComputedRef<State['selectedTaskSet']> {
      return computed(() => state.value.selectedTaskSet)
    },
    get overrideCMS(): boolean {
      if (state.value.overrideCMS) {
        state.value.overrideCMS = false
        return true
      } else return false
    }
  }

  // ------------  Actions --------------

  const actions = {
    getActivities: async function (language: LanguageCodes): Promise<void> {
      const query = activityQuery.loc.source.body as string
      const response: CmsGQLQuery = await cmsRequest(cmsAppName, query, {}, language)
      if (response.data) {
        const results = response.data.results as ActivityData[]
        const activities = results.map((a) => new Activity(a))
        state.value.activities = activities
      }
    },

    overrideCMS: function (): void {
      state.value.overrideCMS = true
    },
    resetStorage(): void {
      state.value.selectedActivity = { activity: undefined, cmsID: '' }
      state.value.activities.length = 0
      state.value.selectedEpisode = undefined
      state.value.selectedSession = undefined
      state.value.selectedTask = undefined
      state.value.selectedTaskSet = []
    },
    // Retrieve from CMS the Set data for top levels of navigation
    getSets: async function (language: LanguageCodes): Promise<void> {
      if (state.value.activities.length === 0 || state.value.overrideCMS) {
        console.log('SL: Get CMS data...')
        appStore.actions.setLoading(true)
        const response: CmsGQLQuery = await fetchSets(language)
        const newActivities: Activity[] = []
        if (response.data) {
          const topLevelDataList = response.data.results as ActivityData
          const newLevel = new Activity(topLevelDataList)
          newActivities.push(newLevel)

          // If User has an assigned Activity ID, set the selectedActivity
          if (state.value.selectedActivity.cmsID === newLevel.id)
            state.value.selectedActivity.activity = newLevel

          // Sort root Sets by index key
          // if (newRoot.length > 0) newRoot.sort((a, b) => a.index - b.index)
          state.value.activities = newActivities
        } else {
          const error = 'Sett query contains no records'
          appStore.actions.setError(error)
          console.error(error)
        }
        appStore.actions.setLoading(false)
        return Promise.resolve()
      } else {
        return Promise.resolve()
      }
    },

    // Retrieve Question data for a given Session
    // Relies on a Session containing Questions being currently selected
    getQuestions: async function (s: Session, language: LanguageCodes): Promise<void> {
      appStore.actions.setLoading(true)
      // If session not found we have a problem
      if (!s) return Promise.reject('Error finding Session')
      s.clearTasks() // force task removal before fetching again

      const response: CmsGQLQuery = await fetchQuestionsForSession(s, language)
      if (!response.data) {
        const error = 'Question query contains no records'
        console.error(response.errors ? response.errors : error)
        appStore.actions.setError(error)
        return Promise.reject()
      }
      const allTasks: CmsQuestionData = response.data.results as CmsQuestionData
      const warmups = allTasks.flatData.warmups
      if (warmups) addQuestions(warmups, s, language, 'warmups')
      const tasks: CmsQuestionUnionType = {
        ...allTasks.flatData.tasksMp,
        ...allTasks.flatData.tasksSp,
        ...allTasks.flatData.tasksSt
      } as unknown as CmsQuestionUnionType
      if (tasks) addQuestions(tasks, s, language, 'tests')
      appStore.actions.setLoading(false)
      return Promise.resolve()
    },

    // This is used to construct a 'sample' Question
    getQuestionByID: async function (
      schema: string,
      id: string,
      language: LanguageCodes,
      session: Session
    ): Promise<QuestionUnion | void> {
      appStore.actions.setLoading(true)
      const response: CmsGQLQuery = await fetchQuestionByID(schema, id, language)
      if (response.data) {
        const taskData: CmsGQLData = response.data.results as CmsGQLData
        if (taskData) {
          const newQuestion = addQuestion(taskData, session, language, 'tests')
          return Promise.resolve(newQuestion)
        }
      }
      appStore.actions.setLoading(false)
      return Promise.resolve()
    },
    // Used by Layout vue, which changing the displayed Sett grid
    selectSet: function (set: Activity | Episode | Session | undefined, level: number): void {
      state.value.selectedSet.set = set
      state.value.selectedSet.level = level
    },
    setActivityID(activityID: string): void {
      state.value.selectedActivity.cmsID = activityID
    },
    selectEpisode(episode: Episode): void {
      state.value.selectedEpisode = episode
    },
    selectCollection(collection: Collection): void {
      state.value.selectedCollection = collection
    },
    selectSession(session: Session): void {
      state.value.selectedSession = session
    },
    setSessionActivation(activate: boolean, session?: Session): void {
      const s = session || state.value.selectedSession
      if (s) s.activated = activate
    },
    setTaskSet(tasks: QuestionUnion[]): void {
      state.value.selectedTaskSet = tasks
    },
    selectTask(task: QuestionUnion): void {
      state.value.selectedTask = task
    },

    // Ensure the order of items inside the Activity complies with the shuffle details decided server-side
    sortActivity(shuffleDetails: ShuffledContentDetails): void {
      const selectedActivity = state.value.selectedActivity.activity
      if (selectedActivity) {
        // Sort Episodes
        const episodes: Episode[] = selectedActivity.episodes
        const episodeNewOrder = shuffleDetails.episodes
        const episodeNewOrderIDs = episodeNewOrder.map((e) => e.id)
        const sortedEpisodes: Episode[] = sortItemsByKey<Episode>(
          episodeNewOrderIDs,
          episodes,
          'id'
        )
        selectedActivity.episodes = sortedEpisodes

        sortedEpisodes.forEach((episode, i) => {
          // Sort Collections
          const collections: Collection[] = episode.collections
          const collectionNewOrder = episodeNewOrder[i] ? episodeNewOrder[i].collections : []
          const collectionNewOrderIDs = collectionNewOrder.map((c) => c.index)
          const sortedCollections: Collection[] = sortItemsByKey<Collection>(
            collectionNewOrderIDs,
            collections,
            'index'
          )
          episode.collections = sortedCollections

          sortedCollections.forEach((collection, i) => {
            // Sort Sessions
            const sessions: Session[] = collection.sessions
            const sessionNewOrder = collectionNewOrder[i] ? collectionNewOrder[i].sessions : []
            const sessionNewOrderIDs = sessionNewOrder.map((s) => s.id)
            const sortedSessions: Session[] = sortItemsByKey<Session>(
              sessionNewOrderIDs,
              sessions,
              'id'
            )
            collection.sessions = sortedSessions
          })
        })
      }
    },

    sortTasks(shuffleDetails: ShuffledContentDetails): void {
      const selectedActivity = state.value.selectedActivity.activity
      if (selectedActivity) {
        const episodeNewOrder = shuffleDetails.episodes
        selectedActivity.episodes.forEach((episode, i) => {
          const collectionNewOrder = episodeNewOrder[i] ? episodeNewOrder[i].collections : []
          episode.collections.forEach((collection, i) => {
            const sessionNewOrder = collectionNewOrder[i] ? collectionNewOrder[i].sessions : []
            collection.sessions.forEach((session, i) => {
              // Sort Tasks
              const taskNewOrder = sessionNewOrder[i] ? sessionNewOrder[i].tasks : []
              const shuffleTasks = sessionNewOrder[i]?.shuffle ?? false
              const taskNewOrderIDs = taskNewOrder.map((t) => t.id)
              let sortedTasks: QuestionUnion[] = session.allTasks
              let sortedWarmups: QuestionUnion[] = session.allWarmups
              if (shuffleTasks) {
                sortedWarmups = shuffleItems(sortedWarmups) // TODO: Should 'shuffle' boolean control warmups as well?
                if (SESSION_TYPE.multiPlayer === session.type) {
                  sortedTasks = sortItemsByKey<QuestionUnion>(taskNewOrderIDs, sortedTasks, 'id') // sort items according to info provided in shuffleDetails
                } else if (SESSION_TYPE.singlePlayer === session.type) {
                  sortedTasks = shuffleItems(sortedTasks)
                }
              }
              session.sortedWarmups = sortedWarmups
              session.sortedTasks = sortedTasks
            })
          })
        })
      }
    }
  }

  // This defines the interface used externally

  return {
    getters,
    actions
  }
}

export type CMSStoreType = ReturnType<typeof useCMSStore>
export default useCMSStore
// export const UserKey: InjectionKey<UseUser> = Symbol('UseUser')
