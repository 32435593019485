import { computed, reactive, ComputedRef } from 'vue'
import type { IConnackPacket } from 'mqtt'

import useMqttService from '@/composition/mqtt'
import { Parcel, User } from '@/models/main'
import router from '@/router'

// Note the Parcel service should be kept independent of the Pinia stores
// Upon message received, call store functions from App.vue

const mqttService = useMqttService()

// Register a callback to this to receive new messages in the app
let newMessageCB: ((topic: string, payload: Buffer) => void) | undefined = undefined

interface State {
  logs: Record<string, unknown>
  logIDs: []
  lastSentParcel?: Parcel
}

const _state: State = reactive({
  task: undefined,
  message: undefined,
  logs: {},
  logIDs: [],
  lastSentParcel: undefined
})

interface Getters {
  mqttConnected: ComputedRef<boolean>
  lastSentParcel: ComputedRef<Parcel | undefined>
}

interface Actions {
  setNewMessageCallback: (fn: (topic: string, payload: Buffer) => void) => void
  openMQTTConnection: (user: User) => Promise<void>
  closeMQTTConnection: () => Promise<void>
  subscribeTopic: (topic: string) => Promise<void>
  unSubscribeTopic: (topic: string) => void
  sendParcel: (topic: string, parcel: Parcel) => Promise<void>
}

interface ServiceInterface {
  state: State
  getters: Getters
  actions: Actions
}

function useParcelStore(): ServiceInterface {
  const getters = {
    get mqttConnected(): ComputedRef<boolean> {
      return computed(() => mqttService.connected())
    },
    get lastSentParcel(): ComputedRef<Parcel | undefined> {
      return computed(() => _state.lastSentParcel)
    }
  }

  const actions = {
    setNewMessageCallback: (fn: (topic: string, payload: Buffer) => void): void => {
      newMessageCB = fn
    },
    openMQTTConnection: (user: User): Promise<void> => {
      return new Promise((resolve, reject) => {
        try {
          if (!mqttService.connected()) {
            const connectionCallback = (data?: IConnackPacket, error?: Error): void => {
              if (error) {
                console.log(error)
                reject(error)
              } else resolve()
            }

            const newMessageCallback = (topic: string, payload: Buffer): void => {
              if (newMessageCB) newMessageCB(topic, payload)
              else console.log('Message received but no callback defined to manage it')
            }

            const catchCallback = (message: string): void => {
              console.warn(`catched mqtt event: ${message}`)
              router.push('/dashboard')
            }

            const userId = user._id
            let mqttUsername = import.meta.env.MOSQUITTO_USER
            let mqttPassword = import.meta.env.MOSQUITTO_PASSWORD
            if (user.mqtt) {
              // replace values (if existing)
              if (user.mqtt.username) mqttUsername = user.mqtt.username
              if (user.mqtt.password) mqttPassword = user.mqtt.password
            }
            if (mqttUsername && mqttPassword)
              mqttService
                .initializeClientConnection({
                  userId,
                  mqttUsername,
                  mqttPassword,
                  connectionCallback,
                  newMessageCallback,
                  catchCallback
                })
                .then(() => {
                  resolve()
                })
            else reject(new Error('Username or password missing'))
          } else resolve() // mqttService is active and nothing needs to be done
        } catch (e) {
          reject(new Error(`Error during mqtt connection establishment: ${e}`))
        }
      })
    },
    closeMQTTConnection: async (): Promise<void> => {
      await mqttService.closeClientConnection().catch((error) => {
        if (!window.location.href.includes('login')) console.error(error)
      })
    },
    subscribeTopic: (topic: string): Promise<void> => {
      return new Promise<void>((resolve, reject): void => {
        mqttService.subscribe(topic).then((granted) => {
          const t = granted.pop()
          if (t) {
            console.log(`Subscribed ${topic}`)
            resolve()
          } else reject(new Error('Subscription failed'))
        })
      })
    },
    unSubscribeTopic: (topic: string): void => {
      if (mqttService.connected())
        mqttService
          .unSubscribe(topic)
          .then(() => {
            console.log(`Unsubscribed ${topic}`)
          })
          .catch((error) => console.error(error))
    },
    sendParcel: async (topic: string, parcel: Parcel): Promise<void> => {
      return mqttService
        .publish(topic, parcel.serialised())
        .then(() => {
          console.log(`Sent parcel ${parcel.parcelType.toString()}`)
          _state.lastSentParcel = parcel
        })
        .catch((error) => console.error(error))
    }
  }

  const state = _state

  return { state, getters, actions }
}

export default useParcelStore
