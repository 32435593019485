<!-- Copyright 2023 Richard Nesnass, Tom Bjarne Seidel

 This file is part of KMMP.

KMMP is free software: you can redistribute it and/or modify
 it under the terms of the GNU Affero General Public License as published by
 the Free Software Foundation, either version 3 of the License, or
 (at your option) any later version.

 GPL-3.0-only or GPL-3.0-or-later

KMMP is distributed in the hope that it will be useful,
 but WITHOUT ANY WARRANTY; without even the implied warranty of
 MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 GNU Affero General Public License for more details.

 You should have received a copy of the GNU Affero General Public License
 along with KMMP.  If not, see http://www.gnu.org/licenses/. -->
<template>
  <div v-if="state.currentGame" class="flex flex-col">
    <div
      v-if="edit"
      class="flex flex-col rounded border border-slate-700 p-4 m-2"
      :class="[showSave ? 'bg-green-200' : 'bg-slate-900']"
    >
      <ConfirmationPopup
        v-if="state.deletionPopupIsVisible"
        :text="t('dialogDeleteText')"
        @close-popup="togglePopup()"
        @confirm="confirmGameDeletion()"
      />
      <div class="flex flex-row flex-wrap">
        <div class="flex flex-col items-start ml-3">
          <p class="mt-7 text-white text-xl">Game details</p>

          <AnswerInput
            v-if="state.currentGame.profile"
            v-model="state.currentGame.profile.name"
            class="my-2"
            mode="text"
            label="Name"
            :border="false"
            @change="saveActive"
          ></AnswerInput>
          <AnswerInput
            v-if="state.currentGame.profile"
            v-model="state.currentGame.profile.ref"
            class="my-2"
            mode="text"
            label="Reference"
            :border="false"
            @change="saveActive"
          ></AnswerInput>
          <Notice
            class="-ml-3 rounded-none mt-7"
            text="Warning: changing the Activity will reset some Game parameters:
          current shuffle order, current Mastery set"
            :type="DialogMessageType.Warning"
          />
          <SelectionBox
            v-if="hasAdminRole && activities.length"
            v-model="state.activitySelected"
            class="p-2 w-full"
            :label="'Activity *'"
            :options="activities"
            :reset-on-choose="false"
            @change="updateActivity"
          ></SelectionBox>
          <p class="ml-3 mb-3 text-xs text-gray-400">
            * a Singleplayer activity can be assigned once the dyad has been split up (affected
            participant(s) were removed)
          </p>
          <SelectionBox
            v-model="selectedGameLanguage"
            :label="'Game language'"
            :options="gameLanguageList"
            class="p-2 w-full"
            @change="updateGameLanguage"
          ></SelectionBox>
          <SelectionBox
            v-model="selectedGameType"
            :label="'Game type'"
            :options="gameTypeList"
            class="p-2 w-full"
            @change="updateGameType"
          ></SelectionBox>
        </div>

        <div
          class="flex flex-col mt-4 p-1 w-full text-white"
          v-if="selectedGameType.item !== GameType.SP"
        >
          <div class="m-3 flex flex-col flex-wrap">
            <p class="mb-3 text-xl">Participants</p>
            <SelectionBox
              v-if="
                state.currentGame.details &&
                state.currentGame.details.participants &&
                state.currentGame.details.participants.length < 2 &&
                state.currentGame.details.currentActivityId
              "
              v-model="theSelectedUser"
              class="w-full"
              :label="'Choose a participant'"
              :options="state.userOptionList"
              @change="updatePairing"
              @click.prevent.stop="() => {}"
            ></SelectionBox>
            <Notice
              v-else
              text="Number of maximum participants reached, or no Activity selected"
              :type="DialogMessageType.Success"
            />
            <div
              v-for="(participant, index) in getParticipantsForGame()"
              :key="index"
              class="flex justify-between items-center bg-slate-700 rounded pl-5 my-2"
            >
              <p class="items-center flex">{{ participant.profile.username }}</p>
              <Button
                v-if="game.progress?.size === 0"
                :backgroundcolour="'bg-red-300'"
                :bordercolour="'border-red-600'"
                class="my-2 mr-2"
                @click="updatePairing(null, participant)"
                >Remove</Button
              >
            </div>
          </div>
        </div>

        <div class="flex flex-col items-start mx-5 text-white">
          <p class="mt-7 text-xl">Game options</p>
          <MultipleChoiceInput
            v-if="state.currentGame.sharing"
            v-model="state.currentGame.sharing.groups"
            label="Sharing"
            description="Sets of users (classes) that can be paired to this game"
            mode="multiChoice"
            :options="usersGroups"
            :underline="false"
            class="my-2"
            @change="saveActive"
          />
          <div v-if="usersGroups.length === 0" class="text-red-500 bg-red-200 p-2 rounded">
            no classes available
          </div>
          <AnswerInput
            v-model:booleanValue="state.currentGame.status.deleted"
            class="my-2"
            mode="binaryChoice"
            label="Deleted"
            :border="false"
            @change="saveActive"
          ></AnswerInput>
        </div>

        <div class="flex flex-col items-start p-1 m-4 text-white">
          <p class="text-xl">Progress Control</p>
          <AnswerInput
            v-model:booleanValue="state.currentGame.status.controlActive"
            class="my-2 pt-2"
            mode="binaryChoice"
            :border="false"
            label="Restrict SET progress"
            description="Enforce progress using the Mastery monitor panel (DSLPlus)"
            @change="saveActive"
          ></AnswerInput>
          <AnswerInput
            v-model:booleanValue="state.currentGame.status.redoTasks"
            class="my-2 pt-2"
            mode="binaryChoice"
            :border="false"
            label="Allow access to previous tasks"
            description="Previously completed questions can be tried again.
          Activating this affects the meaning of a 'completed Set', as a Set
          is normally completed immediately after the final Question"
            @change="saveActive"
          ></AnswerInput>
          <AnswerInput
            v-model:booleanValue="state.currentGame.status.skipTasks"
            class="my-2 pt-2"
            mode="binaryChoice"
            :border="false"
            label="Allow access to future tasks"
            description="Future tasks will be accessible even if the current task is not completed"
            @change="saveActive"
          ></AnswerInput>
        </div>
        <div class="flex flex-col items-start p-1 m-4">
          <div
            v-for="consent in state.currentGame.consent"
            :key="consent.id"
            class="flex flex-col items-start"
          >
            <AnswerInput
              v-model="consent.state"
              class="my-2"
              mode="singleChoice"
              :border="false"
              :options="consentStateList"
              label="Consent"
              @change="saveActive"
            ></AnswerInput>
            <AnswerInput
              v-model="consent.id"
              class="my-2"
              mode="text"
              :border="false"
              label="Consent ID"
              @change="saveActive"
            ></AnswerInput>
          </div>
        </div>
      </div>

      <div class="flex flex-row flex-wrap justify-end mr-3 mb-11">
        <slot name="closeEdit" />
        <div>
          <Button
            v-if="showSave"
            :backgroundcolour="'bg-green-600'"
            :textcolour="'text-white'"
            class="col-start-2 mr-2"
            @click="saveGame()"
            >Save Game</Button
          >
          <Button
            class="mr-2 rounded-none"
            :backgroundcolour="'bg-red-300'"
            :bordercolour="'border-red-600'"
            @click="togglePopup()"
            >Delete Game</Button
          >
        </div>
      </div>
    </div>
    <div v-else class="flex flex-row w-full rounded items-center justify-between bg-slate-700">
      <div class="flex flex-col w-full">
        <div v-if="game.profile" class="flex flex-row pt-4 px-4 text-white">
          <p>
            <strong v-if="game.profile.name">{{ game.profile.name }}</strong>
            <strong v-else><i style="color: grey">unnnamed</i></strong>
          </p>
          <p class="text-blue-400 ml-11">
            <strong v-if="game.profile.name"
              >{{ game.profile.name }} ({{ game.profile.ref }})</strong
            >
            <strong v-else><i style="color: grey">unnnamed</i></strong>
          </p>
        </div>
        <div
          v-if="game.details"
          class="flex flex-col justify-start w-full text-xs p-4 text-slate-300"
        >
          <p class="mr-2">ID: {{ game._id }}</p>
          <p
            :class="{
              'text-green-500': game.details.participants.length === 2,
              'text-red-500': game.details.participants.length !== 2
            }"
          >
            {{ game.details.participants.length }} / 2 paired
          </p>
        </div>
      </div>
      <slot />
    </div>
  </div>
</template>

<script setup lang="ts">
import { PropType, ref, Ref, computed, reactive, watch, toRefs, onMounted } from 'vue'
import { useI18n } from 'vue-i18n'

import { Game, User, GameType, Group, Parcel } from '@/models/main'
import {
  ActivityOptionListItem,
  consentStates,
  DialogMessageType,
  GameTypeListItem,
  UserOptionListItem,
  USER_ROLE,
  ParcelType,
  LanguageCodes
} from '@/constants'

import useGameStore from '@/store/useGameStore'
import useUserStore from '@/store/useUserStore'
import useCMSStore from '@/store/useCMSStore'

import MultipleChoiceInput from '@/components/base/MultipleChoiceInput.vue'
import SelectionBox from '@/components/base/SelectionBox.vue'
import ConfirmationPopup from '@/components/dialog/ConfirmationPopup.vue'
import AnswerInput from '@/components/base/AnswerInput.vue'
import Notice from '@/components/dialog/Notice.vue'
import Button from '@/components/base/Button.vue'
import useParcelStore from '@/composition/parcel'

const messages = {
  no: {
    listenAgain: 'Hør igjen',
    dialogDeleteTitle: 'Slett dette Spillet',
    dialogDeleteText: 'Hvis du sletter, du kan ikke angre. All spillerfremgang vil bli fjernet!',
    dialogDeleteConfirm: 'Slette',
    dialogDeleteCancel: 'Avbryt'
  },
  sv: {
    listenAgain: 'Hør igjen',
    dialogDeleteTitle: 'Slett dette Spillet',
    dialogDeleteText: 'Hvis du sletter, du kan ikke angre. All spillerfremgang vil bli fjernet!',
    dialogDeleteConfirm: 'Slette',
    dialogDeleteCancel: 'Avbryt'
  },
  en: {
    listenAgain: 'Listen again',
    dialogDeleteTitle: 'Delete this Game',
    dialogDeleteText: 'Deletion cannot be undone. All player progress will be removed!',
    dialogDeleteConfirm: 'Delete',
    dialogDeleteCancel: 'Cancel'
  }
}

const { t } = useI18n({ messages })

const { actions: gameActions, getters: gameGetters } = useGameStore()
const { actions: userActions, getters: userGetters } = useUserStore()
const { getters: cmsGetters, actions: cmsActions } = useCMSStore()

const parcelStore = useParcelStore()

interface GroupShareOptionListItem {
  title: string
  id: string
}

const props = defineProps({
  game: { type: Object as PropType<Game>, required: true },
  edit: { type: Boolean, required: true },
  selectedUser: { type: Object as PropType<User>, required: false, default: null }
})

const emit = defineEmits<{
  (e: 'gamedeleted'): void
  (e: 'pairingupdated'): void
}>()

const pRefs = toRefs(props)

const theSelectedUser: Ref<UserOptionListItem> = ref({
  item: undefined,
  itemName: 'no user selected'
})

const selectedGameLanguage: Ref<GameTypeListItem> = ref({
  item: props.game.details.language,
  itemName: props.game.details.language
})

const selectedGameType: Ref<GameTypeListItem> = ref({
  item: props.game.details.gameType,
  itemName: props.game.details.gameType
})

// local variables
const showSave = ref(false)

const state = reactive({
  currentGame: new Game(),
  activitySelected: {} as ActivityOptionListItem,
  userOptionList: [] as UserOptionListItem[],
  deletionPopupIsVisible: false
})

async function setupState() {
  state.currentGame = new Game(pRefs.game.value)
  const activity = activities.value?.find(
    (a: ActivityOptionListItem) => a.item.id === state.currentGame.details.currentActivityId
  )
  if (activity) state.activitySelected = activity
  await setUserOptionList()
}

onMounted(async () => {
  await setupState()
})

watch(pRefs.edit, async (trueValue) => {
  if (trueValue) {
    setupState()
  }
})

const usersGroups = computed(() => {
  return userGetters.allGroups.value.map<GroupShareOptionListItem>((g: Group) => {
    return {
      id: g._id,
      title: g.name
    }
  })
})

const setUserOptionList = async () => {
  //await userActions.getAllUsers()
  const allUsers = userGetters.allUsers.value
  const users = []
  for (const user of allUsers) {
    const paired = await userActions.getUserPairedInfo(user._id)
    const inSharedGroups = user.groups.some((group: Group) =>
      state.currentGame.sharing.groups.includes(group._id)
    )
    if (!paired && inSharedGroups && !state.currentGame.details.participants.includes(user._id))
      users.push(user)
  }

  const uol: UserOptionListItem[] = users.map((u: User) => ({
    item: u,
    itemName: u.profile.username
  }))

  uol.unshift({
    item: undefined,
    itemName: 'clear filter'
  })

  state.userOptionList = uol
}

const gameTypeList = computed((): GameTypeListItem[] => {
  const gtl: GameTypeListItem[] = Object.values(GameType).map((t: GameType) => ({
    item: t,
    itemName: t
  }))
  return gtl
})

const gameLanguageList = computed((): GameTypeListItem[] => {
  const gl: GameTypeListItem[] = Object.values(LanguageCodes).map((l: LanguageCodes) => ({
    item: l,
    itemName: l
  }))
  return gl
})

const hasAdminRole = computed(() =>
  userActions.hasMinimumRole(userGetters.myUser.value, USER_ROLE.monitor)
)

const activities = computed(() => {
  const as = cmsGetters.root.value
  const activities: ActivityOptionListItem[] = []
  for (const a of as) {
    activities.push({
      itemName: a.name,
      item: a
    })
  }
  return activities
})

// -------------- Activity --------------

const updateActivity = async (a: ActivityOptionListItem) => {
  state.currentGame.details.currentActivityId = a.item.id
  await gameActions.updateGame(state.currentGame)
  showSave.value = false
  await gameActions.getGames(undefined, props.selectedUser._id)
  cmsActions.overrideCMS()
}

// -------------------------Game functions-----------------------------

const updatePairing = async (user: UserOptionListItem | null, removedParticipant?: User) => {
  let participantIds: string[] = []
  const updatedGame = new Game(state.currentGame)
  if (removedParticipant) {
    const index = updatedGame.details.participants.findIndex(
      (p: string) => p === removedParticipant._id
    )
    updatedGame.details.participants.splice(index, 1) // remove participant
    updatedGame.details.dyadSplit = true
    participantIds = [...updatedGame.details.participants, removedParticipant._id]
  } else if (user !== null) {
    // participant was added
    const addedUser = user.item
    if (addedUser) {
      updatedGame.details.participants.push(addedUser._id) // add user id to list
      if (updatedGame.details.participants.length === 2) updatedGame.details.dyadSplit = false // only mark as 'complete' if the new length is 2
      participantIds = updatedGame.details.participants
    }
  }
  await gameActions.updateGame(updatedGame)
  if (props.selectedUser) {
    //await gameActions.getGames(undefined, props.selectedUser._id) // get the games for the chosen user, not the one added from the list
    await setUserOptionList()
    for (const participantId of participantIds) {
      const parcel = new Parcel({
        parcelType: ParcelType.PairingUpdate,
        subscription: {
          game_id: gameGetters.selectedGame.value?._id,
          user: {
            id: userGetters.myUser.value._id,
            username: userGetters.myUser.value.profile.username
          }
        }
      })
      await parcelStore.actions.sendParcel(participantId, parcel) // user id topic
    }
    emit('pairingupdated')
  }
}

const updateGameLanguage = async (language: LanguageCodes) => {
  const updatedGame = new Game(state.currentGame)
  updatedGame.details.language = language.toString()
  await gameActions.updateGame(updatedGame)
}

const updateGameType = async (type: GameTypeListItem) => {
  const updatedGame = new Game(state.currentGame)
  updatedGame.details.gameType = type.item as GameType
  await gameActions.updateGame(updatedGame)
}

const getParticipantsForGame = () => {
  const game = state.currentGame
  return game.details
    ? userGetters.allUsers.value.filter((u: User) => game.details.participants.includes(u._id))
    : []
}

const consentStateList = Object.values(consentStates).map((v, i) => ({
  id: '' + i,
  title: v
}))

const saveGame = () => {
  // Update a selected Game
  gameActions.updateGame(state.currentGame).then(async () => {
    await setUserOptionList()
  })
  showSave.value = false
}

const togglePopup = () => {
  state.deletionPopupIsVisible = !state.deletionPopupIsVisible
}

const confirmGameDeletion = async () => {
  emit('gamedeleted')
  await gameActions.deleteGame(state.currentGame)
}

const saveActive = () => {
  showSave.value = true
}
</script>

<style scoped></style>
