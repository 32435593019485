<template>
  <div
    v-if="!state.decisionGiven"
    class="bg-faded w-full h-full absolute flex flex-col justify-end items-center"
  >
    <div class="w-3/5 dialog-bg p-5 rounded-xl h-32 flex justify-evenly mb-4">
      <Button
        class="mr-3 font-bold rounded-3xl border-none"
        textcolour="text-white"
        backgroundcolour="bg-red-600"
        @click="updateDecisionStatus()"
        @touchstart.prevent="updateDecisionStatus()"
        >{{ rejectTag }}</Button
      >
      <Button
        class="font-bold rounded-3xl border-none"
        textcolour="text-white"
        backgroundcolour="bg-green-500"
        @click="updateDecisionStatus(true)"
        @touchstart.prevent="updateDecisionStatus(true)"
        >{{ confirmTag() }}</Button
      >
    </div>
  </div>
</template>
<script setup lang="ts">
import { computed, onMounted, reactive } from 'vue'
import { useI18n } from 'vue-i18n'

import { FinalDecisionStatus } from '@/constants'
import { Tasktype3mp } from '@/models/tasktypes/Tasktype3mp'

import Button from '@/components/base/Button.vue'

import useMultiPlayerState from '@/composition/useMultiplayerState'
import useCMSStore from '@/store/useCMSStore'

const multiplayer = useMultiPlayerState()
const cms = useCMSStore()

const { t } = useI18n()

const state = reactive({
  decisionGiven: false
})

onMounted(() => {
  state.decisionGiven = false
})

function updateDecisionStatus(confirmation = false) {
  state.decisionGiven = true
  multiplayer.actions.setFinalDecisionStatus(
    confirmation ? FinalDecisionStatus.Accepted : FinalDecisionStatus.Rejected
  )
}

const selectedTasktypeIsBinary = computed(() => {
  const selectedTask = cms.getters.selectedTask.value
  if (selectedTask) return [Tasktype3mp].some((tt) => selectedTask instanceof tt)
  return false
})

const rejectTag = computed(() => {
  return selectedTasktypeIsBinary.value &&
    multiplayer.getters.firstDecision.value?.solution_id !==
      multiplayer.getters.advice.value?.solution_id
    ? t('acceptadvice')
    : t('chooseagain')
})

const confirmTag = () => {
  return t('acceptfirstdecision')
}
</script>

<style scoped>
.bg-faded {
  background-color: rgba(0, 0, 0, 0.4);
}

.decision-wrapper {
  bottom: -8%;
}

.decision-wrapper button {
  width: 50%;
  padding: 0.75rem 0;
}
</style>
