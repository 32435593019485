<!-- Copyright 2023 Richard Nesnass, Tom Bjarne Seidel

 This file is part of SL+.

 SL+ is free software: you can redistribute it and/or modify
 it under the terms of the GNU Affero General Public License as published by
 the Free Software Foundation, either version 3 of the License, or
 (at your option) any later version.

 GPL-3.0-only or GPL-3.0-or-later

 SL+ is distributed in the hope that it will be useful,
 but WITHOUT ANY WARRANTY; without even the implied warranty of
 MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 GNU Affero General Public License for more details.

 You should have received a copy of the GNU Affero General Public License
 along with SL+.  If not, see http://www.gnu.org/licenses/. -->
<template>
  <div class="flex flex-col">
    <label v-if="label" class="block uppercase tracking-wide text-gray-400 text-xs font-bold mb-2" :for="elementId" :class="{ 'ml-5': border }">
      {{ label }}
    </label>
    <p v-if="description" class="text-xs">{{ description }}</p>
    <div :id="elementId" class="flex flex-col">
      <div v-for="o in props.options" :key="o.id" class="py-1">
        <input
          :id="`${elementId}-option-${o.title.replace(/\s/g, '')}`"
          v-model="state.modelValue"
          class="mr-1 mb-1"
          type="checkbox"
          :value="o.id"
          @change.prevent.stop="valueInput(o)"
        />
        <label class="mr-2" :for="`${elementId}-option-${o.title.replace(/\s/g, '')}`">{{ o.title }}</label>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { OptionItem } from '@/constants'
import { PropType, reactive, onMounted, onUpdated } from 'vue'

const emit = defineEmits(['change', 'update:modelValue'])
const props = defineProps({
  modelValue: {
    type: Object as PropType<string[]>,
    required: true,
  },
  id: {
    type: String,
    default: '',
  },
  required: {
    type: Boolean,
    default: false,
  },
  options: {
    type: Array as PropType<OptionItem[]>,
    default: () => [],
  },
  label: {
    type: String,
    default: '',
  },
  description: {
    type: String,
    default: '',
  },
  placeholder: {
    type: String,
    default: '',
  },
  // Use for scaling the input size for text input
  customSize: {
    type: String,
    default: '',
  },
  border: {
    type: Boolean,
    default: false,
  },
  underline: {
    type: Boolean,
    default: true,
  },
})

const state = reactive({
  modelValue: [] as string[],
  id: '',
})

const elementId = state.id ? state.id : 'ai-id-' + Math.floor(Math.random() * 10000000)

onMounted(() => {
  updateValues()
})

onUpdated(() => {
  updateValues()
})

const updateValues = (): void => {
  state.id = props.id
  state.modelValue = props.modelValue
}

const valueInput = (option: OptionItem): void => {
  // const ie = $event.target as HTMLInputElement
  const index = state.modelValue.indexOf(option.id)
  if (index !== -1) state.modelValue.splice(index, 1)
  else state.modelValue.push(option.id)
  emit('update:modelValue', state.modelValue) // If using v-model on this element, this is the updated value
  emit('change', state.modelValue) // Also possible to listen for this change event if not using v-model
}
</script>

<style lang="postcss" scoped>
  label {
    @apply pointer-events-none;
  }
</style>
