/*
 Designed and developed by Richard Nesnass

 This file is part of SL+.

 SL+ is free software: you can redistribute it and/or modify
 it under the terms of the GNU Affero General Public License as published by
 the Free Software Foundation, either version 3 of the License, or
 (at your option) any later version.

 GPL-3.0-only or GPL-3.0-or-later

 SL+ is distributed in the hope that it will be useful,
 but WITHOUT ANY WARRANTY; without even the implied warranty of
 MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 GNU Affero General Public License for more details.

 You should have received a copy of the GNU Affero General Public License
 along with SL+.  If not, see <http://www.gnu.org/licenses/>.
 */

import { XHR_REQUEST_TYPE, HttpException, XHR_CONTENT_TYPE, CmsGQLQuery } from '../models/main'
import { apiRequest } from './apiRequest'
import {
  cmsUrl,
  cmsClientId,
  cmsClientSecret,
  cmsTokenUrl,
  LanguageFallbacks,
  LanguageCodes
} from '../constants'
import { emitError } from '../utilities'

interface TokenResponse {
  access_token: string
  expires_in: number
  token_type: string
  scope: string
}

function fetchToken(): Promise<string> {
  if (cmsUrl.includes('localhost')) return Promise.resolve('')
  return new Promise((resolve) => {
    apiRequest<TokenResponse>({
      route: '',
      method: XHR_REQUEST_TYPE.POST, // or 'PUT'
      credentials: false,
      contentType: XHR_CONTENT_TYPE.URLENCODED,
      body: `grant_type=client_credentials&client_id=${cmsClientId}&client_secret=${cmsClientSecret}&scope=squidex-api`,
      baseURL: `${cmsTokenUrl}`
    })
      .then((result) => {
        resolve(result.access_token)
      })
      .catch((error) => {
        emitError(new Error('Fetch token error: ' + error.toString()))
      })
  })
}

function cmsRequest(
  projectName: string,
  query: string,
  replacables: Record<string, string | number>,
  language: LanguageCodes
): Promise<CmsGQLQuery> {
  return new Promise((resolve) => {
    const token = localStorage.getItem('squidex-token')
    const fallbacks = LanguageFallbacks[language].reduce((prev, curr) => prev + ',' + curr, '')
    // Squidex does not support standard a GraphQL query body!
    // const body = JSON.stringify({ query, variables }),
    Object.keys(replacables).forEach((key: string) => {
      const regex = new RegExp(key, 'g')
      const v = replacables[key].toString()
      query = query.replace(regex, v)
    })

    const variables = {}
    const body = { query, variables } // NOTE: MUST use the name 'query' here
    apiRequest<CmsGQLQuery>({
      route: `/api/content/${projectName}/graphql`,
      method: XHR_REQUEST_TYPE.POST,
      credentials: false,
      headers: {
        Authorization: `Bearer ${token}`,
        'X-Languages': `${language}${fallbacks}`
      },
      body,
      contentType: XHR_CONTENT_TYPE.JSON,
      baseURL: `${cmsUrl}`
    })
      .then((res: CmsGQLQuery) => {
        if (res.errors && res.errors.length > 0) {
          emitError(new Error('CMS request error: ' + res.errors.map((e) => e['message'] + ' : ')))
        }
        resolve(res)
      })
      .catch((error: HttpException) => {
        console.log(error)
        if (error.status === 401) {
          console.log('Unauthorized. Attempting to fetch new token ...' + error.message)
          fetchToken().then((newToken) => {
            localStorage.setItem('squidex-token', newToken)
            resolve(cmsRequest(projectName, query, variables, language))
          })
        } else emitError(new Error('XHR error: ' + error.message))
      })
  })
}
/*
function cmsQuestionByID(
  projectName: string,
  schema: string,
  id: string
): Promise<CmsSingleItemQuery> {
  return new Promise(resolve => {
    const token = localStorage.getItem('squidex-token')
    apiRequest<CmsSingleItemQuery>({
      route: `/api/content/${projectName}/${schemaName}/${id}`,
      method: XHR_REQUEST_TYPE.GET,
      credentials: false,
      headers: {
        Authorization: `Bearer ${token}`,
      },
      contentType: XHR_CONTENT_TYPE.JSON,
      baseURL: `${cmsUrl}`,
    })
      .then((res: CmsSingleItemQuery) => {
        resolve(res)
      })
      .catch((error: HttpException) => {
        console.log(error)
        if (error.status === 401) {
          console.log('Unauthorized. Attempting to fetch new token ...')
          fetchToken().then(newToken => {
            localStorage.setItem('squidex-token', newToken)
            resolve(cmsQuestionByID(projectName, schemaName, id))
          })
        } else console.error('Error: ', error)
      })
  })
} */

export { cmsRequest, fetchToken }
