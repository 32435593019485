import { AudioContext, AudioBufferSourceNode, TAudioContextState } from 'standardized-audio-context'
type SafariAudioContextState = TAudioContextState & 'interrupted'

// This is the Web Audio API version of the above class - can tell us if the sound is ready to play
// It creates sounds from a buffer supplied by loading from local cache, or loading a URi using fetch()
// It also calls the 'ended' callback immediately if the sound is not ready to play
export class WebAudio {
  audioBuffer: AudioBuffer | undefined
  audioSource: AudioBufferSourceNode<AudioContext> | undefined
  uri?: string
  isReady = false
  playOnReady = false
  loadAttempts = 0
  maxLoadAttempts = 1

  endedCallback?: () => unknown
  errorCallback?: OnErrorEventHandler
  onReadyCallback?: () => unknown

  pausedAt = 0
  startedAt = 0
  paused = false
  playing = false
  sourceWasURI = false

  idleTimer?: ReturnType<typeof setTimeout>

  useLogging = false

  static audioContext: AudioContext = new AudioContext()

  static createAudioContext() {
    WebAudio.audioContext = new AudioContext()
  }
  static closeAudioContext() {
    WebAudio.audioContext.close()
  }
  static suspendAudioContext() {
    WebAudio.audioContext.suspend()
  }
  static resumeAudioContext() {
    return WebAudio.audioContext.resume()
  }

  // ArrayBuffer should be an MP3
  constructor(arrayBuffer?: ArrayBuffer, uri?: string, onEnded?: () => unknown) {
    this.uri = uri
    if (onEnded) this.endedCallback = onEnded
    if (!arrayBuffer && !uri) console.log(`WebAudio intstantiation error - no sources supplied`)
    this.load(arrayBuffer)
  }

  // The resulting AudioBuffer is kept for reuse each time the sound is played
  async decodeFile(arrayBuffer: ArrayBuffer) {
    try {
      this.audioBuffer = await WebAudio.audioContext.decodeAudioData(arrayBuffer)
      this.isReady = true
    } catch (error) {
      const err = error as unknown as Error
      console.log(`WebAudio Error decoding: ${this.uri}: ${err.message}`)
      this.hasErrored(err)
    }
  }

  // Each time we play we must create a new Node
  createSource() {
    try {
      this.audioSource = new AudioBufferSourceNode(WebAudio.audioContext, {
        buffer: this.audioBuffer,
        playbackRate: 1
      })
      this.audioSource.onended = () => {
        this.hasEnded()
      }
    } catch (error) {
      const err = error as unknown as Error
      console.log(`WebAudio Error creating source: ${this.uri}: ${err.message}`)
      this.hasErrored(err)
    }
  }

  async load(arrayBuffer?: ArrayBuffer) {
    if (!arrayBuffer && this.uri) {
      const response = await fetch(this.uri)
      arrayBuffer = await response.arrayBuffer()
      this.sourceWasURI = true
    }
    if (arrayBuffer) {
      await this.decodeFile(arrayBuffer)
      if (this.isReady) {
        if (this.onReadyCallback) this.onReadyCallback()
        if (this.playOnReady) {
          this.playOnReady = false
          this.play()
        }
      }
    } else {
      console.log('WebAudio error - no usable arrayBuffer')
    }
  }

  async play() {
    try {
      if (this.useLogging)
        console.log(
          `WebAudio attempting to play ${this.uri}. AudioContext state: ${WebAudio.audioContext.state}`
        )
      if (
        WebAudio.audioContext.state === ('interrupted' as SafariAudioContextState) ||
        WebAudio.audioContext.state === 'suspended'
      ) {
        await WebAudio.audioContext.resume()
      }
      if (this.audioBuffer && !this.playing) {
        const duration = this.audioBuffer.duration
        if (duration === 0) {
          console.log(`WebAudio duration is zero! Ending..`)
          this.hasEnded()
        } else {
          this.createSource()
          // A backup timer in case audio for some reason does not play
          this.idleTimer = setTimeout(
            () => {
              console.log(`WebAudio timed out (${duration}s + 2s)`)
              this.hasEnded()
            },
            duration * 1000 + 2000
          )

          if (this.audioSource) {
            this.audioSource.connect(WebAudio.audioContext.destination)
            if (this.pausedAt) {
              this.startedAt = Date.now() - this.pausedAt
              this.audioSource.start(0, this.pausedAt / 1000)
            } else {
              this.startedAt = Date.now()
              this.audioSource.start(0)
            }
            this.playing = true
            const ab = this.sourceWasURI ? 'a URL' : 'disk cache'
            console.log(`WebAudio playing: ${this.uri} loaded from ${ab}`)
          } else {
            console.log('WebAudio error - No source created')
          }
        }
      } else if (!this.audioBuffer) {
        console.log('WebAudio error - No buffer to play')
      }
    } catch (error) {
      const err = error as unknown as Error
      console.log(`WebAudio Error playing source ${this.uri}: ${err.message}`)
      this.hasErrored(err)
      this.hasEnded()
    }
  }

  pause() {
    if (this.idleTimer) clearTimeout(this.idleTimer)
    if (this.audioSource && this.playing) {
      this.audioSource.stop(0)
      this.pausedAt = Date.now() - this.startedAt
      this.paused = true
      this.playing = false
    }
  }

  stop() {
    if (this.idleTimer) clearTimeout(this.idleTimer)
    if (this.audioSource) {
      this.audioSource.stop(0)
      this.pausedAt = 0
      this.playing = false
    }
  }

  private hasEnded = () => {
    if (this.idleTimer) clearTimeout(this.idleTimer)
    this.playing = false
    this.paused = false
    this.pausedAt = 0
    if (this.useLogging) console.log(`WebAudio ended: ${this.uri}`)
    if (this.endedCallback) this.endedCallback()
  }

  private hasErrored = (error: Error) => {
    if (this.idleTimer) clearTimeout(this.idleTimer)
    if (this.errorCallback) this.errorCallback('WebAudio error', '', 0, 0, error)
  }

  set onready(callback: () => unknown) {
    this.onReadyCallback = callback
  }
  set onended(callback: () => unknown) {
    this.endedCallback = callback
  }
  set onerror(callback: OnErrorEventHandler) {
    this.errorCallback = callback
  }
  set retries(tries: number) {
    // Currently unused in this class
    this.maxLoadAttempts = tries
  }

  public addEventListener(type: string, callback: () => unknown) {
    switch (type) {
      case 'ended':
        this.endedCallback = callback
        break
    }
  }
  public removeEventListener(type: string, callback: () => unknown) {
    switch (type) {
      case 'ended':
        if (this.endedCallback == callback) this.endedCallback = undefined
        break
    }
  }

  playWhenReady: () => void = () => {
    if (!this.isReady) {
      this.playOnReady = true
    } else this.play()
  }
}
