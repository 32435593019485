<!-- Copyright 2023 Richard Nesnass, Tom Bjarne Seidel

 This file is part of KMMP.

KMMP is free software: you can redistribute it and/or modify
 it under the terms of the GNU Affero General Public License as published by
 the Free Software Foundation, either version 3 of the License, or
 (at your option) any later version.

 GPL-3.0-only or GPL-3.0-or-later

KMMP is distributed in the hope that it will be useful,
 but WITHOUT ANY WARRANTY; without even the implied warranty of
 MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 GNU Affero General Public License for more details.

 You should have received a copy of the GNU Affero General Public License
 along with KMMP.  If not, see http://www.gnu.org/licenses/. -->
<template>
  <div class="flex flex-row h-full">
    <div class="pt-2 px-2 border-r-2 border-grey w-1/4">
      <div v-if="user" class="border border-slate-700 bg-slate-900 rounded px-3 py-1 text-white">
        <p class="flex flex-col mt-2 items-baseline content-start">
          <span class="text-lg">Last login:</span>
          <span class="text-left text-slate-300 text-md font-light">{{ lastLogin }}</span>
        </p>
        <p class="flex flex-col mt-2 items-baseline content-start">
          <span class="text-lg">Selected:</span>
          <span class="text-slate-300 font-light">{{ user.profile.username }}</span>
        </p>
        <p class="flex flex-row mt-2 items-baseline content-start">
          <span class="text-lg">Classes:</span>
          <span class="text-slate-300 font-light">{{ user.groups.length }}</span>
        </p>
      </div>
      <div class="flex flex-col pt-3 text-left">
        <hr class="my-3 border-slate-700" />
        <div class="my-1">
          <label class="block tracking-wide text-gray-400 text-xs font-bold mb-2">
            Search in users</label
          >
          <input
            v-model="searchText"
            placeholder="Ola Nordmann"
            class="p-2 rounded w-full border-2 border-slate-700"
            type="text"
          />
        </div>
        <div>
          <p class="text-lg text-white font-bold mb-2 mt-11">Users</p>
          <ul class="mt-3 half-height overflow-scroll text-white -ml-5">
            <li
              v-for="(us, i) in filteredUsers"
              :id="`p-selectuser-${us.profile.username}`"
              :key="i"
              :class="{ 'bg-black': us._id !== user._id, 'bg-slate-900': us._id === user._id }"
              class="cursor-pointer p-1 my-px border-t-2 border-slate-700 flex"
              @click="selectUser(us)"
              @touchstart.prevent="selectUser(us)"
            >
              <span class="ml-4">{{ us.profile.username }}</span>
              <span
                v-if="user && us._id === user._id"
                class="bg-green-500 rounded-full flex w-6 h-6 ml-2"
                >&nbsp;✔︎&nbsp;</span
              >
            </li>
            <li
              v-if="filteredUsers.length === 0"
              class="bg-red-100 text-red-500 p-1.5 mx-px rounded"
            >
              No results
            </li>
          </ul>
        </div>
      </div>
    </div>

    <div class="flex-col h-full overflow-scroll scroll-container w-3/4 text-xl">
      <div class="flex w-full p-4">
        <Button
          :textcolour="'text-purple-600'"
          :childclass="'w-32'"
          :backgroundcolour="'bg-slate-900'"
          :bordercolour="'border-slate-700'"
          class="p-1 place-self-center mr-2"
          @click="selectUser(userGetters.myUser.value)"
          >Show my user</Button
        >
        <Button
          :textcolour="'text-green-600'"
          :childclass="'w-32'"
          :backgroundcolour="'bg-slate-900'"
          :bordercolour="'border-slate-700'"
          class="p-1 place-self-center"
          @click.stop="addUser()"
        >
          Add new User
        </Button>
      </div>

      <div
        class="flex flex-row flex-grow p-4 bg-slate-900 border-slate-700 border rounded text-white text-left m-4"
      >
        <div class="w-1/3">
          <h1 class="font-bold">User Details</h1>
          <AnswerInput
            v-if="hasAdminRole"
            v-model="user.profile.username"
            v-model:valid="validators.username"
            mode="text"
            label="Brukernavn"
            :border="false"
            placeholder="91234567"
            :underline="false"
            class="my-1 text-black text-lg mb-3"
            @change="saveUser()"
          />
          <AnswerInput
            v-if="hasAdminRole"
            v-model="user.profile.email"
            v-model:valid="validators.email"
            mode="email"
            label="e-post"
            :border="false"
            placeholder="user@example.com"
            :underline="false"
            class="my-1 text-black text-lg mb-3"
            @change="saveUser()"
          />
          <AnswerInput
            v-if="hasAdminRole"
            v-model="user.profile.mobil"
            v-model:valid="validators.mobil"
            mode="tel"
            label="Mobilnummer"
            :border="false"
            placeholder="91234567"
            :underline="false"
            class="my-1 text-black text-lg mb-3"
            @change="saveUser()"
          />

          <SelectionBox
            v-if="hasAdminRole"
            id="select-role"
            v-model="currentUserRole"
            class="my-1 text-black"
            :label="'Role'"
            :options="roleOptionList"
            :reset-on-choose="false"
            @change="updateRole"
          ></SelectionBox>

          <div
            v-if="hasAdminRole"
            class="flex flex-col items-center my-4 mt-11 bg-slate-700 p-3 rounded w-full"
          >
            <Button
              v-if="!editPassword"
              class="focus:outline-none bg-blue-200 border-blue-300 w-full"
              :textcolour="'text-black'"
              :childclass="'rounded-none'"
              @click.stop="editPassword = true"
            >
              Change password
            </Button>
            <AnswerInput
              v-if="editPassword"
              v-model="password"
              mode="password"
              :border="false"
              placeholder="new password"
              :underline="false"
              class="my-1 text-black"
            />
            <div
              v-if="editPassword"
              class="flex flex-row items-center justify-between w-full mt-1 p-2"
            >
              <Button
                class="focus:outline-none border-none bg-red-400"
                :textcolour="'text-black'"
                :childclass="'w-24'"
                @click.stop="editPassword = false"
                >Cancel</Button
              >
              <Button
                :disabled="!password"
                class="bg-green-400 border-none"
                :textcolour="'text-black'"
                :childclass="'w-24'"
                @click.stop="updatePassword()"
                >Update</Button
              >
            </div>
          </div>
        </div>

        <div v-if="hasAdminRole" class="flex flex-col mb-2 w-2/3">
          <div class="ml-11">
            <h1 class="mb-2">Admin-only controls</h1>
            <AnswerInput
              v-model:booleanValue="delaysDisabled"
              class="mt-3 text-sm"
              mode="binaryChoice"
              :border="false"
              label="Disable delays (not stored!)"
              description="Remove deliberate time delays. This is only for testing."
              @change="disableDelays"
            ></AnswerInput>
            <AnswerInput
              v-model:booleanValue="user.status.canEditPlayers"
              class="mt-5 text-sm"
              mode="binaryChoice"
              :border="false"
              description="This user can: create new Players and change the avatar's name"
              label="Can edit Players"
              @change="saveUser()"
            ></AnswerInput>
            <AnswerInput
              v-model:booleanValue="user.status.canEditGames"
              class="mt-5 text-sm"
              mode="binaryChoice"
              :border="false"
              label="Can edit Games"
              description="This user can: change a Game's name and shared groups, add / remove Players from Games"
              @change="saveUser()"
            ></AnswerInput>
            <AnswerInput
              v-model="user.profile.language"
              class="mt-5 text-sm"
              mode="singleChoice"
              description="Choose 'system' to allow the user's device to decide"
              :border="false"
              :options="languageList"
              label="Language"
              @change="saveUser()"
            ></AnswerInput>
          </div>
        </div>
      </div>

      <div
        class="flex flex-col flex-grow py-2 text-left px-2 overflow-y-auto bg-slate-900 border border-slate-700 rounded p-4 m-4"
      >
        <!-- Group Selection -->
        <h1 class="font-bold mb-1 text-white">Locations / Classes</h1>
        <p class="mb-6 text-xs text-gray-400">A user can only be part of a single group</p>
        <div class="mx-2 flex flex-col">
          <div class="flex flex-row items-center justify-between text-white text-lg w-full px-4">
            <p>Name</p>
            <p>Class</p>
            <p>Controls</p>
          </div>
          <div v-for="(g, i) in selectedGroups" :key="i" class="flex flex-row">
            <MonitorGroupItem :edit="false" :group="g" class="my-2 w-full">
              <Button
                :id="`button-remove-group-${g._id}`"
                :childclass="'w-20 -ml-3 -mr-1 my-px border-red-400 rounded-none text-sm'"
                :backgroundcolour="'bg-red-300'"
                :textcolour="'text-black'"
                @click.stop="removeGroup(i)"
                >Remove</Button
              >
            </MonitorGroupItem>
          </div>
          <!-- NOTE: refactor if users are supposed to join one or more groups -->
          <div v-if="user.groups.length === 0" class="flex flex-row justify-end mb-2 w-full">
            <SelectionBox
              id="select-group"
              v-model="groupSelected"
              :label="'Add a Group'"
              :options="groupOptionList"
              :reset-on-choose="true"
              class="w-full"
              @change="addGroupToUser"
            ></SelectionBox>
          </div>
        </div>
      </div>

      <div v-if="!editingGame">
        <div
          class="flex flex-col flex-grow py-2 text-left px-2 overflow-y-auto bg-slate-900 border border-slate-700 rounded p-4 m-4"
        >
          <!-- Games -->
          <h1 class="font-bold mb-3 text-white">Current game</h1>
          <p class="mb-6 text-xs text-gray-400">A user can only be paired to one Game at a time</p>
          <MonitorGameItem
            v-for="g in currentGames"
            :key="g._id"
            class="flex flex-row"
            :game="g"
            :edit="false"
            :selected-user="user"
            @gamedeleted="setEditingGame()"
          >
            <Button
              v-if="!editingGame"
              :id="`button-edit-player-${g._id}`"
              :childclass="'w-20 my-px rounded-none text-sm mr-4'"
              :bordercolour="'border-blue-400'"
              :backgroundcolour="'bg-blue-200'"
              :textcolour="'text-black'"
              @click.stop="setEditingGame(g)"
              >Edit</Button
            >
            <template #closeEdit>
              <Button
                v-if="editingGame"
                id="`button-doneediting"
                :backgroundcolour="'bg-white'"
                :textcolour="'text-black'"
                class="col-start-2 my-2 mr-2 border-blue-300"
                @click.stop="setEditingGame()"
                >Close</Button
              >
            </template>
          </MonitorGameItem>
          <Button
            class="w-full mt-3"
            :bordercolour="'border-blue-300'"
            :backgroundcolour="'bg-blue-200'"
            :textcolour="'text-black'"
            :childclass="'rounded-none'"
            @click.stop="newGame()"
            >Add new Game</Button
          >
        </div>

        <div
          class="flex flex-col flex-grow text-left overflow-y-auto bg-slate-900 border border-slate-700 rounded p-2 m-4 mb-11"
        >
          <!-- Games Pairing -->
          <h1 class="font-bold mb-3 text-white">Available Games to pair</h1>
          <div>
            <label class="block text-gray-400 text-xs font-bold mb-5">
              Search in all available games</label
            >
            <input
              v-model="gameSearchText"
              class="p-2 w-full rounded mb-3 border-2 text-sm"
              type="text"
              placeholder="Game name..."
            />
          </div>
          <div v-if="availableGames.length === 0" class="p-2 rounded bg-red-200 text-red-500">
            No further games available for the users' group
          </div>
          <MonitorGameItem
            v-for="(g, index) in availableGames"
            :key="g._id"
            class="flex flex-row w-full my-1"
            :game="g"
            :edit="false"
            :selected-user="user"
            @gamedeleted="setEditingGame()"
          >
            <div v-if="index === 0" class="w-1/2 text-center text-green-400 text-sm">
              recently added
            </div>
            <Button
              :id="`button-edit-player-${g._id}`"
              :childclass="'px-5 text-sm'"
              :backgroundcolour="'bg-blue-200'"
              :textcolour="'text-black'"
              :bordercolour="'border-blue-400'"
              class="rounded-none mr-4"
              @click.stop="setEditingGame(g)"
              >Edit</Button
            >
            <template #closeEdit>
              <Button
                v-if="editingGame"
                id="`button-doneediting"
                :backgroundcolour="'bg-white'"
                :textcolour="'text-black'"
                class="col-start-2 mr-2"
                @click.stop="setEditingGame()"
                >Close</Button
              >
            </template>
          </MonitorGameItem>
        </div>
      </div>
      <div
        v-else
        class="flex flex-col flex-grow py-2 text-left px-2 overflow-y-auto bg-slate-900 border border-slate-700 rounded p-4 m-4"
      >
        <MonitorGameItem
          :key="editingGame._id"
          class="flex flex-row w-full my-1"
          :game="editingGame"
          :edit="true"
          :selected-user="user"
          @gamedeleted="setEditingGame()"
        >
          <template #closeEdit>
            <Button
              id="`button-doneediting"
              :backgroundcolour="'bg-white'"
              :textcolour="'text-black'"
              class="col-start-2 mr-2"
              @click.stop="setEditingGame()"
              >Close</Button
            >
          </template>
        </MonitorGameItem>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, computed, Ref, onMounted } from 'vue'
import { USER_ROLE, LanguageNames } from '@/constants'
import { dateToFormattedString } from '@/utilities'
import { User, Group, Game } from '@/models/main'

import MonitorGameItem from '@/components/admin/MonitorGameItem.vue'
import MonitorGroupItem from '@/components/admin/MonitorGroupItem.vue'
import SelectionBox from '@/components/base/SelectionBox.vue'
import AnswerInput from '@/components/base/AnswerInput.vue'
import Button from '@/components/base/Button.vue'

import useAppStore from '@/store/useAppStore'
import useUserStore from '@/store/useUserStore'
import useGameStore from '@/store/useGameStore'
import useCMSStore from '@/store/useCMSStore'

interface RoleOptionListItem {
  itemName: string
  item: USER_ROLE
}
interface GroupOptionListItem {
  itemName: string
  item: Group
}

const { getters: appGetters, actions: appActions } = useAppStore()
const { getters: userGetters, actions: userActions } = useUserStore()
const { getters: gameGetters, actions: gameActions } = useGameStore()
const { actions: cmsActions } = useCMSStore()

const u = new User(userGetters.selectedUser.value)
const user = ref(u)
const validators: Ref<Record<string, boolean>> = ref({})
const groupSelected = ref({ item: new Group(), itemName: 'none selected' })
const editingParticipant: Ref<string> = ref('')
const editingGame: Ref<Game | undefined> = ref()
const password = ref('')
const searchText = ref('')
const gameSearchText = ref('')
const editPassword = ref(false)

const currentUserRole = ref({
  item: u.profile.role,
  itemName: u.profile.role
})

const showSave = ref(false)
const delaysDisabled = ref(false)
delaysDisabled.value = appGetters.disableDelays.value

onMounted(() => {
  groupSelected.value = { item: new Group(), itemName: 'none selected' } // force reset
  // Request data to populate the Monitor views
  userActions.getAllUsers().then(async () => {
    await userActions.getAllGroups()
    selectUser(userGetters.myUser.value)
  })
  cmsActions.getActivities(appGetters.languageCode.value)
})

const saveUser = async () => {
  return userActions.updateUser(user.value).then((user: User | undefined) => {
    if (user) {
      showSave.value = false
      selectUser(user)
    }
  })
}

const updatePassword = async () => {
  await userActions.updateUserPassword(password.value, user.value._id)
  password.value = ''
  editPassword.value = false
}

const hasAdminRole = computed(() =>
  userActions.hasMinimumRole(userGetters.myUser.value, USER_ROLE.monitor)
)
const hasLogsRole = computed(() =>
  userActions.hasMinimumRole(userGetters.myUser.value, USER_ROLE.logs)
)

const selectUser = (us: User) => {
  groupSelected.value = { item: new Group(), itemName: 'none selected' } // force reset
  userActions.selectUser(us)
  user.value = new User(userGetters.selectedUser.value)
  currentUserRole.value.item = user.value.profile.role
  currentUserRole.value.itemName = user.value.profile.role
  gameActions.getGames(undefined, us._id).then(() => {
    editingParticipant.value = ''
    resetSelectedGameValues()
  })
}

const resetSelectedGameValues = () => {
  editingGame.value = undefined
}

const lastLogin = computed(() => {
  if (userGetters.selectedUser.value.status.lastLogin)
    return dateToFormattedString(userGetters.selectedUser.value.status.lastLogin)
  else return 'Unknown'
})

const addUser = () => {
  groupSelected.value = { item: new Group(), itemName: 'none selected' } // force reset
  userActions.createUser().then((user) => {
    selectUser(user)
  })
}

const languageNames = Object.keys(LanguageNames)
const languageList = languageNames.map((v, i) => ({
  id: '' + i,
  title: v
}))

// -------------- Role -----------------
const roleOptionList = computed((): RoleOptionListItem[] => {
  const roles = Object.values(USER_ROLE)
    .filter(
      (r) =>
        hasLogsRole.value ||
        (hasAdminRole.value && r !== USER_ROLE.logs) ||
        (r !== USER_ROLE.logs && r !== USER_ROLE.admin)
    )
    .map((r) => ({ item: r, itemName: r }))
  return roles as RoleOptionListItem[]
})

const updateRole = async (role: RoleOptionListItem) => {
  user.value.profile.role = role.item
  await saveUser()
}

// -------------- Groups --------------

const groupOptionList = computed((): GroupOptionListItem[] => {
  return userGetters.allGroups.value
    .filter((g: Group) => !user.value.groups.some((up: Group) => up._id === g._id))
    .map((g: Group) => {
      return { item: g, itemName: g.name }
    })
})

const selectedGroups = computed(() => {
  return user.value.groups
})

const addGroupToUser = (value: GroupOptionListItem) => {
  user.value.groups.push(value.item)
  saveUser().then(() => userActions.syncUserGroupsToGamesPlayers(user.value))
}

const removeGroup = (index: number) => {
  user.value.groups.splice(index, 1)
  saveUser().then(() => userActions.syncUserGroupsToGamesPlayers(user.value))
}

const setEditingGame = (game?: Game) => {
  editingGame.value = game
}

const disableDelays = (value: boolean) => {
  appActions.setDisableDelays(value)
}

// ---------------- Games -------------------

const currentGames = computed(() => {
  return gameGetters.games.value.filter(
    (g: Game) => g.details.participants.includes(user.value._id) && !g.details.dyadSplit
  )
})

const availableGames = computed(() => {
  const searchFilter = (g: Game) => {
    const text = gameSearchText.value.toLowerCase()
    return gameSearchText.value.trim().length > 0
      ? g.profile.name.toLowerCase().includes(text) || g.profile.ref.toLowerCase().includes(text)
      : true
  }

  return gameGetters.games.value
    .filter(
      (g: Game) =>
        currentGames.value.findIndex((game: Game) => game._id === g._id) === -1 &&
        user.value.groups.some((group: Group) => g.sharing.groups.includes(group._id)) &&
        searchFilter(g)
    ) // filter out the walls that are already shown in 'currentGames' and restrict them to the users group
    .reverse() // show the latest one on top
})

const newGame = async () => {
  await gameActions.addGame(user.value)
  await gameActions.getGames(undefined, user.value._id)
}

// ------------  Initial data --------------

const filteredUsers = computed(() => {
  return userGetters.allUsers.value.filter((u: User) =>
    u.profile.username.toLowerCase().includes(searchText.value.toLowerCase())
  )
})
</script>

<style scoped></style>
