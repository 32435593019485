<!-- Copyright 2023 Richard Nesnass, Tom Bjarne Seidel

 This file is part of KMMP.

KMMP is free software: you can redistribute it and/or modify
 it under the terms of the GNU Affero General Public License as published by
 the Free Software Foundation, either version 3 of the License, or
 (at your option) any later version.

 GPL-3.0-only or GPL-3.0-or-later

KMMP is distributed in the hope that it will be useful,
 but WITHOUT ANY WARRANTY; without even the implied warranty of
 MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 GNU Affero General Public License for more details.

 You should have received a copy of the GNU Affero General Public License
 along with KMMP.  If not, see http://www.gnu.org/licenses/. -->
<template>
  <div
    class="m-2 relative transform ease-linear duration-75 rounded-xl border-4"
    :class="{
      'border-transparent': !selected,
      'scale-90': mouseIsDown,
    }"
    :style="{ backgroundColor: colour, borderColor: highlightColor }"
    @mousedown="mouseDown(true)"
    @mouseup="mouseDown(false)"
    @touchstart="mouseDown(true)"
    @touchend="mouseDown(false)"
  >
    <slot />
    <div
      v-if="selected && actionAllowed"
      class="checkmark absolute top-0 right-0 m-1 rounded-full p-2"
      :class="{ '-m-3': corners }"
      :style="{ backgroundColor: highlightColor }"
    >
      <img class="w-5 h-5 invert" alt="selected" :src="CheckIcon" />
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed, ref } from 'vue'

import CheckIcon from '@/assets/icons/fontawesome/circle-check-solid.svg'

const props = defineProps({
  selected: {
    type: Boolean,
    required: true,
  },
  colour: {
    type: String,
    required: false,
    default: '#ffffff',
  },
  borderColour: {
    type: String,
    required: false,
    default: '#5ccc61',
  },
  corners: {
    type: Boolean,
  },
  actionAllowed: {
    type: Boolean,
    default: true,
  },
})

const highlightColor = computed(() => {
  return props.selected ? (props.borderColour ? props.borderColour : '#5ccc61') : ''
})

const mouseIsDown = ref(false)

function mouseDown(down: boolean) {
  mouseIsDown.value = down
}
</script>

<style scoped></style>
