<!-- Copyright 2023 Richard Nesnass, Tom Bjarne Seidel

 This file is part of KMMP.

KMMP is free software: you can redistribute it and/or modify
 it under the terms of the GNU Affero General Public License as published by
 the Free Software Foundation, either version 3 of the License, or
 (at your option) any later version.

 GPL-3.0-only or GPL-3.0-or-later

KMMP is distributed in the hope that it will be useful,
 but WITHOUT ANY WARRANTY; without even the implied warranty of
 MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 GNU Affero General Public License for more details.

 You should have received a copy of the GNU Affero General Public License
 along with KMMP.  If not, see http://www.gnu.org/licenses/. -->
<template>
  <div
    ref="mapContainer"
    class="flex flex-col justify-center items-center mt-11"
    :style="randomBackground()"
  >
    <div class="flex flex-col justify-center items-center">
      <div
        v-for="(row, rowIndex) in state.raster"
        :key="rowIndex"
        class="flex flex-row w-full justify-center"
      >
        <div
          v-for="(cell, cellIndex) in row"
          :id="cell.word"
          :key="cellIndex"
          class="flex justify-center items-center flex-col w-full h-full"
          :class="cellClass(cell)"
          :style="cellStyles()"
          @click="giveAnswerOrPlaySound(cell)"
          @touchstart.prevent="giveAnswerOrPlaySound(cell)"
        >
          <img
            class="cellimage rounded"
            v-if="cell.image"
            :src="cell.image[0].url"
            :alt="cell.word"
          />
          <div>
            <p v-if="!wordsAreVisible">{{ cell.word }}</p>
          </div>
        </div>
      </div>
    </div>
    <Drawing ref="drawing" class="absolute pointer-events-none z-51 opacity-80" />
  </div>
</template>

<script setup lang="ts">
import { ref, PropType, reactive, onMounted, nextTick, computed } from 'vue'

import {
  DialogMessageType,
  TaskCallbackType,
  UserTaskRole,
  STSyncType,
  TaskSyncST,
  TaskCallback,
  TaskCallbackParam
} from '@/constants'
import { SolutionUnion, Image, LinkedWord, Word, STSolutionUnion } from '@/models/tasktypes'
import { Tasktype24st, Type24stOptions } from '@/models/tasktypes/Tasktype24st'
import useState from '@/composition/useState'
import { Choice, Tracking } from '@/models/main'

import Drawing from '@/components/task/Drawing.vue'
import useDialogStore from '@/composition/dialog'
import { createSound } from '@/api/audioService'
import moment from 'moment'

const dialog = useDialogStore()

const drawing = ref<InstanceType<typeof Drawing>>()
const mapContainer = ref<HTMLElement | null>()

const emit = defineEmits<{
  (e: 'completed', value: boolean, tracking: Tracking): void
  (e: 'addSync', taskId: string, solution: SolutionUnion, solutionId: string): void
  (e: 'addSTSync', type: STSyncType, taskId: string, solution: STSolutionUnion): void
  (e: 'registerCallback', type: TaskCallbackType, callback: TaskCallback): void
  (
    e: 'showResultMessage',
    messageType: DialogMessageType,
    solution: Image,
    callback: () => void
  ): void
}>()

const props = defineProps({
  task: { required: true, type: Object as PropType<Tasktype24st> },
  myIndex: { required: false, type: Number, default: 0 },
  actionAllowed: { required: true, type: Boolean },
  role: { required: true, type: String }
})

/* const messages = {
      no: {},
      sv: {},
      en: {},
    } */

const { getters: stateGetters, setters: stateSetters } = useState()

// We need a mutatable copy of results that we will submit to the store at Task completion
const tracking = new Tracking(stateGetters.tracking.value)
stateSetters.trackingData = tracking
let choiceTimer = new Date()
const roundData = { correct: 0, of: 0, phase: 1 }

const state = reactive({
  currentPath: [] as Type24stOptions[],
  correctPath: [] as Type24stOptions[],
  raster: [] as Type24stOptions[][],

  wordTimerHasElapsed: false
})

onMounted(() => {
  setStateValues(props.task.raster, props.task.words) // set values initially
  emit('registerCallback', TaskCallbackType.StudentTeacherAction, handleSTAction)

  // send initial path sync parcel after values have been set and callback was added
  if (props.role !== UserTaskRole.Student)
    emit('addSTSync', STSyncType.PathSync, props.task.id, state.raster)
  playInstructionsAndHideWords()
})

const wordsAreVisible = computed(() => {
  return props.role === UserTaskRole.Teacher ? props.task.showWords : state.wordTimerHasElapsed
})

// TODO: this needs to be delayed until the dialogManager has emptied the messages array...
const playInstructionsAndHideWords = () => {
  const words = state.raster.flat().filter((w) => w.hasValue)
  const duration = 4000
  let currentIndex = 0

  const wordAudioInterval = setInterval(async () => {
    const word = words[currentIndex]
    const wordAudio = await createSound(word.audioURL)
    if (wordAudio.audioBuffer) {
      wordAudio.play()
      word.isHighlighted = true
      wordAudio.onended = () => {
        word.isHighlighted = false
        if (currentIndex === words.length) {
          state.wordTimerHasElapsed = true
          clearInterval(wordAudioInterval)
        }
        currentIndex++
      }
    } else state.wordTimerHasElapsed = true
  }, duration) // hide words after 10 seconds
}

/**
 * handleSTAction()
 * triggered by teacher, student receives parcel and this function is invoked (by callback specified in Task.vue)
 * @param data
 */
const handleSTAction = (args: TaskCallbackParam) => {
  const data = args as TaskSyncST
  switch (data.type) {
    case STSyncType.PathSync:
      setStateValues(data.solution as Type24stOptions[][])
      showCompletePathForStudent()
      break
    case STSyncType.PathComplete:
      handleAnswerValues() // end task for other user (Student)
      break
  }
}

const showCompletePathForStudent = () => {
  nextTick(() => {
    for (const option of state.correctPath) giveAnswerOrPlaySound(option, true)
  })
}

const setStateValues = (raster?: Type24stOptions[][], words?: Type24stOptions[]) => {
  if (raster) state.raster = raster // raster can be re-set after shuffle
  if (words) state.correctPath = words.filter((w) => !w.isDistractor)
  // the correct path is the (already sorted by order) list of non-distractor words -> the path to enter
}

const giveAnswerOrPlaySound = async (option: Type24stOptions, override = false) => {
  // override allows to run this in the begining of the task for the student
  if (props.role === UserTaskRole.Teacher || override) {
    // only the teacher can act here...
    if (option.isDistractor) {
      option.borderRed = true
    } else {
      const currentStep = state.currentPath.length
      if (state.correctPath[currentStep].word === option.word) {
        state.currentPath.push(option)
        option.borderGreen = true
        option.borderRed = false
        if (currentStep > 0) {
          const word = {
            text: option.word,
            enabled: true,
            draggable: true,
            id: option.word,
            opacity: 1
          } as Word

          const startElement = getElementByWord(state.currentPath[currentStep - 1].word)
          const endElement = getElementByWord(state.currentPath[currentStep].word)

          if (startElement && endElement) {
            const startRect = startElement.getBoundingClientRect()
            const startRectX = startRect.right - (startRect.right - startRect.x / 2)
            const startRectY = startRect.bottom - (startRect.bottom - startRect.top / 2)

            const endRect = endElement.getBoundingClientRect()
            const endRectX = endRect.right - (endRect.right - endRect.x / 2)
            const endRectY = endRect.bottom - (endRect.bottom - endRect.top / 2)

            const linkedImage = {
              word: word,
              startX: startRectX,
              startY: startRectY,
              startElement: startElement,
              startElementId: startElement.id,
              endX: endRectX,
              endY: endRectY,
              endElement: endElement,
              endElementId: endElement.id
            } as LinkedWord
            if (drawing.value)
              drawing.value.message({ message: 'morfologiTaskLine', word: linkedImage })
          }

          if (
            state.currentPath.length === state.correctPath.length &&
            props.role === UserTaskRole.Teacher
          )
            handleAnswerValues()
        }
      } else {
        option.borderRed = true
      }
    }
  } else {
    // TODO: check if this is needed
    // for the student the sound of the current word is played
    const wordAudio = await createSound(option.audioURL)
    wordAudio.play()
  }
}

const handleAnswerValues = () => {
  const choice = new Choice()
  choice.duration = moment().diff(moment(choiceTimer), 'milliseconds')
  choice.round = 1
  choice.content = state.correctPath
    .map((i) => i)
    .map((j) => j.word)
    .join(';')
  choice.correct = true
  choice.response = state.correctPath.map((i) => i.word).join(';')
  choice.phase = roundData.phase
  choice.target = '' // TODO: How to determine this?
  tracking.choices.push(choice)
  stateSetters.trackingData = tracking
  choiceTimer = new Date()

  // TODO: add proper translation
  dialog.actions.pushMultiplayerMessage(DialogMessageType.ResultSplashCorrect, 'Correct')
  roundData.correct++
  emit('completed', true, tracking) // finish task
  if (props.role === UserTaskRole.Teacher)
    emit('addSTSync', STSyncType.PathComplete, props.task.id, state.raster)
}

const getElementByWord = (word: string) => {
  return document.getElementById(word)
}

const randomBackground = () => {
  const backgrounds = ['white', 'blue', 'red'] // TODO: populate with imported images
  return `background: ${backgrounds[Math.floor(Math.random() * backgrounds.length)]};`
}

const cellClass = (cell: Type24stOptions) => {
  let result = cell.isHighlighted ? 'scale-150' : ''
  if (cell.borderGreen) result += ' border-green-400 border-4'
  if (cell.borderRed) result += ' border-red-400 border-4'
  if (cell.hasValue) result += ' bg-white rounded'
  if (cell.isStartNode && wordsAreVisible.value) result += 'p-4 bg-blue-300'
  if (cell.isEndNode && wordsAreVisible.value) result += 'p-4 bg-yellow-300'
  return result
}

const cellStyles = () => {
  if (mapContainer.value) {
    const height = mapContainer.value.getBoundingClientRect().height
    const padding = 1 // additional space in squares (height)
    const cellSize = height / (props.task.mapsize + padding)
    const margin = (height / props.task.mapsize - cellSize) / 4
    return `height: ${cellSize}px; width: ${cellSize}px; margin: ${margin}px; `
  }
}
</script>

<style scoped>
.cellimage {
  max-height: 70%;
}
</style>
