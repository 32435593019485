<template>
  <img :src="assembledAvatarFileName(props.avatarRef)" :alt="props.avatarRef" />
</template>

<script setup lang="ts">
const props = defineProps({
  avatarRef: { type: String, required: true },
})

function assembledAvatarFileName(ref: string) {
  return new URL(`../../assets/icons/avatar/${ref}`, import.meta.url).toString()
}
</script>

<style scoped></style>
