<template>
  <div class="popup fadein absolute h-full w-full z-50 flex justify-center items-center bg-slate-translucent top-0 left-0">
    <div class="popup-wrapper bg-white-alt z-50 w-1/3 max-w-md flex flex-row-reverse rounded-xl">
      <div class="flex flex-col justify-between w-full h-full px-9 py-9 font-thin rounded-xl mt-3">
        <p class="text-2xl font-bold text-slate-white mb-5">{{ t('headline') }}</p>
        <Notice :type="DialogMessageType.Error" :text="props.text" />
        <div class="pt-5 flex justify-between items-center w-full">
          <Button class="mr-20" :backgroundcolour="''" :bordercolour="'border-blue-600'" :textcolour="'text-blue-600'" @click="close()">{{
            t('back')
          }}</Button>
          <Button :backgroundcolour="''" :bordercolour="'border-red-600'" :textcolour="'text-red-600'" @click="confirm()">{{ t('confirm') }}</Button>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useI18n } from 'vue-i18n'

import { Game } from '@/models/main'
import { DialogMessageType } from '@/constants'

import Notice from '@/components/dialog/Notice.vue'
import Button from '@/components/base/Button.vue'

const messages = {
  no: {
    headline: 'Er du sikker å gå videre?',
  },
  en: {
    headline: 'Are you sure to proceed?',
  },
}

const emit = defineEmits<{
    (e: 'closePopup'): void
    (e: 'confirm', game?: Game): void
  }>()

const { t } = useI18n({ messages })

const props = defineProps({
  text: { type: String, required: true },
})

function close() {
  emit('closePopup')
}

function confirm() {
  emit('confirm')
  close()
}
</script>

<style scoped></style>
