import gql from 'graphql-tag'
import { ExternalSquidexData, LanguageCodes, TASK_TYPES } from '@/constants'
import { Session } from '../navigationModels'
import { LC, CommonTaskAttributes, TasktypeData, Tasktype } from '../questionModels'

export const Tasktype24STQuery = gql`
  fragment Tasktype24st on Tasktype24st {
    __typename
    id
    data {
      name {
        __language
      }
      mapsize {
        __language
      }
      words {
        __language {
          word
          wordAudio {
            url
            slug
          }
          image {
            url
          }
          order
          isDistractor
        }
      }
      randomstartlocation {
        iv
      }
      showWords {
        iv
      }
    }
  }
`
// TODO: does this need suffix/prefix and more of the 'common' task details?
interface Type24STAttributes {
  name: LC<string>
  mapsize: LC<number>
  words: LC<
    {
      word: string
      wordAudio: ExternalSquidexData[]
      image: ExternalSquidexData[]
      order: number
      isDistractor: boolean
    }[]
  >
  randomstartlocation: boolean
  showWords: boolean
}

export interface Type24stOptions {
  hasValue: boolean
  word: string
  wordAudio: ExternalSquidexData[]
  audioURL: string
  image: ExternalSquidexData[]
  imageURL: string
  order: number
  isDistractor: boolean
  borderGreen: boolean
  borderRed: boolean
  isStartNode?: boolean
  isEndNode?: boolean
  isHighlighted?: boolean
}

// NOTE: this does not extend TasktypeData because the old fields are simply not needed
export interface Tasktype24STData extends TasktypeData {
  data: CommonTaskAttributes & Type24STAttributes
}

export class Tasktype24st extends Tasktype {
  name = ''
  mapsize = 6 // default
  words: Type24stOptions[] = []
  raster: Type24stOptions[][] = []
  randomstartlocation = false
  showWords = false

  constructor(spec: Tasktype24STData, language: LanguageCodes, parent?: Session) {
    super(spec, language, parent)

    this.type = TASK_TYPES.Tasktype24st
    this.name = spec.data.name[language] || 'no name'
    this.mapsize = spec.data.mapsize[language] || 6

    spec.data.words[language]?.forEach((w, index) =>
      this.words.push({
        hasValue: true,
        word: w.word,
        wordAudio: w.wordAudio,
        audioURL: w.wordAudio[0]?.url + w.wordAudio[0]?.slug || '',
        image: w.image,
        imageURL: w.image[0]?.url + w.image[0]?.slug || '',
        order: w.order || index,
        isDistractor: w.isDistractor,
        borderGreen: false,
        borderRed: false,
        isStartNode: false,
        isEndNode: false,
        isHighlighted: false
      })
    )

    this.words = this.words
      .filter((word: Type24stOptions) => !word.isDistractor)
      .sort((p, c) => {
        return p.order - c.order
      })

    const emptyCell: Type24stOptions = {
      hasValue: false,
      word: '',
      wordAudio: [
        {
          url: '',
          slug: ''
        }
      ],
      audioURL: '',
      image: [
        {
          url: '',
          slug: ''
        }
      ],
      imageURL: '',
      order: 0,
      isDistractor: false,
      borderGreen: false,
      borderRed: false
    }

    this.raster = Array(this.mapsize)
      .fill(emptyCell)
      .map(() => Array(this.mapsize).fill(emptyCell))

    this.createMap(spec.data.randomstartlocation)
    this.showWords = spec.data.showWords
    this.randomstartlocation = spec.data.randomstartlocation
  }

  createMap(randomstartlocation: boolean) {
    const randomSlot = randomstartlocation ? Math.floor(Math.random() * 3) + 1 : 2 // default to bottom left as start if set to false
    const wordsToAllocate = Array.from(this.words).sort((a, b) => a.order - b.order) // sort by order attribute to ensure right order...
    const startNode = wordsToAllocate.shift()
    const endNode = wordsToAllocate.pop()

    if (startNode && endNode && !endNode.isDistractor && !startNode.isDistractor) {
      this.assignStartAndEndNode(randomSlot, startNode, endNode)
      // assuming the start node is in the first row of the raster
      const randomstartdirection = Math.floor(Math.random()) + 1 // 0 means we start from the bottom and need to increase the row count; 1 means the opposite - the rownumber is decreased
      let currentRow = randomstartdirection ? 1 : this.mapsize - 2 // prevent currentRow from being on an "edge" (either top or bottom one)
      for (const word of wordsToAllocate) {
        if (!word.isDistractor) {
          if (randomstartdirection) {
            // we have to work our way "down" the raster (rows)
            currentRow++ // row index is not proceeded if word is a distractor
          } else {
            // we have to work our way "up" the raster (rows)
            currentRow--
          }
        }
        let emptySlotFound = false
        while (!emptySlotFound) {
          const randomSlotInRow = Math.floor(Math.random() * this.mapsize)
          if (
            randomSlotInRow > 0 &&
            randomSlotInRow < this.mapsize - 1 &&
            !this.raster[currentRow][randomSlotInRow].hasValue
          ) {
            this.raster[currentRow][randomSlotInRow] = word
            emptySlotFound = true
          }
        }
      }
    } else throw Error('Invalid settings, map could not be created')
    console.log(this.raster)
  }

  assignStartAndEndNode(randomSlot: number, startNode: Type24stOptions, endNode: Type24stOptions) {
    // randomslot 0/2 -> left edge
    // randomslot 1/3 -> right edge
    startNode.isStartNode = true
    endNode.isEndNode = true
    const randomSlotForEdges = Math.floor(Math.random() * this.mapsize - 1) + 1
    if (randomSlot % 2 === 0) {
      // left edge
      this.raster[0][randomSlotForEdges] = startNode // random slot along left edge of the map raster
      this.raster[this.mapsize - 1][randomSlotForEdges] = endNode // random slot along left edge of the map raster
    } else {
      // right edge
      this.raster[this.mapsize - 1][randomSlotForEdges] = startNode // random slot along left edge of the map raster
      this.raster[0][randomSlotForEdges] = endNode // random slot along left edge of the map raster
    }
    /*

    switch (randomSlot) {
      case 0:
        this.raster[0][randomSlotForEdges] = startNode // top left
        this.raster[this.mapsize - 1][this.mapsize - 1] = endNode // bottom right
        break
      case 1:
        this.raster[0][this.mapsize - 1] = startNode // top right
        this.raster[this.mapsize - 1][0] = endNode // bottom left
        break
      case 2: // bottom right
        this.raster[this.mapsize - 1][0] = startNode // bottom left
        this.raster[0][this.mapsize - 1] = endNode // top right
        break
      case 3: // top right
        this.raster[this.mapsize - 1][this.mapsize - 1] = startNode // bottom right
        this.raster[0][0] = endNode // top left
        break
    }
    */
  }

  get assetList(): string[] {
    const list: string[] = []
    this.words.forEach((w) => {
      if (w.audioURL) list.push(w.audioURL)
      if (w.imageURL) list.push(w.imageURL)
    })
    return list
  }
}
