<!-- Copyright 2023 Richard Nesnass, Tom Bjarne Seidel

 This file is part of SL+.

 SL+ is free software: you can redistribute it and/or modify
 it under the terms of the GNU Affero General Public License as published by
 the Free Software Foundation, either version 3 of the License, or
 (at your option) any later version.

 GPL-3.0-only or GPL-3.0-or-later

 SL+ is distributed in the hope that it will be useful,
 but WITHOUT ANY WARRANTY; without even the implied warranty of
 MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 GNU Affero General Public License for more details.

 You should have received a copy of the GNU Affero General Public License
 along with SL+.  If not, see http://www.gnu.org/licenses/. -->
<template>
  <div class="taskNoUserSelect rounded-full flex justify-start items-center" @click="clickSpeaker()">
    <img class="w-12 p-1 invert" :class="speakerStyles" src="@/assets/icons/fontawesome/volume-solid.svg" />
  </div>
</template>

<script setup lang="ts">
import useState from '@/composition/useState'
import { computed } from 'vue'

const { actions, getters } = useState()

const speakerStyles = computed(() => {
  return !getters.speakerIsPlaying ? 'animate-pulse' : ''
})

const clickSpeaker = () => {
  actions.speakerClick()
}
</script>

<style lang="postcss"></style>
