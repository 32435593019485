<!-- Copyright 2023 Richard Nesnass, Tom Bjarne Seidel

 This file is part of KMMP.

KMMP is free software: you can redistribute it and/or modify
 it under the terms of the GNU Affero General Public License as published by
 the Free Software Foundation, either version 3 of the License, or
 (at your option) any later version.

 GPL-3.0-only or GPL-3.0-or-later

KMMP is distributed in the hope that it will be useful,
 but WITHOUT ANY WARRANTY; without even the implied warranty of
 MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 GNU Affero General Public License for more details.

 You should have received a copy of the GNU Affero General Public License
 along with KMMP.  If not, see http://www.gnu.org/licenses/. -->
<template>
  <div class="flex flex-col">
    <label v-if="label" class="block tracking-wide text-gray-400 text-xs font-bold mb-2" :for="elementId">
      {{ label }}
    </label>

    <select
      :id="elementId"
      class="block w-full py-2 px-3 border border-gray-300 bg-white shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
      :value="displayValue.itemName"
      @change="updateSelection"
    >
      <option value="" disabled>Choose..</option>
      <option v-for="(o, i) in options" :key="i" :value="o.itemName">
        {{ o.itemName }}
      </option>
    </select>
  </div>
</template>

<script setup lang="ts">
import { ref, watch, toRefs, PropType, nextTick } from 'vue'
  interface OptionItem {
    item: unknown
    itemName: string
  }
const emit = defineEmits(['change', 'update:modelValue'])
const props = defineProps({
  label: {
    type: String,
    default: '',
  },
  modelValue: {
    type: Object as PropType<OptionItem>,
    required: true,
  },
  id: {
    type: String,
    default: '',
  },
  options: {
    // SelectionBox should take options as: [{ item: any, itemName: string }]
    type: Object as PropType<OptionItem[]>,
    required: true,
  },
  resetOnChoose: {
    type: Boolean,
    default: false,
  },
})
const { modelValue, id } = toRefs(props)
const displayValue = ref(modelValue.value)
const elementId = id.value ? id.value : 'select-' + Math.floor(Math.random() * 10000000)

watch(
  () => props.modelValue,
  (newValue) => {
    displayValue.value = newValue
  },
)

const updateSelection = ($event: Event) => {
  const ie = $event.target as HTMLSelectElement
  const selection: OptionItem | undefined = props.options.find((o) => o.itemName === ie.value)
  if (selection) {
    displayValue.value = selection
    nextTick(() => {
      emit('change', selection)
    })
    emit('update:modelValue', selection)
    if (props.resetOnChoose) {
      displayValue.value = { item: undefined, itemName: '' }
    }
  }
}
</script>

<style scoped>
  select {
    -webkit-appearance: menu-item;
    -moz-appearance: menu-item;
    appearance: menu-item;
    height: 3em;
  }
</style>
