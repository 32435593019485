import gql from 'graphql-tag'
import { LanguageCodes, TASK_TYPES } from '@/constants'
import { Session } from '../navigationModels'
import { LC, CommonTaskAttributes, TasktypeData, Tasktype } from '../questionModels'

export const Tasktype12Query = gql`
  fragment Tasktype12 on Tasktype12 {
    __typename
    id
    data {
      slug {
        iv
      }
      reference {
        iv
      }
      morph {
        __language
      }
      stem {
        __language
      }
      morphedStem {
        __language
      }
      affix {
        iv
      }
      category {
        iv
      }
      audioDone {
        iv
      }
      # specific attibutes start here..
      word {
        __language
      }
      introductionAudio {
        __language {
          url
          slug
        }
      }
    }
  }
`

interface Type12Attributes {
  word: LC<string>
  introductionAudio: LC<{ url: string; slug: string }[]>
}
export interface Tasktype12Data extends TasktypeData {
  data: CommonTaskAttributes & Type12Attributes
}
export class Tasktype12 extends Tasktype {
  word = ''
  introductionAudio = '' // URL

  constructor(spec: Tasktype12Data, language: LanguageCodes, parent?: Session) {
    super(spec, language, parent)
    this.type = TASK_TYPES.Tasktype12
    this.parent = parent

    this.word = spec.data.word[language] || ''
    const ia = spec.data.introductionAudio[language]
    this.introductionAudio = ia ? ia[0].url + ia[0]?.slug : ''
  }

  get assetList(): string[] {
    const list: string[] = [this.introductionAudio]
    return list
  }
}
