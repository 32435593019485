import gql from 'graphql-tag'
import { LanguageCodes, TASK_TYPES } from '@/constants'
import { Session } from '../navigationModels'
import { LC, CommonTaskAttributes, TasktypeData, Tasktype } from '../questionModels'

export const Tasktype11stQuery = gql`
  fragment Tasktype11st on Tasktype11st {
    __typename
    id
    data {
      slug {
        iv
      }
      reference {
        iv
      }
      morph {
        __language
      }
      stem {
        __language
      }
      morphedStem {
        __language
      }
      affix {
        iv
      }
      category {
        iv
      }
      audioDone {
        iv
      }
      # specific attibutes start here..
      word {
        __language
      }
      audio {
        __language {
          url
          slug
        }
      }
      instructionAudio {
        __language {
          url
          slug
        }
      }
      unforgiving {
        iv
      }
      images {
        __language {
          file {
            url
            slug
            fileName
          }
          correct
        }
      }
    }
  }
`

interface Type11Attributes {
  word: LC<string>
  audio: LC<{ url: string; slug: string }[]>
  instructionAudio: LC<{ url: string; slug: string }[]>
  unforgiving: LC<boolean> // iv
  images: LC<
    {
      file: { url: string; slug: string; fileName: string }[]
      correct: boolean
    }[]
  >
}
export interface Tasktype11stData extends TasktypeData {
  data: CommonTaskAttributes & Type11Attributes
}

export class Tasktype11st extends Tasktype {
  word = ''
  audio = '' // URL
  instructionAudio = '' // URL
  unforgiving = false
  images: {
    file: string // URL
    name: string
    filename: string
    correct: boolean
  }[] = []

  constructor(spec: Tasktype11stData, language: LanguageCodes, parent?: Session) {
    super(spec, language, parent)
    this.type = TASK_TYPES.Tasktype11st
    this.parent = parent

    this.word = spec.data.word[language] || 'undefined'
    const a = spec.data.audio[language]
    if (a) this.audio = a[0]?.url + a[0]?.slug || ''
    const ia = spec.data.instructionAudio[language]
    if (ia) this.instructionAudio = ia[0]?.url + ia[0]?.slug || ''
    this.unforgiving = spec.data.unforgiving.iv || false
    spec.data.images[language]?.forEach((i) =>
      this.images.push({
        name: i.file[0]?.fileName || '(unknown)',
        file: i.file[0]?.url + i.file[0]?.slug || '',
        correct: i.correct,
        filename: i.file[0]?.slug || ''
      })
    )
  }

  get assetList(): string[] {
    const list: string[] = []
    if (this.audio) list.push(this.audio)
    if (this.instructionAudio) list.push(this.instructionAudio)
    this.images.forEach((w) => {
      if (w.file) list.push(w.file)
    })
    return list
  }
}
