<!-- Copyright 2023 Richard Nesnass, Tom Bjarne Seidel

 This file is part of SL+.

 SL+ is free software: you can redistribute it and/or modify
 it under the terms of the GNU Affero General Public License as published by
 the Free Software Foundation, either version 3 of the License, or
 (at your option) any later version.

 GPL-3.0-only or GPL-3.0-or-later

 SL+ is distributed in the hope that it will be useful,
 but WITHOUT ANY WARRANTY; without even the implied warranty of
 MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 GNU Affero General Public License for more details.

 You should have received a copy of the GNU Affero General Public License
 along with SL+.  If not, see http://www.gnu.org/licenses/. -->
<template>
  <div class="flex w-1/2 my-2 items-center">
    <div class="pointer-events-none p-1.5 border-2 border-slate-600 mr-1 battery-background">
      <img src="@/assets/images/bar/stardust.png" :style="{ width: getWidth }" class="transition-width ease-linear duration-1000 h-9" />
    </div>
    <div class="h-9 w-6 border-slate-600 border-2"></div>
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue'

import useState from '@/composition/useState'
const { getters } = useState()

const props = defineProps({
  ship: { type: Boolean, default: false },
})

const getWidth = computed(() => {
  const base = props.ship ? getters.progress.value.shipBarData.completedPercent : getters.progress.value.barData.completedPercent
  return Math.floor(base) + '%'
})
</script>

<style scoped>
  .battery-background {
    background-clip: content-box;
    background-size: 10px;
    background-image: linear-gradient(to right, var(--bg-slate-bright) 2px, transparent 2px);
  }
</style>
