<!-- Copyright 2020 Richard Nesnass, Tom Bjarne Seidel

 This file is part of SL+.

 SL+ is free software: you can redistribute it and/or modify
 it under the terms of the GNU Affero General Public License as published by
 the Free Software Foundation, either version 3 of the License, or
 (at your option) any later version.

 GPL-3.0-only or GPL-3.0-or-later

 SL+ is distributed in the hope that it will be useful,
 but WITHOUT ANY WARRANTY; without even the implied warranty of
 MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 GNU Affero General Public License for more details.

 You should have received a copy of the GNU Affero General Public License
 along with SL+.  If not, see http://www.gnu.org/licenses/. -->

<template>
  <div class="fadeInOut w-full h-full relative bg-cover bg-no-repeat" :style="backGroundStyles">
    <div
      v-if="
        !isMobileApp &&
        (canSeeMonitor || disableDelays) &&
        !router.currentRoute.value.fullPath.includes('/monitor')
      "
      class="absolute top-0 right-20 text-xs flex flex-row z-50 m-1 mr-24 pointer-events-none"
    >
      <div v-if="false" class="flex flex-col">
        <button
          id="button-monitor"
          class="bg-black text-green-400 rounded-sm p-2 pointer-events-auto"
          @click="monitor()"
        >
          Monitor
        </button>
        <button
          id="button-pairing"
          class="bg-black text-green-400 rounded-sm p-2 pointer-events-auto"
          @click="pairing()"
        >
          Pairing
        </button>
        <p v-if="disableDelays" class="text-red-400">Delays & locks disabled</p>
      </div>

      <!-- Debug feature: Show StateVariables on app screen at all times.
      <div v-if="disableDelays" class="text-red-600 text-xs flex flex-row place-items-center">
        <p class="bg-black bg-opacity-50 p-1 text-yellow-300 ml-2 max-w-md">
          <template v-for="(s, i) in stateVars" :key="`sVar-${i}`">
            <span>{{ s }}</span>
            <br />
          </template>
        </p>
      </div>
      -->
    </div>

    <div v-if="disableDelays" class="absolute left-0 top-0 p-3 z-101 text-xs">
      <p
        class="cursor-pointer bg-red-200 bg-opacity-75 rounded-sm m-2 p-1 text-center text-white"
        @click="state.variablesAreVisible = !state.variablesAreVisible"
      >
        show/hide vars
      </p>
      <p
        class="cursor-pointer bg-blue-200 bg-opacity-75 rounded-sm m-2 p-1 text-center text-white"
        @click="state.trackingVisible = !state.trackingVisible"
      >
        show/hide tracking
      </p>
      <div
        v-if="state.variablesAreVisible"
        class="mt-7 flex flex-col w-96 h-full bg-red-200 bg-opacity-75 rounded-sm p-1"
      >
        <div class="flex flex-col">
          <p>Participant information: {{ multiplayer.getters.participantInformation }}</p>
          <p>Ready state: {{ multiplayer.getters.participantReady }}</p>

          <p>Role: {{ multiplayer.getters.currentRole }}</p>
          <p>User id: {{ userStore.getters.myUser.value._id }}</p>
          <p>Current affix: {{ cmsStore.getters.selectedTask.value?.morph }}</p>
          <p>First decision: {{ multiplayer.getters.firstDecision }}</p>
          <p>Advice: {{ multiplayer.getters.advice }}</p>
          <p>Final decision: {{ multiplayer.getters.finalDecision }}</p>

          <p>Mode: {{ multiplayer.getters.mode }}</p>
          <p>Leader affixes: {{ multiplayer.getters.leaderAffixes }}</p>
          <p>Episode id: {{ cmsStore.getters.selectedEpisode.value?.id }}</p>
          <p>
            Session ids {{ cmsStore.getters.selectedCollection.value?.sessions.map((s) => s.id) }}
          </p>
          <p>Task ids: {{ cmsStore.getters.selectedTaskSet.value?.map((s) => s.id) }}</p>
          <p>Task item ids: {{ multiplayer.getters.shuffleOrder }}</p>
        </div>
      </div>
      <div
        v-if="state.trackingVisible"
        class="mt-7 flex flex-col h-full max-h-screen pb-20 bg-blue-200 bg-opacity-75 rounded-sm p-1"
      >
        <div class="flex flex-col mt-4 overflow-y-auto w-full">
          <pre>{{ formattedTracking }}</pre>
        </div>
      </div>
    </div>

    <!-- App -->
    <div class="bg-slate-middle absolute flex flex-col text-center items-center w-full h-full">
      <DialogManager data-html2canvas-ignore="true" />
      <!-- Router view -->
      <router-view />
      <div class="absolute bottom-1 left-1">
        <p class="text-white opacity-75 text-xs font-mono">{{ feedback }}</p>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed, onMounted, reactive } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { useI18n } from 'vue-i18n'

import {
  DialogMessageType,
  FinalDecisionStatus,
  ParcelType,
  ParticipantInformation,
  USER_ROLE,
  FinalDecisionStatusData,
  TaskShuffleSync,
  TaskSyncST,
  ParticipantReadyState,
  TASK_TYPES
} from '@/constants'
import { Game, IParcel, Parcel, SESSION_TYPE, TaskSync } from './models/main'

import useDialogStore from '@/composition/dialog'
import useParcelStore from '@/composition/parcel'
import useStateService from '@/composition/useState'
import useMultiPlayerState from '@/composition/useMultiplayerState'

import useAppStore from './store/useAppStore'
import useUserStore from './store/useUserStore'
import useGameStore from './store/useGameStore'
import useCMSStore from './store/useCMSStore'

import DialogManager from '@/components/dialog/DialogManager.vue'

const userStore = useUserStore()
const appStore = useAppStore()
const parcelStore = useParcelStore()
const dialogStore = useDialogStore()
const gameStore = useGameStore()
const multiplayer = useMultiPlayerState()
const cmsStore = useCMSStore()

const stateService = useStateService()
const disableDelays = appStore.getters.disableDelays
const isMobileApp = appStore.getters.status.value.isMobileApp

const router = useRouter()
const route = useRoute()

const monitor = () => router.push('/monitor')
const pairing = () => multiplayer.actions.setParticipantReady()

const messages = {
  no: {
    gamejoined: 'ble med i spillet',
    gameinvited: 'og inviterte deg å være med',
    lobbyleft: 'forlot lobbyen',
    wallshared3: 'with you',
    walltemplate1: 'enabled the template mode for wall',
    walltemplate2: 'for you',
    walltemplate3: 'disabled the template mode for wall',
    wallpermission1: 'changed your permission on wall',
    wallremove1: 'removed your access rights to wall',
    walldelete1: 'deleted the wall',
    walldelete2: 'deleted a wall you are part of',
    boardcreated1: 'A new board was created',
    boardcreated2: 'has joined the wall',
    boardcreated3: 'Someone has joined the wall on board',
    logoff: 'Forced logoff',
    wallleave1: 'has left the wall',
    wallleave2: 'Someone has left the wall',
    waitingToProceed: 'is waiting for you to proceed to the next task'
  },
  en: {
    gamejoined: 'joined the game',
    gameinvited: 'and invited you to play',
    lobbyleft: 'left the lobby',
    wallshared3: 'with you',
    walltemplate1: 'enabled the template mode for wall',
    walltemplate2: 'for you',
    walltemplate3: 'disabled the template mode for wall',
    wallpermission1: 'changed your permission on wall',
    wallremove1: 'removed your access rights to wall',
    walldelete1: 'deleted the wall',
    walldelete2: 'deleted a wall you are part of',
    boardcreated1: 'A new board was created',
    boardcreated2: 'has joined the wall',
    boardcreated3: 'Someone has joined the wall on board',
    logoff: 'Forced logoff',
    wallleave1: 'has left the wall',
    wallleave2: 'Someone has left the wall',
    waitingToProceed: 'is waiting for you to proceed to the next task'
  }
}

const { t } = useI18n({ messages })

onMounted(() => {
  parcelStore.actions.setNewMessageCallback(receiveParcel)
})

const state = reactive({
  gameId: '',
  variablesAreVisible: false,
  trackingVisible: false
})

const backGroundStyles = computed(() => {
  return `background-image: url(${stateService.getters.backgroundImage.value})`
})

const canSeeMonitor = computed(() => {
  const hasRights =
    userStore.getters.myUser.value.profile.role === USER_ROLE.monitor ||
    userStore.getters.myUser.value.profile.role === USER_ROLE.admin ||
    userStore.getters.myUser.value.profile.role === USER_ROLE.logs
  return hasRights && !router.currentRoute.value.path.includes('monitor')
})

appStore.actions.detectOldApp()

const stateVars = computed(() => {
  return Object.entries(stateService.getters.state.value).map((e) => `${e[0]}: ${e[1]}`)
})

const formattedTracking = computed(() => {
  return stateService.getters.trackingData.value.asPOJO()
})

// Updates to our data initiated by other users are managed here
const receiveParcel = async (topic: string, payload: ArrayBuffer): Promise<void> => {
  const parcelData: IParcel = JSON.parse(payload.toString())
  const parcel = new Parcel(parcelData)
  const lastSentParcelId = parcelStore.getters.lastSentParcel.value?._id || '_'
  let body,
    message = ''
  if (
    lastSentParcelId !== parcel._id &&
    parcel.subscription.user.id !== userStore.getters.myUser.value._id
  ) {
    // prevent user from receiving their own parcels again...
    console.log(
      `Incoming Parcel ${topic}: ${parcel._id}: ${parcel.parcelType} from ${parcel.subscription.user.username}`
    )
    switch (parcel.parcelType) {
      // pairing topic
      case ParcelType.AudioSnippet:
        body = parcel.body as string // audio file information
        fetch(body).then(async (res) => {
          const convertedBlob = await res.blob() // base64 to blob
          multiplayer.actions.setIncomingAudio(convertedBlob)
        })
        break

      //  Used in the initial Lobby connection phase
      case ParcelType.UserConnect:
        body = parcel.body as ParticipantInformation
        if (!body.name.includes('initial')) {
          // prevent proceeding if something went wrong during session setup communication
          pairing() // update ParticipantReadyState
          if (
            multiplayer.getters.participantReady.value === ParticipantReadyState.Initiated &&
            parcel.subscription.game_id
          ) {
            multiplayer.actions.setParticipantInformation(body)
            if (!route.fullPath.includes('game/')) {
              // being here means the participant has not sent UserConnect parcel yet
              state.gameId = parcel.subscription.game_id
              message = `${parcel.subscription.user.username} ${t('gamejoined')} '${getNameForGameById()}' ${t('gameinvited')}`
              const isMultiplayerGame =
                cmsStore.getters.selectedSession.value?.type !== SESSION_TYPE.singlePlayer
              if (isMultiplayerGame) {
                dialogStore.actions.pushMessage(
                  DialogMessageType.Confirmation,
                  message,
                  15000,
                  joinGameLobby
                )
              }
            } else {
              state.gameId = parcel.subscription.game_id
              message = `${parcel.subscription.user.username} ${t('gamejoined')} '${getNameForGameById()}'`
              dialogStore.actions.pushMessage(DialogMessageType.Confirmation, message)
            }
          }
        }
        break

      case ParcelType.UserDisconnect:
        multiplayer.actions.reset()
        message = `${parcel.subscription.user.username} ${route.fullPath.includes('dashboard') ? t('lobbyleft') : t('gameleft')}`
        dialogStore.actions.pushMessage(DialogMessageType.Information, message, 5000, () =>
          router.push('/dashboard')
        )
        break

      // Used when the previous Session was SP and the next is MP or ST
      case ParcelType.UserReconnect:
        multiplayer.actions.setParticipantReadyToProceed()
        message = `${parcel.subscription.user.username} ${t('waitingToProceed')}`
        //dialogStore.actions.pushMessage(DialogMessageType.Information, message) NOTE: disabled for testing purposes
        break

      case ParcelType.FinalDecisionStatus:
        body = parcel.body as FinalDecisionStatusData

        if (cmsStore.getters.selectedTask.value?.type !== TASK_TYPES.Tasktype3mp) {
          // do not show task type 3 dialog here... it's special due to it's binary choice
          message = `${parcel.subscription.user.username} ${
            t('finaldecision1') +
            ' ' +
            (body.status === FinalDecisionStatus.Accepted ? t('accepted') : t('rejected')) +
            ' ' +
            t('finaldecision2')
          }`
          // display the rejected message only of no solution dialog is invoked (which is only the case for FinalDecisionStatus.Accepted)
          if (body.status === FinalDecisionStatus.Rejected)
            dialogStore.actions.pushMultiplayerMessage(DialogMessageType.Information, message, 3500)
        }
        multiplayer.actions.setFinalDecisionStatus(body.status)
        break

      case ParcelType.TaskSyncST:
        body = parcel.body as TaskSyncST
        multiplayer.actions.addSTSync(body) // this value is re-set each time and can represent a number of different data (see STSolutionUnion and the task types)
        break

      case ParcelType.ScreenState:
        body = parcel.body as string // audio file information
        fetch(body).then(async (res) => {
          const convertedBlob = await res.blob() // base64 to blob
          multiplayer.actions.addStudentScreen(convertedBlob)
        })
        break

      case ParcelType.TaskProceed:
        body = parseInt(parcel.body as string)
        multiplayer.actions.setAdvisorTaskStatus(body)
        break

      case ParcelType.TaskSync:
        body = parcel.body as TaskSync
        multiplayer.actions.addSync(body)
        break

      case ParcelType.TaskShuffleSync:
        body = parcel.body as TaskShuffleSync
        multiplayer.actions.setShuffleOrder(body.shuffleOrder)
        break

      // Sent when a Monitor (Admin) changes pairing. This causes the users (if connected) to reload their game
      case ParcelType.PairingUpdate:
        if (route.fullPath.includes('dashboard')) {
          if (gameStore.getters.selectedGame.value) {
            parcelStore.actions.unSubscribeTopic(gameStore.getters.selectedGame.value?._id)
          }
          gameStore.actions.getGames().then(() => {
            const newGame = gameStore.getters.games.value.filter(
              (g: Game) =>
                g.details.participants.includes(userStore.getters.myUser.value._id) &&
                !g.details.dyadSplit
            )[0]
            if (newGame) {
              parcelStore.actions.subscribeTopic(newGame._id)
            }
            // refetch games, should update dashboard compmonent
            dialogStore.actions.pushMessage(DialogMessageType.Warning, t('pairingupdated'), 5000)
            dialogStore.actions.empty() // remove all messages in order to show the confirmation message properly
          })
        }
        break
      default:
        break
    }
  }
}

async function joinGameLobby() {
  if (!route.fullPath.includes('game/') && state.gameId !== '') {
    // prevent option to join other games when already playing a game
    const game = gameStore.getters.games.value.find((g: Game) => g._id === state.gameId)
    if (game) {
      const detailedGame: Game = await gameStore.actions.getGameDetails(game._id)
      gameStore.actions.selectGame(detailedGame) // select game that was started by the participant
      cmsStore.actions.setActivityID(detailedGame.details.currentActivityId) // set game activity id
      await cmsStore.actions.getSets(appStore.getters.languageCode.value)

      // sorting the shuffled items...
      cmsStore.actions.sortActivity(detailedGame.details.shuffleDetails)
      stateService.actions.begin()
    }
  }
}

function getNameForGameById() {
  return gameStore.getters.games.value.find((g) => g._id === state.gameId)?.profile.name
}

const feedback = computed(() => {
  const sessionPercent = stateService.getters.progress.value.barData.completedPercent.toFixed(0)
  const shipPercent = stateService.getters.progress.value.shipBarData.completedPercent.toFixed(0)
  if (appStore.getters.feedback.value) return appStore.getters.feedback.value
  let text = userStore.getters.myUser.value.profile.username
  if (gameStore.getters.selectedGame.value)
    text += ' : ' + gameStore.getters.selectedGame.value.profile.name + ` ${shipPercent}%`
  if (cmsStore.getters.selectedSession.value)
    text += ' : ' + cmsStore.getters.selectedSession.value.name + ` ${sessionPercent}%`
  if (cmsStore.getters.selectedTask.value) {
    const split = cmsStore.getters.selectedTask.value.name.split('_')
    const taskName =
      split[0] && split[1] && split[3] ? split[0] + '-' + split[1] + '-' + split[3] : ''
    text += ' : ' + (import.meta.env.PROD ? taskName : cmsStore.getters.selectedTask.value.name)
  }
  return text
})
</script>

<style lang="postcss">
html {
  @apply font-playful;
}
.fadeInOut {
  opacity: 1;
  -webkit-transition: opacity 0.5s ease-in-out;
  -moz-transition: opacity 0.5s ease-in-out;
  -ms-transition: opacity 0.5s ease-in-out;
  -o-transition: opacity 0.5s ease-in-out;
  transition: opacity 0.5s ease-in-out;
}

#nav {
  padding: 30px;
}

#nav a {
  font-weight: bold;
  color: #2c3e50;
}

#nav a.router-link-exact-active {
  color: #42b983;
}

/* ---- Transition effects available throughout the app ---- */

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s ease;
}
.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

.slidenext-enter-from {
  transform: translateX(100%);
}
.slidenext-enter-active,
.slidenext-leave-active {
  transition: all 0.25s ease-out;
}
.slidenext-leave-to {
  transform: translateX(-100%);
}

.slideprev-enter-from {
  transform: translateX(-100%);
}
.slideprev-enter-active,
.slideprev-leave-active {
  transition: all 0.25s ease-out;
}
.slideprev-leave-to {
  transform: translateX(100%);
}

.slideup-enter-from {
  transform: translateY(100%);
}
.slideup-enter-active,
.slideup-leave-active {
  transition: all 0.25s ease-out;
}
.slideup-leave-to {
  transform: translateY(-100%);
}
/* @media screen and (prefers-reduced-motion: reduce) {
    .next-enter {
      opacity: 0;
      transform: translate3d(100px, 0, 0);
    }
    .next-enter-active,
    .next-leave-active {
      transition: 0.5s;
    }
    .next-leave-to {
      opacity: 0;
      transform: translate3d(-100px, 0, 0);
    }

    .prev-enter {
      opacity: 0;
      transform: translate3d(-100px, 0, 0);
    }
    .prev-enter-active,
    .prev-leave-active {
      transition: 0.5s;
    }
    .prev-leave-to {
      opacity: 0;
      transform: translate3d(100px, 0, 0);
    }
  } */
</style>
