/* eslint-disable @typescript-eslint/no-unused-vars */
<!-- Copyright 2023 Richard Nesnass, Tom Bjarne Seidel

 This file is part of KMMP.

KMMP is free software: you can redistribute it and/or modify
 it under the terms of the GNU Affero General Public License as published by
 the Free Software Foundation, either version 3 of the License, or
 (at your option) any later version.

 GPL-3.0-only or GPL-3.0-or-later

KMMP is distributed in the hope that it will be useful,
 but WITHOUT ANY WARRANTY; without even the implied warranty of
 MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 GNU Affero General Public License for more details.

 You should have received a copy of the GNU Affero General Public License
 along with KMMP.  If not, see http://www.gnu.org/licenses/. -->
<template>
  <div>
    <div class="flex flex-col justify-center items-center fadeInOut" :style="{ opacity: opacity }">
      <div class="w-full" @mouseup="checkMouseEnd" @touchend="checkTouchEnd">
        <div
          class="taskNoUserSelect flex flex-col"
          @mousemove="mouseMoving"
          @touchmove="mouseMoving"
        >
          <div class="z-50">
            <div class="flex flex-row justify-around">
              <!-- Release Area (stem) -->
              <div>
                <div id="box1" class="relative p-5 cursor-pointer">
                  <img
                    id="box1img"
                    v-cache
                    class="imageLarge rounded-lg"
                    :src="box1Image"
                    style="border: solid 2px white"
                  />
                </div>
              </div>

              <!-- Release Area (morphed stem) -->
              <div>
                <div id="box2" class="relative p-5 cursor-pointer">
                  <img
                    id="box2img"
                    v-cache
                    class="imageLarge rounded-lg"
                    :src="box2Image"
                    style="border: solid 2px white"
                  />
                </div>
              </div>
            </div>
          </div>

          <Drawing
            ref="drawing"
            class="overflow-hidden fixed top-0 left-0 pointer-events-none"
            style="z-index: 100"
          ></Drawing>

          <div class="flex flex-col justify-around mt-12" style="height: 30vh; z-index: 200">
            <div class="flex flex-row justify-around w-full h-full">
              <!-- Drawable Words column 1-->
              <div
                v-for="(word, i) in displayedWords1"
                :key="`word-index-${i}`"
                class="fadeInOut text-white z-10"
                @mousedown="mouseDownOnWord($event, word)"
                @touchstart="mouseDownOnWord($event, word)"
              >
                <span
                  class="borderedWordBoxFlex fadeInOut extraPadding"
                  :style="{ opacity: word.opacity, 'font-size': word.fontSize }"
                  >{{ word.text }}</span
                >
              </div>
            </div>

            <div class="flex flex-row justify-around w-full h-full">
              <!-- Drawable Words column 2 -->
              <div
                v-for="(word, j) in displayedWords2"
                :key="`word-index-${j}`"
                @mousedown="mouseDownOnWord($event, word)"
                @touchstart="mouseDownOnWord($event, word)"
              >
                <span
                  class="borderedWordBoxFlex fadeInOut extraPadding"
                  :style="{ opacity: word.opacity, 'font-size': word.fontSize }"
                  >{{ word.text }}</span
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, PropType, toRefs, Ref } from 'vue'
import { Choice, Tracking } from '@/models/main'
import useState from '@/composition/useState'
import { shuffleItems } from '@/utilities'
import { SpeechSounds, TaskMode } from '@/constants'
import { Tasktype6 } from '@/models/tasktypes/Tasktype6'
import Drawing from '@/components/task/Drawing.vue'
import { WebAudio } from '@/models/audio'
import { createSound } from '@/api/audioService'
import { LinkedWord, Word } from '@/models/tasktypes'
import moment from 'moment'

const emit = defineEmits(['completed'])
const props = defineProps({
  task: { required: true, type: Object as PropType<Tasktype6> },
  myIndex: { required: false, type: Number, default: 0 }
})
const { getters: stateGetters, setters: stateSetters, actions: stateActions } = useState()
const { task } = toRefs(props)
const tracking = new Tracking(stateGetters.tracking.value)
stateSetters.trackingData = tracking
const roundData = { correct: 0, of: 0, attempts: 0 }
let choiceTimer = new Date()

const drawing = ref<InstanceType<typeof Drawing>>()
const opacity = ref(0)

let challengeActive = false

// 'correct' is the count of correctly answered items. 'of' is the total allocated correct items in each set
let box1Image = ''
let box2Image = ''
let words: Word[] = []

const displayedWords1: Ref<Word[]> = ref([])
const displayedWords2: Ref<Word[]> = ref([])

let linkedWord: LinkedWord | undefined
let tappedFilename: WebAudio | undefined

async function setupTask() {
  // Choose random locations
  //let firstItem = Math.random() > 0.5 ? '' : 'itemB';
  //let secondItem = firstItem === 'itemA' ? 'itemB' : 'itemA';

  if (!task.value) {
    alert('A Type 6 task does not exist - check your Session layout in the CMS')
    return
  }

  box1Image = task.value.box1image ? task.value.box1image : ''
  box2Image = task.value.box2image ? task.value.box2image : ''

  const tempWords: Word[] = []
  for (let i = 0; i < task.value.words.length; i++) {
    const word = task.value.words[i]
    const audio = word.audio ? await createSound(word.audio) : undefined
    if (word.text) {
      const correctBox =
        word.type === task.value.box1word
          ? 'box1'
          : word.type === task.value.box2word
            ? 'box2'
            : 'none'
      const w: Word = {
        text: word.text,
        correctBox: correctBox,
        audio,
        enabled: true,
        draggable: true,
        id: `word-${i}`,
        opacity: 0,
        fontSize: word.text.length > 10 ? '1em' : '2em',
        holdPositionInWords: -1 // Track the index of this word in the 'words' array.
        // Next time it is shuffled, we have the chance to position correct words at the same index
      }
      tempWords.push(w)
    }
  }

  stateActions.progress.progressShow(roundData.of)

  words = shuffleItems(tempWords)
  words.forEach((w, i) => {
    w.holdPositionInWords = i
  })
  displayedWords1.value = words
  displayedWords2.value = words.splice(3, 2)

  introduceChallenge()
}

function introduceChallenge() {
  opacity.value = 1
  stateSetters.speakerIsPlaying = true
  setTimeout(() => {
    stateActions.speakLocalised(SpeechSounds.instructions.tasks.T6)
    setTimeout(() => {
      stateSetters.speakerIsPlaying = false
      if (stateGetters.state.value.taskMode === TaskMode.Warmups) {
        stateActions.speakLocalised(SpeechSounds.instructions.warmups.T6, undefined, 1000, false)
      }
      challengeActive = true
    }, 2000)

    const showWord = (wordArray: Word[], index: number) => {
      setTimeout(() => {
        wordArray[index].opacity = 1
        if (index < wordArray.length - 1) {
          showWord(wordArray, ++index)
        }
      }, 500)
    }
    showWord(displayedWords1.value.concat(displayedWords2.value), 0)
  }, 1000)
}

function checkTouchEnd(event: TouchEvent) {
  event.preventDefault()
  event.stopPropagation()
  const changedTouch = event.changedTouches[0]
  const element = document.elementFromPoint(changedTouch.clientX, changedTouch.clientY)

  if ((element && element.id === 'box1') || (element && element.id === 'box1img')) {
    mouseUpOnBox(event, 'box1', element)
  } else if ((element && element.id === 'box2') || (element && element.id === 'box2img')) {
    mouseUpOnBox(event, 'box2', element)
  } else {
    mouseUpOutsideBox()
  }
}

function checkMouseEnd(event: MouseEvent) {
  event.preventDefault()
  event.stopPropagation()
  const element = document.elementFromPoint(event.clientX, event.clientY)

  if ((element && element.id === 'box1') || (element && element.id === 'box1img')) {
    mouseUpOnBox(event, 'box1', element)
  } else if ((element && element.id === 'box2') || (element && element.id === 'box2img')) {
    mouseUpOnBox(event, 'box2', element)
  } else {
    mouseUpOutsideBox()
  }
}

function mouseMoving(event: MouseEvent | TouchEvent) {
  event.preventDefault()
  event.stopPropagation()
  tappedFilename = undefined
  if (linkedWord && drawing.value) {
    drawing.value.message({ event, message: 'morfologiTaskMousemove' })
  }
}

function mouseDownOnWord(event: MouseEvent | TouchEvent, item: Word) {
  tappedFilename = item.audio
  if (item.enabled) {
    let startX = 0,
      startY = 0

    if (event.type.includes('touch')) {
      const e = event as TouchEvent
      startX = e.changedTouches[0].clientX
      startY = e.changedTouches[0].clientY
    } else if ((event as MouseEvent).clientX) {
      const e = event as MouseEvent
      startX = e.clientX
      startY = e.clientY
    }

    const element = document.elementFromPoint(startX, startY)
    if (drawing.value && element) {
      linkedWord = {
        word: item,
        startX: startX,
        startY: startY,
        startElement: element,
        endElement: undefined,
        endX: 0,
        endY: 0
      }
      drawing.value.message({ message: 'morfologiTaskMousedown', word: linkedWord })
    }
  }
}

function mouseUpOutsideBox() {
  const choice = new Choice()
  choice.duration = moment().diff(moment(choiceTimer), 'milliseconds')
  choice.round = roundData.correct + 1
  choice.content = words.map((w) => w.text).join(';')
  tracking.choices.push(choice)
  stateSetters.trackingData = tracking
  choiceTimer = new Date()

  if (tappedFilename) {
    tappedFilename.onended = () => {
      tappedFilename = undefined
    }
    tappedFilename.playWhenReady()
    tracking.use_audio_content_items++
  }
  if (drawing.value) drawing.value.message({ message: 'morfologiTaskMouseup' })
  linkedWord = undefined
}

function mouseUpOnBox(event: MouseEvent | TouchEvent, boxType: string, el: Element) {
  tappedFilename = undefined
  roundData.attempts++
  const correctAnswer = linkedWord && linkedWord.word?.correctBox === boxType

  const choice = new Choice()
  choice.duration = moment().diff(moment(choiceTimer), 'milliseconds')
  choice.round = roundData.correct + 1
  choice.content = words.map((w) => w.text).join(';')
  choice.correct = !!correctAnswer
  choice.response = linkedWord?.word?.text ?? '(none)'
  tracking.choices.push(choice)
  stateSetters.trackingData = tracking
  choiceTimer = new Date()

  if (challengeActive && linkedWord && (correctAnswer || task.value.unforgiving)) {
    // Correct combination

    if (event.type.includes('touch')) {
      const e = event as TouchEvent
      linkedWord.endX = e.changedTouches[0].clientX
      linkedWord.endY = e.changedTouches[0].clientY
    } else if ((event as MouseEvent).clientX) {
      const e = event as MouseEvent
      linkedWord.endX = e.clientX
      linkedWord.endY = e.clientY
    }

    linkedWord.endElement = el

    if (drawing.value) drawing.value.message({ message: 'morfologiTaskLine', word: linkedWord })
    roundData.correct++
    stateActions.progress.completeAStar()
    linkedWord.word?.audio?.playWhenReady()
    if (linkedWord.word) linkedWord.word.enabled = false
    const allUnforgivingAttemptsUsed = task.value.unforgiving && roundData.attempts === roundData.of
    if (roundData.of === roundData.correct || allUnforgivingAttemptsUsed) {
      setTimeout(() => {
        completeTask()
      }, 1500)
    }
  } else if (linkedWord) {
    if (challengeActive && linkedWord !== null && linkedWord.word?.correctBox !== boxType) {
      const allUnforgivingAttemptsUsed =
        task.value.unforgiving && roundData.attempts === roundData.of
      if (allUnforgivingAttemptsUsed) {
        setTimeout(() => {
          completeTask()
        }, 1500)
      }
    }
    reshuffleUnusedWords()
    // dataService.tryAgain();
  }
  linkedWord = undefined
  if (drawing.value) drawing.value.message({ message: 'morfologiTaskMouseup' })
}

const reshuffleUnusedWords = () => {
  let firstCorrectSelection: { index: number; item: Word | undefined } = {
    index: -1,
    item: undefined
  }
  let tempWords = displayedWords1.value.concat(displayedWords2.value)
  tempWords = shuffleItems(tempWords)
  tempWords.forEach((w, i) => {
    if (!w.enabled) {
      firstCorrectSelection = { index: i, item: w }
    } else {
      w.holdPositionInWords = i
    }
  })
  if (firstCorrectSelection.index > -1 && firstCorrectSelection.item) {
    tempWords.splice(
      firstCorrectSelection.item.holdPositionInWords || 0,
      0,
      tempWords.splice(firstCorrectSelection.index, 1)[0]
    )
  }
  displayedWords1.value = tempWords
  displayedWords2.value = tempWords.splice(3, 2)
}

const completeTask = () => {
  opacity.value = 0
  setTimeout(() => {
    emit('completed', true, tracking)
  }, 1000)
}

setupTask()
</script>

<style scoped lang="postcss">
.extraPadding {
  padding: 6px 12px;
}
.scanner-content {
  position: absolute;
  width: 100%;
}

.task6BoxWord,
.task6BoxWord:focus {
  outline: none;
  background-color: rgba(255, 255, 255, 1);
  border: solid #9370db 5px;
  padding: 5px;
  border-radius: 10px;
  cursor: pointer;
  font-size: 1.5em;

  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  outline-style: none; /*IE*/
}

.type6canvas {
  overflow: hidden;
  position: fixed;
  top: 0;
  z-index: 100;
  pointer-events: none;
}
</style>
