<!-- Copyright 2023 Richard Nesnass, Tom Bjarne Seidel

 This file is part of KMMP.

KMMP is free software: you can redistribute it and/or modify
 it under the terms of the GNU Affero General Public License as published by
 the Free Software Foundation, either version 3 of the License, or
 (at your option) any later version.

 GPL-3.0-only or GPL-3.0-or-later

KMMP is distributed in the hope that it will be useful,
 but WITHOUT ANY WARRANTY; without even the implied warranty of
 MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 GNU Affero General Public License for more details.

 You should have received a copy of the GNU Affero General Public License
 along with KMMP.  If not, see http://www.gnu.org/licenses/. -->
<template>
  <div class="flex flex-col w-full h-full bg-black opacity-30"></div>
  <div class="absolute grid grid-cols-1 place-content-center items-center w-full h-full top-0 left-0 text-center p-4 text-white">
    <h1 class="text-lg m-4">Privacy Notice</h1>
    <p>
      Kaptein Morf is a 'gamified' research tool built to support the Institute for Special Pedagogy at the University of Oslo, Norway. No personal
      data is collected or stored using Kaptein Morf. Responses to tasks are sent to a secure data storage system within the University of Oslo. Data
      sets are analyzed and shared and among a closed network of researchers for research purposes.
    </p>
  </div>
</template>

<style scoped></style>
