<!-- Copyright 2023 Richard Nesnass, Tom Bjarne Seidel

 This file is part of SL+.

 SL+ is free software: you can redistribute it and/or modify
 it under the terms of the GNU Affero General Public License as published by
 the Free Software Foundation, either version 3 of the License, or
 (at your option) any later version.

 GPL-3.0-only or GPL-3.0-or-later

 SL+ is distributed in the hope that it will be useful,
 but WITHOUT ANY WARRANTY; without even the implied warranty of
 MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 GNU Affero General Public License for more details.

 You should have received a copy of the GNU Affero General Public License
 along with SL+.  If not, see http://www.gnu.org/licenses/. -->
<template>
  <div
    class="fadein absolute w-full h-full flex flex-col justify-center items-center bg-black z-101"
  >
    <div class="flex justify-center flex-col items-center w-2/3">
      <div class="text-white flex flex-row w-full items-center mb-11">
        <p class="border rounded-xl p-4 w-full flex justify-between border-slate-700">
          {{ `${t('chooseavatar')}` }}
        </p>
        <AvatarVue class="relative w-40 -ml-40" avatar-ref="KM_pet.svg" />
      </div>
      <div class="h-1/4 flex flex-wrap overflow-y-scroll justify-start">
        <div
          v-for="(avatar, index) in availableAvatars"
          :key="index"
          class="flex flex-col items-center mb-11 w-1/3 border-8 border-black"
          :style="avatarBackground(avatar)"
        >
          <ItemSelection
            class="h-48 w-48 m-7 p-8 flex flex-col justify-center items-center cursor-pointer avatar-item"
            :selected="state.selectedAvatar.name === avatar.name"
            :border-colour="userColor"
            @click.prevent="selectAvatar(avatar)"
            @touchstart.prevent="selectAvatar(avatar)"
          >
            <AvatarVue :avatar-ref="avatar.ref" />
          </ItemSelection>
          <p
            class="text-white font-bold text-3xl mb-6 px-4 py-2 rounded"
            :style="selectedHighlight(avatar)"
          >
            {{ avatar.name }}
          </p>
        </div>
      </div>
      <div class="flex justify-between w-full">
        <Button
          class="text-3xl mt-11 ml-2 w-20 self-start border-none"
          backgroundcolour="bg-red-500"
          @click="confirmAvatar()"
          ><img class="w-8 invert" :src="Arrow"
        /></Button>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { PropType, reactive, computed, onMounted } from 'vue'
import { useI18n } from 'vue-i18n'

import { Avatar, User } from '@/models/main'
import availableAvatars from '@/constants'

import useUserStore from '@/store/useUserStore'
import useColorStore from '@/composition/colors'

import AvatarVue from '@/components/base/Avatar.vue'
import ItemSelection from '@/components/base/ItemSelection.vue'
import Button from '@/components/base/Button.vue'

import Arrow from '@/assets/icons/fontawesome/arrow-left-solid.svg'

const messages = {
  no: {
    confirm: 'Bekreft'
  },
  sv: {
    confirm: 'Bekreft'
  },
  en: {
    confirm: 'Confirm'
  }
}

const { t } = useI18n({ messages })

const userStore = useUserStore()
const color = useColorStore()

const props = defineProps({
  user: { type: Object as PropType<User>, required: true }
})

const state = reactive({
  selectedAvatar: {} as Avatar
})

const emit = defineEmits<{
  (e: 'updateAvatar'): void
}>()

onMounted(() => {
  if (props.user.avatar) state.selectedAvatar = props.user.avatar
})

const userColor = computed(() => {
  return color.actions.selectColorAsHex(props.user.profile.username)
})

// -------------------------Avatar chooser functions-----------------------------

const selectAvatar = (avatar: Avatar) => {
  state.selectedAvatar = avatar
  confirmAvatar()
}

const selectedHighlight = (avatar: Avatar) => {
  return avatar.ref === state.selectedAvatar.ref ? `background-color: ${userColor.value}` : ''
}

const confirmAvatar = async () => {
  const updatedUser = props.user
  if (state.selectedAvatar) {
    updatedUser.avatar = state.selectedAvatar
    await userStore.actions.updateUser(updatedUser)
  }
  emit('updateAvatar')
}

const avatarBackground = (avatar: Avatar) => {
  return `background-image: linear-gradient(${color.actions.selectColorAsHex(avatar.ref)}, transparent);`
}
</script>

<style scoped>
.avatar-item {
  background-color: transparent !important;
  border-radius: 50%;
}
</style>
