<!-- Copyright 2023 Richard Nesnass, Tom Bjarne Seidel

 This file is part of SL+.

 SL+ is free software: you can redistribute it and/or modify
 it under the terms of the GNU Affero General Public License as published by
 the Free Software Foundation, either version 3 of the License, or
 (at your option) any later version.

 GPL-3.0-only or GPL-3.0-or-later

 SL+ is distributed in the hope that it will be useful,
 but WITHOUT ANY WARRANTY; without even the implied warranty of
 MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 GNU Affero General Public License for more details.

 You should have received a copy of the GNU Affero General Public License
 along with SL+.  If not, see http://www.gnu.org/licenses/. -->
<template>
  <div class="flex flex-col bg-white justify-center items-center w-full p-5 m-11 rounded-xl">
    <div
      v-if="props.role === UserTaskRole.Student || !state.teacherFeedbackIsVisible"
      class="flex w-1/2 justify-center items-center"
    >
      <img v-cache :src="currentRound.targetImageURL" :alt="currentRound.targetImageURL" />
    </div>
    <!-- Student View -->
    <div
      v-if="props.role === UserTaskRole.Student"
      ref="boxContainer"
      class="flex-wrap overflow-scroll flex flex-row mt-11 p-5 justify-evenly w-full h-1/2"
    >
      <div
        v-for="(word, index) in primesForCurrentRound"
        :key="index"
        class="flex flex-row border-4 rounded-xl w-full justify-center items-center"
        :style="boxSize(word.word, index)"
        @click="clickBoxedWord(currentRound.targetWord)"
      >
        <p class="text-white">{{ word.word }}</p>
      </div>

      <div
        v-for="(prime, index) in receivedWordByType(Type23WordTypes.primes)"
        :key="index"
        class="flex flex-row border-4 rounded-xl justify-center items-center"
        :style="boxSize(prime.word, index)"
      >
        <p class="text-white">
          {{ prime.word }}
        </p>
      </div>

      <div
        class="flex flex-row border-4 rounded-xl justify-center items-center"
        :style="boxSize(currentRound.rootWord.word, primesForCurrentRound.length)"
      >
        <p v-if="receivedWordByType(Type23WordTypes.rootWord).length > 0" class="text-white">
          {{ receivedWordByType(Type23WordTypes.rootWord)[0].word }}
        </p>
        <p v-else class="text-white font-bold text-3xl">???</p>
      </div>

      <div
        class="flex flex-row border-4 rounded-xl justify-center items-center"
        :style="boxSize(currentRound.targetWord.word, primesForCurrentRound.length + 1)"
      >
        <p v-if="receivedWordByType(Type23WordTypes.targetWord).length > 0" class="text-white">
          {{ receivedWordByType(Type23WordTypes.targetWord)[0].word }}
        </p>
        <p v-else class="text-white font-bold text-3xl">???</p>
      </div>
    </div>

    <!-- Teacher View -->
    <div
      v-if="props.role === UserTaskRole.Teacher"
      ref="boxContainer"
      class="flex-wrap overflow-scroll flex flex-row mt-11 p-5 justify-evenly w-full h-1/2"
    >
      <!-- Teacher Feedback -->
      <div v-if="state.teacherFeedbackIsVisible" class="flex flex-col items-center justify-between">
        <h2 class="text-5xl font-bold mb-11">Teacher rating</h2>
        <AnswerInput v-model="state.teacherFeedback" mode="singleChoice" :options="ratingOptions" />
        <Button @click="toggleTeacherFeedback()" @touchstart.prevent="toggleTeacherFeedback()">{{
          t('done')
        }}</Button>
      </div>
      <template v-else>
        <div
          v-for="(word, index) in primesForCurrentRound"
          :key="index"
          class="flex flex-row border-4 rounded-xl w-full justify-center items-center"
          :style="boxSize(word.word, index)"
          @click="clickBoxedWord(currentRound.targetWord)"
        >
          <p class="text-white">{{ word.word }}</p>
        </div>

        <div
          v-for="(word, index) in sentPrimes"
          :key="index"
          class="flex flex-row border-4 rounded-xl w-full justify-center items-center"
          :style="boxSize(word.word, index)"
        >
          <p class="text-white">{{ word.word }}</p>
        </div>

        <div
          class="flex flex-col border-4 rounded-xl justify-center items-center"
          :style="boxSize(currentRound.rootWord.word, primesForCurrentRound.length + 1)"
        >
          <p class="text-white">{{ currentRound.rootWord.word }}</p>
          <div class="flex flex-row bg-white rounded mt-3">
            <div
              v-if="!rootWordWasGiven()"
              class="relative scale-50 w-12 h-12 taskNoUserSelect rounded-full border-4 border-green-500 bg-green-500 flex justify-center items-center cursor-pointer"
              @click="show(currentRound.rootWord, Type23WordTypes.rootWord)"
            >
              <img
                class="w-8 p-1 invert"
                src="@/assets/icons/fontawesome/arrows-rotate-solid.svg"
              />
            </div>
            <div v-else>
              <!-- TODO: include proper translation -->
              <p>Already given</p>
            </div>
          </div>
        </div>

        <div
          class="flex flex-col border-4 rounded-xl justify-center items-center"
          :style="boxSize(currentRound.targetWord.word, primesForCurrentRound.length)"
        >
          <p v-if="true" class="text-white">{{ currentRound.targetWord.word }}</p>
        </div>
        <div
          class="flex border-4 rounded-xl flex-row-reverse w-full justify-center items-center bg-white p-3 m-1"
          :style="boxSize('0', 1)"
        >
          <input v-model="state.newPrime" class="p-3 rounded w-full" type="text" />
          <Button
            class="text-2xl w-11 border-none font-bold"
            :textcolour="'text-cyan-400'"
            :backgroundcolour="'bg-green-200'"
            @click="sendNewPrime()"
            @touchstart.prevent="sendNewPrime()"
          >
            +
          </Button>
        </div>
      </template>
    </div>
    <Button
      v-if="props.role === UserTaskRole.Teacher && !state.teacherFeedbackIsVisible"
      :backgroundcolour="'bg-green-200'"
      @click="correctSolutionGiven()"
    >
      Correct solution was given
    </Button>
  </div>
</template>

<script setup lang="ts">
import { ref, PropType, reactive, onMounted, computed, toRefs } from 'vue'
import { useI18n } from 'vue-i18n'

import {
  DialogMessageType,
  TaskCallbackType,
  UserTaskRole,
  STSyncType,
  TaskSyncST,
  OptionItem,
  TaskCallback,
  TaskCallbackParam
} from '@/constants'
import {
  Tasktype23st,
  Type23WordTypes,
  Type23stWord,
  Type23stParcel
} from '@/models/tasktypes/Tasktype23st'
import { Image, STSolutionUnion } from '@/models/tasktypes'
import { Choice, Tracking } from '@/models/main'

import Button from '@/components/base/Button.vue'

import useColorStore from '@/composition/colors'
import useState from '@/composition/useState'
import useDialogStore from '@/composition/dialog'
import AnswerInput from '@/components/base/AnswerInput.vue'
import { WebAudio } from '@/models/audio'
import { createSound } from '@/api/audioService'
import moment from 'moment'

const { t } = useI18n()

// TODO: add proper translations
const ratingOptions = [
  { id: '0', title: 'Does notrespond' },
  { id: '1', title: 'Responds with single word' },
  { id: '2', title: 'Responds with complete utterance' }
] as OptionItem[]

const emit = defineEmits<{
  (e: 'completed', value: boolean, tracking: Tracking): void
  (e: 'addSTSync', type: STSyncType, taskId: string, solution: STSolutionUnion): void
  (e: 'updateShuffleOrder', shuffleOrder: string[]): void
  (e: 'registerCallback', type: TaskCallbackType, callback: TaskCallback): void
  (
    e: 'showResultMessage',
    messageType: DialogMessageType,
    solution: Image,
    callback: () => void
  ): void
}>()

const props = defineProps({
  task: { required: true, type: Object as PropType<Tasktype23st> },
  myIndex: { required: false, type: Number, default: 0 },
  actionAllowed: { required: true, type: Boolean },
  role: { required: true, type: String }
})
const { task } = toRefs(props)
const boxContainer = ref<HTMLElement | null>(null)
const wordAudio: { [key: string]: WebAudio } = {}
const color = useColorStore()
const dialog = useDialogStore()

/* const messages = {
      no: {},
      sv: {},
      en: {},
    } */

const { getters: stateGetters, setters: stateSetters } = useState()

// We need a mutatable copy of results that we will submit to the store at Task completion
const tracking = new Tracking(stateGetters.tracking.value)
stateSetters.trackingData = tracking
let choiceTimer = new Date()
const roundData = { correct: 0, of: 0, phase: 1 }

const state = reactive({
  currentRound: 0,

  sentWords: {} as Map<Type23WordTypes, Type23stWord[]>, // sent words state for the teacher
  receivedWords: {} as Map<Type23WordTypes, Type23stWord[]>, // received words state for the student

  newPrime: '',
  teacherFeedbackIsVisible: false,
  teacherFeedback: ''
})

onMounted(() => {
  // TODO: play instruction audio

  state.sentWords = new Map<Type23WordTypes, Type23stWord[]>()
  state.receivedWords = new Map<Type23WordTypes, Type23stWord[]>()
  setupWords()

  emit('registerCallback', TaskCallbackType.StudentTeacherAction, handleSTAction)
})

const primesForCurrentRound = computed(() => {
  return [...props.task.rounds[state.currentRound].primes]
})

const handleSTAction = (args: TaskCallbackParam) => {
  const data = args as TaskSyncST
  const solution = data.solution as Type23stParcel
  state.receivedWords.set(solution.type, solution.words)
  if (solution.type === Type23WordTypes.targetWord)
    handleAnswerValues() // this is invoked if the targetWord was given -> the correct solution was 'reached'
  else dialog.actions.pushMessage(DialogMessageType.Information, 'The teacher has given you a hint')
}

/**
 * handleAnswerValues()
 * invoked by either clicking the 'correct answer given' button or via mqtt (handleSTAction callback)
 */
const handleAnswerValues = () => {
  const choice = new Choice()
  choice.duration = moment().diff(moment(choiceTimer), 'milliseconds')
  choice.round = 1
  choice.content = primesForCurrentRound.value.map((w) => w.word).join(';')
  choice.correct = true
  choice.response = currentRound.value.targetWord.word
  choice.phase = roundData.phase
  choice.target = '' // TODO: How to determine this?
  tracking.choices.push(choice)
  stateSetters.trackingData = tracking
  choiceTimer = new Date()

  // TODO: add proper translation
  dialog.actions.pushMultiplayerMessage(DialogMessageType.ResultSplashCorrect, 'Correct')

  roundData.correct++

  emit('completed', true, tracking) // finish task
}

const currentRound = computed(() => {
  return props.task.rounds[state.currentRound]
})

const setupWords = async () => {
  const words = primesForCurrentRound.value
  for (let i = 0; i < words.length; i++) {
    const w = words[i]
    if (w.audioURL) {
      const audio = await createSound(w.audioURL)
      w.audio = audio
      wordAudio[w.audioURL] = await createSound(w.audioURL)
      roundData.of++
    }
  }
  for (let i = 0; i < task.value.rounds.length; i++) {
    const round = task.value.rounds[i]
    wordAudio[round.rootWord.audioURL] = await createSound(round.rootWord.audioURL)
    wordAudio[round.targetWord.audioURL] = await createSound(round.targetWord.audioURL)
  }
}

const sendNewPrime = () => {
  let sentPrimes: Type23stWord[] | undefined = []
  if (state.sentWords.has(Type23WordTypes.primes) && state.sentWords !== undefined) {
    sentPrimes = state.sentWords.get(Type23WordTypes.primes) as Type23stWord[]
  }
  if (sentPrimes === undefined || sentPrimes.length === 0) sentPrimes = [] // (re)set to empty array

  sentPrimes.push({
    hasValue: true,
    word: state.newPrime,
    audio: undefined,
    audioURL: '',
    imageURL: ''
  })
  state.sentWords.set(Type23WordTypes.primes, sentPrimes) // keep track of sent words
  state.newPrime = ''

  const solution = { words: sentPrimes, type: Type23WordTypes.primes } // part of SolutionUnion
  emit('addSTSync', STSyncType.StatusUpdate, props.task.id, solution)
}

const sentPrimes = computed(() => {
  try {
    return state.sentWords.get(Type23WordTypes.primes)
  } catch (e) {
    return []
  }
})

const toggleTeacherFeedback = () => {
  state.teacherFeedbackIsVisible = !state.teacherFeedbackIsVisible
  if (!state.teacherFeedbackIsVisible) {
    correctSolutionGiven()
    // TODO: add teacher feedback to tracking
  }
}

const correctSolutionGiven = () => {
  show(currentRound.value.targetWord, Type23WordTypes.targetWord) // show word to the student
  handleAnswerValues()
}

const receivedWordByType = (type: Type23WordTypes) => {
  try {
    const words = state.receivedWords.has(type) ? state.receivedWords.get(type) : []
    return words ?? []
  } catch (e) {
    return []
  }
}

const boxSize = (id: string, index: number) => {
  if (boxContainer.value) {
    index += 1
    const primes = primesForCurrentRound.value
    const numberOfBoxes =
      props.role === UserTaskRole.Student
        ? Object.values(state.receivedWords).length
        : Object.values(state.sentWords).length + 1
    const value =
      boxContainer.value.getBoundingClientRect().width / (numberOfBoxes + primes.length + 3)

    const adjustedIndex = index % 2 === 0 ? -index : index
    const backgroundColor = color.actions.selectColorAsHex(`${id}`)
    return `background-color: ${backgroundColor}; width: ${value}px; height: ${value}px; rotate: ${adjustedIndex}deg; margin-top: ${
      2 * adjustedIndex
    }px;`
  }
}

const show = (word: Type23stWord, type: Type23WordTypes) => {
  state.sentWords.set(Type23WordTypes.rootWord, [word]) // keep track of the rootWord being flipped
  const solution = { words: [word], type: type } // part of SolutionUnion
  emit('addSTSync', STSyncType.CardFlip, props.task.id, solution)
}

const rootWordWasGiven = () => {
  try {
    return state.sentWords.has(Type23WordTypes.rootWord)
  } catch (e) {
    return false
  }
}

const clickBoxedWord = (word?: Type23stWord) => {
  if (word && word?.wordAudio) {
    wordAudio[word.audioURL].playWhenReady()
    tracking.use_audio_content_items++
  }
}
</script>

<style scoped></style>
