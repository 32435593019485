<!-- Copyright 2023 Richard Nesnass, Tom Bjarne Seidel

 This file is part of KMMP.

KMMP is free software: you can redistribute it and/or modify
 it under the terms of the GNU Affero General Public License as published by
 the Free Software Foundation, either version 3 of the License, or
 (at your option) any later version.

 GPL-3.0-only or GPL-3.0-or-later

KMMP is distributed in the hope that it will be useful,
 but WITHOUT ANY WARRANTY; without even the implied warranty of
 MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 GNU Affero General Public License for more details.

 You should have received a copy of the GNU Affero General Public License
 along with KMMP.  If not, see http://www.gnu.org/licenses/. -->
<template>
  <div class="flex flex-row items-center justify-center">
    <div v-for="(star, index) in stars" :key="index">
      <img v-if="!star.active" class="w-14 h-14" :src="star.file" />
      <Transition v-else name="bounce">
        <img v-if="star.active" class="w-14 h-14" :src="star.file" />
      </Transition>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue'
import useState from '@/composition/useState'
import fullStarYellow from '@/assets/images/stars/yellow/star-full.svg'
import emptyStarYellow from '@/assets/images/stars/yellow/star-empty.svg'
import animationStarYellow from '@/assets/images/stars/yellow/star-full.svg' // TODO: add animation

  interface StarData {
    file: string
    active: boolean
  }

const { getters } = useState()
const starImages = {
  full: fullStarYellow,
  empty: emptyStarYellow,
  animation: animationStarYellow,
}

//const wrapper = ref<HTMLElement | null>()

const stars = computed(() => {
  const starCount = getters.progress.value.starData.stars
  const completed = getters.progress.value.starData.completed

  const stars: StarData[] = []
  for (let i = 1; i <= starCount; i++) {
    const condition = i <= completed // true -> star complete, false -> star incomplete
    const starFile = condition ? starImages.full : starImages.empty
    stars.push({
      file: starFile,
      active: condition,
    })
  }
  return stars
})

/* const wrapperHeight = computed(() => {
  if (wrapper.value) return `top: ${window.innerHeight - wrapper.value.clientHeight / 2} px;`
  else return `top: ${window.innerHeight / 3} px;`
}) */
</script>

<style lang="postcss">
  .bounce-enter-active {
    animation: bounce-in 1s;
  }
  .bounce-leave-active {
    animation: bounce-in 1s reverse;
  }
  @keyframes bounce-in {
    0% {
      transform: scale(0);
    }
    50% {
      transform: scale(1.4);
    }
    100% {
      transform: scale(1);
    }
  }
</style>
