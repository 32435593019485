import gql from 'graphql-tag'
import { ExternalSquidexData, LanguageCodes, TASK_TYPES } from '@/constants'
import { Session } from '../navigationModels'
import { LC, CommonTaskAttributes, TasktypeData, Tasktype } from '../questionModels'
import { WebAudio } from '../audio'

export const Tasktype23STQuery = gql`
  fragment Tasktype23st on Tasktype23st {
    __typename
    id
    data {
      name {
        __language
      }
      rounds {
        __language {
          targetImage {
            url
            slug
          }
          instructionAudio {
            url
            slug
          }
          targetWord {
            word
            wordAudio {
              url
              slug
            }
            image {
              url
            }
            order
          }
          rootWord {
            word
            wordAudio {
              url
              slug
            }
            image {
              url
            }
            order
          }
          primes {
            word
            wordAudio {
              url
              slug
            }
            image {
              url
            }
            order
          }
        }
      }
    }
  }
`
// TODO: does this need suffix/prefix and more of the 'common' task details?
interface Type23STAttributes {
  name: LC<string>
  rounds: LC<
    {
      instructionAudio: ExternalSquidexData[]
      targetImage: ExternalSquidexData[]
      targetWord: {
        word: string
        wordAudio: ExternalSquidexData[]
        image: ExternalSquidexData[]
        order: number
      }
      rootWord: {
        word: string
        wordAudio: ExternalSquidexData[]
        image: ExternalSquidexData[]
        order: number
      }
      primes: {
        word: string
        wordAudio: ExternalSquidexData[]
        image: ExternalSquidexData[]
        order: number
      }[]
    }[]
  >
}

// TODO: properly rename the interfaces

export interface Type23stRound {
  instructionAudioURL: string
  targetImageURL: string
  targetWord: Type23stWord
  rootWord: Type23stWord
  primes: Type23stWord[]
  additionalPrimes: Type23stWord[] // to be determined by the teacher while the task is played
}

export enum Type23WordTypes {
  targetWord = 'targetWord',
  rootWord = 'rootWord',
  primes = 'primes',
  additionalPrimes = 'additionalPrimes'
}

export interface Type23stParcel {
  words: Type23stWord[]
  type: Type23WordTypes
}

export interface Type23stWord {
  hasValue: boolean
  word: string
  wordAudio?: ExternalSquidexData[]
  audio: WebAudio | undefined
  audioURL: string // URL
  imageURL: string
  order?: number
  position?: WordPosition
}

export interface WordPosition {
  x: number
  y: number
}

// NOTE: this does not extend TasktypeData because the old fields are simply not needed
export interface Tasktype23STData extends TasktypeData {
  data: CommonTaskAttributes & Type23STAttributes
}

export class Tasktype23st extends Tasktype {
  name = ''
  rounds: Type23stRound[] = []

  constructor(spec: Tasktype23STData, language: LanguageCodes, parent?: Session) {
    super(spec, language, parent)

    this.type = TASK_TYPES.Tasktype23st
    this.name = spec.data.name[language] || 'no name'

    const rounds = spec.data.rounds[language]
    if (rounds) {
      for (const round of rounds) {
        const roundPrimes = [] as Type23stWord[]
        round.primes?.forEach((w, index) =>
          roundPrimes.push({
            hasValue: true,
            word: w.word,
            audio: undefined,
            audioURL: w.wordAudio[0]?.url + w.wordAudio[0]?.slug || '',
            wordAudio: w.wordAudio,
            imageURL: w.image[0]?.url + w.image,
            order: w.order || index,
            position: { x: 0, y: 0 }
          })
        )

        const roundTarget: Type23stWord = {
          hasValue: true,
          word: round.targetWord.word,
          wordAudio: round.targetWord.wordAudio,
          audioURL: round.targetWord.wordAudio[0]?.url + round.targetWord.wordAudio[0]?.slug || '',
          audio: undefined,
          imageURL: round.targetWord.image[0]?.url + round.targetWord.image[0]?.slug || '',
          order: round.targetWord.order,
          position: { x: 0, y: 0 }
        }

        const roundRoot: Type23stWord = {
          hasValue: true,
          word: round.rootWord.word,
          wordAudio: round.rootWord.wordAudio,
          audio: undefined,
          audioURL: round.rootWord.wordAudio[0]?.url + round.rootWord.wordAudio[0]?.slug || '',
          imageURL: round.rootWord.image[0]?.url + round.rootWord.image[0]?.slug || '',
          order: round.rootWord.order,
          position: { x: 0, y: 0 }
        }

        this.rounds.push({
          targetImageURL: round.targetImage[0]?.url + round.targetImage[0]?.slug || '',
          instructionAudioURL:
            round.instructionAudio[0]?.url + round.instructionAudio[0]?.slug || '',
          targetWord: roundTarget,
          rootWord: roundRoot,
          primes: roundPrimes,
          additionalPrimes: []
        } as Type23stRound)
      }
    }
  }

  get assetList(): string[] {
    const list: string[] = []
    this.rounds.forEach((r) => {
      if (r.instructionAudioURL) list.push(r.instructionAudioURL)
      if (r.targetImageURL) list.push(r.targetImageURL)
      if (r.targetWord.audioURL) list.push(r.targetWord.audioURL)
      if (r.targetWord.imageURL) list.push(r.targetWord.imageURL)
      if (r.rootWord.audioURL) list.push(r.rootWord.audioURL)
      if (r.rootWord.imageURL) list.push(r.rootWord.imageURL)
      r.primes.forEach((w) => {
        if (w.audioURL) list.push(w.audioURL)
        if (w.imageURL) list.push(w.imageURL)
      })
      r.additionalPrimes.forEach((w) => {
        if (w.audioURL) list.push(w.audioURL)
        if (w.imageURL) list.push(w.imageURL)
      })
    })
    return list
  }
}
