import gql from 'graphql-tag'
import { LanguageCodes, TASK_TYPES } from '@/constants'
import { Session } from '../navigationModels'
import { LC, CommonTaskAttributes, TasktypeData, Tasktype } from '../questionModels'
import { Type10CorrectType } from '.'

export const Tasktype10MPQuery = gql`
  fragment Tasktype10 on Tasktype10 {
    __typename
    id
    data {
      slug {
        iv
      }
      reference {
        iv
      }
      morph {
        __language
      }
      stem {
        __language
      }
      morphedStem {
        __language
      }
      affix {
        iv
      }
      category {
        iv
      }
      audioDone {
        iv
      }
      # specific attibutes start here..

      introductionAudio {
        __language {
          url
          slug
        }
      }
      unforgiving {
        iv
      }
      sentence1text {
        __language
      }
      sentence1audio {
        __language {
          url
          slug
        }
      }
      sentence2TextBefore {
        __language
      }
      sentence2TrailingWord {
        __language
      }
      sentence2AudioBefore {
        __language {
          url
          slug
        }
      }
      sentence2SecondTrailingWord {
        __language
      }
      sentence2TextAfter {
        __language
      }
      sentence2AudioAfter {
        __language {
          url
          slug
        }
      }
      sentence2AudioComplete {
        __language {
          url
          slug
        }
      }
      words {
        __language {
          text
          correct
          audio {
            url
            slug
          }
        }
      }
      preselectedText {
        __language
      }
      preselectedCorrect {
        iv
      }
      preselectedAudio {
        __language {
          url
          slug
        }
      }
    }
  }
`

interface Type10Attributes {
  introductionAudio?: LC<{ url: string; slug: string }[]>
  unforgiving: LC<boolean> // iv
  sentence1text: LC<string>
  sentence1audio: LC<{ url: string; slug: string }[]>
  sentence2TextBefore: LC<string>
  sentence2TrailingWord: LC<string>
  sentence2AudioBefore: LC<{ url: string; slug: string }[]>
  sentence2SecondTrailingWord: LC<string>
  sentence2TextAfter: LC<string>
  sentence2AudioAfter: LC<{ url: string; slug: string }[]>
  sentence2AudioComplete: LC<{ url: string; slug: string }[]>
  words: LC<
    {
      text: string
      correct: Type10CorrectType
      audio: { url: string; slug: string }[]
    }[]
  >
  preselectedText: LC<string>
  preselectedCorrect: LC<Type10CorrectType> // iv
  preselectedAudio: LC<{ url: string; slug: string }[]>
}
export interface Tasktype10MPData extends TasktypeData {
  data: CommonTaskAttributes & Type10Attributes
}
export class Tasktype10mp extends Tasktype {
  introductionAudio = ''
  unforgiving = false
  sentence1text = ''
  sentence1audio = ''
  sentence2TextBefore = ''
  sentence2TrailingWord = ''
  sentence2AudioBefore = ''
  sentence2SecondTrailingWord = ''
  sentence2TextAfter = ''
  sentence2AudioAfter = ''
  sentence2AudioComplete = ''
  words: {
    text: string
    correct: Type10CorrectType
    audio: string // URL
  }[] = []
  preselectedText = ''
  preselectedCorrect = Type10CorrectType.None // iv
  preselectedAudio = ''

  constructor(spec: Tasktype10MPData, language: LanguageCodes, parent?: Session) {
    super(spec, language, parent)
    this.type = TASK_TYPES.Tasktype10mp
    this.parent = parent

    const ia = spec.data.introductionAudio ? spec.data.introductionAudio[language] : undefined
    if (ia) this.introductionAudio = ia[0]?.url + ia[0]?.slug || ''
    this.unforgiving = spec.data.unforgiving.iv || false
    this.sentence1text = spec.data.sentence1text[language] || ''
    const s1a = spec.data.sentence1audio[language]
    if (s1a) this.sentence1audio = s1a[0]?.url + s1a[0]?.slug || ''
    this.sentence2TextBefore = spec.data.sentence2TextBefore[language] || ''
    this.sentence2TrailingWord = spec.data.sentence2TrailingWord[language] || ''
    const s2ab = spec.data.sentence2AudioBefore[language]
    if (s2ab) this.sentence2AudioBefore = s2ab[0]?.url + s2ab[0]?.slug || ''
    this.sentence2SecondTrailingWord = spec.data.sentence2SecondTrailingWord[language] || ''
    this.sentence2TextAfter = spec.data.sentence2TextAfter[language] || ''
    const s2aa = spec.data.sentence2AudioAfter[language]
    if (s2aa) this.sentence2AudioAfter = s2aa[0]?.url + s2aa[0]?.slug || ''
    const s2ac = spec.data.sentence2AudioComplete[language]
    if (s2ac) this.sentence2AudioComplete = s2ac[0]?.url + s2ac[0]?.slug || ''

    spec.data.words[language]?.forEach((w) => {
      this.words.push({
        text: w.text,
        correct: w.correct,
        audio: w.audio && w.audio[0] ? w.audio[0]?.url + w.audio[0]?.slug : ''
      })
    })

    this.preselectedText = spec.data.preselectedText[language] || ''
    this.preselectedCorrect = spec.data.preselectedCorrect.iv || Type10CorrectType.None
    const pa = spec.data.preselectedAudio[language]
    if (pa) this.preselectedAudio = pa[0]?.url + pa[0]?.slug || ''
  }

  get assetList(): string[] {
    const list: string[] = [
      this.introductionAudio,
      this.sentence1audio,
      this.sentence2AudioBefore,
      this.sentence2AudioAfter,
      this.sentence2AudioComplete,
      this.preselectedAudio
    ]
    this.words.forEach((w) => list.push(w.audio))
    return list
  }

  get correctWords(): string {
    return this.words
      .filter((w) => w.correct !== 'None')
      .map((w) => w.text)
      .join(';')
  }
}
