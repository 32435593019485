import gql from 'graphql-tag'
import { LanguageCodes, TASK_TYPES, StemType } from '@/constants'
import { Session } from '../navigationModels'
import { LC, CommonTaskAttributes, TasktypeData, Tasktype } from '../questionModels'

export const Tasktype5Query = gql`
  fragment Tasktype5 on Tasktype5 {
    __typename
    id
    data {
      slug {
        iv
      }
      reference {
        iv
      }
      morph {
        __language
      }
      stem {
        __language
      }
      morphedStem {
        __language
      }
      affix {
        iv
      }
      category {
        iv
      }
      audioDone {
        iv
      }
      # specific attibutes start here..
      instruction1Text {
        __language
      }
      instruction1Audio {
        __language {
          url
          slug
        }
      }
      instruction1AudioText {
        __language
      }
      instruction2Text {
        __language
      }
      instruction2Audio {
        __language {
          url
          slug
        }
      }
      instruction2AudioText {
        __language
      }
      box1 {
        __language {
          image {
            url
            slug
          }
          word
          audio {
            url
            slug
          }
          audioText
        }
      }
      box2 {
        __language {
          image {
            url
            slug
          }
          word
          audio {
            url
            slug
          }
          audioText
        }
      }
      draggableImages {
        __language {
          image {
            url
            slug
          }
          word
        }
      }
    }
  }
`

interface Type5Attributes {
  instruction1Text: LC<string>
  instruction1Audio: LC<{ url: string; slug: string }[]>
  instruction1AudioText: LC<string>
  instruction2Text: LC<string>
  instruction2Audio: LC<{ url: string; slug: string }[]>
  instruction2AudioText: LC<string>
  box1: LC<{
    image: { url: string; slug: string }[]
    word: string
    audio: { url: string; slug: string }[]
    audioText: string
  }>
  box2: LC<{
    image: { url: string; slug: string }[]
    word: string
    audio: { url: string; slug: string }[]
    audioText: string
  }>
  draggableImages: LC<
    {
      image: { url: string; slug: string }[]
      word: string
    }[]
  >
}

export interface Tasktype5Data extends TasktypeData {
  data: CommonTaskAttributes & Type5Attributes
}

export class Tasktype5 extends Tasktype {
  instruction1Text = ''
  instruction1Audio = '' // URL
  instruction1AudioText = ''
  instruction2Text = ''
  instruction2Audio = '' // URL
  instruction2AudioText = ''
  box1 = {
    image: '', // URL
    word: '',
    audio: '', // URL
    audioText: ''
  }
  box2 = {
    image: '', // URL
    word: '',
    audio: '', // URL
    audioText: ''
  }
  draggableImages: {
    image: string
    word: StemType
  }[] = []

  constructor(spec: Tasktype5Data, language: LanguageCodes, parent?: Session) {
    super(spec, language, parent)
    this.type = TASK_TYPES.Tasktype5
    this.parent = parent

    this.instruction1Text = spec.data.instruction1Text[language] || 'undefined'
    const i1a = spec.data.instruction1Audio[language]
    if (i1a) this.instruction1Audio = i1a[0]?.url + i1a[0]?.slug || ''
    this.instruction1AudioText = spec.data.instruction1AudioText[language] || 'undefined'
    this.instruction2Text = spec.data.instruction2Text[language] || 'undefined'
    const i2a = spec.data.instruction2Audio[language]
    if (i2a) this.instruction2Audio = i2a[0]?.url + i2a[0]?.slug || ''
    this.instruction2AudioText = spec.data.instruction2AudioText[language] || 'undefined'

    let bd = spec.data.box1[language]
    if (bd) {
      this.box1 = {
        image: bd.image && bd.image[0] ? bd.image[0]?.url + bd.image[0]?.slug : '',
        word: bd.word,
        audio: bd.audio && bd.audio[0] ? bd.audio[0]?.url + bd.audio[0]?.slug : '',
        audioText: bd.audioText
      }
    }
    bd = spec.data.box2[language]
    if (bd) {
      this.box2 = {
        image: bd.image && bd.image[0] ? bd.image[0]?.url + bd.image[0]?.slug : '',
        word: bd.word,
        audio: bd.audio && bd.audio[0] ? bd.audio[0]?.url + bd.audio[0]?.slug : '',
        audioText: bd.audioText
      }
    }
    spec.data.draggableImages[language]?.forEach((di) => {
      this.draggableImages.push({
        image: di.image[0]?.url + di.image[0]?.slug || '',
        word: di.word as StemType
      })
    })
  }

  get assetList(): string[] {
    const list = [
      this.instruction1Audio,
      this.instruction2Audio,
      this.box1.audio,
      this.box1.image,
      this.box2.audio,
      this.box2.image
    ]
    this.draggableImages.forEach((b) => list.push(b.image))
    return list
  }
}
