/*
 Designed and developed by Richard Nesnass

 This file is part of SL+.

 SL+ is free software: you can redistribute it and/or modify
 it under the terms of the GNU Affero General Public License as published by
 the Free Software Foundation, either version 3 of the License, or
 (at your option) any later version.

 GPL-3.0-only or GPL-3.0-or-later

 SL+ is distributed in the hope that it will be useful,
 but WITHOUT ANY WARRANTY; without even the implied warranty of
 MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 GNU Affero General Public License for more details.

 You should have received a copy of the GNU Affero General Public License
 along with SL+.  If not, see <http://www.gnu.org/licenses/>.
 */
import { Question, QuestionData } from './main'
import { Session } from './navigationModels'
import { LanguageCodes } from '@/constants'

// -------------- Regular Question Types ---------

export type LC<T> = {
  [key in LanguageCodes]: T | null
}

export interface CommonTaskAttributes {
  slug: LC<string>
  reference: LC<string>
  morph: LC<string>
  stem: LC<string>
  morphedStem: LC<string>
  affix: LC<string>
  category: LC<string>
  audioDone: LC<string>
}
// All Squidex/GraphQL requests will return the shape defined in QuestionData, but each question type will differ in shape inside data {}
// In KM, there are a set of common attributes across Tasktypes so we extend to an intermediate class before defining each Task Type
export interface TasktypeData extends QuestionData {
  data: CommonTaskAttributes
}

// The concrete Tasktype classes flatten out the data
export class Tasktype extends Question {
  reference = ''
  morph = ''
  stem = ''
  morphedStem = ''
  affix = ''
  category = ''
  audioDone = ''

  parent?: Session

  constructor(spec: TasktypeData, language: LanguageCodes, parent?: Session) {
    super(spec)
    try {
      this.parent = parent
      this.reference = spec.data.reference.iv || 'not found'
      this.name = this.reference
      this.morph = spec.data.morph[language]?.trim() || 'not found'
      this.stem = spec.data.stem[language] || 'not found'
      this.morphedStem = spec.data.morphedStem[language] || 'not found'
      this.affix = spec.data.affix.iv || 'not found'
      this.category = spec.data.category.iv || 'not found'
      this.audioDone = spec.data.audioDone.iv || 'not found'
    } catch (e) {
      this.reference = 'not found'
      this.name = this.reference
      this.morph = 'not found'
      this.stem = 'not found'
      this.morphedStem = 'not found'
      this.affix = 'not found'
      this.category = 'not found'
      this.audioDone = 'not found'
    }

    /* if (useLocalAssets) {
      this.rewriteUrlsToLocalAssets(spec)
    } */
  }
}
