import gql from 'graphql-tag'
import { LanguageCodes, TASK_TYPES, WordIndexName } from '@/constants'
import { Session } from '../navigationModels'
import { LC, CommonTaskAttributes, TasktypeData, Tasktype } from '../questionModels'
import { WebAudio } from '../audio'

export const Tasktype7Query = gql`
  fragment Tasktype7 on Tasktype7 {
    __typename
    id
    data {
      slug {
        iv
      }
      reference {
        iv
      }
      morph {
        __language
      }
      stem {
        __language
      }
      morphedStem {
        __language
      }
      affix {
        iv
      }
      category {
        iv
      }
      audioDone {
        iv
      }
      # specific attibutes start here..

      totalCorrect {
        iv
      }
      words {
        __language {
          text
          audio {
            url
            slug
          }
        }
      }
      correct {
        __language {
          firstWord
          secondWord
          thirdWord
          audio {
            url
            slug
          }
        }
      }
    }
  }
`

const wordIndexes = {
  [WordIndexName.None]: -1,
  [WordIndexName.Word1]: 0,
  [WordIndexName.Word2]: 1,
  [WordIndexName.Word3]: 2,
  [WordIndexName.Word4]: 3,
  [WordIndexName.Word5]: 4,
  [WordIndexName.Word6]: 5,
  [WordIndexName.Preselected]: 5 // Word6 is the same as Preselected in Squidex
}
const wordsFromIndex = {
  '-1': WordIndexName.None,
  0: WordIndexName.Word1,
  1: WordIndexName.Word2,
  2: WordIndexName.Word3,
  3: WordIndexName.Word4,
  4: WordIndexName.Word5,
  5: WordIndexName.Word6
}
interface Type7Attributes {
  totalCorrect: LC<string>
  words: LC<
    {
      text: string
      audio: { url: string; slug: string }[]
    }[]
  >
  correct: LC<
    {
      firstWord: WordIndexName
      secondWord: WordIndexName
      thirdWord: WordIndexName
      audio: { url: string; slug: string }[]
    }[]
  >
}
export interface Tasktype7Data extends TasktypeData {
  data: CommonTaskAttributes & Type7Attributes
}
export interface Type7Correct {
  firstWord: WordIndexName
  secondWord: WordIndexName
  thirdWord: WordIndexName
  audioURL: string
  audio: WebAudio | undefined
}
export class Tasktype7 extends Tasktype {
  totalCorrect = 0
  words: {
    text: string
    reference: WordIndexName
    audioURL: string // URL
  }[] = []
  correct: Type7Correct[] = []

  constructor(spec: Tasktype7Data, language: LanguageCodes, parent?: Session) {
    super(spec, language, parent)
    this.type = TASK_TYPES.Tasktype7
    this.parent = parent

    this.totalCorrect = parseInt(spec.data.totalCorrect.iv || '-1')
    spec.data.words[language]?.forEach((w, i) => {
      const wordKey = i as keyof typeof wordsFromIndex
      this.words.push({
        text: w.text,
        reference: wordsFromIndex[wordKey],
        audioURL: w.audio[0]?.url + w.audio[0]?.slug || ''
      })
    })
    spec.data.correct[language]?.forEach((c) => {
      const audioURL = c.audio[0]?.url + c.audio[0]?.slug || ''
      this.correct.push({
        firstWord: c.firstWord,
        secondWord: c.secondWord,
        thirdWord: c.thirdWord,
        audioURL,
        audio: undefined
      })
    })
  }

  get assetList(): string[] {
    const list: string[] = []
    this.words.forEach((w) => list.push(w.audioURL))
    this.correct.forEach((c) => list.push(c.audioURL))
    return list
  }

  // Calculate if the given combination fully or partially matches a correct answer
  public hasCombinationStatus(
    cc: Type7Correct,
    compareInput: Type7Correct[]
  ): { status: string; correctRef?: Type7Correct } {
    const result: { status: string; correctRef?: Type7Correct } = {
      status: 'incorrect',
      correctRef: undefined
    }

    // If only one word has been placed, return 'some'
    if (
      (cc.firstWord === WordIndexName.None &&
        cc.secondWord === WordIndexName.None &&
        cc.thirdWord !== WordIndexName.None) ||
      (cc.firstWord === WordIndexName.None &&
        cc.secondWord !== WordIndexName.None &&
        cc.thirdWord === WordIndexName.None) ||
      (cc.firstWord !== WordIndexName.None &&
        cc.secondWord === WordIndexName.None &&
        cc.thirdWord === WordIndexName.None)
    ) {
      result.status = 'some'
      return result
    }

    const checkCombinations = (cc: Type7Correct, compare: Type7Correct): boolean => {
      const wordCount =
        compare.firstWord !== WordIndexName.None &&
        compare.secondWord !== WordIndexName.None &&
        compare.thirdWord !== WordIndexName.None
          ? 3
          : 2

      const a = compare.firstWord === cc.firstWord
      const b = compare.secondWord === cc.secondWord
      const c = compare.thirdWord === cc.thirdWord

      if (a && b && c) {
        result.status = 'correct'
        result.correctRef = compare
        return true
      } else {
        if (wordCount === 3) {
          if (
            (a && b && cc.thirdWord === WordIndexName.None) ||
            (a && cc.secondWord === WordIndexName.None && cc.thirdWord === WordIndexName.None) ||
            (cc.firstWord === WordIndexName.None && b && c) ||
            (cc.firstWord === WordIndexName.None && b && cc.thirdWord === WordIndexName.None) ||
            (a && cc.secondWord === WordIndexName.None && c) ||
            (cc.firstWord === WordIndexName.None && cc.secondWord === WordIndexName.None && c)
          ) {
            result.status = 'some'
            return true
          }
        } else {
          if (
            (cc.firstWord === WordIndexName.None && b && cc.thirdWord === WordIndexName.None) ||
            (a && cc.secondWord === WordIndexName.None && cc.thirdWord === WordIndexName.None) ||
            (cc.firstWord === WordIndexName.None && cc.secondWord === WordIndexName.None && c) ||
            (cc.firstWord === WordIndexName.None && b && cc.thirdWord === WordIndexName.None)
          ) {
            result.status = 'some'
            return true
          }
        }
      }
      return false
    }

    if (compareInput.length) {
      compareInput.forEach((item) => checkCombinations(cc, item))
    } else {
      this.correct.forEach((item) => checkCombinations(cc, item))
    }
    return result
  }

  // Return the fully concatenated word for the given word parts
  public combinedWordFromCC(cc?: Type7Correct): string {
    if (cc) {
      let word =
        cc.firstWord !== WordIndexName.None ? this.words[wordIndexes[cc.firstWord]].text : ''
      word +=
        cc.secondWord !== WordIndexName.None ? this.words[wordIndexes[cc.secondWord]].text : ''
      word += cc.thirdWord !== WordIndexName.None ? this.words[wordIndexes[cc.thirdWord]].text : ''
      return word
    } else return ''
  }

  // A string showing the words inside the given Type7Correct class
  public wordsFromCC(cc: Type7Correct) {
    let w1 = '',
      w2 = '',
      w3 = ''
    if (cc.firstWord !== WordIndexName.None) {
      w1 = this.words[wordIndexes[cc.firstWord]].text
    }
    if (cc.secondWord !== WordIndexName.None) {
      w2 = ', ' + this.words[wordIndexes[cc.secondWord]].text
    }
    if (cc.thirdWord !== WordIndexName.None) {
      w3 = ', ' + this.words[wordIndexes[cc.thirdWord]].text
    }
    return w1 + w2 + w3
  }

  public allCorrectAnswers(): string {
    return this.correct
      .map((c) => {
        const str = []
        if (c.firstWord) str.push(c.firstWord)
        if (c.secondWord) str.push(c.secondWord)
        if (c.thirdWord) str.push(c.thirdWord)
        return str.join(';')
      })
      .join(';')
  }
}
