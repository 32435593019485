<!-- Copyright 2020 Richard Nesnass

 This file is part of SL+.

 SL+ is free software: you can redistribute it and/or modify
 it under the terms of the GNU Affero General Public License as published by
 the Free Software Foundation, either version 3 of the License, or
 (at your option) any later version.

 GPL-3.0-only or GPL-3.0-or-later

 SL+ is distributed in the hope that it will be useful,
 but WITHOUT ANY WARRANTY; without even the implied warranty of
 MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 GNU Affero General Public License for more details.

 You should have received a copy of the GNU Affero General Public License
 along with SL+.  If not, see http://www.gnu.org/licenses/. -->

<template>
  <div class="flex flex-col p-4 justify-center items-center h-screen">
    <Loader />
  </div>
</template>

<script setup lang="ts">
import { computed, onMounted } from 'vue'
import { useI18n } from 'vue-i18n'
import { useRouter } from 'vue-router'

import { DialogMessageType, LanguageCodes } from '@/constants'
import { fetchToken } from '@/api/cmsService'
import { Game, LocalUser } from '@/models/main'

import useAppStore from '@/store/useAppStore'
import useUserStore from '@/store/useUserStore'
import useGameStore from '@/store/useGameStore'
import useParcelStore from '@/composition/parcel'
import useDeviceService from '@/composition/useDevice'
import useDialogStore from '@/composition/dialog'

import Loader from '@/components/base/Loader.vue'
import useMultiPlayerState from '@/composition/useMultiplayerState'

// This component completes setup of the app after login

const { locale } = useI18n({ useScope: 'global' })

const router = useRouter()
const parcelStore = useParcelStore()
const dialogStore = useDialogStore()
const multiplayer = useMultiPlayerState()

const messages = {
  no: {
    mqtterror:
      'Det oppstod et problem med forbindelsen til flerspiller serveren. Vennligst prøv igjen.'
  },
  en: {
    mqtterror: 'There was an issue with the connection to the multiplayer server. Please try again.'
  }
}
const { t } = useI18n({ messages })

const { getters: appGetters, actions: appActions } = useAppStore()
const { getters: gameGetters, actions: gameActions } = useGameStore()
const { getters: userGetters, actions: userActions } = useUserStore()
const { getters: deviceGetters, actions: deviceActions } = useDeviceService()

const isMobileDevice: boolean = deviceGetters.deviceReady.value
const isOnline: boolean = deviceGetters.deviceOnline.value
const currentLocalUser: LocalUser | undefined = appGetters.currentLocalUser.value

onMounted(() => {
  fetchToken().then((newToken: string) => {
    console.log('Fetched new CMS token')
    localStorage.setItem('squidex-token', newToken)
  })

  getData()
    .then(async () => {
      console.log('Data fetched')
      // await updateGames()
      await deviceActions.loadMediaCache()
      await initMqtt()
    })
    .catch((e) => {
      console.error(`error during getting data: ${e}`)
    })
    .finally(() => {
      router.push('/dashboard')
    })
})

const user = computed(() => {
  return userGetters.myUser.value
})

const getData = async (): Promise<void> => {
  if (isMobileDevice && !isOnline && currentLocalUser) {
    userActions.setCordovaPath(currentLocalUser._id)
    await userActions.loadData() // Load user, project and players from local disk
  } else {
    // Load user from server, select it (including user.participants) then save it locally
    await userActions.getMyUser()
    await userActions.saveData()
  }

  const myUser = user.value
  const languageCode = myUser.profile.language
  if (languageCode !== 'system') {
    locale.value = languageCode as string
    appActions.setLanguageCode(locale.value as LanguageCodes)
  } else if (Object.keys(LanguageCodes).some((language) => language == locale.value)) {
    // NOTE: Activate supported languages in constants.ts
    const lang: LanguageCodes = locale.value as LanguageCodes
    appActions.setLanguageCode(lang)
  } else appActions.setLanguageCode(LanguageCodes.no)

  // Mobile device storage path. Must be set before requesting games, trackings etc.
  gameActions.setCordovaPath(myUser._id)

  // Load the Games & Players for this user
  // 1. From server
  // 2. From disk (replacing any matches from server)
  // Stage F. sync should remove a Player if it doesn't exist on server
  // await gameActions.getGames('', myUser._id, true) // get games for current user only... // NOTE: Done on Dashboard mount (needed also after session end)
  // Games must be loaded before Trackings as Trackings are stored in 'user/userID/games/gameID/trackings.json'
  // await gameActions.loadGames() // If games have been removed server-side or User was removed from a Group, incorrect Games may be loaded here..
  // Stage F. sync should remove a Game if it doesn't exist on server
  await gameActions.loadTrackings()

  return Promise.resolve()
}

const initMqtt = async () => {
  try {
    parcelStore.actions.openMQTTConnection(user.value).then(async () => {
      // unsubscribe from topics (to ensure not being subscribed to multiple ones of the same type / purpose)
      const userId = user.value._id
      parcelStore.actions.unSubscribeTopic(userId)
      await parcelStore.actions.subscribeTopic(userId)

      const games = gameGetters.games.value.filter(
        (game: Game) =>
          game.details.participants.includes(userId) && game.details.participants.length === 2
      )
      for (const game of games) {
        parcelStore.actions.unSubscribeTopic(game._id)
        await parcelStore.actions.subscribeTopic(game._id)
      }
    })
  } catch (e) {
    const cb = () => {
      router.push('/dashboard')
    }
    dialogStore.actions.pushMessage(DialogMessageType.Warning, t('mqtterror'), 5000, cb)
    multiplayer.actions.reset()
    console.error(e)
  }
}

// Synce sequence Stage E. and F. Post Trackings and Update Games
// updateGameProgress() also saves to disk Stage D.
/* const updateGames = async (): Promise<void> => {
  const isLeader = multiplayer.getters.currentRole.value !== UserTaskRole.Advisor

  // We don't need to wait for trackings post to complete, just let in run
  gameActions.sendTrackings()
  if (isOnline && isMobileDevice && isLeader) {
    // Synchronise any locally stored Game data with the server
    await gameActions.updateGameProgress(true)
  } else return Promise.resolve()
} */
</script>

<style scoped></style>
