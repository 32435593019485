/* eslint-disable @typescript-eslint/no-unused-vars */
<!-- Copyright 2023 Richard Nesnass, Tom Bjarne Seidel

 This file is part of KMMP.

KMMP is free software: you can redistribute it and/or modify
 it under the terms of the GNU Affero General Public License as published by
 the Free Software Foundation, either version 3 of the License, or
 (at your option) any later version.

 GPL-3.0-only or GPL-3.0-or-later

KMMP is distributed in the hope that it will be useful,
 but WITHOUT ANY WARRANTY; without even the implied warranty of
 MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 GNU Affero General Public License for more details.

 You should have received a copy of the GNU Affero General Public License
 along with KMMP.  If not, see http://www.gnu.org/licenses/. -->
<template>
  <div
    class="relative flex flex-col justify-center items-center fadeInOut"
    :style="{ opacity: opacity }"
  >
    <div class="relative flex flex-col justify-center items-center fadeInOut">
      <div class="scanner-padding flex flex-col justify-center items-center">
        <div class="flex flex-row m-4">
          <div class="flex flex-col justify-around w-1/2 mr-8">
            <div class="flex flex-row justify-start align-middle">
              <img v-cache class="roundCorners imageVeryLarge" :src="boxImage1" />
            </div>

            <div
              class="flex flex-row justify-center items-center"
              style="position: relative; margin-top: 30px; color: white"
            >
              <span
                v-if="!showCombinedWord[1]"
                class="text-2xl mt-auto mb-auto"
                @click="clickSentence(1)"
                >{{ task.round1.sentenceBeforeWord }}&nbsp;</span
              >

              <!-- Drop boxes -->

              <div
                v-if="displayedItem[1].length === 0"
                id="word-box-1"
                ref="wordBox1"
                class="flex justify-between items-center dialog-bg fadedBorder relative rounded-full p-1 m-2 drop-target w-1/2 h-16"
                @start="onStart"
                @stop="onDrop"
                @mouseenter="onDropAreaMouseEnter"
                @mouseleave="onDropAreaMouseLeave"
              ></div>

              <transition mode="out-in" name="fade">
                <span
                  v-if="displayedItem[1].length > 0 || !showCombinedWord[1]"
                  class="wordHighlight fadeInOut"
                  :class="{ yellowWordText: displayedItem[1].length > 0 }"
                  >{{ displayedItem[1] }}
                </span>
              </transition>

              <!-- Combined word -->
              <transition mode="out-in" name="fade">
                <span
                  v-if="combinedWord[1].length > 0 || showCombinedWord[1]"
                  class="wordHighlight text-2xl"
                  style="line-height: 24pt"
                  :class="{ yellowWordText: !mergeCombinedWord[1] }"
                  @click="playCompleteAudio(1)"
                  >{{ combinedWord[1] }}&nbsp;
                </span>
              </transition>

              <span v-if="!showCombinedWord[1]" class="text-2xl"
                >&nbsp;{{ task.round1.sentenceAfterWord }}</span
              >
            </div>
          </div>

          <div class="flex flex-col justify-around w-1/2">
            <div class="flex flex-row justify-start align-middle">
              <img v-cache class="roundCorners imageVeryLarge" :src="boxImage2" />
            </div>

            <div
              class="flex flex-row justify-center items-center"
              style="position: relative; margin-top: 30px; color: white"
            >
              <span
                v-if="!showCombinedWord[2]"
                class="text-2xl mt-auto mb-auto"
                @click="clickSentence(2)"
                >{{ task.round2.sentenceBeforeWord }}&nbsp;</span
              >

              <!-- Drop boxes -->

              <div
                v-if="displayedItem[2].length === 0"
                id="word-box-2"
                ref="wordBox2"
                class="flex justify-between items-center dialog-bg fadedBorder relative rounded-full p-1 m-2 drop-target w-1/2 h-16"
                @start="onStart"
                @stop="onDrop"
                @mouseenter="onDropAreaMouseEnter"
                @mouseleave="onDropAreaMouseLeave"
              ></div>

              <transition mode="out-in" name="fade">
                <span
                  v-if="displayedItem[2].length > 0 || !showCombinedWord[2]"
                  class="wordHighlight fadeInOut"
                  :class="{ yellowWordText: displayedItem[2].length > 0 }"
                  >{{ displayedItem[2] }}
                </span>
              </transition>

              <!-- Combined word -->
              <transition mode="out-in" name="fade">
                <span
                  v-if="combinedWord[2].length > 0 || showCombinedWord[2]"
                  class="wordHighlight text-2xl"
                  :class="{ yellowWordText: !mergeCombinedWord[2] }"
                  @click="playCompleteAudio(2)"
                  >{{ combinedWord[2] }}&nbsp;</span
                >
              </transition>

              <span v-if="!showCombinedWord[2]" class="text-2xl"
                >&nbsp;{{ task.round2.sentenceAfterWord }}</span
              >
            </div>
          </div>
        </div>
        <div class="flex w-full flex-wrap justify-center" :style="{ opacity: opacityWords }">
          <!-- Draggable Items -->
          <Draggable
            v-for="(word, i) of words"
            :id="`word-index-${i}`"
            :key="word.id"
            :class="`${activeDrags ? 'pointer-events-none touch-none' : ''}`"
            :style="{ opacity: opacityWords }"
            :position="word.position"
            @pointerdown="onPointerDown"
            @start="(e: any) => onStart(e)"
            @stop="(e: any) => onDrop(e, word)"
          >
            <span class="borderedWordBoxFlex" @click="clickImage(word)">{{ word.text }}</span>
          </Draggable>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, PropType, toRefs, Ref } from 'vue'
import { ControlPosition, DraggableEvent } from '@braks/revue-draggable'
import { Draggable } from '@braks/revue-draggable'
import { Choice, Tracking } from '@/models/main'
import useState from '@/composition/useState'
import { shuffleItems } from '@/utilities'
import { SpeechSounds, TaskMode } from '@/constants'
import { Tasktype4 } from '@/models/tasktypes/Tasktype4'
import { WebAudio } from '@/models/audio'
import { createSound } from '@/api/audioService'
import moment from 'moment'

type DraggableDIVElementEvent = DraggableEvent & { event: { target: HTMLDivElement } }

interface Word {
  text: string
  audio?: WebAudio
  correctBox: number
  enabled: boolean
  draggable: boolean
  opacity: number
  element?: HTMLElement
  id: string
  position: ControlPosition
}

let idleTimer: ReturnType<typeof setTimeout>

const emit = defineEmits(['completed'])
const props = defineProps({
  task: { required: true, type: Object as PropType<Tasktype4> },
  myIndex: { required: false, type: Number, default: 0 }
})
const { getters: stateGetters, setters: stateSetters, actions: stateActions } = useState()
const { task } = toRefs(props)
const tracking = new Tracking(stateGetters.tracking.value)
stateSetters.trackingData = tracking
const roundData = { correct: 0, of: 0 }
let choiceTimer = new Date()

const opacity = ref(0)
const activeDrags = ref(0)

const unforgivingTestMode = false
let attempts = 0
const opacityWords = ref(0)
const wordBox1 = ref()
const wordBox2 = ref()

let taskCompleted = false
const boxAudio: {
  1?: WebAudio
  2?: WebAudio
} = {}
const correctAudio: {
  1?: WebAudio
  2?: WebAudio
} = {}
const combinedWordAudio: {
  1?: WebAudio
  2?: WebAudio
} = {}
const boxCompleted = {
  1: false,
  2: false
}
const displayedItem = {
  1: '',
  2: ''
}
const combinedWord = ref({
  1: '',
  2: ''
})
const showCombinedWord = ref({
  1: false,
  2: false
})
const mergeCombinedWord = ref({
  1: false,
  2: false
})
let audioPlaying = false
let finishDone = false

// 'correct' is the count of correctly answered items. 'of' is the total allocated correct items
const boxImage1 = ref('')
const boxImage2 = ref('')
const words: Ref<Word[]> = ref([])
const audioSentenceQueue: number[] = []
const cancelDropEvent = false

// ---------  Dragula functions -------------------

// A 'dropbox' element should incude the class 'drop-target'
const getDropboxElement = (e: DraggableEvent): Element | undefined => {
  let x = 0
  let y = 0
  if (e.event.type.includes('touch')) {
    const te = e.event as TouchEvent
    x = te.changedTouches[0].clientX
    y = te.changedTouches[0].clientY
  } else {
    const me = e.event as MouseEvent
    x = me.clientX
    y = me.clientY
  }
  const el = document.elementFromPoint(x, y)
  return el && el.classList.contains('drop-target') ? el : undefined
}

const onStart = (e: DraggableEvent) => {
  if (!e.event) return
  e.event.preventDefault()
  activeDrags.value++
}

const onPointerDown = (e: PointerEvent) => {
  const el = e.target as HTMLDivElement
  if (e.pointerId && e.target) el.releasePointerCapture(e.pointerId)
}

const onDrop = (e: DraggableDIVElementEvent, theWord?: Word) => {
  const choice = new Choice()
  choice.duration = moment().diff(moment(choiceTimer), 'milliseconds')
  choice.round = roundData.correct + 1
  choice.content = words.value.map((w) => w.text).join(';')
  tracking.choices.push(choice)
  choiceTimer = new Date()

  activeDrags.value = 0
  const el = getDropboxElement(e)
  if (theWord && el) {
    choice.valid = true
    const word = words.value.find((w) => w.id === theWord?.id)
    const boxIndex = (getBoxIndex(el) as keyof typeof boxAudio) || undefined
    if (!cancelDropEvent && word) {
      choice.response = word.text
      choice.target = `box ${word.correctBox}`
      attempts++
      if (word.correctBox === boxIndex) {
        choice.correct = true
        onDropComplete(word, boxIndex)
      } else {
        theWord.position = { x: 0, y: 0 }
        words.value = shuffleItems(words.value)
      }
    }
  } else if (theWord) {
    clickImage(theWord)
    theWord.position = { x: 0, y: 0 }
  }
  stateSetters.trackingData = tracking
}

function onDropAreaMouseEnter(e: MouseEvent | TouchEvent) {
  const el = e && (e.target as HTMLElement)
  if (activeDrags.value && el) {
    if (el.id === 'word-box-1') wordBox1.value.classList.add('bg-red-400')
    else wordBox2.value.classList.add('bg-red-400')
  }
}

function onDropAreaMouseLeave(e: MouseEvent | TouchEvent) {
  const el = e && (e.target as HTMLElement)
  if (activeDrags.value && el) {
    if (el.id === 'word-box-2') wordBox1.value.classList.remove('bg-red-400')
    else wordBox2.value.classList.remove('bg-red-400')
  }
}

const getBoxIndex = (target: Element) => {
  return parseInt(target.id.substring(9), 10) || undefined
}

const playCompleteAudio = (wordNumber: keyof typeof combinedWordAudio) => {
  if (!audioPlaying) {
    audioPlaying = true
    const correctWordAudio = combinedWordAudio[wordNumber]
    if (correctWordAudio) {
      correctWordAudio.playWhenReady()
    } else audioPlaying = false
  }
  tracking.use_audio_content_items++
}

const clickImage = (item: Word) => {
  if (activeDrags.value === 0 && item.audio) {
    item.audio.playWhenReady()
    tracking.use_audio_content_items++
  }
}

const clickSentence = (boxNumber: number) => {
  const box = boxNumber as keyof typeof boxAudio
  const audio = boxAudio[box]
  if (audioPlaying) {
    audioSentenceQueue.push(box)
  } else if (box && audio) {
    audioPlaying = true
    audio.onended = () => {
      audioPlaying = false
    }
    audio.playWhenReady()
    tracking.use_audio_content_items++
  } else if (!unforgivingTestMode && roundData.of === roundData.correct && !finishDone) {
    fadeOut()
  }
}

const setupSentence = async (box: keyof typeof boxCompleted) => {
  const playAgainOrFadeOut = () => {
    audioPlaying = false
    if (audioSentenceQueue.length > 0) {
      const a = audioSentenceQueue.pop()
      if (a) clickSentence(a)
    } else if (!unforgivingTestMode && roundData.of === roundData.correct && !finishDone) {
      fadeOut()
    }
  }

  let before: WebAudio | undefined, after: WebAudio | undefined
  const word: WebAudio | undefined = correctAudio[box]
  const round = box === 1 ? task.value.round1 : task.value.round2

  if (round.audioBeforeWord) {
    before = await createSound(round.audioBeforeWord)
    before.onended = () => {
      if (word && boxCompleted[box]) {
        word.playWhenReady()
      } else {
        if (after) {
          setTimeout(() => {
            if (after) after.playWhenReady()
          }, 1000)
        } else {
          playAgainOrFadeOut()
        }
      }
    }
  }

  if (word) {
    word.onended = () => {
      if (after) after.playWhenReady()
      else playAgainOrFadeOut()
    }
  }

  if (round.audioAfterWord) {
    after = await createSound(round.audioAfterWord)
    after.onended = () => {
      playAgainOrFadeOut()
    }
  }

  if (before) boxAudio[box] = before
  else if (after) boxAudio[box] = after
}

const setupTask = async () => {
  finishDone = false
  audioPlaying = false
  taskCompleted = false
  stateActions.progress.progressShow(2)

  if (!task.value) {
    alert('A Type 4 task does not exist - check your Session layout in the CMS')
    return
  }

  combinedWordAudio[1] = await createSound(task.value.correctWord1Audio)
  combinedWordAudio[1].onended = () => (audioPlaying = false)
  combinedWordAudio[2] = await createSound(task.value.correctWord2Audio)
  combinedWordAudio[2].onended = () => (audioPlaying = false)

  boxImage1.value = task.value.round1.boxImage ? task.value.round1.boxImage : ''
  boxImage2.value = task.value.round2.boxImage ? task.value.round2.boxImage : ''
  const correctWord1 = parseInt(task.value.correctWord1)
  const correctWord2 = parseInt(task.value.correctWord2)
  const tempWords: Word[] = []
  task.value.draggableText.filter((t) => t.text)
  for (let i = 0; i < task.value.draggableText.length; i++) {
    const t = task.value.draggableText[i]
    const word: Word = {
      text: t.text,
      audio: t.audioURL ? await createSound(t.audioURL) : undefined,
      enabled: true,
      correctBox: 0,
      draggable: true,
      opacity: 1,
      id: 'draggable-word-' + i,
      position: { x: 0, y: 0 }
    }
    if (i + 1 === correctWord1) {
      word.correctBox = 1
      correctAudio[1] = word.audio
    } else if (i + 1 === correctWord2) {
      word.correctBox = 2
      correctAudio[2] = word.audio
    }
    tempWords.push(word)
  }
  words.value = shuffleItems(tempWords)
  setupSentence(1)
  setupSentence(2)
  introduceChallenge()
}

const introduceChallenge = () => {
  attempts = 0
  stateSetters.speakerIsPlaying = true
  setTimeout(() => {
    opacity.value = 1
    opacityWords.value = 1
    stateActions.speakLocalised(
      SpeechSounds.instructions.tasks.T4,
      () => {
        stateSetters.speakerIsPlaying = false
      },
      1000
    )
  }, 1000)
}

const completeTheWord = (box: keyof typeof displayedItem, item: Word) => {
  const wordIndex = words.value.findIndex((w) => w.id === item.id)
  showCombinedWord.value[box] = true
  if (wordIndex > -1) words.value.splice(wordIndex, 1)
  const round = box === 1 ? task.value.round1 : task.value.round2
  displayedItem[box] = item.text
  if (round.mergeBefore) combinedWord.value[box] = round.sentenceBeforeWord + item.text
  else combinedWord.value[box] = round.sentenceBeforeWord + ' ' + item.text

  if (round.mergeAfter) combinedWord.value[box] += round.sentenceAfterWord
  else combinedWord.value[box] += ' ' + round.sentenceAfterWord

  setTimeout(() => {
    mergeCombinedWord.value[box] = true
  }, 2000)
}

const onDropComplete = async (item: Word, box: keyof typeof boxAudio) => {
  roundData.correct++
  boxCompleted[box] = true
  item.enabled = false // TODO: Check that mutating a template ref works!?
  // this['dottyText' + box] = ''
  // this['enableZone' + box] = false
  stateActions.progress.completeAStar()

  const newCompletedSentenceAudioUrl =
    box === 1 ? task.value.correctWord1Audio : task.value.correctWord2Audio
  const done =
    (unforgivingTestMode && attempts === roundData.of && !finishDone) ||
    roundData.of === roundData.correct

  if (done) opacityWords.value = 0

  if (newCompletedSentenceAudioUrl) {
    const a = await createSound(newCompletedSentenceAudioUrl)
    a.onended = () => {
      // Check the allowed number of attempts here - at the moment it is matched with the number of correct answers
      // If number of attempts are limited to correct answers we will finish the task
      if (done) fadeOut()
    }
    if (!unforgivingTestMode) {
      a.playWhenReady()
      //  This deliberately finishes the task if the user does not answer after 10 seconds
      // idleTimer = setTimeout(() => fadeOut(), 10000)
    } else if (done) fadeOut()
    boxAudio[box] = a
  } else if (done) fadeOut()

  completeTheWord(box, item)
}

const finish = () => {
  opacity.value = 0
  if (!taskCompleted) {
    taskCompleted = true
    setTimeout(() => {
      emit('completed', true, tracking)
    }, 1000)
  }
}

const fadeOut = () => {
  clearTimeout(idleTimer)
  finishDone = true
  opacityWords.value = 0
  if (stateGetters.state.value.taskMode === TaskMode.Warmups) {
    stateActions.speakLocalised(SpeechSounds.instructions.warmups.T4, () => finish(), 1000, false)
  } else {
    finish()
  }
}

setupTask()
</script>

<style scoped lang="postcss">
.wordDropBox {
  display: inline-block;
  width: 170px;
  height: 70px;
  margin: 0 5px;

  border-radius: 75px;
  background-color: black;
  outline: none;
  border: solid white 5px;
  cursor: pointer;
  font-weight: 500;
  text-align: center;
  color: #000000;
}

.yellowWordText {
  font-size: 1.5rem;
  color: #ffd700;
  margin-right: 0.5rem;
}
</style>
@/draggable
