import type { ControlPosition } from '@braks/revue-draggable'

import { WordIndexName } from '@/constants'

import { Tasktype1Query, Tasktype1, Tasktype1Data } from './Tasktype1'
import { Tasktype2Query, Tasktype2, Tasktype2Data } from './Tasktype2'
import { Tasktype3Query, Tasktype3, Tasktype3Data } from './Tasktype3'
import { Tasktype4Query, Tasktype4, Tasktype4Data } from './Tasktype4'
import { Tasktype5Query, Tasktype5, Tasktype5Data } from './Tasktype5'
import { Tasktype6Query, Tasktype6, Tasktype6Data } from './Tasktype6'
import { Tasktype7Query, Tasktype7, Tasktype7Data } from './Tasktype7'
import { Tasktype8Query, Tasktype8, Tasktype8Data } from './Tasktype8'
import { Tasktype9Query, Tasktype9, Tasktype9Data } from './Tasktype9'
import { Tasktype10Query, Tasktype10, Tasktype10Data } from './Tasktype10'
import { Tasktype11Query, Tasktype11, Tasktype11Data } from './Tasktype11'
import { Tasktype12Query, Tasktype12, Tasktype12Data } from './Tasktype12'
import { Tasktype13Query, Tasktype13, Tasktype13Data } from './Tasktype13'

import { Tasktype2MPQuery, Tasktype2mp, Tasktype2MPData } from './Tasktype2mp'
import { Tasktype3MPQuery, Tasktype3mp, Tasktype3MPData } from './Tasktype3mp'
import { Tasktype9MPQuery, Tasktype9mp, Tasktype9MPData } from './Tasktype9mp'
import { Tasktype10MPQuery, Tasktype10mp, Tasktype10MPData } from './Tasktype10mp'
import { Tasktype11stData, Tasktype11stQuery, Tasktype11st } from './Tasktype11st'
import { Tasktype24STData, Tasktype24STQuery, Tasktype24st, Type24stOptions } from './Tasktype24st'
import { Tasktype22STData, Tasktype22STQuery, Tasktype22st, Type22stWord } from './Tasktype22st'
import { Tasktype23STData, Tasktype23STQuery, Tasktype23st, Type23stParcel } from './Tasktype23st'
import { WebAudio } from '@/models/audio'

/* SOLUTION TYPES */

export interface Word {
  text: string
  correctBox?: string
  enabled: boolean
  draggable: boolean
  type?: string
  id: string
  opacity: number
  audio?: WebAudio
  fontSize?: string
  holdPositionInWords?: number
  reference?: WordIndexName
}
export interface LinkedWord {
  word?: Word
  endWord?: Word
  startX: number
  'startX%'?: number
  startY: number
  'startY%'?: number
  startElement?: Element
  startElementId?: string
  endX: number
  'endX%'?: number
  endY: number
  'endY%'?: number
  endElement?: Element
  endElementId?: string
}
export interface Message {
  message?: string
  word?: LinkedWord
  event?: MouseEvent | TouchEvent
}

export interface TT3Word {
  text: string
  correct: string
  enabled: boolean
  draggable: boolean
  opacity: number
  element?: HTMLElement
  id: string
  position: ControlPosition
}

export enum Type10CorrectType {
  None = 'None',
  First = 'First',
  Second = 'Second'
}

export interface TT10Word {
  index: number
  visible: boolean
  enabled: boolean
  draggable: boolean
  opacity: number
  element?: HTMLElement
  id: string
  referenceId: string
  position: ControlPosition

  audio?: WebAudio
  text: string
  correct: Type10CorrectType
}

export interface Image {
  id: string
  index: number
  filename: string
  imageUrl: string
  correct1: boolean // correct for the first word
  correct2: boolean // correct for the second word
  enabled: boolean
  borderGreen: boolean
  borderRed: boolean
}

export interface TT11Image {
  id: string
  imageUrl: string
  imageFilename: string
  name: string
  correct: boolean
  enabled: boolean
  borderGreen: boolean
  borderRed: boolean
}

export type QuestionDataIntersection = Tasktype1Data &
  Tasktype2Data &
  Tasktype2MPData &
  Tasktype22STData &
  Tasktype23STData &
  Tasktype24STData &
  Tasktype3MPData &
  Tasktype3Data &
  Tasktype4Data &
  Tasktype5Data &
  Tasktype6Data &
  Tasktype7Data &
  Tasktype8Data &
  Tasktype9Data &
  Tasktype9MPData &
  Tasktype10Data &
  Tasktype10MPData &
  Tasktype11Data &
  Tasktype11stData &
  Tasktype12Data &
  Tasktype13Data

export type ShuffledItem = TT3Word[] | TT10Word

export type SPQuestionUnion =
  | Tasktype1
  | Tasktype2
  | Tasktype3
  | Tasktype4
  | Tasktype5
  | Tasktype6
  | Tasktype7
  | Tasktype8
  | Tasktype9
  | Tasktype10
  | Tasktype11
  | Tasktype12
  | Tasktype13

export type MPQuestionUnion = Tasktype2mp | Tasktype3mp | Tasktype9mp | Tasktype10mp

export type STQuestionUnion = Tasktype11st | Tasktype22st | Tasktype23st | Tasktype24st

export type QuestionUnion =
  | Tasktype1
  | Tasktype2
  | Tasktype3
  | Tasktype4
  | Tasktype5
  | Tasktype6
  | Tasktype7
  | Tasktype8
  | Tasktype9
  | Tasktype10
  | Tasktype11
  | Tasktype12
  | Tasktype13
  | Tasktype2mp
  | Tasktype3mp
  | Tasktype9mp
  | Tasktype10mp
  | Tasktype11st
  | Tasktype22st
  | Tasktype23st
  | Tasktype24st

export type SolutionUnion = TT3Word | TT10Word | Image | TT11Image | Word | LinkedWord
export type STSolutionUnion =
  | Type22stWord[]
  | Type23stParcel
  | Type24stOptions[]
  | Type24stOptions[][]
  | string
  | boolean
  | null // this is used to do all sorts of (sync) operations for a ST task type

export const QuestionQueriesSP = {
  Tasktype1: Tasktype1Query,
  Tasktype2: Tasktype2Query,
  Tasktype3: Tasktype3Query,
  Tasktype4: Tasktype4Query,
  Tasktype5: Tasktype5Query,
  Tasktype6: Tasktype6Query,
  Tasktype7: Tasktype7Query,
  Tasktype8: Tasktype8Query,
  Tasktype9: Tasktype9Query,
  Tasktype10: Tasktype10Query,
  Tasktype11: Tasktype11Query,
  Tasktype12: Tasktype12Query,
  Tasktype13: Tasktype13Query
}

export const QuestionQueriesMP = {
  Tasktype2mp: Tasktype2MPQuery,
  Tasktype3mp: Tasktype3MPQuery,
  Tasktype9mp: Tasktype9MPQuery,
  Tasktype10mp: Tasktype10MPQuery
}

export const QuestionQueriesST = {
  Tasktype22st: Tasktype22STQuery,
  Tasktype23st: Tasktype23STQuery,
  Tasktype24st: Tasktype24STQuery,
  Tasktype11st: Tasktype11stQuery
}

export const QuestionQueries = {
  Tasktype1: Tasktype1Query,
  Tasktype2: Tasktype2Query,
  Tasktype3: Tasktype3Query,
  Tasktype2mp: Tasktype2MPQuery,
  Tasktype22st: Tasktype22STQuery,
  Tasktype23st: Tasktype23STQuery,
  Tasktype24st: Tasktype24STQuery,
  Tasktype3mp: Tasktype3MPQuery,
  Tasktype4: Tasktype4Query,
  Tasktype5: Tasktype5Query,
  Tasktype6: Tasktype6Query,
  Tasktype7: Tasktype7Query,
  Tasktype8: Tasktype8Query,
  Tasktype9: Tasktype9Query,
  Tasktype9mp: Tasktype9MPQuery,
  Tasktype10mp: Tasktype10MPQuery,
  Tasktype10: Tasktype10Query,
  Tasktype11: Tasktype11Query,
  Tasktype11st: Tasktype11stQuery,
  Tasktype12: Tasktype12Query,
  Tasktype13: Tasktype13Query
}

export const Tasktypes = {
  Tasktype1,
  Tasktype2,
  Tasktype2mp,
  Tasktype22st,
  Tasktype23st,
  Tasktype24st,
  Tasktype3,
  Tasktype3mp,
  Tasktype4,
  Tasktype5,
  Tasktype6,
  Tasktype7,
  Tasktype8,
  Tasktype9,
  Tasktype9mp,
  Tasktype10,
  Tasktype10mp,
  Tasktype11,
  Tasktype11st,
  Tasktype12,
  Tasktype13
}
