/* eslint-disable @typescript-eslint/no-unused-vars */
<!-- Copyright 2023 Richard Nesnass, Tom Bjarne Seidel

 This file is part of KMMP.

KMMP is free software: you can redistribute it and/or modify
 it under the terms of the GNU Affero General Public License as published by
 the Free Software Foundation, either version 3 of the License, or
 (at your option) any later version.

 GPL-3.0-only or GPL-3.0-or-later

KMMP is distributed in the hope that it will be useful,
 but WITHOUT ANY WARRANTY; without even the implied warranty of
 MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 GNU Affero General Public License for more details.

 You should have received a copy of the GNU Affero General Public License
 along with KMMP.  If not, see http://www.gnu.org/licenses/. -->
<template>
  <div
    class="relative flex flex-col justify-center items-center fadeInOut"
    :style="{ opacity: opacity }"
  >
    <div class="star-container flex flex-grow mt-8">
      <div class="star-left star-transition" :style="starLeftCss">
        <div class="star-content">
          <img :src="star2partsLeft" />
          <div
            v-if="!droppedItem1"
            id="word-box-1"
            v-draggable="{ axis: 'none' }"
            class="wordDropBox wordDropBoxLeft drop-target"
            @start="onStart"
            @stop="onDrop"
            @touchstart="preventDropOnTap"
          ></div>
          <transition mode="out-in" name="fade">
            <div v-if="droppedItem1 && combinedWord === ''" class="wordDropBox wordDropBoxMiddle">
              <span @click="clickWord(droppedItem1)">{{ droppedItem1.text }}</span>
            </div>
          </transition>
        </div>
      </div>

      <div class="star-right star-transition" :style="starRightCss">
        <div class="star-content">
          <img :src="star2partsRight" />
          <div
            v-if="!droppedItem2"
            id="word-box-2"
            v-draggable="{ axis: 'none' }"
            style="left: 65px"
            class="wordDropBox wordDropBoxRight drop-target"
            @start="onStart"
            @stop="onDrop"
            @touchstart="preventDropOnTap"
          ></div>
          <transition mode="out-in" name="fade">
            <div v-if="droppedItem2 && combinedWord === ''" class="wordDropBox wordDropBoxMiddle">
              <span @click="clickWord(droppedItem2)">{{
                droppedItem2 ? droppedItem2.text : ''
              }}</span>
            </div>
          </transition>
        </div>
      </div>

      <!-- Combined word -->
      <transition mode="out-in" name="fade">
        <div v-if="combinedWord" class="wordDropBox combinedWord">
          <span>{{ combinedWord }}</span>
        </div>
      </transition>
    </div>

    <div v-if="discoveredCombinations.length > 0" class="completedWords">
      <ul>
        <li v-for="(word, index) in discoveredCombinations" :key="`word-${index}`">{{ word }}</li>
      </ul>
    </div>

    <div
      id="word-source-box"
      class="fadeInOut flex flex-row justify-between w-full px-8 my-8 place-self-end"
      :style="{ opacity: opacityWords }"
      style="margin: 50px 0"
    >
      <!-- Draggable Items -->
      <template v-for="word in words" :key="word.reference">
        <Draggable
          :id="`word-index-{{word.reference}}`"
          class="z-10"
          :class="`${activeDrags ? 'pointer-events-none touch-none' : ''}`"
          :position="word.position"
          @pointerdown="onPointerDown"
          @start="(e: any) => onStart(e)"
          @stop="(e: any) => onDrop(e, word)"
        >
          <span
            class="taskNoUserSelect borderedWordBoxFlex touch-auto pointer-events-auto"
            @click="clickWord(word)"
            >{{ word['text'] }}</span
          >
        </Draggable>
      </template>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, PropType, toRefs, Ref } from 'vue'
import { ControlPosition, DraggableEvent } from '@braks/revue-draggable'
import { Draggable } from '@braks/revue-draggable'
import { Choice, Tracking } from '@/models/main'
import useState from '@/composition/useState'
import { shuffleItems } from '@/utilities'
import { SpeechSounds, TaskMode, WordIndexName } from '@/constants'
import { Tasktype8 } from '@/models/tasktypes/Tasktype8'
import type { Type8Correct } from '@/models/tasktypes/Tasktype8'
import star2partsLeft from '@/assets/images/tasks/type7/star2parts/StarLeft@2x.png'
import star2partsRight from '@/assets/images/tasks/type7/star2parts/StarRight@2x.png'
import { WebAudio } from '@/models/audio'
import { createSound } from '@/api/audioService'
import moment from 'moment'

type DraggableDIVElementEvent = DraggableEvent & { event: { target: HTMLDivElement } }

interface Word {
  index: number
  visible: boolean
  enabled: boolean
  draggable: boolean
  opacity: number
  element?: HTMLElement
  id: string
  position: ControlPosition

  audio: WebAudio
  text: string
  reference: WordIndexName
}

const emit = defineEmits(['completed'])
const props = defineProps({
  task: { required: true, type: Object as PropType<Tasktype8> },
  myIndex: { required: false, type: Number, default: 0 }
})
const { getters: stateGetters, setters: stateSetters, actions: stateActions } = useState()
const { task } = toRefs(props)
const tracking = new Tracking(stateGetters.tracking.value)
stateSetters.trackingData = tracking
const roundData = { correct: 0, of: 0, attempts: 0 }
let choiceTimer = new Date()

const opacity = ref(0)
const opacityWords = ref(0)
const activeDrags = ref(0)

const unforgivingTestMode = false
let attempts = 0
let cancelDropEvent = false

// Models for words
const words: Ref<Word[]> = ref([])
const discoveredCombinations: Ref<string[]> = ref([])

// Divide the correct combined word answers into twos and threes, so we know how many answer boxes to show on screen
let correctCombinations: Type8Correct[] = []

const droppedItem1: Ref<Word | undefined> = ref(undefined)
const droppedItem2: Ref<Word | undefined> = ref(undefined)
const combinedWord = ref('')

let draggingNow = false
let finishDone = false
let taskCompleted = false
let audioPlaying = false

const starLeftCss = ref({})
const starRightCss = ref({})

// A 'dropbox' element should incude the class 'drop-target'
const getDropboxElement = (e: DraggableEvent): Element | undefined => {
  let x = 0
  let y = 0
  if (!e.event) return
  if (e.event.type.includes('touch')) {
    const te = e.event as TouchEvent
    x = te.changedTouches[0].clientX
    y = te.changedTouches[0].clientY
  } else {
    const me = e.event as MouseEvent
    x = me.clientX
    y = me.clientY
  }
  const el = document.elementFromPoint(x, y)
  return el && el.classList.contains('drop-target') ? el : undefined
}

const onStart = (e: DraggableEvent) => {
  if (!e.event) return
  e.event.preventDefault()
  activeDrags.value++
}

const onPointerDown = (e: PointerEvent) => {
  const el = e.target as HTMLDivElement
  if (e.pointerId && e.target) el.releasePointerCapture(e.pointerId)
}

const setupTask = async () => {
  finishDone = false
  audioPlaying = false
  taskCompleted = false
  combinedWord.value = ''
  if (typeof task.value === 'undefined' || task.value === null) {
    alert('A Type 8 task does not exist - check your Session layout in the CMS')
    return
  }

  const c2: Type8Correct[] = []
  task.value.correct.forEach((c) => {
    if (c.firstWord !== WordIndexName.None || c.secondWord !== WordIndexName.None) c2.push(c)
  })
  correctCombinations = shuffleItems(c2)
  openStars()

  if (task.value.presetWordText) {
    const audio = await createSound(task.value.presetWordAudio)
    const word: Word = {
      index: -1,
      visible: true,
      enabled: true,
      draggable: true,
      opacity: 1,
      id: `drag-word-preselected`,
      position: { x: 0, y: 0 },
      audio,
      text: task.value.presetWordText,
      reference: WordIndexName.Preselected
    }
    const presetContainer = task.value.presetWordPosition === 'First' ? droppedItem1 : droppedItem2
    presetContainer.value = word
  }

  const tempWords: Word[] = []
  for (let i = 0; i < task.value.words.length; i++) {
    const w = task.value.words[i]
    const audio = await createSound(w.audioURL)
    if (w.text)
      tempWords.push({
        index: i,
        visible: true,
        enabled: true,
        draggable: true,
        opacity: 1,
        id: `drag-word-${i}`,
        position: { x: 0, y: 0 },
        audio,
        text: w.text,
        reference: w.reference
      })
  }
  roundData.of = correctCombinations.length
  stateActions.progress.progressShow(roundData.of)
  words.value = shuffleItems(tempWords)
  /* words.sort((a, b) => {
      return a.reference - b.reference
    }) */
  introduceChallenge()
}

function preventDropOnTap() {
  cancelDropEvent = true
  setTimeout(() => {
    cancelDropEvent = false
  }, 1000)
}

function removeAllWords(discardCorrectWord: boolean) {
  if (droppedItem1.value && droppedItem1.value.reference !== WordIndexName.Preselected) {
    if (!discardCorrectWord) words.value.push(droppedItem1.value)
    droppedItem1.value = undefined
  }
  if (droppedItem2.value && droppedItem2.value.reference !== WordIndexName.Preselected) {
    if (!discardCorrectWord) words.value.push(droppedItem2.value)
    droppedItem2.value = undefined
  }
  words.value = shuffleItems(words.value)
  draggingNow = false
}

const onDrop = (e: DraggableDIVElementEvent, theWord?: Word) => {
  activeDrags.value = 0
  attempts++
  const el = getDropboxElement(e)

  const startDate = moment(choiceTimer)
  const endDate = moment()
  const choice = new Choice()
  choice.createdAt = new Date()
  choice.duration = endDate.diff(startDate, 'milliseconds')
  choice.response = theWord?.text ?? '(no word)'
  choice.target = task.value.allCorrectAnswers()
  choice.content = words.value.map((w) => w.text).join(';')
  choice.round = roundData.correct

  tracking.choices.push(choice)
  choiceTimer = new Date()

  if (theWord && el) {
    const boxIndex = getBoxIndex(el)
    const presetBoxEquivalent = boxIndex === 1 ? 'First' : boxIndex === 2 ? 'Second' : 'None'
    if (
      cancelDropEvent ||
      boxIndex === 0 ||
      presetBoxEquivalent === task.value.presetWordPosition
    ) {
      // Don't allow dropping onto the preset word, or outside a box
      theWord.position = { x: 0, y: 0 }
      return
    } else {
      choice.valid = true
      const container = boxIndex === 1 ? droppedItem1 : droppedItem2
      container.value = theWord
      const i = words.value.indexOf(theWord)
      words.value.splice(i, 1)
    }

    const cc: Type8Correct = {
      firstWord: (droppedItem1.value && droppedItem1.value.reference) || WordIndexName.None,
      secondWord: (droppedItem2.value && droppedItem2.value.reference) || WordIndexName.None,
      audioURL: '',
      audio: undefined
    }

    const result: { status: string; correctRef?: Type8Correct } = task.value.hasCombinationStatus(
      cc,
      correctCombinations
    ) // Result is the correct combination including audio

    choice.response = task.value.wordsFromCC(cc)

    if (!task.value.unforgiving) {
      if (result.status === 'correct') {
        choice.correct = true
        setTimeout(async () => {
          combinedWord.value =
            (droppedItem1.value ? droppedItem1.value.text : '') +
            (droppedItem2.value ? droppedItem2.value.text : '')
          if (discoveredCombinations.value.indexOf(combinedWord.value) === -1) {
            roundData.correct++
            stateActions.progress.completeAStar()
            // Fuse into one word
            closeStars()
            // Read completed word audio
            if (result.correctRef) {
              if (result.correctRef.audioURL) {
                const correctAudio = await createSound(result.correctRef.audioURL)
                correctAudio.playWhenReady()
              }
              // Add completed word to completed list
              discoveredCombinations.value.push(combinedWord.value)
              // Remove this combination from the list of correct combinations
              const correctIndex = correctCombinations.indexOf(result.correctRef)
              correctCombinations.splice(correctIndex, 1)
            }
            // After a delay, check if we are finished
            if (roundData.correct >= roundData.of) {
              opacityWords.value = 0
              setTimeout(() => {
                fadeOut()
              }, 1500)
            }
          }
          // Place all words back into 'words', except for a word that formed a correct combination
          setTimeout(() => {
            combinedWord.value = ''
            removeAllWords(true)
            openStars()
          }, 2000)
        }, 1000)
      } else if (result.status === 'some') {
        choice.correct = true
        draggingNow = false
        // Deactivate the boxIndex position
      } else if (result.status === 'incorrect') {
        choice.correct = false
        setTimeout(() => {
          removeAllWords(false)
        }, 100)
      }
    } else {
      // Show a star regardless of correctness
      stateActions.progress.completeAStar()

      if (result.status === 'correct') {
        choice.correct = true
        choice.phase++
      } else if (result.status === 'some') {
        choice.correct = true
        draggingNow = false
      } else if (result.status === 'incorrect') {
        choice.correct = false
      }

      setTimeout(() => {
        removeAllWords(false)
      }, 2000)

      // Check the allowed number of attempts here - at the moment it is matched with the number of correct answers
      // If number of attempts are limited to correct answers we will finish the task
      if (attempts >= roundData.of && !finishDone) {
        opacityWords.value = 0
        setTimeout(() => {
          fadeOut()
        }, 1500)
      }
    }

    // Check the allowed number of attempts here - at the moment it is matched with the number of correct answers
    // If number of attempts are limited to correct answers we will finish the task
    if (unforgivingTestMode && attempts === roundData.of && !finishDone) {
      opacityWords.value = 0
      fadeOut()
    }
  } else if (theWord) {
    clickWord(theWord)
    theWord.position = { x: 0, y: 0 }
  }
  stateSetters.trackingData = tracking
}

function getBoxIndex(target: Element) {
  return parseInt(target.id.substring(9), 10) || 0
}

function openStars() {
  starLeftCss.value = {
    left: '60px'
  }
  starRightCss.value = {
    left: '399px'
  }
}

function closeStars() {
  starLeftCss.value = {
    left: '170px'
  }
  starRightCss.value = {
    left: '309px'
  }
}

function clickWord(item?: Word) {
  if (item && !audioPlaying && !draggingNow) {
    audioPlaying = true
    item.audio.onended = () => (audioPlaying = false)
    item.audio.playWhenReady()
    tracking.use_audio_content_items++
  } else {
    draggingNow = false
  }
}

function introduceChallenge() {
  attempts = 0
  setTimeout(async () => {
    opacity.value = 1
    opacityWords.value = 1
    audioPlaying = true
    const instructionAudio = await createSound(task.value.introductionAudio)
    instructionAudio.onended = () => {
      audioPlaying = false
      stateActions.setSpeakerSound([task.value.introductionAudio])
      stateSetters.speakerIsPlaying = false
    }
    instructionAudio.playWhenReady()
    stateSetters.speakerIsPlaying = true
  }, 1000)
}

function fadeOut() {
  if (!finishDone) {
    finishDone = true
    if (stateGetters.state.value.taskMode === TaskMode.Warmups) {
      stateActions.speakLocalised(
        SpeechSounds.instructions.warmups.T8,
        () => {
          finish()
        },
        1000,
        false
      )
    } else finish()
  }
}

function finish() {
  setTimeout(() => {
    setTimeout(() => {
      opacity.value = 0
      if (!taskCompleted) {
        taskCompleted = true
        setTimeout(() => {
          emit('completed', true, tracking)
        }, 1000)
      }
    }, 500)
  }, 1000)
}

setupTask()

// ----------------- TASK 5 -----------------------
</script>

<style scoped lang="postcss">
.gu-mirror span {
  font-size: 1em;
}
.gu-transit span {
  font-size: 1em;
}

.wordDropBox {
  min-width: 150px;
  height: 70px;
  position: absolute;

  border-radius: 75px;
  background-color: #330670;

  outline: none;
  border: solid #7714ff 5px;
  cursor: pointer;
  font-size: 24pt;
  font-weight: 500;
  text-align: center;
  color: #ffd700;
  padding: 0 10px;
}

.wordDropBox span {
  display: inline-block;
  vertical-align: middle;
  height: 100%;
  line-height: 60px;
}

.wordDropBoxLeft {
  top: 170px;
  left: 0;
}
.wordDropBoxMiddle {
  top: 170px;
  left: 15px;
}
.wordDropBoxRight {
  top: 170px;
  left: 30px;
}

.task-container {
  width: 100vw;
  position: relative;
  padding-top: 2%;
}

.scanner-image {
  margin-right: auto;
  margin-left: auto;
  display: block;
}
.scanner-content {
  position: absolute;
  width: 100%;
}
.scanner-word {
  margin-top: -45px;
}

.completedWords {
  position: absolute;
  top: 80%;
  right: 5%;
  color: #0ee5ff;
  font-size: 14pt;
  text-align: left;

  background-color: rgba(0, 0, 0, 0.6);
  padding: 10px 15px 10px 10px;
  border-radius: 10px;
}
.completedWords ul {
  margin: 0;
}

.combinedWord {
  top: 170px;
  left: 260px;
}

.star-container {
  min-height: 300px;
  position: relative;
  display: block;
  width: 80%;
}

.star-transition {
  -webkit-transition: left 0.5s ease;
  -moz-transition: left 0.5s ease;
  -ms-transition: left 0.5s ease;
  -o-transition: left 0.5s ease;
  transition: left 0.5s ease;
}

.star-left {
  position: absolute;
  top: 0;
}
.star-left img {
  height: 400px;
}

.star-middle {
  position: absolute;
  top: 0px;
}
.star-middle img {
  height: 400px;
}

.star-right {
  position: absolute;
  top: 0px;
}
.star-right img {
  height: 400px;
}

.star-content {
  position: relative;
  width: 100%;
  height: 100%;
}
</style>
@/draggable
