<!-- Copyright 2023 Richard Nesnass, Tom Bjarne Seidel

 This file is part of KMMP.

KMMP is free software: you can redistribute it and/or modify
 it under the terms of the GNU Affero General Public License as published by
 the Free Software Foundation, either version 3 of the License, or
 (at your option) any later version.

 GPL-3.0-only or GPL-3.0-or-later

KMMP is distributed in the hope that it will be useful,
 but WITHOUT ANY WARRANTY; without even the implied warranty of
 MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 GNU Affero General Public License for more details.

 You should have received a copy of the GNU Affero General Public License
 along with KMMP.  If not, see http://www.gnu.org/licenses/. -->
<template>
  <div class="flex flex-row py-2 rounded border border-slate-700" :class="[showSave ? 'bg-green-200' : 'bg-slate-900']">
    <template v-if="edit">
      <div class="flex flex-row items-center w-full mx-2">
        <AnswerInput
          v-model="editableGroup.name"
          class="m-2"
          mode="text"
          label="Name"
          :border="false"
          custom-size="12"
          @change="saveActive()"
        ></AnswerInput>
        <AnswerInput
          v-model="editableGroup.location"
          class="m-2"
          mode="text"
          label="Class"
          :border="false"
          custom-size="12"
          @change="saveActive()"
        ></AnswerInput>
        <div class="flex flex-row justify-end w-full px-2">
          <Button v-if="showSave" backgroundcolour="bg-green-300 rounded-none w-24" bordercolour="border-green-500 " @click="saveGroup()">
            Save
          </Button>
          <Button backgroundcolour="bg-red-300 border-red-500 rounded-none w-24" bordercolour="border-red-500 " @click="deleteGroup()">
            Delete
          </Button>
        </div>
      </div>
    </template>

    <template v-else>
      <div class="flex flex-row items-center justify-between w-full text-white px-4">
        <p>
          {{ group.name }}
        </p>
        <p v-if="group.location">
          {{ group.location }}
        </p>
        <p v-else>unknown</p>
        <slot />
      </div>
    </template>
  </div>
</template>

<script setup lang="ts">
import { PropType, ref, reactive } from 'vue'
import { Group } from '@/models/main'
import useUserStore from '@/store/useUserStore'
import AnswerInput from '@/components/base/AnswerInput.vue'
import Button from '@/components/base/Button.vue'

const props = defineProps({
  group: {
    type: Object as PropType<Group>,
    required: true,
  },
  edit: {
    type: Boolean,
  },
  selected: {
    type: Boolean,
  },
})
const { actions: userActions } = useUserStore()
const showSave = ref(false)

const g = new Group(props.group)
const editableGroup = reactive<Group>(g)

const saveGroup = async () => {
  await userActions.updateGroup(editableGroup)
  showSave.value = false
}

const deleteGroup = async () => {
  await userActions.deleteGroup(editableGroup)
  showSave.value = false
}

const saveActive = () => {
  showSave.value = true
}

</script>

<style scoped></style>
