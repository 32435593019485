/* eslint-disable @typescript-eslint/no-unused-vars */
<!-- Copyright 2023 Richard Nesnass, Tom Bjarne Seidel

 This file is part of KMMP.

KMMP is free software: you can redistribute it and/or modify
 it under the terms of the GNU Affero General Public License as published by
 the Free Software Foundation, either version 3 of the License, or
 (at your option) any later version.

 GPL-3.0-only or GPL-3.0-or-later

KMMP is distributed in the hope that it will be useful,
 but WITHOUT ANY WARRANTY; without even the implied warranty of
 MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 GNU Affero General Public License for more details.

 You should have received a copy of the GNU Affero General Public License
 along with KMMP.  If not, see http://www.gnu.org/licenses/. -->
<template>
  <div
    class="relative flex flex-col justify-center items-center fadeInOut"
    :style="{ opacity: state.opacity }"
  >
    <div class="flex flex-wrap justify-between items-center relative text-white h-full">
      <div
        v-for="(image, i) in state.images"
        :key="i"
        class="flex borderedImage imageMedium overflow-hidden items-end"
        :class="imageClasses(image)"
        :style="state.heightStyle + imageStyles(image, fromOwnUser(image))"
      >
        <img
          v-cache
          class="flex self-center w-full h-full object-contain bg-white"
          :src="image.imageUrl"
          @click="clickAnswer(image)"
        />
        <div class="flex absolute z-50 justify-center items-center m-2">
          <div
            v-if="fromParticipantUser(image)"
            class="rounded-full bg-white p-1 relative h-11 w-11 flex justify-center items-center m-px"
            :style="imageStyles(image)"
          >
            <Avatar :avatar-ref="participantInformation.avatar.ref" />
          </div>
          <div
            v-if="fromOwnUser(image)"
            class="rounded-full bg-white p-1 relative h-11 w-11 flex justify-center items-center m-px"
            :style="imageStyles(image, true)"
          >
            <Avatar :avatar-ref="user.getters.myUser.value.avatar.ref" />
          </div>
        </div>
      </div>
    </div>

    <span class="borderedWordBox" @click="playWordAudio()">
      {{ task.word }}
    </span>
  </div>
</template>

<script setup lang="ts">
import { PropType, toRefs, onMounted, nextTick, reactive } from 'vue'
import { useI18n } from 'vue-i18n'

import Avatar from '@/components/base/Avatar.vue'

import {
  SpeechSounds,
  TaskMode,
  TaskCallbackType,
  DialogMessageType,
  UserTaskRole,
  TaskCallback,
  TaskCallbackParam
} from '@/constants'
import { Tasktype11st } from '@/models/tasktypes/Tasktype11st'
import { SolutionUnion, TT11Image } from '@/models/tasktypes'
import { TaskSyncTT11Image } from '@/constants'
import { shuffleItems } from '@/utilities'
import { Choice, TaskSync, Tracking } from '@/models/main'

import useMultiPlayerState from '@/composition/useMultiplayerState'
import useDialogStore from '@/composition/dialog'
import useUserStore from '@/store/useUserStore'
import useColorStore from '@/composition/colors'
import useState from '@/composition/useState'
import { WebAudio } from '@/models/audio'
import { createSound } from '@/api/audioService'
import moment from 'moment'

const emit = defineEmits<{
  (e: 'completed', value: boolean, tracking: Tracking): void
  (
    e: 'addSync',
    taskId: string,
    solution: SolutionUnion | null,
    solutionId: string,
    additionalSolutions?: SolutionUnion[]
  ): void
  (e: 'updateShuffleOrder', shuffleOrder: string[]): void
  (e: 'registerCallback', type: TaskCallbackType, callback: TaskCallback): void
  (
    e: 'showResultMessage',
    messageType: DialogMessageType,
    solution: string,
    callback: () => void
  ): void
}>()

const props = defineProps({
  task: { required: true, type: Object as PropType<Tasktype11st> },
  myIndex: { required: false, type: Number, default: 0 },
  actionAllowed: { required: true, type: Boolean },
  firstDecision: {
    required: false,
    type: Object as PropType<TaskSyncTT11Image | null>,
    default: null
  },
  advice: { required: false, type: Object as PropType<TaskSyncTT11Image | null>, default: null },
  finalDecision: {
    required: false,
    type: Object as PropType<TaskSyncTT11Image | null>,
    default: null
  },
  role: { required: true, type: String }
})

const multiplayer = useMultiPlayerState()
const dialog = useDialogStore()
const user = useUserStore()
const color = useColorStore()

const participantInformation = multiplayer.getters.participantInformation

const { getters: stateGetters, setters: stateSetters, actions: stateActions } = useState()
const { task } = toRefs(props)
const { t } = useI18n()

const tracking = new Tracking(stateGetters.tracking.value)
stateSetters.trackingData = tracking
let choiceTimer = new Date()
let choiceAttempt = 1
const roundData = { correct: 0, of: 0, phase: 1 }

let textAudio: WebAudio | undefined = undefined
let instructionAudio: WebAudio

const state = reactive({
  initialImages: [] as TT11Image[],
  images: [] as TT11Image[],

  opacity: 0,
  attempts: 0,
  finished: false,
  playingAudio: false,
  heightStyle: ''
})

/* HOOKS */
onMounted(() => {
  emit('registerCallback', TaskCallbackType.FinalDecisionAccept, finalDecisionAcceptedCallback)
  emit('registerCallback', TaskCallbackType.FinalDecisionReject, finalDecisionRejectedCallback)
  emit('registerCallback', TaskCallbackType.Shuffle, shuffleCallback)
  emit('registerCallback', TaskCallbackType.ShuffleOnRequest, ShuffleOnRequestCallback)

  setupTask()
  state.heightStyle = 'height: 50%;'
})

function finalDecisionAcceptedCallback(args: TaskCallbackParam) {
  const decision = args as TaskSync
  submitFinalDecision(decision.solution as TT11Image)
}

function finalDecisionRejectedCallback(args: TaskCallbackParam) {
  const decision = args as TaskSync
  multiplayer.actions.resetSync() // reset for current round
  resetImageSelection(decision)
  choiceAttempt++
}

const clickAnswer = (item: TT11Image) => {
  if (props.actionAllowed) emit('addSync', props.task.id, item, item.id)
  else dialog.actions.pushMessage(DialogMessageType.Warning, t('waitforturn'))
}

const resetImageSelection = (decision: TaskSync) => {
  nextTick(() => {
    const index = state.images.findIndex((i) => i.id === decision.solution_id)
    if (index !== -1) {
      // remove any borders (if existing)
      const image = state.images[index]
      image.borderGreen = false
      image.borderRed = false
    }
  })
}

function shuffleCallback(args: TaskCallbackParam) {
  const items = args as string[]
  const shuffledItems = [] as TT11Image[]
  let newIndex = 0
  for (const item of items) {
    const index = state.initialImages.findIndex((image) => image.id === item)
    shuffledItems[newIndex] = state.initialImages[index]
    newIndex++
  }
  nextTick(() => (state.images = shuffledItems.filter((i) => i)))
}

function ShuffleOnRequestCallback() {
  roundData.phase++
  shuffleAndSync(state.images, false) // trigger shuffle parcel after advisor has switched task/round
}

async function setupTask() {
  // Choose random locations
  //let firstItem = Math.random() > 0.5 ? '' : 'itemB';
  //let secondItem = firstItem === 'itemA' ? 'itemB' : 'itemA';
  state.finished = false
  state.playingAudio = false
  if (typeof task.value === 'undefined' || task.value === null) {
    alert('A Type 11MP task does not exist - check your Session layout in the CMS')
    return
  }

  const tempImages: TT11Image[] = []
  task.value.images.forEach((im, i) => {
    const TT11Image: TT11Image = {
      id: `TT11Image-id-${i}`,
      imageUrl: im.file,
      imageFilename: im.filename,
      name: im.name,
      correct: im.correct,
      enabled: true,
      borderGreen: false,
      borderRed: false
    }
    if (TT11Image.correct) roundData.of++
    if (TT11Image.imageUrl) tempImages.push(TT11Image)
  })
  shuffleAndSync(tempImages)

  stateActions.progress.progressShow(roundData.of)

  if (task.value.audio) {
    textAudio = await createSound(task.value.audio)
    textAudio.onended = () => {
      state.playingAudio = false
    }
  }
  if (task.value.instructionAudio) {
    instructionAudio = await createSound(task.value.instructionAudio)
    instructionAudio.onended = () => {
      state.playingAudio = false
      stateActions.setSpeakerSound([task.value.instructionAudio])
      stateSetters.speakerIsPlaying = false
    }
  }
  introduceChallenge()
}

const shuffleAndSync = (images: TT11Image[], shuffle = true) => {
  const shuffledItems = shuffle ? (shuffleItems(images) as TT11Image[]) : state.images
  if (state.initialImages.length === 0) state.initialImages = shuffledItems
  if (props.role === UserTaskRole.Student) {
    const shuffleOrder = shuffledItems.map((image) => {
      return image.id
    })
    emit('updateShuffleOrder', shuffleOrder) // sync the re-shuffled words
  }
  if (shuffle) state.images = shuffledItems
}

function introduceChallenge() {
  state.attempts = 0
  setTimeout(() => {
    state.opacity = 1
    if (task.value.instructionAudio) {
      state.playingAudio = true
      stateSetters.speakerIsPlaying = true
      instructionAudio.playWhenReady()
    }
  }, 1000)
}

function playWordAudio() {
  if (!state.playingAudio && textAudio) {
    state.playingAudio = true
    textAudio.playWhenReady()
    tracking.use_audio_content_items++
  }
}

const imageClasses = (image: TT11Image) => {
  return { borderGreen: image.borderGreen, borderRed: image.borderRed }
}

const imageStyles = (image: TT11Image, ownUser = false) => {
  if (
    [
      props.firstDecision?.solution_id,
      props.advice?.solution_id,
      props.finalDecision?.solution_id
    ].some((id) => id === image.id)
  ) {
    const userName = ownUser
      ? user.getters.myUser.value.profile.username
      : participantInformation.value.name
    return `border: 5px solid ${color.actions.selectColor(userName)};`
  }
}

const fromParticipantUser = (image: TT11Image) => {
  return (
    (props.role === UserTaskRole.Leader && image.id === props.advice?.solution_id) ||
    (props.role === UserTaskRole.Advisor && image.id === props.firstDecision?.solution_id)
  )
}

const fromOwnUser = (image: TT11Image) => {
  return (
    (props.role === UserTaskRole.Leader && image.id === props.firstDecision?.solution_id) ||
    (props.role === UserTaskRole.Advisor && image.id === props.advice?.solution_id)
  )
}

const submitFinalDecision = (item: TT11Image) => {
  const choice = new Choice()
  choice.duration = moment().diff(moment(choiceTimer), 'milliseconds')
  choice.content = state.images.map((i) => i.name).join(';')
  choice.response = item.name
  choice.phase = roundData.phase
  choice.attempt = choiceAttempt
  choice.committed = true
  choice.target = state.images
    .filter((i) => i.correct)
    .map((i) => i.name)
    .join(';')
  tracking.choices.push(choice)
  choiceTimer = new Date()

  if (item.enabled && !state.finished) {
    state.attempts++
    if (item.correct) {
      roundData.correct++
      choice.correct = true
      stateActions.progress.completeAStar()
      item.borderGreen = true
    } else {
      item.borderRed = true
      choice.correct = false
      if (task.value.unforgiving) {
        stateActions.progress.completeAStar()
      }
      //dataService.tryAgain()
    }
    item.enabled = false

    // Set the allowed number of attempts here - at the moment it is matched with the number of correct answers
    if (
      (task.value.unforgiving && state.attempts === roundData.of) ||
      (!task.value.unforgiving && roundData.of === roundData.correct)
    ) {
      state.finished = true
      nextRound()
    }
  }
}

function nextRound() {
  setTimeout(() => {
    state.opacity = 0
    if (stateGetters.state.value.taskMode === TaskMode.Warmups) {
      stateActions.speakLocalised(
        SpeechSounds.instructions.warmups.T11,
        () => {
          state.opacity = 0
          completeTask()
        },
        1000,
        false
      )
    } else {
      setTimeout(() => {
        completeTask()
      }, 1000)
    }
  }, 500)
}

function completeTask() {
  emit('completed', true, tracking)
}
</script>

<style scoped lang="postcss">
.scanner-word {
  margin-top: -50px;
  position: relative;
  z-index: 100;
}
</style>
