/* eslint-disable @typescript-eslint/no-unused-vars */
<!-- Copyright 2023 Richard Nesnass, Tom Bjarne Seidel

 This file is part of KMMP.

KMMP is free software: you can redistribute it and/or modify
 it under the terms of the GNU Affero General Public License as published by
 the Free Software Foundation, either version 3 of the License, or
 (at your option) any later version.

 GPL-3.0-only or GPL-3.0-or-later

KMMP is distributed in the hope that it will be useful,
 but WITHOUT ANY WARRANTY; without even the implied warranty of
 MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 GNU Affero General Public License for more details.

 You should have received a copy of the GNU Affero General Public License
 along with KMMP.  If not, see http://www.gnu.org/licenses/. -->

<template>
  <div
    class="relative flex flex-col justify-center items-center fadeInOut"
    :style="{ opacity: opacity }"
  >
    <div class="flex justify-evenly w-full h-full items-center flex-col">
      <Draggable
        v-if="currentWord"
        class="fadeInOut text-white borderedWordBox borderedTaskWordBox bottom-8 z-10 absolute"
        :style="{ opacity: opacityWords }"
        :class="`${activeDrags ? 'pointer-events-none' : ''}`"
        :position="currentWord.position"
        @start="(e) => onStart()"
        @stop="(e) => onDrop(e, currentWord)"
      >
        <div class="" :class="{ highlightByScaling: highlightWord }">
          <span>{{ currentWord.text }}</span>
        </div>
      </Draggable>

      <div class="flex w-full items-center justify-center">
        <!-- Red Scanner -->
        <div
          id="red-scanner"
          ref="redScanner"
          v-draggable="{ axis: 'none' }"
          class="flex flex-col mr-3 place-items-center justify-center w-1/2 border-2 rounded-xl"
          :class="{ hoverhighlight1: box1Highlight }"
          :style="scannerHeight()"
          @start="onStart"
          @stop="onDrop"
        >
          <div
            id="red-scanner-words"
            class="flex flex-col place-items-center justify-center w-full h-full drop-target"
          >
            <div
              v-for="word in correctBox['red-scanner'].items"
              :key="word.id"
              class="flex justify-between items-center dialog-bg fadedBorder relative rounded-full px-1 mt-2"
              @click="clickBoxedWord(word)"
            >
              <p class="generalTextStyle inline-block z-10 text-white px-3 py-2">{{ word.text }}</p>
            </div>
          </div>
          <span
            class="borderedWordBox red-border wordHighlight"
            :class="{ highlightByScaling: highlight1 }"
            @click="clickWord(1)"
            >{{ task.stem }}</span
          >
        </div>

        <!-- Blue Scanner -->
        <div
          id="blue-scanner"
          ref="blueScanner"
          v-draggable="{ axis: 'none' }"
          class="flex flex-col place-items-center justify-center w-1/2 h-1/2 border-2 rounded-xl"
          :class="{ hoverhighlight2: box2Highlight }"
          :style="scannerHeight()"
          @start="onStart"
          @stop="onDrop"
        >
          <div
            id="blue-scanner-words"
            class="flex flex-col place-items-center justify-center w-full h-full drop-target"
          >
            <div
              v-for="word in correctBox['blue-scanner'].items"
              :key="word.id"
              class="flex justify-between items-center dialog-bg fadedBorder relative rounded-full px-1 mt-2"
              @click="clickBoxedWord(word)"
            >
              <p class="generalTextStyle inline-block z-10 text-white px-3 py-2">{{ word.text }}</p>
            </div>
          </div>
          <span
            class="borderedWordBox blue-border wordHighlight"
            :class="{ highlightByScaling: highlight2 }"
            @click="clickWord(2)"
            >{{ task.morphedStem }}</span
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, PropType, toRefs, Ref } from 'vue'
import type { ControlPosition, DraggableEvent } from '@braks/revue-draggable'
import { Draggable } from '@braks/revue-draggable'
import { Choice, Tracking } from '@/models/main'
import useState from '@/composition/useState'
import { shuffleItems } from '@/utilities'
import { SpeechSounds, TaskMode } from '@/constants'
import { Tasktype3 } from '@/models/tasktypes/Tasktype3'
import { WebAudio } from '@/models/audio'
import { createSound } from '@/api/audioService'
import moment from 'moment'

type DraggableDIVElementEvent = DraggableEvent & { event: { target: HTMLDivElement } }

interface Word {
  text: string
  correct: string
  enabled: boolean
  draggable: boolean
  opacity: number
  element?: HTMLElement
  id: string
  position: ControlPosition
}

const emit = defineEmits(['completed'])
const props = defineProps({
  task: { required: true, type: Object as PropType<Tasktype3> },
  myIndex: { required: false, type: Number, default: 0 }
})
const { getters: stateGetters, setters: stateSetters, actions: stateActions } = useState()
const { task } = toRefs(props)
const tracking = new Tracking(stateGetters.tracking.value)
stateSetters.trackingData = tracking
let choiceTimer = new Date()

const roundData = { correct: 0, of: 0 }
let round = 1

const opacity = ref(0)
const activeDrags = ref(0)

const unforgivingTestMode = false
let attempts = 0
const opacityWords = ref(0.1)
const redScanner = ref()
const blueScanner = ref()

// public draggableWords = [];
const wordStore: Ref<Word[]> = ref([])
const currentWord: Ref<Word | undefined> = ref()
const droppedObjects1: Ref<Word[]> = ref([])
const droppedObjects2: Ref<Word[]> = ref([])

let draggingNow = false
const highlight1 = ref(false)
const highlight2 = ref(false)
const highlightWord = ref(false)
const box1Highlight = ref(false)
const box2Highlight = ref(false)
let instructionAudio1: WebAudio
let instructionAudio2: WebAudio
let taskCompleted = false
const boxAudio: { [key: number]: WebAudio } = {}
const wordAudio: { [key: string]: WebAudio } = {}
let introDone = false
let audioPlaying = false

const correctBox: Ref<{ [key: string]: { name: string; items: Word[] } }> = ref({
  'blue-scanner': { name: 'Morphed Stem', items: [] },
  'red-scanner': { name: 'Stem', items: [] }
})

// ---------      Dragula functions -------------------

const onStart = () => {
  activeDrags.value++
}

// A 'dropbox' element should incude the class 'drop-target'
const getDropboxElement = (e: DraggableEvent): Element | undefined => {
  let x = 0
  let y = 0
  if (!e.event) return
  if (e.event && e.event.type.includes('touch')) {
    const te = e.event as TouchEvent
    x = te.changedTouches[0].clientX
    y = te.changedTouches[0].clientY
  } else if (e.event) {
    const me = e.event as MouseEvent
    x = me.clientX
    y = me.clientY
  } else return
  const el = document.elementFromPoint(x, y)
  return el && el.classList.contains('drop-target') ? el : undefined
}

const onDrop = (e: DraggableDIVElementEvent, word?: Word) => {
  activeDrags.value = 0
  box1Highlight.value = false
  box2Highlight.value = false
  const el = getDropboxElement(e)

  const startDate = moment(choiceTimer)
  const endDate = moment()
  const choice = new Choice()
  choice.duration = endDate.diff(startDate, 'milliseconds')
  choice.response = currentWord.value?.text ?? '(no word)'
  choice.target = currentWord.value?.correct === 'Stem' ? task.value.stem : task.value.morphedStem
  choice.content = currentWord?.value?.text ?? '(no word)'
  choice.round = round

  if (word && el) {
    choice.valid = true
    choice.committed = true
    currentWord.value = undefined
    // if (word && el && el.classList.contains('drop-target')) {
    let boxID = el.id || undefined
    attempts++
    console.log(`Dropped word index ${word.text} onto box ${boxID}.`)
    /* redScanner.value.classList.remove('saturate-200')
      blueScanner.value.classList.remove('saturate-200') */
    if (boxID?.includes('red-scanner')) boxID = 'red-scanner'
    if (boxID?.includes('blue-scanner')) boxID = 'blue-scanner'
    if (boxID && word.correct === correctBox.value[boxID].name) {
      correctBox.value[boxID].items.push(word)
      choice.correct = true
      onDropComplete(word)
    } else {
      word.opacity = 0
      wordStore.value.push(word)
      wordStore.value = shuffleItems(wordStore.value)
      setTimeout(() => {
        const w = wordStore.value.pop()
        if (w) currentWord.value = w
        setTimeout(() => {
          if (currentWord.value) currentWord.value.opacity = 1
          draggingNow = false
          playWordAudio()
        }, 1000)
      }, 0)
    }
  } else if (word) {
    clickBoxedWord(word)
    word.position = { x: 0, y: 0 }
  }
  tracking.choices.push(choice)
  stateSetters.trackingData = tracking
  choiceTimer = new Date()
}

/*   function onDropAreaMouseEnter(e: MouseEvent | TouchEvent) {
    const el = e && (e.target as HTMLElement)
    if (activeDrags.value && el) {
      if (el.id === 'red-scanner') redScanner.value.classList.add('saturate-200')
      else blueScanner.value.classList.add('saturate-200')
    }
  }

  function onDropAreaMouseLeave(e: MouseEvent | TouchEvent) {
    const el = e && (e.target as HTMLElement)
    if (activeDrags.value && el) {
      if (el.id === 'red-scanner') redScanner.value.classList.remove('saturate-200')
      else blueScanner.value.classList.remove('saturate-200')
    }
  } */

// ---------------  Setup --------------------------

const playWordAudio = () => {
  if (!draggingNow && introDone && !audioPlaying) {
    audioPlaying = true
    highlight2.value = false
    highlight1.value = false
    highlightWord.value = true
    if (currentWord.value) {
      wordAudio[currentWord.value.text].onended = () => {
        highlightWord.value = false
        audioPlaying = false
      }
      wordAudio[currentWord.value.text].playWhenReady()
    } else {
      highlightWord.value = false
      audioPlaying = false
    }
  }
}

const clickWord = (box: number) => {
  if (!draggingNow && !audioPlaying && boxAudio[box]) {
    audioPlaying = true
    boxAudio[box].playWhenReady()
    tracking.use_audio_content_items++
  }
}

const clickBoxedWord = (word?: Word) => {
  if (word && !draggingNow && !audioPlaying && wordAudio[word.text]) {
    audioPlaying = true
    wordAudio[word.text].playWhenReady()
    tracking.use_audio_content_items++
  }
}

const setupTask = async () => {
  // Choose random locations
  // let firstItem = Math.random() > 0.5 ? '' : 'itemB';
  // let secondItem = firstItem === 'itemA' ? 'itemB' : 'itemA';

  if (typeof task.value === 'undefined' || task.value === null) {
    alert('A Type 3 task does not exist - check your Session layout in the CMS')
    return
  }
  audioPlaying = false
  taskCompleted = false
  // box1Image = typeof task.box1Image !== 'undefined' ? task.box1Image.url : 'img/placeholder.jpg';
  // box2Image = typeof task.box2Image !== 'undefined' ? task.box2Image.url : 'img/placeholder.jpg';
  //box1Image = '@/assets/images/placeholder.jpg'
  //box2Image = '@/assets/images/placeholder.jpg'

  boxAudio[1] = await createSound(task.value.audio)
  if (boxAudio[1]) {
    boxAudio[1].onended = () => {
      audioPlaying = false
      highlightWord.value = false
    }
  }

  boxAudio[2] = await createSound(task.value.audio2)
  if (boxAudio[2]) {
    boxAudio[2].onended = () => {
      audioPlaying = false
      highlightWord.value = false
    }
  }

  droppedObjects1.value = []
  droppedObjects2.value = []
  let tempWords: Word[] = []
  for (let i = 0; i < task.value.options.length; i++) {
    const o = task.value.options[i]
    if (o.text) {
      const word: Word = {
        text: o.text,
        correct: o.correct,
        enabled: false,
        draggable: true,
        opacity: 0,
        position: { x: 0, y: 0 },
        id: 'draggable-word-' + i
      }

      wordAudio[word.text] = await createSound(o.audio)
      if (wordAudio[word.text]) {
        wordAudio[word.text].onended = () => {
          audioPlaying = false
          highlightWord.value = false
        }
      }

      if (o.correct) roundData.of++
      const occurances = o.repeated
      for (let r = 0; r < occurances; r++) {
        tempWords.push({ ...word })
      }
    }
  }
  stateActions.progress.progressShow(tempWords.length)
  tempWords = shuffleItems(tempWords)

  wordStore.value = tempWords // .splice(0,3);
  // words[1] = tempWords;

  const w = wordStore.value.pop()
  if (w) currentWord.value = w
  if (currentWord.value) currentWord.value.enabled = true

  setTimeout(() => {
    if (currentWord.value) currentWord.value.opacity = 1
  }, 1000)

  if (task.value.audio) instructionAudio1 = await createSound(task.value.audio)
  if (task.value.audio2) instructionAudio2 = await createSound(task.value.audio2)

  introduceChallenge()
}

const introduceChallenge = () => {
  attempts = 0
  opacity.value = 1
  opacityWords.value = 1
  choiceTimer = new Date()

  const onSoundCompleted = () => {
    if (task.value.audio) {
      if (task.value.audio2) {
        instructionAudio1.onended = () => {
          highlight1.value = false
          setTimeout(() => {
            highlight2.value = true
            instructionAudio2.playWhenReady()
          }, 500)
        }
        instructionAudio2.onended = () => {
          introDone = true
          highlight2.value = false
          setTimeout(() => {
            audioPlaying = false
            stateSetters.speakerIsPlaying = false
            playWordAudio()
          }, 1000)
        }
      } else {
        instructionAudio1.onended = () => {
          introDone = true
          highlight1.value = false
          setTimeout(() => {
            audioPlaying = false
            stateSetters.speakerIsPlaying = false
            playWordAudio()
          }, 1000)
        }
      }
      setTimeout(() => {
        highlight1.value = true
        instructionAudio1.playWhenReady()
      }, 500)
    } else {
      introDone = true
      audioPlaying = false
      stateSetters.speakerIsPlaying = false
    }
  }
  // Play generic audio including two box audios
  audioPlaying = true
  stateSetters.speakerIsPlaying = true
  stateActions.speakLocalised(SpeechSounds.instructions.tasks.T3, () => onSoundCompleted(), 1000)
}

const onDropComplete = (item: Word) => {
  highlightWord.value = false
  roundData.correct++
  item.opacity = 0
  item.enabled = false
  round++

  stateActions.progress.completeAStar()
  if (wordStore.value.length > 0) {
    const w = wordStore.value.pop()
    if (w) currentWord.value = w
    if (currentWord.value) currentWord.value.enabled = true
  }
  setTimeout(() => {
    opacityWords.value = 1
    // Set the allowed number of attempts here - at the moment it is matched with the number of correct answers
    if (
      (unforgivingTestMode && attempts === roundData.of) ||
      (!unforgivingTestMode && roundData.of === roundData.correct)
    ) {
      if (!taskCompleted) {
        taskCompleted = true
        opacityWords.value = 0
        if (stateGetters.state.value.taskMode === TaskMode.Warmups) {
          stateActions.speakLocalised(
            SpeechSounds.instructions.warmups.T3,
            () => {
              fadeOut()
            },
            1000,
            false
          )
        } else fadeOut()
      }
    }
    if (currentWord.value) currentWord.value.opacity = 1
    draggingNow = false
    setTimeout(() => {
      choiceTimer = new Date()
      audioPlaying = false
      playWordAudio()
    }, 500)
  }, 1000)
}

const fadeOut = () => {
  //setTimeout(() => {
  opacity.value = 0
  completeTask()
  //}, 1000)
}

const scannerHeight = () => {
  if (redScanner.value) return `height: ${redScanner.value.getBoundingClientRect().width}px;`
}

const completeTask = () => {
  setTimeout(() => {
    emit('completed', true, tracking)
  }, 1000)
}
setupTask()
</script>

<style scoped lang="postcss">
.red-border {
  border-color: #dc1d43;
}
.blue-border {
  border-color: #066ec0;
}
.hoverhighlight1 {
  background-color: rgba(255, 0, 0, 0.3);
}
.hoverhighlight2 {
  background-color: rgba(0, 255, 255, 0.3);
}
</style>
@/draggable
