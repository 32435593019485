interface Actions {
  selectColor: (name: string, override?: boolean) => string
  selectColorAsHex: (name: string) => string
  hslToHex: (hue: number) => string
}

interface ServiceInterface {
  actions: Actions
}

function useColorStore(): ServiceInterface {
  const actions = {
    /**
     * selectColor()
     * returns either a color or its complement by using the override value
     * @param name
     * @param override
     * @returns
     */
    selectColor(name: string, override = false): string {
      // note: this is supposed to work with names, not ids
      let value = name.length * 500

      for (let i = 0; i < value; i++) {
        const current = name.charCodeAt(i)
        // non ascii characters don't work for simplicity reasons
        if (current !== undefined && !isNaN(current)) value += current
      }

      const hue = value * 137.508 // use golden angle approximation
      return !override ? `hsl(${parseInt(hue.toString())},70%,50%);` : `hsl(${parseInt((hue * 137.508).toString())},10%,25%);`
    },

    selectColorAsHex(name: string): string {
      // note: this is supposed to work with names, not ids
      let value = name.length * 500

      for (let i = 0; i < value; i++) {
        const current = name.charCodeAt(i)
        // non ascii characters don't work for simplicity reasons
        if (current !== undefined && !isNaN(current)) value += current
      }

      const hue = value * 137.508 // use golden angle approximation
      return this.hslToHex(hue)
    },

    hslToHex(hue: number) {
      const s = 70
      let l = 50

      l /= 100
      const a = (s * Math.min(l, 1 - l)) / 100
      const getValue = (n: number) => {
        const k = (n + hue / 30) % 12
        const color = l - a * Math.max(Math.min(k - 3, 9 - k, 1), -1)
        return Math.round(255 * color)
          .toString(16)
          .padStart(2, '0')
      }
      return `#${getValue(0)}${getValue(8)}${getValue(4)}`
    },
  }

  return { actions }
}

export default useColorStore
