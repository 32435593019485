<template>
  <template v-if="captureDisallowed">
    <div v-if="state.screenshot" class="z-20 absolute border-4 rounded border-green-400 m-4 p-1">
      <img class="w-full h-full" :src="state.screenshot" />
      <p class="text-green-400">{{ `Student screen: ${state.seconds} seconds ago` }}</p>
    </div>
    <div v-if="!state.screenshot" class="z-20 absolute border-4 rounded m-4 p-1">
      <p class="text-orange-400">{{ `Please wait for the student to make their first move` }}</p>
    </div>
  </template>
  <template v-else>
    <div class="absolute w-0 h-0"></div>
  </template>
</template>

<script setup lang="ts">
import { onMounted, reactive, watch, computed } from 'vue'
import html2canvas from 'html2canvas'

import { ParcelType, USER_ROLE } from '@/constants'
import { Parcel } from '@/models/main'

import useMultiPlayerState from '@/composition/useMultiplayerState'
import useUserStore from '@/store/useUserStore'
import useParcelStore from '@/composition/parcel'
import { blobToBase64 } from '@/utilities'

const multiplayer = useMultiPlayerState()
const parcelStore = useParcelStore()

const { getters: userGetters } = useUserStore()

const state = reactive({
  screenshot: '',
  timestamp: Date.now(),
  seconds: 0,
})

const props = defineProps({
  significantActionDetected: { type: Boolean, required: true },
  role: { type: String, required: true },
  gameId: { type: String, default: '' },
})

const emit = defineEmits<{
    (e: 'screenstateSent'): void
  }>()

onMounted(() => {
  captureScreen()
  setInterval(() => {
    state.seconds = Math.ceil((Date.now() - state.timestamp) / 1000)
  }, 1000)
})

const captureDisallowed = computed(() => {
  return userGetters.myUser.value.profile.role === USER_ROLE.teacher
})

watch(props, (newProps) => {
  if (newProps.significantActionDetected) captureScreen()
})

watch(multiplayer.getters.drawingCanvas, (newCanvas) => {
  if (newCanvas) captureScreen()
})

watch(multiplayer.getters.studentScreen, (screen: Blob | undefined) => {
  if (screen)
    blobToBase64(screen).then((base64) => {
      state.screenshot = base64 as string // convert type explicitly
      state.timestamp = Date.now()
    })
})

const captureScreen = () => {
  if (!captureDisallowed.value) {
    // don't capture the teachers' screen
    const element = document.getElementById('app')

    if (element) {
      //create copy of canvas to be used by observer
      const newCanvas = document.createElement('canvas')
      const context = newCanvas.getContext('2d')
      if (context) {
        try {
          const currentCanvas = multiplayer.getters.drawingCanvas.value
          if (currentCanvas) {
            newCanvas.width = currentCanvas.width
            newCanvas.height = currentCanvas.height
            context.drawImage(currentCanvas, 0, 0)
          }
        } catch (e) {
          console.warn('no canvas to draw on')
        } finally {
          const options = {
            windowWidth: window.innerWidth,
            windowHeight: window.innerHeight,
            useCORS: true,
            proxy: import.meta.env.VITE_CMS_HOST,
            foreignObjectRendering: true,
            allowTaint: true,
            removeContainer: true,
            canvas: newCanvas || null,
            backgroundColor: null,
          }

          html2canvas(element, options).then((canvas: HTMLCanvasElement) => {
            const data = canvas.toDataURL('image/png').replace(/.*,/, '')
            const base64 = 'data:image/png;base64, ' + data

            //canvas.toBlob((blob) => {
            //blobToBase64(blob).then(() => {
            const userDetails = userGetters.myUser.value
            const parcel = new Parcel({
              parcelType: ParcelType.ScreenState,
              subscription: {
                game_id: props.gameId,
                user: {
                  id: userDetails._id,
                  username: userDetails.profile.username,
                },
              },
              body: base64,
            })

            parcelStore.actions.sendParcel(props.gameId, parcel).then(() => {
              emit('screenstateSent')
            })
          })
          // })
          // })
        }
      }
    }
  }
}
</script>

<style scoped>
  #observer {
    width: 100vw;
    height: 100vh;
  }
</style>
