/* eslint-disable @typescript-eslint/no-unused-vars */
<!-- Copyright 2023 Richard Nesnass, Tom Bjarne Seidel

 This file is part of KMMP.

KMMP is free software: you can redistribute it and/or modify
 it under the terms of the GNU Affero General Public License as published by
 the Free Software Foundation, either version 3 of the License, or
 (at your option) any later version.

 GPL-3.0-only or GPL-3.0-or-later

KMMP is distributed in the hope that it will be useful,
 but WITHOUT ANY WARRANTY; without even the implied warranty of
 MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 GNU Affero General Public License for more details.

 You should have received a copy of the GNU Affero General Public License
 along with KMMP.  If not, see http://www.gnu.org/licenses/. -->
<template>
  <div
    class="relative flex flex-col justify-center items-center fadeInOut"
    :style="{ opacity: opacity }"
  >
    <div
      class="scanner-content flex justify-center items-end z-101"
      @touchmove="mouseMoving"
      @mousemove="mouseMoving"
      @touchend="checkActionEnd"
      @mouseup="checkActionEnd"
    >
      <div class="w-full h-full flex flex-col justify-center items-center">
        <div
          class="word-wrapper taskNoUserSelect fadeInOut w-full h-full"
          :style="{ opacity: opacity }"
        >
          <div
            v-for="(word, index) in state.words"
            :id="word.text"
            :key="index"
            class="borderedTaskWordBox absolute"
            :style="state.styles[index]"
            @mousedown="mouseDownOnWord($event, state.words[index])"
            @touchstart="mouseDownOnWord($event, state.words[index])"
          >
            <span class="pointer-events-none">{{ state.words[index].text }}</span>
          </div>

          <!-- Combined word -->
          <transition mode="out-in" name="fade">
            <div v-if="combinedWord" class="absolute" style="top: 35%; left: 40%; z-index: 100">
              <span>
                {{ combinedWord }}
              </span>
            </div>
          </transition>
        </div>

        <div v-if="discoveredCombinations.length > 0" class="completedWords taskNoUserSelect">
          <ul>
            <li
              v-for="(word, i) in discoveredCombinations"
              :key="`word-d-${i}`"
              class="text-2xl m-0 outline-none"
            >
              {{ word }}
            </li>
          </ul>
        </div>
      </div>
    </div>
    <Drawing
      ref="drawing"
      style="width: 100vw; height: 100vh"
      class="z-101 pointer-events-none fixed left-0 top-0"
    ></Drawing>
  </div>
</template>

<script setup lang="ts">
import { ref, PropType, toRefs, Ref, reactive, nextTick, onUnmounted } from 'vue'
import { Choice, Tracking } from '@/models/main'
import useState from '@/composition/useState'
import { shuffleItems } from '@/utilities'
import { SpeechSounds, TaskMode, WordIndexName } from '@/constants'
import { Tasktype9, Type9WordType } from '@/models/tasktypes/Tasktype9'
import type { Type9Correct } from '@/models/tasktypes/Tasktype9'
import Drawing from '@/components/task/Drawing.vue'
import type { LinkedWord, Word } from '@/models/tasktypes'
import { createSound } from '@/api/audioService'
import moment from 'moment'

const emit = defineEmits(['completed'])

const props = defineProps({
  task: { required: true, type: Object as PropType<Tasktype9> },
  myIndex: { required: false, type: Number, default: 0 }
})

interface State {
  words: Word[]
  linkedWord?: LinkedWord
  styles: string[]
}

const state: State = reactive({
  words: [],
  linkedWord: undefined,
  styles: []
})

const { getters: stateGetters, setters: stateSetters, actions: stateActions } = useState()
const { task } = toRefs(props)
const tracking = new Tracking(stateGetters.tracking.value)
stateSetters.trackingData = tracking
let choiceTimer = new Date()
const drawing = ref<InstanceType<typeof Drawing>>()
const opacity = ref(0)
const roundData = { correct: 0, of: 0 }

let linkedUnconfirmedItem1: Word | undefined
let linkedUnconfirmedItem2: Word | undefined
let linkedUnconfirmedItem3: Word | undefined
let linkedUnconfirmedItem4: Word | undefined

const combinedWord = ref('')
const discoveredCombinations: Ref<string[]> = ref([])

let move = 1
let tappedItem: Word | undefined = undefined
let audioPlaying = false

async function setupTask() {
  // Choose random locations
  //let firstItem = Math.random() > 0.5 ? '' : 'itemB';
  //let secondItem = firstItem === 'itemA' ? 'itemB' : 'itemA';

  if (!task.value) {
    alert('A Type 9 task does not exist - check your Session layout in the CMS')
    return
  }

  const tempWords: Word[] = []
  for (let i = 0; i < task.value.words.length; i++) {
    const word = task.value.words[i]
    const audio = await createSound(word.audioURL)
    if (word.text) {
      const w: Word = {
        enabled: true,
        draggable: true,
        type: word.type as string,
        opacity: 1,
        id: `drag-word-${i}`,
        audio,
        text: word.text,
        reference: word.reference
      }
      tempWords.push(w)
    }
  }
  roundData.of = task.value.minimumCorrect
  stateActions.progress.progressShow(roundData.of)
  state.words = shuffleItems(tempWords)
  state.words.sort((a, b) => {
    if (a.type === b.type) {
      return 0
    } else if (
      (a.type === Type9WordType.prefix && b.type === Type9WordType.root) ||
      (a.type === Type9WordType.root && b.type === Type9WordType.suffix)
    ) {
      return -1
    } else {
      return 1
    }
  })

  // Introduce with instruction audio
  setTimeout(() => {
    opacity.value = 1
    audioPlaying = true
    stateActions.speakLocalised(
      SpeechSounds.instructions.tasks.T9,
      () => {
        audioPlaying = false
      },
      1000
    )
  }, 1000)
  window.addEventListener('resize', calculatedWordPosition)
  calculatedWordPosition()
}

onUnmounted(() => {
  window.removeEventListener('resize', calculatedWordPosition)
})

function checkActionEnd(event: MouseEvent | TouchEvent) {
  event.preventDefault()
  event.stopPropagation()
  const y = event instanceof MouseEvent ? event.clientY : event.changedTouches[0].clientY
  const x = event instanceof MouseEvent ? event.clientX : event.changedTouches[0].clientX
  const element = document.elementFromPoint(x, y)
  if (element) {
    const id = element.id // element.id is set to word.text
    const index = state.words.map((w) => w.text).findIndex((text: string) => text === id)
    if (index !== -1) mouseUpOnWord({ clientX: x, clientY: y }, state.words[index], element)
    else mouseUpOutsideBox()
  }
}

function isInOrder(item: Word): boolean {
  if (!task.value.enforceOrder) return true
  else {
    return (
      (move === 1 && task.value.firstWords.indexOf(item.text) > -1) ||
      (move === 2 && task.value.secondWords.indexOf(item.text) > -1) ||
      (move === 3 && task.value.thirdWords.indexOf(item.text) > -1) ||
      (move === 4 && task.value.fourthWords.indexOf(item.text) > -1)
    )
  }
}

function mouseMoving(event: MouseEvent | TouchEvent) {
  if (state.linkedWord && drawing.value)
    drawing.value.message({ event, message: 'morfologiTaskMousemove' })
}

function mouseDownOnWord(event: MouseEvent | TouchEvent, word: Word) {
  tappedItem = word
  if (isInOrder(word)) {
    let x = 0
    let y = 0
    if (!event) return
    if (event.type.includes('touch')) {
      const te = event as TouchEvent
      x = te.changedTouches[0].clientX
      y = te.changedTouches[0].clientY
    } else {
      const me = event as MouseEvent
      x = me.clientX
      y = me.clientY
    }
    const el = document.elementFromPoint(x, y)
    if (el) {
      state.linkedWord = {
        word,
        startX: x,
        startY: y,
        startElement: el,
        endElement: undefined,
        endX: 0,
        endY: 0
      }
      if (move === 1) {
        // We won't see the first item again after mouseDown
        attemptToAddNewItem(word)
      }
      if (state.linkedWord && drawing.value)
        drawing.value.message({ message: 'morfologiTaskMousedown', word: state.linkedWord })
    }
  }
}

function playWordAudio() {
  if (tappedItem && tappedItem.audio && !audioPlaying) {
    audioPlaying = true
    tappedItem.audio.onended = () => {
      tappedItem = undefined
      audioPlaying = false
    }
    tappedItem.audio.playWhenReady()
  }
}

const calculatedWordPosition = () => {
  nextTick(() => {
    state.styles = []
    const wrapperDimensions = document
      .getElementsByClassName('word-wrapper')[0]
      .getBoundingClientRect()
    const wrapperWidth = wrapperDimensions.width
    const wrapperHeight = wrapperDimensions.height

    const radius = wrapperHeight * 0.5
    let fields = state.words,
      width = wrapperWidth,
      height = wrapperHeight,
      angle = 0,
      step = (2 * Math.PI) / fields.length

    const wordElements = document.getElementsByClassName('borderedTaskWordBox')
    for (let i = 0; i < wordElements.length; i++) {
      const dimensions = wordElements[i].getBoundingClientRect()
      var x = Math.round(width / 2 + radius * Math.cos(angle) - dimensions.width / 2), // TODO: replace 80 with actual element dimensions
        y = Math.round(height / 2 + radius * Math.sin(angle) - dimensions.height / 2)
      const css = `left: ${x + wrapperDimensions.left / 2}px; top: ${y + wrapperDimensions.top / 8}px;`
      angle += step
      state.styles.push(css)
    }
  })
}

function mouseUpOutsideBox() {
  if (drawing.value) drawing.value.message({ message: 'morfologiTaskMouseup' })
  if (move === 1) {
    linkedUnconfirmedItem1 =
      linkedUnconfirmedItem2 =
      linkedUnconfirmedItem3 =
      linkedUnconfirmedItem4 =
        undefined
  }
  const choice = new Choice()
  choice.duration = moment().diff(moment(choiceTimer), 'milliseconds')
  choice.target = task.value.allCorrectWords()
  choice.content = state.words.map((w) => w.text).join(';')
  choice.round = roundData.correct
  tracking.choices.push(choice)
  choiceTimer = new Date()
}

async function mouseUpOnWord(
  coords: { clientX: number; clientY: number },
  item: Word,
  el: Element
) {
  let foundASlot = false

  if (drawing.value) {
    drawing.value.message({ message: 'morfologiTaskMouseup' })
  }

  const choice = new Choice()
  choice.phase = 1
  choice.createdAt = new Date()
  choice.duration = moment().diff(moment(choiceTimer), 'milliseconds')
  choice.response = item.text ?? '(no word)'
  choice.target = task.value.allCorrectWords()
  choice.content = state.words.map((w) => w.text).join(';')
  choice.round = roundData.correct

  // Mouse up was on the same item as mouse down
  if (tappedItem === item) {
    playWordAudio()
    tracking.use_audio_content_items++
    state.linkedWord = undefined
    linkedUnconfirmedItem1 =
      linkedUnconfirmedItem2 =
      linkedUnconfirmedItem3 =
      linkedUnconfirmedItem4 =
        undefined
  }

  // Mouse down item was not the same item as the last item in the arrow trail
  else if (move > 0 && move < 5) {
    let container = undefined
    if (move === 1) container = linkedUnconfirmedItem1
    else if (move === 2) container = linkedUnconfirmedItem2
    else if (move === 3) container = linkedUnconfirmedItem3
    else if (move === 4) container = linkedUnconfirmedItem4
    if (state.linkedWord && state.linkedWord.word !== container) state.linkedWord = undefined
  }

  if (state.linkedWord) {
    choice.valid = true
    foundASlot = attemptToAddNewItem(item)

    if (foundASlot) {
      const cc: Type9Correct = {
        firstWord:
          (linkedUnconfirmedItem1 && linkedUnconfirmedItem1.reference) || WordIndexName.None,
        secondWord:
          (linkedUnconfirmedItem2 && linkedUnconfirmedItem2.reference) || WordIndexName.None,
        thirdWord:
          (linkedUnconfirmedItem3 && linkedUnconfirmedItem3.reference) || WordIndexName.None,
        fourthWord:
          (linkedUnconfirmedItem4 && linkedUnconfirmedItem4.reference) || WordIndexName.None,
        audioURL: '',
        audio: undefined,
        matched: false
      }

      const result: { status: string; correctRef?: Type9Correct } | undefined =
        task.value.hasCombinationStatus(cc, discoveredCombinations.value) // Result is the correct combination including audio

      state.linkedWord.endX = coords.clientX
      state.linkedWord.endY = coords.clientY
      state.linkedWord.endElement = el

      choice.response = task.value.wordsFromCC(cc)

      if (result && result.status === 'correct' && result.correctRef) {
        // Correct combination
        if (drawing.value) {
          drawing.value.message({ message: 'morfologiTaskLine', word: state.linkedWord })
        }
        linkedUnconfirmedItem1 =
          linkedUnconfirmedItem2 =
          linkedUnconfirmedItem3 =
          linkedUnconfirmedItem4 =
            undefined
        move = 1
        combinedWord.value = task.value.combinedWordFromCC(result.correctRef)
        if (discoveredCombinations.value.indexOf(combinedWord.value) === -1) {
          choice.correct = true
          roundData.correct++
          stateActions.progress.completeAStar()
          // Fuse into one word
          // Read completed word audio
          if (result.correctRef.audioURL) {
            const correctAudio = await createSound(result.correctRef.audioURL)
            correctAudio.playWhenReady()
          }
          // Add completed word to completed list
          discoveredCombinations.value.push(combinedWord.value)
        }
        // Remove lines from screen
        setTimeout(() => {
          combinedWord.value = ''
          if (drawing.value) drawing.value.message({ message: 'morfologiTaskLineClear' })
        }, 2000)

        if (roundData.of === roundData.correct) {
          setTimeout(() => {
            if (stateGetters.state.value.taskMode === TaskMode.Warmups) {
              stateActions.speakLocalised(
                SpeechSounds.instructions.warmups.T9,
                () => completeTask(),
                1000,
                false
              )
            } else {
              completeTask()
            }
          }, 1500)
        }
      } else if (result && result.status === 'some') {
        // Allow user to continue adding to the unconfirmed set..
        choice.correct = true
        if (drawing.value)
          drawing.value.message({ message: 'morfologiTaskLine', word: state.linkedWord })
        move++
      } else {
        //Incorrect choice
        choice.correct = false
        if (drawing.value) drawing.value.message({ message: 'morfologiTaskLineClear' })
        state.linkedWord = undefined
        linkedUnconfirmedItem1 =
          linkedUnconfirmedItem2 =
          linkedUnconfirmedItem3 =
          linkedUnconfirmedItem4 =
            undefined
        move = 1
      }
    }
  }
  tracking.choices.push(choice)
  stateSetters.trackingData = tracking
  choiceTimer = new Date()
}

// Ensure each linked item is a different word
function attemptToAddNewItem(item: Word): boolean {
  let success = false
  if (!linkedUnconfirmedItem1) {
    linkedUnconfirmedItem1 = item
    success = true
  } else if (linkedUnconfirmedItem1 === item) {
    success = false
    // linkedUnconfirmedItem1 = null;
  } else if (!linkedUnconfirmedItem2) {
    if (linkedUnconfirmedItem1.reference !== item.reference) {
      linkedUnconfirmedItem2 = item
    }
    success = true
  } else if (linkedUnconfirmedItem2 === item) {
    success = false
    // linkedUnconfirmedItem2 = null;
  } else if (!linkedUnconfirmedItem3) {
    if (
      linkedUnconfirmedItem1.reference !== item.reference &&
      linkedUnconfirmedItem2.reference !== item.reference
    ) {
      linkedUnconfirmedItem3 = item
    }
    success = true
  } else if (linkedUnconfirmedItem3 === item) {
    success = false
    // linkedUnconfirmedItem3 = null;
  } else if (!linkedUnconfirmedItem4) {
    if (
      linkedUnconfirmedItem1.reference !== item.reference &&
      linkedUnconfirmedItem2.reference !== item.reference &&
      linkedUnconfirmedItem3.reference !== item.reference
    ) {
      linkedUnconfirmedItem4 = item
    }
    success = true
  } else if (linkedUnconfirmedItem4 === item) {
    success = false
    //linkedUnconfirmedItem4 = null;
  }
  if (!success && move === 1) {
    linkedUnconfirmedItem1 = undefined
  }
  return success
}

const completeTask = () => {
  opacity.value = 0
  setTimeout(() => {
    emit('completed', true, tracking)
  }, 1000)
}

setupTask()
</script>

<style scoped lang="postcss">
.scanner-content {
  position: absolute;
  width: 100%;
  height: 100%;
  background-image: url('../../../assets/images/circular-background.svg');
  background-repeat: no-repeat;
  background-position: center;
  background-size: 65vh;
}
.completedWords {
  position: absolute;
  top: 50%;
  right: 10%;
  color: #00ff45;
  font-size: 14pt;
  text-align: left;
  outline: none;

  background-color: rgba(0, 0, 0, 0.6);
  padding: 10px 15px 10px 10px;
  border-radius: 20px;
}
</style>
