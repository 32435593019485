<!-- Copyright 2023 Richard Nesnass, Tom Bjarne Seidel

 This file is part of KMMP.

KMMP is free software: you can redistribute it and/or modify
 it under the terms of the GNU Affero General Public License as published by
 the Free Software Foundation, either version 3 of the License, or
 (at your option) any later version.

 GPL-3.0-only or GPL-3.0-or-later

KMMP is distributed in the hope that it will be useful,
 but WITHOUT ANY WARRANTY; without even the implied warranty of
 MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 GNU Affero General Public License for more details.

 You should have received a copy of the GNU Affero General Public License
 along with KMMP.  If not, see http://www.gnu.org/licenses/. -->
<template>
  <div class="flex flex-col">
    <div class="flex flex-col w-full h-full bg-black opacity-30"></div>
    <div
      class="absolute grid grid-cols-1 place-content-center items-center w-full h-full top-0 left-0 text-center text-white"
    >
      <p v-if="viewState === DelayMode.AwaitingDownload">{{ t('downloading') }}</p>
      <p v-if="viewState === DelayMode.NoActivitiesFound">{{ t('noActivityFound') }}</p>
      <p v-if="viewState === DelayMode.NoCollectionsFound">{{ t('noCollectionFound') }}</p>
      <p v-if="viewState === DelayMode.NoEpisodesFound">{{ t('noEpisodeFound') }}</p>
      <p v-if="viewState === DelayMode.NoSessionsFound">
        {{ `${t('noSessionFound')} ${episodeName}` }}
      </p>
      <p v-if="viewState === DelayMode.NoTasksFound">{{ t('noTasksFound') }}</p>
      <p v-if="viewState === DelayMode.DemoComplete">{{ t('demoCompleted') }}</p>
      <p v-if="viewState === DelayMode.AccessDenied">{{ t('todaysWorkDone') }}</p>
      <!--div *ngIf="viewState === DelayMode.WarmupsFinished"><p>Da er du ferdig med å øve</div-->
      <!--div *ngIf="viewState === DelayMode.WarmupsStarting">Nå skal du få øve litt</div-->
      <div class="col-start-1 col-end-1 p-4 flex flex-row justify-center">
        <img
          v-if="showExitButton"
          src="@/assets/images/map/backToCockpitButton1@2x.png"
          style="width: 150px"
          @click="exitToLogin()"
        />
        <img
          v-if="showStartButton"
          src="@/assets/images/login/StartButtonInactive@2x.png"
          style="width: 50%"
          @click="start()"
        />
      </div>
      <div class="col-start-1 col-end-1 p-4 flex flex-row justify-center">
        <Button
          bordercolour="border-transparent"
          backgroundcolour="bg-slate-700"
          textcolour="text-white"
          childclass="w-48"
          :roundedcorners="true"
          @click="restart()"
          @touchstart.prevent="restart()"
        >
          <img class="p-2 w-12 invert mr-2" :src="ReloadIcon" alt="log out" />
          <p>{{ t('tryAgain') }}</p>
        </Button>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, Ref, onMounted } from 'vue'
import { useI18n } from 'vue-i18n'
import { useRouter } from 'vue-router'

import { Parcel } from '@/models/main'
import { DelayMode, ViewState, SpeechSounds, StateVariables, ParcelType } from '@/constants'
import ReloadIcon from '@/assets/icons/fontawesome/arrows-rotate-solid.svg'
import Button from '@/components/base/Button.vue'
import useCMSStore from '@/store/useCMSStore'
import useStateService from '@/composition/useState'
import useUserStore from '@/store/useUserStore'
import useGameStore from '@/store/useGameStore'
import useParcelStore from '@/composition/parcel'

const messages = {
  no: {
    downloading: 'Vennligst vent, laster ned data...',
    todaysWorkDone: 'Dagens arbeid er fullført',
    demoCompleted: 'Du har fullført demoen! Bra gjort!',
    noActivityFound: 'Ingen aktiviteter ble funnet for denne studenten',
    noSessionFound: 'Ingen økter ble funnet for episoden',
    noCollectionFound: 'Ingen kolleksjoner ble funnet for episoden',
    noEpisodeFound: 'Ingen episoder ble funnet',
    noTasksFound: 'Ingen task ble funnet',
    tryAgain: 'Last på nytt'
  },
  sv: {
    downloading: 'Vänta, laddar ner data...',
    todaysWorkDone: 'Dagens arbete är klart',
    demoCompleted: 'Du har slutfört demon! Bra gjort!',
    noActivityFound: 'Inga aktiviteter hittades för denna elev',
    noSessionFound: 'Inga sessioner hittades för avsnittet',
    noCollectionFound: 'Ingen kolleksjoner ble funnet for episoden',
    noEpisodeFound: 'Ingen episoder ble funnet',
    noTasksFound: 'Ingen task ble funnet',
    tryAgain: 'Last på nytt'
  },
  en: {
    downloading: 'Please wait, downloding data...',
    todaysWorkDone: "Today's work has been completed",
    demoCompleted: "You've completed the demo! Well done!",
    noActivityFound: 'No Activity was found for this student',
    noSessionFound: 'No session found for Episode',
    noCollectionFound: 'No collections found for this episode',
    noEpisodeFound: 'No episodes found in this activity',
    noTasksFound: 'No tasks found in this session',
    tryAgain: 'Reload'
  }
}

const { t } = useI18n({ messages })

const parcelStore = useParcelStore()
const stateService = useStateService()
const router = useRouter()

const { getters: cmsGetters, actions: cmsActions } = useCMSStore()
const { getters: userGetters } = useUserStore()
const { getters: gameGetters } = useGameStore()

const viewState: Ref<DelayMode> = ref(DelayMode.None)
const showExitButton = ref(false)
const showStartButton = ref(false)
const episodeName = ref('')

onMounted(() => {
  showExitButton.value = false
  showStartButton.value = false
  switch (stateService.getters.state.value.delayMode) {
    case DelayMode.AwaitingDownload:
      showExitButton.value = false
      viewState.value = DelayMode.AwaitingDownload
      break
    case DelayMode.NoActivitiesFound:
      viewState.value = DelayMode.NoActivitiesFound
      showExitButton.value = true
      break
    case DelayMode.NoEpisodesFound:
      viewState.value = DelayMode.NoEpisodesFound
      showExitButton.value = true
      break
    case DelayMode.NoCollectionsFound:
      viewState.value = DelayMode.NoCollectionsFound
      showExitButton.value = true
      break
    case DelayMode.NoSessionsFound:
      episodeName.value = cmsGetters.selectedEpisode.value?.name || ''
      viewState.value = DelayMode.NoSessionsFound
      showExitButton.value = true
      break
    case DelayMode.NoTasksFound:
      viewState.value = DelayMode.NoTasksFound
      showExitButton.value = true
      break
    case DelayMode.DemoComplete:
      viewState.value = DelayMode.DemoComplete
      showExitButton.value = true
      break
    case DelayMode.WarmupsStarting:
      viewState.value = DelayMode.WarmupsStarting
      stateService.actions.speakLocalised(
        SpeechSounds.narrator.N56,
        () => (showStartButton.value = true),
        1000
      )
      break
    case DelayMode.WarmupsFinished:
      viewState.value = DelayMode.WarmupsFinished
      stateService.actions.speakLocalised(
        SpeechSounds.narrator.N45,
        () => (showStartButton.value = true),
        1000,
        false
      )
      setReady() // force set ready on state match
      break
    case DelayMode.NoWarmups:
      viewState.value = DelayMode.WarmupsFinished
      stateService.actions.speakLocalised(
        SpeechSounds.narrator.N64,
        () => (showStartButton.value = true),
        1000,
        false
      )
      break
    case DelayMode.AccessDenied:
    case DelayMode.None:
      showExitButton.value = true
      viewState.value = DelayMode.AccessDenied
      break
  }
})

const restart = () => {
  cmsActions.resetStorage()
  router.push(`/postlogin`)
}

const exitToLogin = () => {
  stateService.actions.exitToLogin()
}

const start = () => {
  const newState: StateVariables = {
    viewState: ViewState.Tasks
  }
  stateService.actions.updateState(newState)
}

const setReady = () => {
  const gameId = gameGetters.selectedGame.value?._id
  if (gameId) {
    const subscriptionUser = userGetters.myUser.value
    const parcel = new Parcel({
      parcelType: ParcelType.UserReconnect,
      subscription: {
        game_id: gameId,
        user: {
          id: subscriptionUser._id,
          username: subscriptionUser.profile.username
        }
      }
    })
    parcelStore.actions.sendParcel(gameId, parcel).then(() => {
      console.log('ready state set __ DELAY')
    })
  }
}
</script>

<style scoped></style>
