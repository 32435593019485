<!-- Copyright 2020, 2021 Richard Nesnass, Sharanya Manivasagam and Ole Smørdal

 This file is part of VIVA.

 VIVA is free software: you can redistribute it and/or modify
 it under the terms of the GNU Affero General Public License as published by
 the Free Software Foundation, either version 3 of the License, or
 (at your option) any later version.

 GPL-3.0-only or GPL-3.0-or-later

 VIVA is distributed in the hope that it will be useful,
 but WITHOUT ANY WARRANTY; without even the implied warranty of
 MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 GNU Affero General Public License for more details.

 You should have received a copy of the GNU Affero General Public License
 along with VIVA.  If not, see http://www.gnu.org/licenses/. -->
<template>
  <button
    class="flex justify-center items-center py-2 pointer-events-auto border-2"
    :class="[
      disabled ? 'text-slate-400 opacity-50' : textcolour,
      childclass ? childclass : 'w-56',
      disabled ? 'cursor-auto' : 'cursor-pointer',
      disabled ? 'bg-white' : backgroundcolour,
      disabled ? 'border-slate-200' : bordercolour,
      roundedcorners ? 'rounded-full' : 'rounded',
    ]"
    @click="click"
  >
    <slot></slot>
    <img v-if="logo == 'feide'" class="w-24" src="../../assets/icons/svg/feide.svg" alt="feide logo" />
    <img v-if="logo == 'canvas'" class="w-24" src="../../assets/icons/svg/canvas.svg" alt="canvas logo" />
    <svg v-if="logo != ''" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 144.2 69.6" class="fill-current w-6">
      <path
        v-if="logo === 'rightArrow'"
        d="M3.5,31.3h127.3L105.4,6c-1.4-1.4-1.4-3.6,0-4.9c1.4-1.4,3.6-1.4,4.9,0l33.8,33.8l-33.8,33.8c-0.7,0.7-1.6,1-2.5,1
	s-1.8-0.3-2.5-1c-1.4-1.4-1.4-3.6,0-4.9l25.3-25.3H3.5c-1.9,0-3.5-1.6-3.5-3.5C0,32.9,1.6,31.3,3.5,31.3z"
      />
      <path
        v-if="logo === 'leftArrow'"
        d="M140.7,31.3H13.4L38.7,6c1.4-1.4,1.4-3.6,0-4.9c-1.4-1.4-3.6-1.4-4.9,0L0,34.8l33.8,33.8c0.7,0.7,1.6,1,2.5,1s1.8-0.3,2.5-1
	c1.4-1.4,1.4-3.6,0-4.9L13.4,38.3h127.3c1.9,0,3.5-1.6,3.5-3.5S142.6,31.3,140.7,31.3z"
      />
    </svg>
  </button>
</template>

<script setup lang="ts">
const emit = defineEmits(['click'])
const props = defineProps({
  logo: {
    type: String,
    default: '',
  },
  childclass: {
    type: String,
    default: '',
  },
  textcolour: {
    type: String,
    default: 'text-black',
  },
  backgroundcolour: {
    type: String,
    default: 'bg-white',
  },
  bordercolour: {
    type: String,
    default: 'border-slate-200',
  },
  roundedcorners: {
    type: Boolean,
    default: false,
  },
  disabled: {
    type: Boolean,
    default: false,
  },
})

function click($event: Event) {
  if (!props.disabled) emit('click', $event)
}
</script>

<style scoped></style>
