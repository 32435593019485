import { reactive } from 'vue'

import { DialogMessageType, DialogMessage } from '@/constants'
import { SolutionUnion } from '@/models/tasktypes'

interface State {
  messages: DialogMessage[]
  multiplayerMessages: DialogMessage[]
  notifications: DialogMessage[]
}

const _state: State = reactive({
  messages: [],
  multiplayerMessages: [],
  notifications: [],
})

interface Actions {
  pushMessage: (type?: DialogMessageType, message?: string, duration?: number, callback?: () => void) => void
  pushMultiplayerMessage: (type?: DialogMessageType, message?: string, duration?: number, element?: SolutionUnion, callback?: () => void) => void
  pushNotification: (type?: DialogMessageType, message?: string, duration?: number, callback?: () => void) => void
  empty: () => void
  emptyMultiPlayer: () => void
  emptyNotifications: () => void
  currentMessage: () => DialogMessage | undefined
  currentMultiplayerMessage: () => DialogMessage | undefined
  currentNotification: () => DialogMessage | undefined
}

interface Getters {
  hasNewMessages: () => boolean
  hasNewMultiplayerMessages: () => boolean
  hasNewNotifications: () => boolean
}

interface ServiceInterface {
  state: State
  actions: Actions
  getters: Getters
}

function useDialogStore(): ServiceInterface {
  const actions = {
    pushMessage(type?: DialogMessageType, message?: string, duration?: number, callback?: () => void) {
      const dialogMessage: DialogMessage = {
        message: message || '',
        duration: duration || 3500,
        type: type || DialogMessageType.Success,
        timestamp: new Date(),
        callback: callback || undefined,
      }

      state.messages.push(dialogMessage)
    },

    pushMultiplayerMessage(type?: DialogMessageType, message?: string, duration?: number, element?: SolutionUnion, callback?: () => void) {
      const dialogMessage: DialogMessage = {
        element: element || undefined,
        message: message || '',
        duration: duration || 3500,
        type: type || DialogMessageType.Success,
        timestamp: new Date(),
        callback: callback || undefined,
      }

      state.multiplayerMessages.push(dialogMessage)
    },

    pushNotification(type?: DialogMessageType, message?: string, duration?: number, callback?: () => void) {
      const dialogMessage: DialogMessage = {
        message: message || '',
        duration: duration || 3500,
        type: type || DialogMessageType.Success,
        timestamp: new Date(),
        callback: callback || undefined,
      }

      state.notifications.push(dialogMessage)
    },

    empty() {
      state.messages = []
    },

    emptyMultiPlayer() {
      state.multiplayerMessages = []
    },

    emptyNotifications() {
      state.notifications = []
    },

    // these functions modify state, so should not be getters
    currentMessage(): DialogMessage | undefined {
      return state.messages.pop()
    },
    currentMultiplayerMessage(): DialogMessage | undefined {
      return state.multiplayerMessages.pop()
    },
    currentNotification(): DialogMessage | undefined {
      return state.notifications.pop()
    },
  }

  const getters = {
    hasNewMessages(): boolean {
      return state.messages.length > 0
    },

    hasNewMultiplayerMessages(): boolean {
      return state.multiplayerMessages.length > 0
    },

    hasNewNotifications(): boolean {
      return state.notifications.length > 0
    },
  }

  const state = _state

  return { state, actions, getters }
}

export default useDialogStore
