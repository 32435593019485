<template>
  <div class="absolute">
    <div
      v-if="!participantIsReadyToProceed && waitingCondition"
      class="absolute z-50 w-3/4 h-1/2 top-1/4 flex flex-col justify-center items-center bg-white rounded-3xl"
    >
      <p class="text-4xl font-bold p-11 animate-bounce">{{ t('waitingonparticipant') }}...</p>
    </div>

    <div
      v-else-if="participantIsReadyToProceed && waitingCondition"
      class="absolute z-50 w-3/4 h-1/2 top-1/4 flex flex-col justify-center items-center bg-white rounded-3xl"
    >
      <p class="text-4xl font-bold p-11">{{ t('participantready') }}</p>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed, watch } from 'vue'
import { useI18n } from 'vue-i18n'

import useMultiPlayerState from '@/composition/useMultiplayerState'
import useParcelStore from '@/composition/parcel'
import useUserStore from '@/store/useUserStore'
import useGameStore from '@/store/useGameStore'
import useCMSStore from '@/store/useCMSStore'

import { Parcel } from '@/models/main'
import { ParcelType } from '@/constants'
import { useRoute } from 'vue-router'
import { QuestionUnion } from '@/models/tasktypes'

const route = useRoute()

const parcelStore = useParcelStore()
const multiplayer = useMultiPlayerState()

const { getters: cmsGetters } = useCMSStore()
const { getters: gameGetters } = useGameStore()
const { getters: userGetters } = useUserStore()

const { t } = useI18n()

const participantIsReadyToProceed = multiplayer.getters.participantReadyToProceed

watch(participantIsReadyToProceed, (newValue) => {
  if (newValue && !route.fullPath.includes('task')) setReady()
})

const currentTask = cmsGetters.selectedTask

const currentTasktypeIsMultiplayer = computed(() => {
  const searchParam = 'mp'
  return currentTask.value?.type.toLowerCase().includes(searchParam) || false
})

const previousTasktypeIsMultiplayer = computed(() => {
  const currentIndex = cmsGetters.selectedTaskSet.value.findIndex(
    (t: QuestionUnion) => t.id === cmsGetters.selectedTask.value?.id
  )
  const previousTask = cmsGetters.selectedTaskSet.value[currentIndex - 1]
  if (previousTask)
    return ['mp', 'st'].some((searchParam) => previousTask.type.toLowerCase().includes(searchParam))
  else return false
})

const waitingCondition = computed(() => {
  try {
    const condition = !previousTasktypeIsMultiplayer.value && currentTasktypeIsMultiplayer.value
    if (condition && multiplayer.getters.participantReadyToProceed.value) {
      // the task was completed / currentTask.valueIndex increased and the other participant is already ready
      setReady()
      return false
    }
    if (previousTasktypeIsMultiplayer.value) multiplayer.actions.resetParticipantReadyToProceed() // reset if previous task was also mp
    return condition // previous type is SP, current one MP
  } catch (e) {
    return false // in case there is no previous task
  }
})

/* PARCEL FUNCTIONS */

const setReady = () => {
  const gameId = gameGetters.selectedGame.value?._id
  if (gameId) {
    const subscriptionUser = userGetters.myUser.value
    const parcel = new Parcel({
      parcelType: ParcelType.UserReconnect,
      subscription: {
        game_id: gameId,
        user: {
          id: subscriptionUser._id,
          username: subscriptionUser.profile.username
        }
      }
    })
    parcelStore.actions.sendParcel(gameId, parcel)
  }
}
</script>
