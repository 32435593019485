import { ComputedRef, computed, reactive } from 'vue'
import { ParticipantInformation, TaskSyncST, ParticipantReadyState } from '@/constants'
import { FinalDecisionStatus, UserTaskRole, SyncType } from '@/constants'

import { GameType, TaskSync } from '@/models/main'
/**
 * useMultiplayerState()
 *
 * this store keeps track of the progess and mqtt communication WITHIN a task
 * the values will be re-set once a task was successfully completed
 */
interface State {
  mode: GameType
  participantReady: ParticipantReadyState // indicates the status of the other participant (if MP or HB session)
  participantReadyToProceed: ParticipantReadyState // indicates waiting status in HB sessions or during warmup

  // Observer
  studentScreen: Blob | undefined // contains screenshot of student screen as Blob
  drawingCanvas: HTMLCanvasElement | undefined

  // Intercom
  listeningState: boolean // only used in ST mode; true -> user is forced to listen to audio package first before being able to send another one
  currentAudio: Blob | undefined // holds audio information as string; converted in Intercom.vue

  leaderAffixes: Record<string, string>
  syncs: Map<number, TaskSync[]> // TaskSync parcel bodies
  finalDecisionStatus: FinalDecisionStatus // true -> first decision will be sent / false -> store must be re-set

  currentRole: UserTaskRole
  participantInformation: ParticipantInformation
  roundIndex: number // reference of current round equivalates to tracking.taskDetails.of

  shuffleOrder: string[]
  advisorTaskStatus: number // used to track proceeding tasks (selected task index)
  studentTeacherSync: TaskSyncST | undefined
}

const _state: State = reactive({
  mode: GameType.SP,
  participantReady: ParticipantReadyState.NotReady, // indicates the status of the other participant (if MP or HB session)
  participantReadyToProceed: ParticipantReadyState.NotReady, // indicates waiting status in HB sessions or during warmup

  // Observer
  studentScreen: undefined, // contains screenshot of student screen as Blob
  drawingCanvas: undefined,

  // Intercom
  listeningState: false, // only used in ST mode; true -> user is forced to listen to audio package first before being able to send another one
  currentAudio: undefined, // holds audio information as string; converted in Intercom.vue

  leaderAffixes: {},
  syncs: new Map(), // TaskSync parcel bodies
  finalDecisionStatus: FinalDecisionStatus.NotSubmitted, // true -> first decision will be sent / false -> store must be re-set

  currentRole: UserTaskRole.Default,
  participantInformation: {
    name: '',
    avatar: {
      fileKey: '',
      name: '',
      ref: ''
    }
  },
  roundIndex: 0, // reference of current round equivalates to tracking.taskDetails.of

  shuffleOrder: [] as string[],
  advisorTaskStatus: 0, // used to track proceeding tasks (selected task index)
  studentTeacherSync: undefined
})

interface State {
  mode: GameType
  participantReady: ParticipantReadyState
  participantReadyToProceed: ParticipantReadyState

  // Observer
  studentScreen: Blob | undefined
  drawingCanvas: HTMLCanvasElement | undefined

  // Intercom
  listeningState: boolean
  currentAudio: Blob | undefined

  leaderAffixes: Record<string, string>
  syncs: Map<number, TaskSync[]>
  studentTeacherSync: TaskSyncST | undefined
  finalDecisionStatus: FinalDecisionStatus

  currentRole: UserTaskRole
  participantInformation: ParticipantInformation
  roundIndex: number

  shuffleOrder: string[]
  advisorTaskStatus: number
}

interface Actions {
  reset: () => void
  resetSync: () => void
  resetFinalDecisionStatus: () => void
  addStudentScreen: (blob: Blob) => void
  setDrawingCanvas: (canvas: HTMLCanvasElement) => void
  addSync: (sync: TaskSync) => void
  addSTSync: (sync: TaskSyncST) => void
  setMode: (mode: GameType) => void
  setFinalDecisionStatus: (value: FinalDecisionStatus) => void
  setParticipantReady: (pstate?: ParticipantReadyState) => void
  resetParticipantReady: () => void
  setParticipantReadyToProceed: (pstate?: ParticipantReadyState) => void
  resetParticipantReadyToProceed: () => void
  setParticipantInformation: (value: ParticipantInformation) => void
  setMultiplayerRole: (isLeader: boolean) => void
  setStudentTeacherRole: (isTeacher: boolean) => void
  proceedRoundIndex: () => void
  setShuffleOrder: (items: string[]) => void
  setAdvisorTaskStatus: (status: number) => void
  initLeaderAffixes: (leaderAffixes: Record<string, string>) => void
  setLeaderAffixes: (affix: string, userId: string) => void
  setIncomingAudio: (audio: Blob) => void
  resetListeningState: () => void
}

interface Getters {
  currentRole: ComputedRef<UserTaskRole>
  leaderAffixes: ComputedRef<Record<string, string>>
  mode: ComputedRef<GameType>
  studentTeacherSync: ComputedRef<TaskSyncST | undefined>

  // Observer
  studentScreen: ComputedRef<Blob | undefined>
  drawingCanvas: ComputedRef<HTMLCanvasElement | undefined>

  // Intercom
  listeningState: ComputedRef<boolean>
  currentAudio: ComputedRef<Blob | undefined>

  participantInformation: ComputedRef<ParticipantInformation>
  finalDecisionStatus: ComputedRef<FinalDecisionStatus>
  participantReady: ComputedRef<ParticipantReadyState>
  participantReadyToProceed: ComputedRef<ParticipantReadyState>

  syncs: ComputedRef<TaskSync[]>
  allSyncs: ComputedRef<Map<number, TaskSync[]>>
  firstDecision: ComputedRef<TaskSync | undefined>
  advice: ComputedRef<TaskSync | undefined>
  finalDecision: ComputedRef<TaskSync | undefined>

  phase: ComputedRef<SyncType>
  shuffleOrder: ComputedRef<string[]>

  actionAllowed: ComputedRef<boolean>
  roundIndex: ComputedRef<number>
  advisorTaskStatus: ComputedRef<number>
}

interface ServiceInterface {
  state: State
  actions: Actions
  getters: Getters
}

function useMultiPlayerState(): ServiceInterface {
  const actions = {
    reset() {
      state.currentRole = UserTaskRole.Default
      state.syncs = new Map<number, TaskSync[]>()
      state.shuffleOrder = []
      state.roundIndex = 0
      state.advisorTaskStatus = 0
      this.resetParticipantReady()
      this.resetParticipantReadyToProceed()
    },

    setDrawingCanvas(canvas: HTMLCanvasElement) {
      state.drawingCanvas = canvas
    },

    addStudentScreen(blob: Blob) {
      state.studentScreen = blob
    },

    resetFinalDecisionStatus() {
      state.finalDecisionStatus = FinalDecisionStatus.NotSubmitted
    },

    resetSync() {
      this.resetFinalDecisionStatus()
      state.syncs.set(state.roundIndex, [] as TaskSync[])
    },

    addSync(sync: TaskSync) {
      let currentSyncs = state.syncs.get(state.roundIndex)
      if (!currentSyncs) currentSyncs = [] // init with empty array if undefined
      state.syncs.set(state.roundIndex, [...currentSyncs, sync])
    },

    setMode(mode: GameType) {
      state.mode = mode
    },

    setFinalDecisionStatus(value: FinalDecisionStatus) {
      state.finalDecisionStatus = value
    },

    setParticipantReady(pstate?: ParticipantReadyState) {
      if (pstate) state.participantReady = pstate
      else {
        switch (state.participantReady) {
          case ParticipantReadyState.NotReady:
            state.participantReady = ParticipantReadyState.Initiated
            break
          case ParticipantReadyState.Initiated:
            state.participantReady = ParticipantReadyState.Paired
            break
          case ParticipantReadyState.Paired:
            state.participantReady = ParticipantReadyState.ProceedToGame
            break
        }
      }
    },

    resetParticipantReady() {
      state.participantReady = ParticipantReadyState.NotReady
    },

    setParticipantReadyToProceed(pstate?: ParticipantReadyState) {
      if (pstate) state.participantReadyToProceed = pstate
      else {
        switch (state.participantReadyToProceed) {
          case ParticipantReadyState.NotReady:
            state.participantReadyToProceed = ParticipantReadyState.Initiated
            break
          case ParticipantReadyState.Initiated:
            state.participantReadyToProceed = ParticipantReadyState.Paired
            break
          case ParticipantReadyState.Paired:
            state.participantReadyToProceed = ParticipantReadyState.ProceedToGame
            break
        }
      }
    },

    resetParticipantReadyToProceed() {
      state.participantReadyToProceed = ParticipantReadyState.NotReady
    },

    setParticipantInformation(value: ParticipantInformation) {
      state.participantInformation = value
    },

    setMultiplayerRole(isLeader: boolean) {
      state.currentRole = isLeader ? UserTaskRole.Leader : UserTaskRole.Advisor
    },

    setStudentTeacherRole(isTeacher: boolean) {
      state.currentRole = isTeacher ? UserTaskRole.Teacher : UserTaskRole.Student
    },

    initLeaderAffixes(leaderAffixes: Record<string, string>) {
      state.leaderAffixes = leaderAffixes // used to set the local value to the one stored in the db (game)
    },

    setLeaderAffixes(affix: string, userId: string) {
      state.leaderAffixes[affix] = userId
    },

    addSTSync(sync: TaskSyncST) {
      state.studentTeacherSync = sync
    },

    setShuffleOrder(ids: string[]) {
      state.shuffleOrder = []
      state.shuffleOrder = ids
    },

    proceedRoundIndex() {
      if (state.mode === GameType.ST) state.studentTeacherSync = undefined // reset once a new round is started
      state.roundIndex++
      state.finalDecisionStatus = FinalDecisionStatus.NotSubmitted
    },

    setAdvisorTaskStatus(status: number) {
      state.advisorTaskStatus = status
    },

    setIncomingAudio(audio: Blob) {
      state.listeningState = true
      state.currentAudio = audio
    },

    resetListeningState() {
      state.listeningState = false
      state.currentAudio = undefined
    }
  }

  const getters = {
    get drawingCanvas(): ComputedRef<HTMLCanvasElement | undefined> {
      return computed(() => state.drawingCanvas)
    },

    get studentScreen(): ComputedRef<Blob | undefined> {
      return computed(() => state.studentScreen)
    },

    get listeningState(): ComputedRef<boolean> {
      return computed(() => state.listeningState)
    },

    get currentAudio(): ComputedRef<Blob | undefined> {
      return computed(() => state.currentAudio)
    },

    get studentTeacherSync(): ComputedRef<TaskSyncST | undefined> {
      return computed(() => state.studentTeacherSync)
    },

    get mode(): ComputedRef<GameType> {
      return computed(() => state.mode)
    },

    get currentRole(): ComputedRef<UserTaskRole> {
      return computed(() => state.currentRole)
    },

    get leaderAffixes(): ComputedRef<Record<string, string>> {
      return computed(() => state.leaderAffixes)
    },

    get finalDecisionStatus(): ComputedRef<FinalDecisionStatus> {
      return computed(() => state.finalDecisionStatus)
    },

    get participantReady(): ComputedRef<ParticipantReadyState> {
      return computed(() => state.participantReady)
    },

    get participantReadyToProceed(): ComputedRef<ParticipantReadyState> {
      return computed(() => state.participantReadyToProceed)
    },

    get participantInformation(): ComputedRef<ParticipantInformation> {
      return computed(() => state.participantInformation)
    },

    get syncs(): ComputedRef<TaskSync[]> {
      return computed(() => {
        const syncs = state.syncs.get(state.roundIndex)
        return syncs ? syncs : []
      })
    },

    get allSyncs(): ComputedRef<Map<number, TaskSync[]>> {
      return computed(() => {
        return state.syncs
      })
    },

    get firstDecision(): ComputedRef<TaskSync | undefined> {
      return computed(() => {
        return state.syncs
          .get(state.roundIndex)
          ?.find((sync) => sync.type === SyncType.FirstDecision)
      })
    },

    get advice(): ComputedRef<TaskSync | undefined> {
      return computed(() => {
        return state.syncs.get(state.roundIndex)?.find((sync) => sync.type === SyncType.Advice)
      })
    },

    get finalDecision(): ComputedRef<TaskSync | undefined> {
      return computed(() => {
        return state.syncs
          .get(state.roundIndex)
          ?.find((sync) => sync.type === SyncType.FinalDecision)
      })
    },

    get phase(): ComputedRef<SyncType> {
      return computed(() => {
        if (!this.firstDecision.value) return SyncType.FirstDecision
        if (!this.advice.value) return SyncType.Advice
        if (!this.finalDecision.value) return SyncType.FinalDecision
        return SyncType.Pairing
      })
    },

    get shuffleOrder(): ComputedRef<string[]> {
      return computed(() => state.shuffleOrder)
    },

    get actionAllowed(): ComputedRef<boolean> {
      return computed(() => {
        const condition =
          ([UserTaskRole.Leader, UserTaskRole.Student].some((role) => role === state.currentRole) &&
            [SyncType.FirstDecision, SyncType.FinalDecision].some(
              (type) => type === this.phase.value
            )) ||
          ([UserTaskRole.Advisor, UserTaskRole.Teacher].some(
            (role) => role === state.currentRole
          ) &&
            this.phase.value === SyncType.Advice)
        return condition
      })
    },

    get roundIndex(): ComputedRef<number> {
      return computed(() => state.roundIndex)
    },

    get advisorTaskStatus(): ComputedRef<number> {
      return computed(() => state.advisorTaskStatus)
    }
  }

  const state = _state

  return { state, actions, getters }
}

export default useMultiPlayerState
